import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  FormControl,
  Autocomplete,
  OutlinedInput,
  TextField,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { GET, POST } from "../../Utils/ApiClient";
import { data } from "../../uiData/UIData";
import DropdownFuelType from "./DropdownFuelType";
import moment from "moment";
import { modalStyle } from "../../baseStyles";
import { getCurrentTime } from "../../Utils/FuncUtils";
import BasicDatePicker from "./DatePicker";
import { TIME_ZONE } from "../../Utils/EnvUtils";

export default function ModalAddHeat({ isOpen, onModalCloseHandler }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [open, setOpen] = React.useState(isOpen);
  const [useElectricity, setUseElectricity] = useState(null);
  const [transactionDate, setTransactionDate] = useState(getCurrentTime());
  const [useFuel, setUseFuel] = useState(null);
  const [fuel, setFuel] = useState({ fuelTypeId: 1, fuelType: "LPG(Kg)" });

  const [fuels, setFuels] = useState([]);
  useEffect(() => {
    fetchFuelType();
    return () => {
      setFuel(null);
      setFuels([]);
      setTransactionDate("");
      setUseFuel(null);
      setUseElectricity(null);
      setOpen(false);
    };
  }, []);

  async function fetchFuelType() {
    try {
      const res = await GET(`Building/fuelType`);
      const { data, status } = res;
      setFuels(data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    return () => {
      setUseElectricity(0);
      setUseFuel(0);
      setOpen(false);
    };
  }, []);

  function handleClose() {
    setOpen(false);
    onModalCloseHandler(null);
  }

  function onSubmit(formData) {
    const responsePayload = {
      fuelTypeId: fuel,
      transactionDate,
      useElectricity,
      useFuel,
    };
    onModalCloseHandler(responsePayload);
    setOpen(false);
  }

  function onSelectFuelHandler(e) {
    setFuel(e.target.value);
  }

  function handleOnDatePickerChanged(type, newDate) {
    const formattedDate = moment
      .tz(newDate, TIME_ZONE.AsiaBangkok)
      .format("yyyy-MM-DD");
    setTransactionDate(formattedDate);
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-heat-modal-title"
        aria-describedby="add-heat-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="add-heat-modal-title" variant="h6" component="h2">
            เพิ่มรายการการใช้พลังงาน
          </Typography>
          <Box sx={{ mt: 3 }}>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Box display={`flex`} flexDirection={`column`}>
                {/* Transaction Date */}
                <Box>
                  <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
                    <BasicDatePicker date={transactionDate} 
                      onSetNewDate={(newDate) => handleOnDatePickerChanged("", newDate)}
                      register={register(`transactionDate`, {
                        required: "กรุณากรอกข้อมูลให้ครบถ้วน",
                      })} 
                      name={`transactionDate`}
                      id={`transactionDate`}
                    />
                    {errors[`transactionDate`] && (
                      <FormHelperText
                        id={`outlined-transactionDate-helper-text`}
                      >
                        {errors[`transactionDate`]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                {/* USGE ELECTRICITY */}
                <Box>
                  <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
                    <OutlinedInput
                      variant={`outlined`}
                      type={`number`}
                      {...register(`useElectricity`, {
                        required: "กรุณากรอกข้อมูลให้ครบถ้วน",
                      })}
                      name={`useElectricity`}
                      id={`useElectricity`}
                      placeholder={`การใช้พลังงานไฟฟ้า`}
                      sx={{ background: "#FFFFFF", borderRadius: 2 }}
                      value={useElectricity}
                      onChange={(e) => setUseElectricity(e.target.value)}
                      inputProps={{
                        readOnly: false,
                      }}
                    />
                    {errors[`useElectricity`] && (
                      <FormHelperText
                        id={`outlined-useElectricity-helper-text`}
                      >
                        {errors[`useElectricity`]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>

                {/* BOTTOM FORM GROUP */}
                <Box
                  display={`flex`}
                  justifyContent={`space-between`}
                  flexDirection={`row`}
                >
                  {/* USGE FUEL */}
                  <Box display={`flex`} sx={{ mt: 1, mr: 2, width: "100%" }}>
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <OutlinedInput
                        variant={`outlined`}
                        type={`number`}
                        {...register(`useFuel`, {
                          required: "กรุณากรอกข้อมูลให้ครบถ้วน",
                        })}
                        name={`useFuel`}
                        id={`useFuel`}
                        placeholder={`การใช้เชื้อเพลิง`}
                        sx={{ background: "#FFFFFF", borderRadius: 2, width: "100%"  }}
                        value={useFuel}
                        onChange={(e) => setUseFuel(e.target.value)}
                        inputProps={{
                          readOnly: false,
                        }}
                      />
                      {errors[`useFuel`] && (
                        <FormHelperText id={`outlined-useFuel-helper-text`}>
                          {errors[`useFuel`]?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                  {/* FUEL TYPE */}
                  {/* TODO: Add required validation */}
                  <Box display={`flex`} sx={{ mt: 1, width: "100%" }}>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        {...register(`fuelType`, {
                          required: "กรุณากรอกข้อมูลให้ครบถ้วน",
                        })}
                        labelId="fuel-dropdown-label"
                        name="fuelType"
                        id="fuel-dropdown"
                        value={fuel}
                        onChange={onSelectFuelHandler}
                        defaultValue=""
                      >
                        <MenuItem value={fuel} selected={true}>
                          <em>เลือกชนิดเชื้อเพลิง</em>
                        </MenuItem>
                        {fuels &&
                          fuels.map((item, index) => (
                            <MenuItem key={index} value={item.fuelTypeId}>
                              {item.fuelType}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    {errors[`fuelType`] && (
                      <FormHelperText id={`outlined-fuelType-helper-text`}>
                        {errors[`fuelType`]?.message}
                      </FormHelperText>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`end`}
                sx={{ mt: 3 }}
              >
                <Box display={`flex`} sx={{ mr: 2 }}>
                  <Button
                    type={`submit`}
                    variant={`contained`}
                    sx={{
                      bgcolor: "#25A18E",
                      borderColor: "#25A18E",
                      ":hover": {
                        borderColor: "#777",
                        bgcolor: "#777",
                      },
                    }}
                  >
                    ตกลง
                  </Button>
                </Box>
                <Box display={`flex`}>
                  <Button variant={`outlined`} onClick={handleClose}>
                    ยกลิก
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
