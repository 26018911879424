import React from "react";
import { ENERGY_USE_TYPE } from "../../Utils/EnvUtils";
import "./PrintPage1.css";
import { ReportEnergyUsePage } from "./ReportEnergyUsePage";
import ReportControlBuildingEnergyUse from "./Prints/ReportControlBuildingEnergyUse";
const PrintPage1 = React.forwardRef((props, ref) => {
  const {
    energySummary,
    selectedYear,
    selectedMonth,
    selectedDay,
    selectedTimeType,
    reportType,
    energySummaryArray,
    sunburstData,
  } = props;
  return (
    <React.Fragment>
      <div ref={ref}>
        {reportType == ENERGY_USE_TYPE.ALL && energySummary && (
          <ReportEnergyUsePage
            energySummary={energySummary}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            selectedDay={selectedDay}
            selectedTimeType={selectedTimeType}
            reportType={ENERGY_USE_TYPE.ALL}
          />
        )}

        {reportType == ENERGY_USE_TYPE.BY_BUILDING &&
          energySummaryArray &&
          energySummaryArray.length > 0 &&
          energySummaryArray.map((item, index) => (
            <ReportEnergyUsePage
              key={index}
              energySummary={item}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              selectedDay={selectedDay}
              selectedTimeType={selectedTimeType}
              reportType={ENERGY_USE_TYPE.BY_BUILDING}
              loopingIndex={index}
              countArray={energySummaryArray.length}
            />
          ))}

        {reportType == ENERGY_USE_TYPE.CONTROL && (
          <div>
            {sunburstData && sunburstData.length > 0 && sunburstData.map((item,index) => (
              <ReportControlBuildingEnergyUse
                sunburstData={item}
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                selectedDay={selectedDay}
                selectedTimeType={selectedTimeType}
                loopingIndex={index}
                countArray={sunburstData.length}
              />
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
});

export default PrintPage1;
