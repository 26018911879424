import React, { useEffect, useRef } from "react";
import { scaleLinear, scaleTime, extent } from "d3";
import { AxisBottom } from "./AxisBottom";
import { AxisLeft } from "./AxisLeft";
import { Marks } from "./Marks";
import moment from "moment";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { v4 as uuidv4 } from "uuid";
import { X_AXIS_TIME_TYPE } from "../../../../../Utils/EnvUtils";
import * as d3 from 'd3'


const width = 0.82 * window.screen.width;
const height = 500;
const margin = { top: 30, right: 30, bottom: 65, left: 90 };
const xAxisLabelOffset = 50;
const yAxisLabelOffset = 60;

export const D3MultipleLineChart = ({ chartData, unit, timeType }) => {

  const chartRef = useRef()
  if (!chartData && chartData.length > 0) {
    return <pre>Loading...</pre>;
  }

  useEffect(() => {
    if(chartData && chartData.length > 0) {
      var div = document.getElementById('multiple-line-chart').innerHTML;
      if(!div) {
        renderMultiChart(chartData)
      }
    }
    return () => {
      const div = document.getElementById('multiple-line-chart');
      if(div) document.getElementById('multiple-line-chart').innerHTML = "";
    };
  }, [chartData]);

  
  function getColor(index) {
    switch (index) {
      case 0:
        return `green`;
        break;
      case 1:
        return `red`;
        break;
      case 2:
        return `blue`;
        break;
      default:
        return `black`;
        break;
    }
  }

  function renderMultiChart(chartData) {
    let data = [];

    chartData.map((item, index) => {
      data.push({
        name: index,
        values: item
      })
    })
    var width = window.screen.width * 0.75;
    var margin = 60;
    var duration = 250;

    var lineOpacity = "0.25";
    var lineOpacityHover = "0.85";
    var otherLinesOpacityHover = "0.1";
    var lineStroke = "1.5px";
    var lineStrokeHover = "2.5px";

    var circleOpacity = "0.85";
    var circleOpacityOnLineHover = "0.25";
    var circleRadius = 3;
    var circleRadiusHover = 6;

    /* Format Data */
    data.forEach(function(d) {
      d.values.forEach(function(d) {
        return  d;
      });
    });
    /* Scale */
    var xScale = d3
      .scaleTime()
      .domain(d3.extent(data[0].values, d => d.timestamp))
      .range([0, width - margin])
      .nice();
    // find max value in array
    
    var theArray = [];
    data.map((item, index) => {
      item.values.map((k, j) => {
        var e = k.electricityUse;
        theArray.push(e)
      })
    })
    var maxInArray = Math.max(...theArray)


    var yScale = d3
      .scaleLinear()
      .domain([0,maxInArray])
      .range([height - margin, 0]);

    // var color = d3.scaleOrdinal(d3.schemeCategory10);

    /* Add SVG */
    var svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin + "px")
      .attr("height", height + margin + "px")
      .style('font-family', 'Kanit')
      .append("g")
      .attr("transform", `translate(${margin}, ${margin})`);

    /* Add line into SVG */
    var line = d3
      .line()
      .x(d => xScale(d.timestamp))
      .y(d => yScale(d.electricityUse));

    let lines = svg.append("g").attr("class", "lines");

    lines
      .selectAll(".line-group")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "line-group")
      .append("path")
      .attr("class", "line")
      .attr("d", d => line(d.values))
      .style("stroke", (d, i) => getColor(i))
      .style("opacity", lineOpacity)

    /* Add circles in the line */
    lines
      .selectAll("circle-group")
      .data(data)
      .enter()
      .append("g")
      .style("fill", (d, i) => getColor(i))
      .selectAll("circle")
      .data(d => d.values)
      .enter()
      .append("g")
      .attr("class", "circle")
      .append("circle")
      .attr("cx", d => xScale(d.timestamp))
      .attr("cy", d => yScale(d.electricityUse))
      .attr("r", circleRadius)
      .style("opacity", circleOpacity)
      .on("mouseover", function(d) {
        d3.select(this)
          .transition()
          .duration(duration)
          .attr("r", circleRadiusHover);
      })
      .on("mouseout", function(d) {
        d3.select(this)
          .transition()
          .duration(duration)
          .attr("r", circleRadius);
      });

    /* Add Axis into SVG */
    var xAxis = d3.axisBottom(xScale).ticks(15).tickFormat((val, index) => {
      let xValue;
      if(timeType == X_AXIS_TIME_TYPE.TIME) {
        xValue = moment(val).format("HH");
      } else if (timeType == X_AXIS_TIME_TYPE.DAY) {
        xValue = moment(val).format("DD");
      } else {
        xValue = format(val, "MMM", { locale: th })
      }
      return xValue;
    });

    var yAxis = d3.axisLeft(yScale)
    .ticks(5);

    svg
      .append("g")
      .attr("class", "x-axis")
      .style('font-family','Kanit')
      .style('font-size', 15)
      .attr("transform", `translate(0, ${height - margin})`)
      .call(xAxis);

    svg
      .append("g")
      .style('font-family', 'Kanit')
      .style("font-size", 15)
      .attr("class", "y-axis")
      .call(yAxis)
      .append("text")      
      .attr("y", 15)
      .attr("transform", "translate(0,-30) rotate(0)")
      .attr("fill", "#000")
      .text(`${unit}`);
  }


  return <>{chartData && chartData.length > 0 && <div ref={chartRef} id="multiple-line-chart" />}</>;
};
