import React, { useState, useEffect } from 'react'
import {
  Container, Box, Grid, Typography, Tabs, Tab, Alert
} from '@mui/material'
import { BASE } from '../../theme/BaseStyle';
import BEMLogoWhite from '../../assets/images/BEMLogoWhite.svg'
import RegisterBuilding from '../../assets/images/RegisterBuilding.svg'
import { UIData } from './../../uiData';
import GeneralForm from './GeneralForm';
import BuildingForm from './BuildingForm';
import { AuthUtils } from '../../Utils';
import { REGISTER_TYPE } from '../../Utils/EnvUtils';
import { ifTokenExistAndValidThenContinueUsingApp } from '../../Utils/AuthUtils';

function TabPanel({ children, currentTab, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={currentTab !== index}
      id={`register-tabpanel-${index}`}
      aria-labelledby={`register-form-tab-${index}`}
      {...other}
    >
      {currentTab === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}
export default function RegisterPage() {
  const [currentTab, setCurrentTab] = useState(0);
  const { data } = UIData;
  const [message, setMessage] = useState("")
  const [alert, setAlert] = useState(false)
  const [severity, setSeverity] = useState("error")

  useEffect(() => {
    ifTokenExistAndValidThenContinueUsingApp();
  }, [])

  function registerTabProps(index) {
    return {
      id: `register-form-tab-${index}`,
      'aria-controls': `register-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  function onAlertHandler(alert, message, severity) {
    setAlert(alert);
    setMessage(message);
    setSeverity(setSeverity)
    setTimeout(() => {
      setAlert(false)
    }, 3000);
  }
  return (
    <>
      {alert && <Alert sx={{ position: 'fixed', width: '100%' }} severity={severity}>{message} </Alert>}
      <Box sx={{ backgroundColor: BASE.registerBackgroundColor, width: "auto" }}>
        <Container sx={{ pt: 5, pb: 10 }}>
          {/* Grid Header */}
          <Grid container spacing={1}>
            <Grid item xs={4} sm={4} lg={4} xl={4}>
              <Box sx={{ pt: 10 }}>
                <img sx={{ width: '100%' }} src={BEMLogoWhite} alt='logo white' />
              </Box>
              <Box>
                <Typography sx={{ color: BASE.white, width: '60%', pt: 2, fontSize: BASE.regular }} varaint="p">
                  โครงการพัฒนาเทคโนโลยีดิจิทัลพลังงาน
                  เพื่อเพิ่มประสิทธิภาพการใช้พลังงาน
                  ตามกฏหมายในภาคอาคารธุรกิจตามแผนพัฒนา
                  Electronics Monitoring ในอาคารควบคุม
                </Typography>
              </Box>
              <Box sx={{ mt: 10, mb: 2 }}>
                <Typography
                  varaint="p"
                  sx={{ width: 'auto' }}
                  variant="contained"
                  sx={{ background: BASE.white, color: 'black', fontSize: BASE.regular, pt: 2, pb: 2, pr: 10, pl: 10, borderRadius: 2 }}
                >
                  เลือกประเภทการลงทะเบียน
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 1 }}>
                  <Tabs value={currentTab} onChange={handleTabChange} aria-label="basic tabs example">
                    {data && data.buttons.map((item, index) => (
                      <Tab key={index} label={
                        <Box key={item.id} className={currentTab == index ? 'selected-tab' : ''} sx={{pl: 1}}>
                          <Box 
                            className={currentTab == index}
                            // className={currentTab == index ? 'selected-tab' : ''}
                            sx={{ borderRadius: 2, mt: 2, p: 0, justifyContent: 'center', cursor: 'pointer' }}                      >
                            <img src={currentTab == index ? item.iconNormal : item.icon } sx={{ width: '100%', height: '100%' }} alt="icon" />
                          </Box>
                          <Typography className={currentTab == index ? 'selected-tab-text' : 'text-white'} sx={{pt: 1 }}>
                            {item.wording}
                          </Typography>
                        </Box>
                      } {...registerTabProps(0)} />
                    ))}
                  </Tabs>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={8} sm={8} lg={8} xl={8}>
              <Box>
                <img sx={{ height: '100%', width: '50%' }} src={RegisterBuilding} alt='logo white' />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 10, pb: 2, borderRadius: 2, backgroundColor: '#E0E2DB' }}>
            <Grid item xs={12}>
              <Box sx={{
                pt: 2,
                pr: 5,
                pb: 2,
                pl: 5,
                mb: 1
              }}>
                <Typography sx={{ fontSize: 20, color: '#25A18E' }}>
                  ข้อมูลการลงทะเบียน
                </Typography>
                <TabPanel currentTab={currentTab} index={0}>
                  <GeneralForm onAlertHandler={onAlertHandler} registerType={REGISTER_TYPE.General} />
                </TabPanel>
                <TabPanel currentTab={currentTab} index={1}>
                  <BuildingForm onAlertHandler={onAlertHandler} />
                </TabPanel>
                <TabPanel currentTab={currentTab} index={2}>
                  <GeneralForm  onAlertHandler={onAlertHandler} registerType={REGISTER_TYPE.Officer} />
                </TabPanel>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

