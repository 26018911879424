import React, { useState, useEffect } from "react";
import AlertNotification from "../Common/AlertNotification";
import {
  ALERT_DISMISS_TIME,
  GRID_PAGE_SIZE,
  HTTP_STATUS,
} from "../../Utils/EnvUtils";
import { NOTIFICATION_MESSAGE } from "../../Utils/Messages";
import { GET, GET_NO_AUTH } from "../../Utils/ApiClient";
import { Box } from "@mui/system";
import SupplySideTable from "./SupplySideTable";
import { Typography } from "@mui/material";
import AHUTable from "./AHUTable";
import CHPSystem from "./CHPSystem";
import Chiller from "./Chiller";
import MuiTablePagination from "../Common/MuiTablePagination";
import ChillerPlant from "./ChillerPlant";
import { data } from "../../uiData/UIData";
import { modifiedLineChartDataTimePeriod } from "../../Utils/FuncUtils";
import PaginationSquared from "../Common/PaginationSquared";

export default function CentralAirSystem({
  activePill,
  selectedYear,
  selectedMonth,
  selectedDay,
  buildingInfo,
  selectedTimeType,
}) {
  const { supplySideCategories } = data;
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [supplySide, setSupplySide] = useState();
  const [supplySideCategory, setSupplySideCategory] = useState(
    supplySideCategories[0]
  );

  const [supplySides, setSupplySides] = useState([]);
  const [ahuList, setAhuList] = useState([]);
  const [ahuData, setAhuData] = useState();
  const [chillerPlantElectricity, setChillerPlantElectricity] = useState();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(GRID_PAGE_SIZE);
  const [total, setTotal] = useState();

  const [pageNumberAhu, setPageNumberAhu] = useState(1);
  const [pageSizeAhu, setPageSizeAhu] = useState(GRID_PAGE_SIZE);
  const [totalAhu, setTotalAhu] = useState();
  const [chillerList, setChillerList] = useState();
  const [chillerLineChartData, setChillerLineChartData] = useState();
  const [chillerLineChartDataUnit, setChillerLineChartDataUnit] = useState();
  const [chillerPlantRefrigerant, setChillerPlantRefrigerant] = useState();
  const [chillerPlantAhu, setChillerPlantAhu] = useState();

  const [chillerPlantElectricityUse, setChillerPlantElectricityUse] =
    useState();

  useEffect(() => {
    //selectedPlant
    return () => {};
  }, []);

  useEffect(() => {
    fetchSupplySide();
    return () => {};
  }, [pageNumber, pageSize, selectedYear, selectedMonth, selectedDay]);

  useEffect(() => {
    if (supplySide && supplySide.plantNo) {
      fetchAhu();
    }
    return () => {};
  }, [supplySide, selectedYear, selectedMonth, selectedDay]);

  useEffect(() => {
    if (supplySideCategory && supplySide) {
      if (supplySideCategory.id === 1) {
        fetchBarChartData();
        fetchChillerPlantRefrigeration();
        fetchChillerLineChartData();
        fetchChillerPlantAhu();
      } else if (supplySideCategory.id === 2) {
        fetchChillerList();
        fetchChillerLineChartData();
      } else if (supplySideCategory.id === 3) {
        fetchChillerPlantElectricityUse();
      }
    }
    return () => {};
  }, [
    supplySideCategory,
    supplySide,
    selectedMonth,
    selectedDay,
    selectedYear,
  ]);

  async function fetchChillerLineChartData() {
    try {
      if (!buildingInfo && !supplySide) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const res = await GET(
        `dashboard/central/chillerPlant/chillerElectricity/${buildingId}/${supplySide.plantNo}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        let modifiedGraphData = [];
        if (data && data.data && data.data.length > 0) {
          modifiedGraphData = modifiedLineChartDataTimePeriod(
            data,
            selectedYear,
            selectedMonth,
            selectedDay
          );
          setChillerLineChartData(modifiedGraphData);
          setChillerLineChartDataUnit(data.unit);
        }
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchSupplySide() {
    // GET:/dashboard/central/plant/{buildingId}/{year}/{month}/{day}?pageSize=10&pageNumber=1
    try {
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const res = await GET(
        `dashboard/central/plant/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}?pageSize=${pageSize}&pageNumber=${pageNumber}`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setSupplySides(data);
        const totalRows = data.length > 0 ? data[0].total : 1;
        const currentFaction = totalRows / pageSize;
        setTotal(Math.ceil(currentFaction));
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchChillerPlantRefrigeration() {
    // GET:/dashboard/central/chillerPlant/refrigeration/{buildingId}/{plantNo}/{year}/{month}/{day}
    try {
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const plantNo = supplySide ? supplySide.plantNo : 0;
      const res = await GET(
        `dashboard/central/chillerPlant/refrigeration/${buildingId}/${plantNo}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );

      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setChillerPlantRefrigerant(data);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchChillerPlantAhu() {
    // GET:/dashboard/central/chillerPlant/ahuElectricity/{buildingId}/{plantNo}/{year}/{month}/{day}
    try {
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const plantNo = supplySide ? supplySide.plantNo : 0;
      const res = await GET(
        `dashboard/central/chillerPlant/ahuElectricity/${buildingId}/${plantNo}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );

      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setChillerPlantAhu(data);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchChillerPlantElectricityUse() {
    // GET:/dashboard/central/chillerPlant/electricityuse/{buildingId}/{plantNo}/{year}/{month}/{day}
    try {
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const plantNo = supplySide ? supplySide.plantNo : 0;
      const res = await GET(
        `dashboard/central/chillerPlant/electricityuse/${buildingId}/${plantNo}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );

      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setChillerPlantElectricityUse(data);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchBarChartData() {
    // GET:/dashboard/central/chillerPlant/electricity/{buildingId}/{plantNo}/{year}/{month}/{day}
    try {
      if (!supplySide && !buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const plantNo = supplySide && supplySide.plantNo;
      const res = await GET(
        `dashboard/central/chillerPlant/electricity/${buildingId}/${plantNo}/${selectedYear}/${selectedMonth}/${selectedDay}?pageSize=${pageSize}&pageNumber=${pageNumber}`
      );

      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setChillerPlantElectricity(data);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }
  async function fetchAhu() {
    // GET:/dashboard/central/plant/{buildingId}/{year}/{month}/{day}?pageSize=10&pageNumber=1
    try {
      if (!supplySide && !buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const plantNo = supplySide && supplySide.plantNo;
      const res = await GET(
        `dashboard/central/ahu/${buildingId}/${plantNo}/${selectedYear}/${selectedMonth}/${selectedDay}?pageSize=${pageSizeAhu}&pageNumber=${pageNumberAhu}`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setAhuList(data);
        // const singleRecord = data[0];
        // setAhuData(singleRecord);
        const totalRows = data.length > 0 ? data[0].total : 1;
        const currentFaction = totalRows / pageSize;
        setTotalAhu(Math.ceil(currentFaction));
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchChillerList() {
    // GET:/dashboard/central/plant/{buildingId}/{year}/{month}/{day}?pageSize=10&pageNumber=1
    try {
      if (!supplySide && !buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const plantNo = supplySide && supplySide.plantNo;
      const res = await GET(
        `dashboard/central/chillerPlantInfo/${buildingId}/${plantNo}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setChillerList(data);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  function handleOnSelectSupplySideCategory(item) {
    setSupplySideCategory(item);
  }

  function handleOnSelectSupplySide(item) {
    setSupplySide(item);
  }
  // function handleChangePage(event, newPage) {
  //   // let actuallyPage = 1;
  //   // if (newPage < 1) {
  //   //   actuallyPage = 1;
  //   // } else {
  //   //   actuallyPage = newPage;
  //   // }
  //   // console.log('page number :', actuallyPage);
  //   console.log('new page : ', newPage);
  //   setPageNumber(newPage);
  // }

  // function handleChangeRowsPerPage(event) {
  //   const rpp = event.target.value;
  //   setPageSize(rpp);
  // }

  // function handleChangeAHUPage(event, newPage) {
  //   console.log("on change page : ", event);
  // }

  // function handleChangeAHURowsPerPage(event) {
  //   console.info("handle change rows per page : ", event.target.value);
  // }

  function onSupplySidePageChangeHandler(event, pageNumber) {
    setPageNumber(pageNumber);
  }

  function onAhuPageChangeHandler(event, pageNumber) {
    setPageNumberAhu(pageNumber);
  }

  return (
    <div>
      {/* <AlertNotification alert={alert} severity={severity} message={message} /> */}
      <Box display={`flex`} flexDirection={`column`}>
        <Box
          display={`flex`}
          flexDirection={`row`}
          justifyContent={`space-between`}
        >
          {/* BOX#1 */}
          <Box
            className="box-wrapper"
            sx={{ mt: 1 }}
            display={`flex`}
            flex={1}
            flexDirection={`column`}
          >
            <Box display={`flex`}>
              <Typography className="subtitle-header">Supply Side</Typography>
            </Box>
            {supplySides && (
              <SupplySideTable
                supplySideData={supplySides}
                supplySideSingle={supplySide}
                onSelectSupplySideCategory={handleOnSelectSupplySideCategory}
                onSelectSupplySide={handleOnSelectSupplySide}
                buildingInfo={buildingInfo}
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                selectedDay={selectedDay}
              />
            )}
            {/* <MuiTablePagination
                currentPage={pageNumber}
                currentRowsPerPage={5}
                totalCount={total}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              /> */}
            <Box display={`flex`} alignSelf={`end`} sx={{ mt: 5, mr: 5 }}>
              {supplySides && supplySides.length > 0 && (
                <PaginationSquared
                  page={pageNumber}
                  count={total}
                  onPageChange={onSupplySidePageChangeHandler}
                />
              )}
            </Box>
          </Box>

          {/* BOX#2 */}
          <Box
            className="box-wrapper"
            sx={{ mt: 1 }}
            display={`flex`}
            flex={1}
            flexDirection={`column`}
          >
            <Box display={`flex`}>
              <Typography className="subtitle-header">AHU</Typography>
            </Box>
            {ahuList && (
              <AHUTable
                ahuData={ahuList}
                buildingInfo={buildingInfo}
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                selectedDay={selectedDay}
              />
            )}
            {/* {ahuList && ahuList.length > 0 && (
              <MuiTablePagination
                currentPage={1}
                currentRowsPerPage={1}
                totalCount={100}
                handleChangePage={handleChangeAHUPage}
                handleChangeRowsPerPage={handleChangeAHURowsPerPage}
              />
            )} */}

            <Box display={`flex`} alignSelf={`end`} sx={{ mt: 5, mr: 5 }}>
              {ahuList && ahuList.length > 0 && (
                <PaginationSquared
                  page={pageNumberAhu}
                  count={totalAhu}
                  onPageChange={onAhuPageChangeHandler}
                />
              )}
            </Box>
          </Box>
        </Box>

        {/* BOX#4 */}
        {/* FOR CHP SYSTEM */}
        {supplySideCategory &&
          supplySideCategory.id == 1 &&
          chillerPlantElectricity && (
            <ChillerPlant
              buildingInfo={buildingInfo}
              selectedPlant={supplySide.plantNo}
              chillerPlantElectricity={chillerPlantElectricity}
              chillerLineChartData={chillerLineChartData}
              chillerPlantRefrigerant={chillerPlantRefrigerant}
              chillerPlantAhu={chillerPlantAhu}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              selectedDay={selectedDay}
              selectedTimeType={selectedTimeType}
            />
          )}
        {supplySideCategory && supplySide && supplySideCategory.id == 2 && (
          <Chiller
            chillerList={chillerList}
            selectedPlantNo={supplySide.plantNo}
            chillerPlantGraph={chillerLineChartData}
            chillerGraphUnit={chillerLineChartDataUnit}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            selectedDay={selectedDay}
            selectedTimeType={selectedTimeType}
          />
        )}
        {supplySideCategory &&
          supplySide &&
          supplySideCategory.id == 3 &&
          chillerPlantElectricityUse && (
            <CHPSystem
              buildingInfo={buildingInfo}
              chpSystemData={chillerPlantElectricityUse}
              selectedPlantNo={supplySide.plantNo}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              selectedDay={selectedDay}
              selectedTimeType={selectedTimeType}
            />
          )}
      </Box>
    </div>
  );
}
