import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Container,
  Button,
  TextField,
} from "@mui/material";
import Layout from "../../layouts/Layout";
import {
  ALERT_DISMISS_TIME,
  HTTP_STATUS,
  DIALOG_TYPE,
  TIME_ZONE,
  LAUNCH_STATUS_FEATURE,
  X_AXIS_TIME_TYPE,
} from "../../Utils/EnvUtils";
import SearchIcon from "@mui/icons-material/Search";

import "./../../App.css";
import { NOTIFICATION_MESSAGE } from "../../Utils/Messages";
import AlertNotification from "../Common/AlertNotification";
import { GET } from "../../Utils/ApiClient";
import SearchResultList from "../Common/SearchResultList";
import { data } from "../../uiData/UIData";
import DropdownMonthPill from "../Common/DropdownMonthPill";
import DropdownDayPill from "../Common/DropdownDayPill";
import DropdownYearPill from "../Common/DropdownYearPill";
import AccumElectricityUse from "../Home/AccumElectricityUse";
import AccumHeatUse from "../Home/AccumHeatUse";
import AlternativeEnergyPercent from "../Home/AlternativeEnergyPercent";
import Overview from "./Overview";
import CentralAirSystem from "./CentralAirSystem";
import ZoneEnergyUse from "./ZoneEnergyUse";
import LightingSystem from "./LigtingSystem";
import EnergyGenerateSystem from "./EnergyGenerateSystem";
import OtherSystem from "./OtherSystem";
import moment from "moment";

const isReadyToLaunch = LAUNCH_STATUS_FEATURE.DASHBOARD;
export default function DashboardPage() {
  const { buildingEachSystems, lineGraphPills } = data;
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [searchPhrase, setSearchPhrase] = useState();
  const [buildingInfoList, setBuildingInfoList] = useState();
  const [buildingInfo, setBuildingInfo] = useState();
  const [activePill, setActivePill] = useState(buildingEachSystems[0]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedDay, setSelectedDay] = useState(
    moment(new Date()).format("D")
  );
  const [buildingEnergyUse, setBuildingEnergyUse] = useState();
  // const [buildingEnergyUseByPeriod, setBuildingEnergyUseByPeriod] = useState()
  const [buildingEnergyRatio, setBuildingEnergyRatio] = useState();
  const [buildingSummaryEnergyUse, setBuildingSummaryEnergyUse] = useState();
  const [selectedTimeType, setSelectedTimeType] = useState(
    X_AXIS_TIME_TYPE.TIME
  );

  useEffect(() => {
    return () => {
      setMessage();
      setAlert(false);
      setSeverity("info");
      setSearchPhrase();
      setBuildingInfoList([]);
      setBuildingInfo();
      setActivePill(buildingEachSystems[0]);
    };
  }, []);

  useEffect(() => {
    if (
      searchPhrase &&
      searchPhrase != "" &&
      buildingInfoList &&
      searchPhrase != (buildingInfo && buildingInfo.buildingControlName)
    ) {
      fetchBuildingInfo();
    } else {
      if (buildingInfoList && buildingInfoList.length > 0) {
        setBuildingInfoList([]);
      }
    }
  }, [searchPhrase]);

  useEffect(() => {
    if (activePill && activePill.systemTypeId == 0) {
      fetchOverviewBuildingEnergyUse();
      fetchOverviewBuildingEnergyUseRatio();
      fetchSummaryEnergyUse();
    }
    return () => {};
  }, [buildingInfo, activePill, selectedYear, selectedMonth, selectedDay]);

  async function fetchBuildingInfo() {
    try {
      const res = await GET(
        `building${!searchPhrase || searchPhrase == "" ? "" : `?search=`}${
          searchPhrase || ""
        }`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setBuildingInfoList(data);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchOverviewBuildingEnergyUse() {
    // GET: /dashboard/overview/{buildingId}/{year}/{mongh}/{day}
    try {
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const res = await GET(
        `dashboard/overview/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setBuildingEnergyUse(data);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchSummaryEnergyUse() {
    // GET: /dashboard/overview/{buildingId}/{year}/{mongh}/{day}
    try {
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const res = await GET(
        `dashboard/electricitySystem/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = res;
      if (status == HTTP_STATUS.OK) {
        setBuildingSummaryEnergyUse(data);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchOverviewBuildingEnergyUseRatio() {
    // GET: /dashboard/overview/{buildingId}/{year}/{mongh}/{day}
    try {
      if (!buildingInfo) return;
      // const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0; // mock
      const res = await GET(
        `dashboard/electricitySystemRatio/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setBuildingEnergyRatio(data);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  function handleOnSearchPhraseChanging(e) {
    e.preventDefault();
    const text = e.target.value;
    setSearchPhrase(text);
  }

  function hitEnter(e) {
    if (e.key === "Enter") {
      fetchBuildingInfo();
    }
  }

  function onSearchHandle() {
    fetchBuildingInfo();
  }

  function onBuildingSelectedHandler(item) {
    setBuildingInfo(item);
    setSearchPhrase(item.buildingControlName);
    setBuildingInfoList([]);
    setActivePill(buildingEachSystems[0]);
  }

  function onSelectYearHandler(year) {
    setSelectedYear(year);
    const daysInMonth = moment(
      new Date(`${year}-${selectedMonth}-01`)
    ).daysInMonth();
    if (selectedDay <= daysInMonth) {
      setSelectedDay(selectedDay);
    } else {
      const d = moment(new Date(`${year}-${selectedMonth}-${daysInMonth}`)).format(
        "D"
      );
      setSelectedDay(d);
    }
  }
  
  function onSelectMonthHandler(e) {
    const theMonth = e < 0 ? parseInt(e) : parseInt(e) + 1;
    if (theMonth < 0) {
      setSelectedDay(-1);
      // show 12 months
      setSelectedTimeType(X_AXIS_TIME_TYPE.MONTH);
    } else if (selectedDay < 0) {
      setSelectedTimeType(X_AXIS_TIME_TYPE.DAY);
    } else {
      setSelectedTimeType(X_AXIS_TIME_TYPE.TIME);
      const daysInMonth = moment(new Date(`${selectedYear}-${theMonth}-01`)).daysInMonth();
      // if(theMonth === 2) {
      //   if(selectedDay <= daysInMonth) {
      //     setSelectedDay(selectedDay);
      //   } else {
      //     const d = moment(new Date(`${selectedYear}-${theMonth}-${daysInMonth}`)).format("D");
      //     setSelectedDay(
      //       d
      //     );
      //   }        
      // } else {
      //   setSelectedDay(selectedDay);
      // }
      if (selectedDay <= daysInMonth) {
        setSelectedDay(selectedDay);
      } else {
        const d = moment(new Date(`${selectedYear}-${theMonth}-${daysInMonth}`)).format(
          "D"
        );
        setSelectedDay(d);
      }

    }
    setSelectedMonth(theMonth);
  }

  function onSelectDayHandler(e) {
    if (e < 0) {
      setSelectedDay(e);
      // show 31 days
      setSelectedTimeType(X_AXIS_TIME_TYPE.DAY);
    } else {
      setSelectedTimeType(X_AXIS_TIME_TYPE.TIME);
    }
    setSelectedDay(e);
  }
  return (
    <>
      <Layout>
        <AlertNotification
          alert={alert}
          severity={severity}
          message={message}
        />

        {!isReadyToLaunch && (
          <Box style={{ marginTop: "35px", textAlign: "center" }}>
            อยู่ระหว่างการพัฒนา
          </Box>
        )}

        {/* START : DASHBOAD */}
        {isReadyToLaunch && (
          <Box sx={{ bgcolor: "rgb(37, 161, 142, .2)" }}>
            {/* CONTAINER */}
            <Container maxWidth={`2xl`}>
              <Box display={`flex`} flexDirection={`column`}>
                {/* SEARCH FORM */}
                <Box display={`flex`}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <OutlinedInput
                      id="outlined-adornment-search"
                      value={searchPhrase ? searchPhrase : ""}
                      onChange={(e) => handleOnSearchPhraseChanging(e)}
                      onKeyDown={(e) => hitEnter(e)}
                      placeholder="ค้นหา"
                      startAdornment={
                        <InputAdornment
                          position="start"
                          sx={{ cursor: "pointer" }}
                          onClick={onSearchHandle}
                        >
                          <SearchIcon />
                        </InputAdornment>
                      }
                      sx={{ bgcolor: "#FFFFFF", borderRadius: "10px" }}
                    />
                  </FormControl>
                </Box>
                {/* END SEARCH FORM */}

                {/* SEARCH RESULT LIST */}
                <Box display={`flex`}>
                  {buildingInfoList && (
                    <SearchResultList
                      list={buildingInfoList}
                      onSelectHandler={onBuildingSelectedHandler}
                    />
                  )}
                </Box>
                {/* END SEARCH RESULT LIST */}

                {/* START PILL */}
                <Box
                  sx={{
                    p: 1,
                    mt: 1,
                    display: "flex",
                    flexWrap: "nowrap",
                    overflowY: "hidden",
                  }}
                  display={`flex`}
                >
                  {buildingEachSystems.map((item, index) => (
                    <Button
                      display={`flex`}
                      key={index}
                      disabled={!buildingInfo && item.systemTypeId != 0}
                      variant={
                        activePill &&
                        item.systemTypeId == activePill.systemTypeId
                          ? `contained`
                          : `outlined`
                      }
                      className={
                        activePill &&
                        item.systemTypeId == activePill.systemTypeId
                          ? `custom-contained`
                          : `custom-outlined`
                      }
                      size="large"
                      sx={{
                        width: "200",
                        ml: 1,
                        mr: 1,
                        minWidth: "220px",
                        maxWidth: "250px",
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        setActivePill(item);
                        // setSearchPhrase("");
                      }}
                    >
                      <Typography>{item.systemTypeName}</Typography>
                    </Button>
                  ))}
                </Box>
                {/* END PILL */}

                {/* TOP Y M D combobox */}
                <Box
                  className="box-wrapper"
                  sx={{ mt: 1 }}
                  display={`flex`}
                  flexDirection={`column`}
                  justifyContent={`start`}
                >
                  <Box display={`flex`}>
                    <div className="subtitle-header">
                      เลือกช่วงเวลาของข้อมูล
                    </div>
                  </Box>
                  <Box display={`flex`}>
                    <Box sx={{ mt: 1, mr: 1 }}>
                      <DropdownYearPill onSelectHandler={onSelectYearHandler} />
                    </Box>
                    <Box sx={{ mt: 1, mr: 1 }}>
                      <DropdownMonthPill
                        onSelectHandler={onSelectMonthHandler}
                      />
                    </Box>
                    <Box sx={{ mt: 1, mr: 1 }}>
                      <DropdownDayPill
                        onSelectHandler={onSelectDayHandler}
                        selectedMonth={selectedMonth}
                        initDate={selectedDay}
                        selectedYear={selectedYear}
                      />
                    </Box>
                  </Box>
                </Box>

                {/* OVERVIEW PAGE */}
                {activePill && activePill.id == 0 && (
                  <Overview
                    activePill={activePill}
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    selectedDay={selectedDay}
                    selectedTimeType={selectedTimeType}
                    buildingInfo={buildingInfo}
                    buildingEnergyRatio={buildingEnergyRatio}
                    buildingSummaryEnergyUse={buildingSummaryEnergyUse}
                  />
                )}

                {/* Central Air system PAGE */}
                {activePill && activePill.id == 1 && (
                  <CentralAirSystem
                    activePill={activePill}
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    selectedDay={selectedDay}
                    selectedTimeType={selectedTimeType}
                    buildingInfo={buildingInfo}
                  />
                )}

                {activePill && activePill.id == 2 && (
                  <ZoneEnergyUse
                    buildingInfo={buildingInfo}
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    selectedDay={selectedDay}
                    selectedTimeType={selectedTimeType}
                  />
                )}

                {activePill && activePill.id == 3 && buildingInfo && (
                  <LightingSystem
                    buildingInfo={buildingInfo}
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    selectedDay={selectedDay}
                    selectedTimeType={selectedTimeType}
                  />
                )}

                {activePill && activePill.id == 4 && (
                  <EnergyGenerateSystem
                    buildingInfo={buildingInfo}
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    selectedDay={selectedDay}
                    selectedTimeType={selectedTimeType}
                  />
                )}

                {activePill && activePill.id == 5 && (
                  <OtherSystem
                    buildingInfo={buildingInfo}
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    selectedDay={selectedDay}
                    selectedTimeType={selectedTimeType}
                  />
                )}
              </Box>
            </Container>
          </Box>
        )}
      </Layout>
    </>
  );
}
