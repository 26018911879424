import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, {  } from "react";
import { data } from "../../uiData/UIData";
import NoDataToShow from "../Common/NoDataToShow";

export default function AHUTable({
  ahuData,
  buildingInfo,
  selectedYear,
  selectedMonth,
  selectedDay,
}) {
  const { mockAHUData } = data;
  return (
    <>
      <Box display={`flex`} flexDirection={`column`}>
        <table
          className="table"
          style={{ background: '#FFFFFF'}}
        >
          <thead className="ahu-table-header">
            <tr className="row">
              <th className="col"></th>
              <th className="col" colSpan={`3`}>
                <Typography className="text-primary">
                  ข้อมูล ณ ปัจจุบัน จากเครื่องวัด
                </Typography>
              </th>
              <th className="col"></th>
            </tr>
            <tr className="row-ahu">
              <th className="col-ahu" colSpan={0.5}>
                <Typography fontSize={`sm`}>No</Typography>
              </th>
              <th className="col-ahu">
                <Typography fontSize={`sm`}>Power(kW)</Typography>
              </th>
              <th className="col-ahu">
                <Typography fontSize={`sm`}>
                  Temperature Returned (Celcius)
                </Typography>
              </th>
              <th className="col-ahu">
                <Typography fontSize={`sm`}>Set Point (Celcius)</Typography>
              </th>
              <th className="col-ahu" style={{alignSelf: 'start', justifyContent: 'start',  paddingLeft: '5%'}}>
                <Typography fontSize={`sm`} style={{ paddingLeft: '10%'}}>Hz</Typography>
              </th>
              <th>
                <Typography fontSize={`sm`} style={{ marginRight: '15px'}}>Sum Energy/Day (kWh)</Typography>
              </th>
            </tr>
          </thead>
          <tbody style={{
            maxHeight: "300px",
            overflowY: "scroll",
            background: "#FFFFFF",
          }}>
            {ahuData && ahuData.length > 0 ? (
              ahuData.map((item, index) => (
                <tr key={index} className="row-ahu">
                  <td className="col-ahu">{item.ahuNo}</td>
                  <td className="col-ahu">{item.power || "-"}</td>
                  <td className="col-ahu">{item.tempReturn || "-"}</td>
                  <td className="col-ahu">{item.setPoint || "-"}</td>
                  <td className="col-ahu">{item.frequency || "-"}</td>
                  <td className="col-ahu">{item.sumEnergy || "-"}</td>
                </tr>
              ))
            ) : (
              <NoDataToShow />
            )}
          </tbody>
        </table>
      </Box>
    </>
  );
}
