
import { Box } from '@mui/system';
import React from 'react';
import D3SunburstChart from '../../Common/d3/Sunburst/D3SunburstChart';
import Exporter from '../Exporter';

export default function Report1() {
  return <>
      <Exporter>
        <Box style={{width: '21cm', height: '29.7cm'}} display={`flex`} justifyContent={`center`} alignItems={`center`}><D3SunburstChart /></Box>
      </Exporter>
  </>;
}

