import { X_AXIS_TIME_TYPE } from "../../../Utils/EnvUtils";
import { findTick } from "../../../Utils/FuncUtils" 
export const AxisBottomReport = ({
  xScale,
  innerHeight,
  tickFormat,
  tickOffset = 3,
  timeType
}) =>
  xScale.ticks(findTick(timeType)).map((tickValue, i) => (
    <g
      className="tick"
      key={tickValue}
      transform={`translate(${xScale(tickValue)},0)`}
    >
      <line y2={innerHeight} />
      <text
        style={{ textAnchor: "middle" }}
        dy=".71em"
        fontSize={`7px`}
        y={innerHeight + tickOffset}
      >
        {tickFormat(tickValue)}
      </text>
    </g>
  ));
