import millify from "millify";
import moment from "moment";
import numeral from "numeral";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { X_AXIS_TIME_TYPE } from "../../../Utils/EnvUtils";
import { BarChartWidth, ChartWidth } from "../../../Utils/FuncUtils";
import './Bar.css';

export default function Bar({ chartData, selectedTimeType }) {
  const [chart, setChart] = useState({
    options: {
      legend: {
        show: true,
        position: 'top'
      },
      noData: {
        text: "No data to show ...",
      },
      chart: {
        toolbar: { show: false },
        offsetX: 50,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        // categories: chartData.data.map((d) => d.xaxis),
        title: {
          text: 'เวลา',
          style: {
            fontFamily: 'Kanit',
            fontSize: '16px',
            fontWeight: 'light'
          }
        },
      },
      yaxis: {
        labels: {
          show: true,
          offsetX: -10,
          align: "center",
          style: {
            cssClass: 'custom-y-axis-label'
          },
          formatter: (e) => numeral(e).format('0,0')
        },
        axisTicks: {
          show: true,
        },
      },
    },

    series: [
      {
        data: chartData.data.map((d) => d.yaxis),
      },
    ],
  });

  useEffect(() => {
    const srs = [{ data: chartData.data.map((d) => d.yaxis) }];
    setChart({ series: srs,options: { xaxis: {
      categories:  chartData.data.map((d) => {
        if(selectedTimeType == X_AXIS_TIME_TYPE.TIME) {
          const year = new Date().getFullYear();
          const month = new Date().getMonth();          
          const hh = moment(new Date(`${year}-${month}-01 ${d.xaxis}`)).format('HH');
          return hh;
        } else {
          return d.xaxis;
        }
      })
    } } });
    return () => {};
  }, [chartData, selectedTimeType]);

  return (
    <>
      <div className="bar">
        {chartData && chart && (
          <Chart
            options={chart.options}
            series={chart.series}
            type="bar"
            width={BarChartWidth()}
            height={500}
          />
        )}
      </div>
    </>
  );
}
