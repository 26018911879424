import React, { useEffect, useRef } from "react";
import './D3PieChart.css'
import * as d3 from "d3";

const D3PieChart = (props) => {
  const ref = useRef(null);
  const createPie = d3
    .pie()
    .value((d) => d.value)
    .sort(null);
  const createArc = d3
    .arc()
    .innerRadius(props.innerRadius)
    .outerRadius(props.outerRadius);
  const colors = d3.scaleOrdinal(d3.schemeCategory10);
  const format = d3.format(".0f");

  useEffect(() => {
    const data = createPie(props.data);
    const group = d3.select(ref.current);
    const groupWithData = group.selectAll("g.arc").data(data);

    groupWithData.exit().remove();

    groupWithData
      .style('cursor', 'pointer')
      .on("mouseover", function (d, val) {
        // document.getElementById("tooltip").classList.remove("hidden");
        d3.select("#tooltip")
          .style("left", d.pageX + "px")
          .style("top", d.pageY + "px")
          .style("opacity", 1)
          .select("#pie-tooltip-value")
          .text(`${val.data.value}%`);

        // document.getElementById(
        //   "#pie-tooltip-value"
        // ).innerHTML = `${val.data.value}%`;
      })
      .on("mouseout", function () {
        // Hide the tooltip
        d3.select("#tooltip").style("opacity", 0);
        // document.getElementById("tooltip").classList.add("hidden");
      });

    const groupWithUpdate = groupWithData
      .enter()
      .append("g")
      .attr("class", "arc");

    const path = groupWithUpdate
      .append("path")
      .merge(groupWithData.select("path.arc"));

    path
      .attr("class", "arc")
      .attr("d", createArc)
      .attr("fill", (d, i) => colors(i));

    const text = groupWithUpdate
      .append("text")
      .merge(groupWithData.select("text"));

    text
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .attr("transform", (d) => `translate(${createArc.centroid(d)})`)
      .style("fill", "white")
      .style("font-size", 15)
      .text((d) => `${d.value}%`);
  }, [props.data]);

  return (
    <>
      <div id="tooltip" className="hidden-tooltip">
        <span id="pie-tooltip-value"></span>
      </div>
      <svg width={props.width} height={props.height}>
        <g
          ref={ref}
          transform={`translate(${props.outerRadius} ${props.outerRadius})`}
        />
      </svg>
    </>
  );
};

export default D3PieChart;
