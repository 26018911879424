import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { addYears } from "date-fns/esm";

export default function TargetGrid({ data, selectedDate }) {
  const navigate = useNavigate();
  const [localYear, setLocalYear] = useState();
  const [localMonth, setLocalMonth] = useState();
  useEffect(() => {
    // const currentDate = new Date();
    if(selectedDate) {
      const formattedMonth = format(selectedDate, "MMMM", { locale: th });
      const formattedYear = format(addYears(selectedDate, 543), "yyyy", {
        locale: th,
      });
      setLocalMonth(formattedMonth);
      setLocalYear(formattedYear);
    }
   
  }, [selectedDate]);
  
  function goToAddEnergyTarget() {
    navigate(`add-target`);
  }
  return (
    <>
      <Box
        display={`flex`}
        flex={1}
        flexDirection={`column`}
        className="box-wrapper"
        flexGrow={1}
        sx={{ bgcolor: "rgb(37, 161, 142,.2)" }}
      >
        {/* HEADER BOX */}
        <Box display={`flex`} flexDirection={`row`}>
          <Typography className="subtitle-header" display={`flex`} sx={{ color: "rgb(37,161,142)" }}>
            ภาพรวมดัชนีการใช้พลังงานตามประเภทอาคารในเดือน
          </Typography>
          <Typography className="subtitle-header-black" display={`flex`} sx={{ color: "rgb(0,0,0)", ml: 2 }}>
            ( {localMonth} , {localYear} )
          </Typography>

          <Box display={`flex`} sx={{ ml: 2 }}>
            <button
              className={`button-graph-select`}
              onClick={goToAddEnergyTarget}
            >
              <Typography sx={{ pr: 2, pl: 2 }}>
                กดเพื่อตั้งค่าเป้าหมาย
              </Typography>
            </button>
          </Box>
        </Box>

        {/* ARRAY HERE */}
        <Box display={`flex`} flexDirection={`column`}>
          {data &&
            data.map((item, index) => (
              <Box
                key={index}
                display={`flex`}
                flexDirection={`row`}
                sx={{ mt: 1 }}
              >
                <Box display={`flex`} className="input-active" flex={2}>
                  {item.buildingTypeName} {item.buildingTypeUnit}
                </Box>
                <Box
                  display={`flex`}
                  className="input-active-secondary"
                  flexDirection={`column`}
                  justifyContent={`center`}
                  alignItems={`center`}
                  sx={{ color: "#000000", ml: 1 }}
                  flex={1}
                >
                  <Box display={`flex`}>
                    <Typography sx={{ fontSize: "9px" }}>
                      เป้าหมาย SEC ที่ถูกกำหนด
                    </Typography>
                  </Box>
                  <Box display={`flex`}>{item.secTarget}</Box>
                </Box>

                <Box
                  display={`flex`}
                  className="input-active-terterry"
                  flexDirection={`column`}
                  justifyContent={`center`}
                  alignItems={`center`}
                  sx={{ color: "#000000", ml: 1 }}
                  flex={1}
                >
                  <Box display={`flex`}>
                    <Typography sx={{ fontSize: "9px" }}>
                      SEC เฉลี่ยของเดือน
                    </Typography>
                  </Box>
                  <Box display={`flex`}>{item.secAverage}</Box>
                </Box>

                <Box
                  display={`flex`}
                  className="input-active-terterry"
                  flexDirection={`column`}
                  justifyContent={`center`}
                  alignItems={`center`}
                  sx={{ color: "#000000", ml: 1 }}
                  flex={1}
                >
                  <Box display={`flex`}>
                    <Typography sx={{ fontSize: "9px" }}>
                      SEC สูงสุดของเดือน
                    </Typography>
                  </Box>
                  <Box display={`flex`}>{item.secMax}</Box>
                </Box>

                <Box
                  display={`flex`}
                  className="input-active-terterry"
                  flexDirection={`column`}
                  justifyContent={`center`}
                  alignItems={`center`}
                  sx={{ color: "#000000", ml: 1 }}
                  flex={1}
                >
                  <Box display={`flex`}>
                    <Typography sx={{ fontSize: "9px" }}>
                      SEC ต่ำสุดของเดือน
                    </Typography>
                  </Box>
                  <Box display={`flex`}>{item.secMin}</Box>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </>
  );
}
