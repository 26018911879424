import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { ENV } from './Utils/EnvUtils'  
const initialState = {};

// list of middleware
const middleware = [thunk];

const store =
  ENV === "PROD"
    ? createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware))
      )
    : createStore(
        rootReducer,
        initialState,
        compose(
          applyMiddleware(...middleware),
          window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      );

export default store;
