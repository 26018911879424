import { Box, FormControl, Select, MenuItem } from "@mui/material";
import React from "react";

export default function RoleCombobox({
  handleSelectChange,
  userTypes,
  selected,
  defaultValue
}) {
  return (
    <>
      <Box sx={{ width: "100%", mt: 1, ml: 1 }}>
        <FormControl fullWidth>
          <Select
            labelId="user-group-select-label"
            id="user-group-select"
            defaultValue={defaultValue}
            value={selected || defaultValue}
            onChange={handleSelectChange}
          >
            {userTypes &&
              userTypes.map((item, index) => (
                <MenuItem key={index} value={item.roleId}>
                  {item.roleName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
