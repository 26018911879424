import React from "react";
import { Box } from "@mui/system";
import { Container } from "@mui/material";

export default function GeneralLayoutLight({ children }) {
  return (
    <>
      <Container>
        <Box sx={{ background: "#FFFFFF", height: "100%" }}>{children}</Box>
      </Container>
    </>
  );
}
