import { Container, FormControl, OutlinedInput, Typography, InputAdornment, Autocomplete, TextField, Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState, useEffect } from 'react'
import Layout from '../../layouts/Layout'
import { ApiClient } from '../../Utils';
import PaginationSquared from "../Common/PaginationSquared";
import DeleteIcon from '@mui/icons-material/Delete';
import { data } from '../../uiData/UIData';
import { FuncUtils,Messages } from '../../Utils';
import ImageIcon from "../../assets/images/icons/ImageIcon.svg";
import { DELETE, PATCH } from '../../Utils/ApiClient';
import AlertNotification from "../Common/AlertNotification";
import { ALERT_DISMISS_TIME, HTTP_STATUS, DIALOG_TYPE, TIME_ZONE } from "../../Utils/EnvUtils";
import DropdownFuelType from '../Common/DropdownFuelType';
import ModalAddHeat from '../Common/ModalAddHeat';
import AlertDialog from "../Common/AlertDialog";

import { PlugIcon, FrameIcon } from './../../assets'
import './BuildingInfo.css'
import moment from 'moment';
import 'moment-timezone';
import BasicDatePicker from '../Common/DatePicker';
const MY_PAGE_SIZE = 7;

export default function BuildingInfoPage() {
  const { toBase64 } = FuncUtils;
  const { OK } = HTTP_STATUS;
  const { NOTIFICATION_MESSAGE } = Messages;
  const { buildingCategories } = data;
  const { GET, POST } = ApiClient;
  const [searchPhrase, setSearchPhrase] = useState(null)
  const [buildingInfoList, setBuildingInfoList] = useState(null)
  const [selected, setSelected] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [currentSelectedBuilding, setCurrentSelectedBuilding] = useState(null)
  const [buildingInfo, setBuildingInfo] = useState(null)
  const [buildingTransformer, setBuildingTransformer] = useState(null)
  const [base64Str, setBase64Str] = useState("")
  const [buildingHeatConsumption, setBuildingHeatConsumption] = useState()
  const [buildingHeatConsumptionList, setBuildingHeatConsumptionList] = useState([])
  const [message, setMessage] = useState("")
  const [alert, setAlert] = useState(false)
  const [severity, setSeverity] = useState("error")
  const [currentSelectedBuildingType, setCurrentSelectedBuildingType] = useState()
  const [filterHeatYear, setFilterHeatYear] = useState((new Date()).getFullYear())
  const [filterHeatMonth, setFilterHeatMonth] = useState(((new Date()).getMonth() + 1))
  const [filterHeatDay, setFilterHeatDay] = useState((new Date()).getDate())
  const [heatUse, setHeatUse] = useState(0)
  const [fuelTypeUse, setFuelTypeUse] = useState(0)
  const [fuelUse, setFuelUse] = useState(0)
  const [isOpenAddHeatModal, setIsOpenAddHeatModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState()
  // pagination heat grid
  const [currentPageHeatGrid, setCurrentPageHeatGrid] = useState(1)
  const [currentCountHeatGrid, setCurrentCountHeatGrid] = useState(1)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [dialogType, setDialogType] = useState(0) // 0 confirm
  const [priority, setPriority] = useState("info")
  const [currentDeletingHeatConsumption, setCurrentDeletingHeatConsumption] = useState()
  const [selectedDateFrom, setSelectedDateFrom] = useState(moment().startOf('month').format('yyyy-MM-DD'));
  const [selectedDateTo, setSelectedDateTo] = useState(moment.tz(new Date(), TIME_ZONE.AsiaBangkok).format('yyyy-MM-DD'));


  // todo: teardown with useEffect
  useEffect(() => {
    return () => {
      setIsOpenAddHeatModal(false)
      setBuildingHeatConsumption(null)
      setCurrentSelectedBuilding(null)
    }
  }, [])

  useEffect(() => {
    fetchBuildingHeatConsumptionGrid()
  }, [currentPageHeatGrid])

  useEffect(() => {
    if (confirmDelete) {
      deleteHeatConsumption(currentDeletingHeatConsumption);
    }
    return () => {
      setConfirmDelete(false);
      setCurrentDeletingHeatConsumption(null)
    }
  }, [confirmDelete])

  useEffect(() => {
    if (!selectedFile) {
      return
    }
    const base64Promise = toBase64(selectedFile);
    base64Promise.then((str) => {
      setBase64Str(str)
      patchBuildingImage(str);
    })

    // free memory when ever this component is unmounted
  }, [selectedFile])

  useEffect(() => {
    const delayDebounceFn =  setTimeout(() => {
      searchBuilding();
    }, 500);
    return () => {
      setBuildingInfo(null)
      setBuildingTransformer([])
      setBuildingHeatConsumption(null)
      setBuildingHeatConsumptionList([])
      clearTimeout(delayDebounceFn)
    }

  }, [searchPhrase])

  useEffect(() => {
    fectchBuildingInfo();
    fetchBuildingTransformer();
    fetchBuildingSummaryUsageOfElectricity();
    fetchBuildingHeatConsumptionGrid();
    return () => {
      setBuildingInfo(null)
      setBuildingTransformer(null)
    }
  }, [currentSelectedBuilding])

  useEffect(() => {
    const delayDebounceFn =  setTimeout(() => {
      fetchBuildingHeatConsumptionGrid();
      fetchBuildingSummaryUsageOfElectricity();
    }, 500);
    return () => clearTimeout(delayDebounceFn)

  }, [selectedDateFrom, selectedDateTo, heatUse, fuelTypeUse, fuelUse])

  async function patchBuildingImage(str) {
    try {
      if (!currentSelectedBuilding) return;
      const payload = {
        fileType: 'jpg',
        image: str
      };
      const { buildingId } = currentSelectedBuilding;
      const res = await PATCH(`building/${buildingId}/Image`, payload);
      const { data, status } = res;
      if (status == OK) {
        const { message, success } = data;
        setMessage(message || NOTIFICATION_MESSAGE.UPDATE_SUCCESS)
        setAlert(true)
        if (success) {
          setSeverity("success")
          fectchBuildingInfo();
        } else {
          setSeverity("error")
        }
        setTimeout(() => {
          setAlert(false)
        }, ALERT_DISMISS_TIME);
      }
    } catch (error) {
      console.log(error);
      setMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setAlert(true)
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }

  async function onSelectFile(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  function onChangeHandle(e) {
    setSearchPhrase(e.target.value);
  }
  async function searchBuilding() {
    try {
      const res =   await GET(`building${!searchPhrase || searchPhrase == "" ? "" : `?search=`}${searchPhrase || ""}`);
      const { data } = res;
      const { message, success } = data;
      setBuildingInfoList(data)
    } catch (error) {
      console.log(error);
      setAlert(true)
      setMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }
  async function onSelectHandle(event, value) {
    // call api
    try {
      if(value) {
        setCurrentSelectedBuilding(value);
        // set building type 
        const buildingCat = buildingCategories.find(x => x.buildingTypeId == value.buildingTypeId);
        setCurrentSelectedBuildingType(buildingCat)
      }
    } catch (error) {
      console.log(error);
      setAlert(true)
      setMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }
  async function fectchBuildingInfo() {
    try {
      if (!currentSelectedBuilding) return;
      const { buildingId } = currentSelectedBuilding;
      const res = await GET(`building/${buildingId}`)
      const { data } = res;
      setBuildingInfo(data)
      const { buildingImage } = data;
      setBase64Str(buildingImage);
    } catch (error) {
      console.log(error);
      setAlert(true)
      setMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }
  async function fetchBuildingTransformer() {
    try {
      if (!currentSelectedBuilding) return;
      const { buildingId } = currentSelectedBuilding;
      const res = await GET(`building/${buildingId}/transformer`)
      const { data } = res;
      setBuildingTransformer(data)
    } catch (error) {
      console.log(error);
      setAlert(true)
      setMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }
  async function fetchBuildingSummaryUsageOfElectricity() {
    try {
      if (!currentSelectedBuilding) return;
      const { buildingId } = currentSelectedBuilding;
      const payload = {
        fromDate: selectedDateFrom,
        toDate: selectedDateTo
      }
      const res = await POST(`building/${buildingId}/summaryUseElectricityAndHeat`, payload)
      const { data } = res;
      setBuildingHeatConsumption(data)
    } catch (error) {
      console.log(error);
      setAlert(true)
      setMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }
  async function fetchBuildingHeatConsumptionGrid() {
    try {
      if (!currentSelectedBuilding) return;
      const { buildingId } = currentSelectedBuilding;
      // harded code according to requirement
      const pageSize = MY_PAGE_SIZE;
      let pageNumber = currentPageHeatGrid;
      if (heatUse || fuelUse) {
        pageNumber = 1;
      }
      const payload = {
        pageNumber: pageNumber, pageSize: pageSize,
        fromDate: selectedDateFrom, toDate: selectedDateTo
      }
      const res = await POST(`building/${buildingId}/getUseElectricityAndHeat`, payload)
      const { data } = res;
      const total = data.length > 0 ? data[0].total : 1;
      const currentFaction = (total / pageSize);
      setCurrentCountHeatGrid(Math.ceil(currentFaction))
      setBuildingHeatConsumptionList(data)
    } catch (error) {
      console.log(error);
      setAlert(true)
      setMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }
  async function deleteHeatConsumption(item) {
    try {
      if (!currentSelectedBuilding) return;
      const { buildingId } = currentSelectedBuilding;
      const { electricityAndHeatId } = item;
      const res = await deleteHeatConsumption(buildingId, electricityAndHeatId)
      const { data } = res;
      const { message, success } = data;
      setMessage(message)
      setAlert(true)
      if (success) {
        setSeverity("success")
        fetchBuildingHeatConsumptionGrid();
      } else {
        setSeverity("error")
      }
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      console.log(error);
      setAlert(true)
      setMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }

  function onChangeHeatHandler(e) {
    setHeatUse(e.target.value)
  }

  function onChangeFuelHandler(e) {
    setFuelUse(e.target.value)
  }

  function onSelectFuelTypeHandler(fuelTypeObject) {
    setFuelTypeUse(fuelTypeObject);
  }


  function onOpenAddHeatModal() {
    setIsOpenAddHeatModal(true)
  }

  async function onModalCloseHandler(payload) {
    setIsOpenAddHeatModal(close);
    try {
      if (!payload) {
        return;
      }
      const { buildingId } = currentSelectedBuilding;
      const res = await POST(`building/${buildingId}/useElectricityAndHeat`, payload)
      const { data, status } = res;
      const { success, message } = data;
      if (status == OK) {
        const { message, success } = data;
        if (success) {
          setMessage(message)
          setSeverity("success")
          setAlert(true)
          fetchBuildingHeatConsumptionGrid()
          fetchBuildingSummaryUsageOfElectricity();
        } else {
          setMessage(message)
          setSeverity("error")
          setAlert(true)
        }
        setTimeout(() => {
          setAlert(false)
        }, ALERT_DISMISS_TIME);
      }
    } catch (error) {
      console.log(error);
      setMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setAlert(true)
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }

  function onHeatGridPageChangeHandle(e, pageNumber) {
    setCurrentPageHeatGrid(pageNumber);
  }

  function handleOnAutoCompleteFocus(event) {
    event.preventDefault();
    searchBuilding();
  }

  function onCloseDialogHandler(isOpenDialog, isConfirm) {
    setIsOpenDialog(false)
    if (dialogType == DIALOG_TYPE.DELETE) {
      setConfirmDelete(isConfirm)
    }
    if (dialogType == DIALOG_TYPE.SAVE) {
      setConfirmSave(isConfirm)
    }
  }

  function OpenDialogConfirmDelete(heat) {
    setCurrentDeletingHeatConsumption(heat)
    setIsOpenDialog(true);
    setDialogType(DIALOG_TYPE.DELETE);
    setPriority('info');
    // <AlertDialog isOpenDialog={isOpenDialog} dialogType={dialogType} priority={priority} onCloseDialogHandler={onCloseDialogHandler} params={params} />
  }

  function handleOnDatePickerChanged(type, newDate) {
    const formattedDate = moment
      .tz(newDate, TIME_ZONE.AsiaBangkok)
      .format("yyyy-MM-DD");
    if (type === "FROM") {
      setSelectedDateFrom(formattedDate);
    } else {
      setSelectedDateTo(formattedDate);
    }
  }
  return (
    <div>
      {isOpenDialog && <AlertDialog isOpenDialog={isOpenDialog} dialogType={dialogType} priority={priority} onCloseDialogHandler={onCloseDialogHandler} />}
      {isOpenAddHeatModal && <ModalAddHeat isOpen={isOpenAddHeatModal} onModalCloseHandler={onModalCloseHandler} />}
      <Layout>
        <AlertNotification alert={alert} severity={severity} message={message} />
        <Container maxWidth="2xl" sx={{ bgcolor: '#FFFFFF' }}>
          <Box sx={{ p: 4, border: '#EAE6E5 1px solid', borderRadius: '5px' }} display={`flex`} flexDirection={`column`}>
            <Box display={`flex`} sx={{ width: '100%' }} flexDirection={`column`}>

              <FormControl
                variant='outlined'
                sx={{ mt: 2, width: '100%' }}
              >
                <Autocomplete
                  options={buildingInfoList || []}
                  getOptionLabel={(option) => option.tsicName}
                  onChange={onSelectHandle}
                  name={`tsicid`}
                  renderInput={(params) => {
                    return (<>
                      <TextField
                        sx={{ background: '#FFFFFF', borderRadius: 2 }}
                        variant={`outlined`}
                        type={`text`}
                        onChange={(e) => onChangeHandle(e)}
                        onFocus={handleOnAutoCompleteFocus}
                        id={`tsicid`}
                        placeholder={`ค้นหาอาคาร`}
                        inputProps={{
                          startAdornment: (<InputAdornment position="start">kg</InputAdornment>)
                        }}
                        {...params}
                      /></>)
                  }}
                >
                </Autocomplete>
              </FormControl>
            </Box>

            <Typography sx={{ fontSize: 20, color: '#25A18E', mt: 5 }}>
              ข้อมูลอาคาร
            </Typography>

            <Box display={`flex`} flexDirection={`row`}>
              {/* LEFT BOX */}
              <Box display={`flex`} flex={1} flexDirection={`column`} sx={{ pt: 1, pb: 1, pr: 2 }}>
                {/* INPUT */}
                <Box display={`flex`} sx={{ mt: 1, mb: 1 }}>
                  <OutlinedInput
                    sx={{ width: '100%' }}
                    inputProps={{
                      "aria-label": `tsicId`,
                    }}
                    name={`tsicId`}
                    id={`input-id-tsicId`}
                    type={`text`}
                    placeholder={`TSIC-ID`}
                    value={buildingInfo ? buildingInfo.tsicId : '-'}
                    readOnly={true}
                  />
                </Box>
                <Box display={`flex`} sx={{ mb: 1 }}>
                  <OutlinedInput
                    sx={{ width: '100%' }}
                    inputProps={{
                      "aria-label": `legalEntityName`,
                    }}
                    name={`legalEntityName`}
                    id={`input-id-legalEntityName`}
                    type={`text`}
                    placeholder={`ชื่อนิติบุคคล`}
                    value={currentSelectedBuilding ? currentSelectedBuilding.legalEntityName : '-'}
                    readOnly={true}
                  />
                </Box>
                <Box display={`flex`} sx={{ mb: 1 }}>
                  <OutlinedInput
                    sx={{ width: '100%' }}
                    inputProps={{
                      "aria-label": `buildingName`,
                    }}
                    name={`buildingName`}
                    id={`input-id-buildingName`}
                    type={`text`}
                    placeholder={`ชื่ออาคาร`}
                    value={buildingInfo ? buildingInfo.buildingName : '-'}
                    readOnly={true}
                  />
                </Box>
                {/* <Box display={`flex`} sx={{ mb: 1 }}>
                  <MyDropzone />
                </Box> */}
                <Box flex={1} sx={{ mb: 1 }} justifyContent={`center`} alignItems={`center`} flexDirection={`column`}>
                  <Box display={`flex`}
                    flex={1}
                    justifyContent={`center`}
                    alignItems={`center`}
                    alignSelf={`center`}
                    sx={{ border: '#EAE6E5 1px dashed', borderRadius: '5px', height: '250px' }}
                  >
                    {base64Str ? (<img src={`${base64Str}`} alt="building image" style={{ maxHeight: '250px' }} />) :
                      (<img src={ImageIcon} alt="placholder" style={{ maxHeight: '250px' }} />)}
                  </Box>
                  {currentSelectedBuilding && <Box display={`flex`} sx={{ mt: 2 }}>
                    <input type="file" className="custom-file-input" onChange={onSelectFile} />
                  </Box>}
                </Box>

              </Box>
              {/* RIGHT BOX */}
              <Box display={`flex`} flex={1} flexDirection={`column`} flexWrap={`nowrap`} sx={{ pt: 1, pb: 1 }}>
                {/* INPUT */}
                <Box display={`flex`} sx={{ mt: 1, mb: 1 }}>
                  <OutlinedInput
                    sx={{ width: '100%' }}
                    inputProps={{
                      "aria-label": `buildingControlName`,
                    }}
                    name={`buildingControlName`}
                    id={`input-id-buildingControlName`}
                    type={`text`}
                    placeholder={`ชื่ออาคารควบคุม`}
                    value={buildingInfo ? buildingInfo.buildingControlName : '-'}
                    readOnly={true}
                  />
                </Box>

                {/* BUTTON GROUP */}
                <Box sx={{ display: 'inline-block', flexWrap: 'nowrap' }}>
                  {buildingCategories && buildingCategories.map((item) => (
                    <Button key={item.id}
                      variant={buildingInfo && item.buildingTypeId == buildingInfo.buildingTypeId ? `contained` : `outlined`}
                      className={buildingInfo && item.buildingTypeId == buildingInfo.buildingTypeId ? `common-buttton-primary` : ''}
                      size="large" sx={{ width: '200', ml: 1, mr: 1, minWidth: '200' }}>
                      {item.pillText}
                    </Button>
                  ))}
                </Box>
                <Box sx={{ border: '#EAE6E5 1px solid', p: 3, mt: 2, borderRadius: '5px' }}>
                  <Box>
                    <Typography sx={{ textAlign: `center`, fontSize: '20px', }}>การใช้ประโยชน์พื้นที่ทั้งหมดอาคาร</Typography>
                  </Box>
                  <Box display={`flex`} justifyContent={`center`} flexDirection={`column`}>
                    {
                      <>
                        {/* TOP BOX */}
                        <Box display={`flex`} justifyContent={`center`}>
                          <Box sx={{ border: '#EAE6E5 1px solid', p: 2, mt: 2, mr: 1, borderRadius: '5px', textAlign: 'center' }}>
                            <Typography sx={{ color: 'rgb(37,161,142)' }}>พื้นที่ปรับอากาศ</Typography>
                            <Typography>{buildingInfo ? buildingInfo.area.airCondition : '-'}</Typography>
                            <Typography>ตารางเมตร</Typography>
                          </Box>
                          <Box sx={{ border: '#EAE6E5 1px solid', p: 2, mt: 2, mr: 1, borderRadius: '5px', textAlign: 'center' }}>
                            <Typography sx={{ color: 'rgb(37,161,142)' }}>พื้นที่ไม่ปรับอากาศ</Typography>
                            <Typography>{buildingInfo ? buildingInfo.area.notAirCondition : '-'}</Typography>
                            <Typography>ตารางเมตร</Typography>
                          </Box>
                          <Box sx={{ border: '#EAE6E5 1px solid', p: 2, mt: 2, mr: 1, borderRadius: '5px', textAlign: 'center' }}>
                            <Typography sx={{ color: 'rgb(37,161,142)' }}>พื้นที่จอดรถ</Typography>
                            <Typography>{buildingInfo ? buildingInfo.area.parking : '-'}</Typography>
                            <Typography>ตารางเมตร</Typography>
                          </Box>
                          <Box sx={{ border: '#EAE6E5 1px solid', p: 2, mt: 2, mr: 1, borderRadius: '5px', textAlign: 'center' }}>
                            <Typography sx={{ color: 'rgb(37,161,142)' }}>รวม</Typography>
                            <Typography>{buildingInfo ? buildingInfo.area.areaTotal : '-'}</Typography>
                            <Typography>ตารางเมตร</Typography>
                          </Box>
                        </Box>

                        <Box display={`flex`} justifyContent={`center`}>
                          <Box sx={{ border: '#EAE6E5 1px solid', p: 2, mt: 2, mr: 1, borderRadius: '5px', textAlign: 'center' }}>
                            <Typography sx={{ color: 'rgb(37,161,142)' }}>เวลาทำงาน</Typography>
                            <Typography>{buildingInfo ? buildingInfo.area.workTime : '-'}</Typography>
                            <Typography>ชั่วโมง/วัน</Typography>
                          </Box>
                          <Box sx={{ border: '#EAE6E5 1px solid', p: 2, mt: 2, mr: 1, borderRadius: '5px', textAlign: 'center' }}>
                            <Typography sx={{ color: 'rgb(37,161,142)' }}>วันทำงาน</Typography>
                            <Typography>{buildingInfo ? buildingInfo.area.workDay : '-'}</Typography>
                            <Typography>วัน/ปี</Typography>
                          </Box>
                          <Box sx={{ border: '#EAE6E5 1px solid', p: 2, mt: 2, mr: 1, borderRadius: '5px', textAlign: 'center' }}>
                            <Typography sx={{ color: 'rgb(37,161,142)' }}>ปีที่เปิดใช้งาน</Typography>
                            <Typography>{buildingInfo ? buildingInfo.area.yearOpen : '-'}</Typography>
                            <Typography>ปี/พ.ศ.</Typography>
                          </Box>
                        </Box>
                      </>
                    }
                  </Box>
                </Box>

              </Box>
            </Box>
          </Box>

          {/* TRANSFORMER GRID */}
          <Box sx={{ p: 5, border: '#EAE6E5 1px solid', borderRadius: '5px', mt: 1 }} display={`flex`} flexDirection={`column`}>
            <Box display={`flex`} sx={{ width: '100%' }} flexDirection={`column`}>
              <Typography sx={{ fontSize: 20, color: '#25A18E', mt: 2 }}>
                ข้อมูลหม้อแปลงไฟฟ้า
              </Typography>
            </Box>

            <Box display={`flex`}>

              <Box varaint="div" flex="1" sx={{ p: 2, mt: 2 }} display={`flex`} flexDirection={`column`}>
                {/* <UserTable tableHeader={`ผู้ใช้งาน`} /> */}
                <Box display={`flex`} flexDirection={`row`} sx={{ color: '#25A18E' }}>
                  <Box display={`flex`} flex={`1`}>No</Box>
                  <Box display={`flex`} flex={`1`}>หมายเลขผู้ใช้ไฟฟ้า</Box>
                  <Box display={`flex`} flex={`1`} justifyContent={`center`}>หมายเลขเครื่องวัดไฟฟ้า</Box>
                  <Box display={`flex`} flex={`1`} justifyContent={`center`}>ประเภทผู้ใช้</Box>
                  <Box display={`flex`} flex={`1`} justifyContent={`center`}>อัตราการใช้ไฟฟ้า</Box>
                  <Box display={`flex`} flex={`1`} justifyContent={`center`}>ขนาด (kVA)</Box>
                  <Box display={`flex`} flex={`1`} justifyContent={`center`}>จำนวน</Box>
                </Box>
                {buildingTransformer && buildingTransformer.length > 0 ? buildingTransformer.map((log, index) => (
                  <Box key={index} display={`flex`} flexDirection={`row`} sx={{ pt: 2 }}>
                    <Box display={`flex`} flex={`1`}>{log.id || '-'}</Box>
                    <Box display={`flex`} flex={`1`}>{log.ownerNumber || '-'}</Box>
                    <Box display={`flex`} flex={`1`} justifyContent={`center`}>{log.transformerNumber || '-'}</Box>
                    <Box display={`flex`} flex={`1`} justifyContent={`center`}>{`${log.type}`}</Box>
                    <Box display={`flex`} flex={`1`} justifyContent={`center`}>{`${log.electricityConsumption}`}</Box>
                    <Box display={`flex`} flex={`1`} justifyContent={`center`}>{`${log.size}`}</Box>
                    <Box display={`flex`} flex={`1`} justifyContent={`center`}>{`${log.quantity}`}</Box>
                  </Box>
                ))
                  : <Box varaint="div" flex="1" sx={{ p: 2, mt: 5 }} display={`flex`} flexDirection={`column`}>
                    <Typography sx={{ mt: 2, mb: 2, color: '#221717', textAlign: 'center' }}>No data to show</Typography>
                  </Box>
                }
                {/* <Box display={`flex`} alignSelf={`end`} sx={{ mt: 5, mr: 5 }}>
                    {dataLog.length > 0 && <PaginationSquared page={currentPage} count={currentCount} onPageChange={onPageChangeHandle} />}
                  </Box> */}
              </Box>

            </Box>
          </Box>

          <Box sx={{ p: 5, border: '#EAE6E5 1px solid', borderRadius: '5px', mt: 1 }} display={`flex`} flexDirection={`column`}>
            <Box display={`flex`} sx={{ width: '100%' }} flexDirection={`column`}>
              <Typography sx={{ fontSize: 20, color: '#25A18E', mt: 2 }}>
                การใช้พลังงานไฟฟ้าและความร้อน
              </Typography>
            </Box>

            <Box display={`flex`}>
              {currentSelectedBuilding && <>
                <Box display={`flex`} flexDirection={`column`} >
                  {/* TOP BUTTON */}
                  <Box display={`flex`} sx={{ m: 1 }}>
                    <Button variant="contained" sx={{
                      bgcolor: "#25A18E",
                      borderColor: "#25A18E",
                      ":hover": {
                        borderColor: "#777",
                        bgcolor: '#777'
                      },
                    }}>  {currentSelectedBuildingType && currentSelectedBuildingType.pillText}</Button>
                  </Box>
                  {/* Bottom */}
                  <Box display={`flex`} flexDirection={`row`}>
                    {/* DROPDOWNS: Y-m-d */}
                    <Box display={`flex`} justifyContent={`start`} flexDirection={`column`}>
                      {/* DROPDOWN FILTER */}
                      <Box display={`flex`}>
                        <Typography display={`flex`} sx={{ color: 'rgb(37,161,142)' }}>เลือกช่วงเวลาของข้อมูล</Typography>
                      </Box>
                      {/* Bottom */}
                      <Box display={`flex`}>
                        <Box display={`flex`}>
                          <BasicDatePicker
                            date={selectedDateFrom}
                            name={`filterDateFrom`}
                            id={`filter-date-from`}
                            onSetNewDate={(newDate) =>
                              handleOnDatePickerChanged("FROM", newDate)
                            }
                          />
                        </Box>
                        <Box display={`flex`} justifyContent={`center`} alignItems={`center`} alignSelf={`center`} sx={{mr: 1}}>-</Box>
                        <Box display={`flex`}>
                          <BasicDatePicker
                            date={selectedDateTo}
                            name={`filterDateTo`}
                            id={`filter-date-to`}
                            onSetNewDate={(newDate) =>
                              handleOnDatePickerChanged("TO", newDate)
                            }
                          />
                        </Box>
                      </Box>
                    </Box>

                    {/* USE HEAT DATA */}
                    <Box display={`flex`} justifyContent={`start`} flexDirection={`column`} sx={{ mr: 1, display: 'none' }}>
                      <Box display={`flex`}>
                        <Typography display={`flex`} sx={{ color: 'rgb(37,161,142)' }}>ข้อมูลการใช้ไฟฟ้า</Typography>
                      </Box>
                      <Box display={`flex`}>
                        <Box display={`flex`} alignItems={`center`}>
                          <Typography sx={{ mr: 1 }}>ปริมาณพลังงานไฟฟ้าที่ใช้ (kWh)</Typography>
                          <TextField
                            sx={{ background: '#FFFFFF', borderRadius: 2, mt: 1 }}
                            variant={`outlined`}
                            type={`number`}
                            onChange={(e) => onChangeHeatHandler(e)}
                            id={`heat`}
                            placeholder={`ปริมาณพลังงานไฟฟ้าที่ใช้ (kWh)`}
                            inputProps={{
                              readOnly: false,
                              min: 0
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>

                    {/* USE FUEL DATA */}
                    <Box display={`flex`} justifyContent={`start`} flexDirection={`column`} sx={{ mr: 1, display: 'none' }}>
                      <Box display={`flex`}>
                        <Typography display={`flex`} sx={{ color: 'rgb(37,161,142)' }}>ข้อมูลการใช้ความร้อน</Typography>
                      </Box>
                      <Box display={`flex`} flexDirection={`row`}>
                        <Box display={`flex`} sx={{ mr: 1 ,minWidth: '200px'}}>
                          <DropdownFuelType onSelectHandler={onSelectFuelTypeHandler} />
                        </Box>
                        <Box display={`flex`} alignItems={`center`}>
                          <Typography sx={{ mr: 1 }}>ปริมาณเชื้อเพลิงที่ใช้</Typography>
                          <TextField
                            sx={{ background: '#FFFFFF', borderRadius: 2, mt: 1 }}
                            variant={`outlined`}
                            type={`number`}
                            onChange={(e) => onChangeFuelHandler(e)}
                            id={`fuel`}
                            placeholder={`ปริมาณพลังงานเชื้อเพลงที่ใช้`}
                            inputProps={{
                              readOnly: false,
                              min: 0
                            }}
                          />
                        </Box>

                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>}
            </Box>

            <Box display={`flex`} flexDirection={`row`}>
              {/* LEFT HEAT GRID */}
              <Box display={`flex`} flex={2} flexDirection={`column`}>
                {/* HEAT GRID */}
                <Box display={`flex`} sx={{ pl: 2, border: '#EAE6E5 1px solid', borderRadius: '5px', mt: 1 }}>
                  <Box varaint="div" flex={1} sx={{ p: 1, mt: 2 }} display={`flex`} flexDirection={`column`}>
                    {/* <UserTable tableHeader={`ผู้ใช้งาน`} /> */}
                    <Box display={`flex`} flexDirection={`row`} sx={{ color: '#25A18E', textAlign: 'center' }} c>
                      <Box display={`flex`} flex={`1`}>No</Box>
                      <Box display={`flex`} flex={`1`}>วัน/เดือน/ปี</Box>
                      <Box display={`flex`} flex={`1`} justifyContent={`center`}>พลังงานที่ใช้ (kWh)</Box>
                      <Box display={`flex`} flex={`1`} justifyContent={`center`}>พลังงานเชื้อเพลิงที่ใช้</Box>
                      <Box display={`flex`} flex={`1`} justifyContent={`center`}>ขนิดเชื้อเพลิงที่ใช้</Box>
                      <Box display={`flex`} flex={`1`}></Box>
                    </Box>
                    <Box display={`flex`} justifyContent={`center`} flexDirection={`column`}>
                      {buildingHeatConsumptionList && buildingHeatConsumptionList.length > 0 ? buildingHeatConsumptionList.map((heat, index) => (
                        <Box key={index} display={`flex`} flexDirection={`row`} sx={{ pt: 2 }} justifyContent={`center`}>
                          <Box display={`flex`} flex={`1`}>{heat.rowNo || '-'}</Box>
                          <Box display={`flex`} flex={`1`}>{heat.transactionDate || '-'}</Box>
                          <Box display={`flex`} flex={`1`} justifyContent={`center`}>
                            <Typography>{heat.useElectricity || '-'}</Typography>
                          </Box>
                          <Box display={`flex`} flex={`1`} justifyContent={`center`}>
                            <Typography>{`${heat.useFuel || '-'}`}</Typography>
                          </Box>
                          <Box display={`flex`} flex={`1`} justifyContent={`center`}>
                            <Typography>{`${heat.fuelType || '-'}`}</Typography>
                          </Box>
                          <Box display={`flex`} flex={`1`} sx={{ cursor: 'pointer' }} className="text-primary" justifyContent={`center`}>
                            <DeleteIcon onClick={() => OpenDialogConfirmDelete(heat)} />
                          </Box>
                        </Box>
                      )) : <Box varaint="div" flex="1" sx={{ p: 2, mt: 5 }} display={`flex`} flexDirection={`column`}>
                        <Typography sx={{ mt: 2, mb: 2, color: '#221717', textAlign: 'center' }}>No data to show</Typography>
                      </Box>}
                    </Box>
                    {/* PAGINATION */}
                    <Box display={`flex`} alignSelf={`end`} sx={{ mt: 5, mr: 5 }}>
                      {buildingHeatConsumptionList.length > 0 && <PaginationSquared page={currentPageHeatGrid} count={currentCountHeatGrid} onPageChange={onHeatGridPageChangeHandle} />}
                    </Box>
                    {/* BUTTON ADD MODAL */}
                    {currentSelectedBuilding && <>
                      <Box display={`flex`} flex={1} sx={{ mt: 5 }}>
                        <Button variant="contained" onClick={onOpenAddHeatModal}
                          sx={{
                            bgcolor: "#25A18E",
                            borderColor: "#25A18E",
                            ":hover": {
                              borderColor: "#777",
                              bgcolor: '#777'
                            },
                          }}
                        >เพิ่มรายการ</Button>
                      </Box>
                    </>}
                  </Box>
                </Box>

              </Box>

              {/* RIGHT SUMMARY GRID */}
              <Box display={`flex`} flex={1} flexDirection={`column`} sx={{ ml: 2, pl: 5 }} justifyContent={`center`}>
                <Box display={`flex`} flexDirection={`column`}>
                  <Box><Typography sx={{ fontSize: '20px', color: '#25A18E' }}>ข้อมูลการใช้ไฟฟ้า</Typography></Box>
                  <Box display={`flex`} flexDirection={`row`}>
                    <Box display={`flex`} sx={{ p: 2, m: 1, }} justifyContent={`center`} alignItems={`center`}>
                      <img src={PlugIcon} alt="plug icon" />
                    </Box>
                    <Box display={`flex`} flexDirection={`column`} sx={{ p: 2, m: 1, }} justifyContent={`center`} alignItems={`center`}>
                      <Typography display={`flex`}>Month to Date</Typography>
                      <Typography display={`flex`} sx={{ fontSize: '40px' }}>{buildingHeatConsumption && buildingHeatConsumption.electricityMonth != 0 ? buildingHeatConsumption.electricityMonth : '-'}</Typography>
                      <Typography display={`flex`}>MWh</Typography>
                    </Box>
                    <Box display={`flex`} flexDirection={`column`} sx={{ p: 2, m: 1, }} justifyContent={`center`} alignItems={`center`}>
                      <Typography display={`flex`}>Year to Date</Typography>
                      <Typography display={`flex`} sx={{ fontSize: '40px' }}>{buildingHeatConsumption && buildingHeatConsumption.electricityYear != 0 ? buildingHeatConsumption.electricityYear : '-'}</Typography>
                      <Typography display={`flex`}>MWh</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display={`flex`} flexDirection={`column`}>
                  <Box><Typography sx={{ fontSize: '20px', color: '#25A18E' }}>ข้อมูลการใช้พลังงานความร้อน</Typography></Box>
                  <Box display={`flex`} flexDirection={`row`}>
                    <Box display={`flex`} sx={{ p: 2, m: 1 }} justifyContent={`center`} alignItems={`center`}>
                      <img src={FrameIcon} alt="frame icon" />
                    </Box>
                    <Box display={`flex`} flexDirection={`column`} sx={{ p: 2, m: 1 }} justifyContent={`center`} alignItems={`center`}>
                      <Typography display={`flex`}>Month to Date</Typography>
                      <Typography display={`flex`} sx={{ fontSize: '40px' }}>{buildingHeatConsumption && buildingHeatConsumption.heatMonth != 0 ? buildingHeatConsumption.heatMonth : '-'}</Typography>
                      <Typography display={`flex`}>GL</Typography>
                    </Box>
                    <Box display={`flex`} flexDirection={`column`} sx={{ p: 2, m: 1 }} justifyContent={`center`} alignItems={`center`}>
                      <Typography display={`flex`}>Year to Date</Typography>
                      <Typography display={`flex`} sx={{ fontSize: '40px' }}>{buildingHeatConsumption && buildingHeatConsumption.heatYear != 0 ? buildingHeatConsumption.heatYear : '-'}</Typography>
                      <Typography display={`flex`}>GL</Typography>
                    </Box>
                  </Box>
                </Box>

              </Box>
            </Box>
          </Box>

        </Container>
      </Layout>
    </div>
  )
} 