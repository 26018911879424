import React, { useState, useEffect } from "react";
import { data } from "../../uiData/UIData";
import { Box } from "@mui/system";
import { Container, OutlinedInput, Typography, TextField } from "@mui/material";
import Layout from "../../layouts/Layout";
import { useNavigate } from "react-router-dom";
import DropdownYearPill from "../Common/DropdownYearPill";
import AlertNotification from "../Common/AlertNotification";
import { GET, POST } from "../../Utils/ApiClient";
import { ALERT_DISMISS_TIME, HTTP_STATUS } from "../../Utils/EnvUtils";
import { AuthUtils, Messages } from "../../Utils";
export default function AddEnergyTarget() {
  const navigate = useNavigate();
  const { homeTargetsInputs } = data;
  const { NOTIFICATION_MESSAGE } = Messages;
  const { OK } = HTTP_STATUS;

  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [secTarget, setSecTarget] = useState([]);

  useEffect(() => {
    fetchSecTarget();
  }, []);

  async function fetchSecTarget() {
    try {
      // todo : add ?buildingId={buildingId}
      const response = await GET(`Home/secTarget/${selectedYear}`);
      const { data, status } = response;
      const { message, success } = data;
      if (status === HTTP_STATUS.OK) {
        setSecTarget(data);
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  function onSelectYearHandler(year) {
    setSelectedYear(year);
  }

  function saveAndExit() {
    navigate("/");
  }

  function dataOfInputs(item, index, monthIndex) {
    let monthTargetValue = 0;
    switch (monthIndex) {
      case 0:
        monthTargetValue = item.january;
        break;
      case 1:
        monthTargetValue = item.february;
        break;
      case 2:
        monthTargetValue = item.march;
        break;
      case 3:
        monthTargetValue = item.april;
        break;
      case 4:
        monthTargetValue = item.may;
        break;
      case 5:
        monthTargetValue = item.june;
        break;
      case 6:
        monthTargetValue = item.july;
        break;
      case 7:
        monthTargetValue = item.august;
        break;
      case 8:
        monthTargetValue = item.september;
        break;
      case 9:
        monthTargetValue = item.october;
        break;
      case 10:
        monthTargetValue = item.november;
        break;
      case 11:
        monthTargetValue = item.december;
        break;

      default:
        monthTargetValue = 0;
        break;
    }

    return monthTargetValue;
  }

  async function onSubmitHandler(e) {
    e.preventDefault();
    let payload = [];
    secTarget.map((item, index) => {
      let payloadSingle = {
        buildingTypeId: item.buildingTypeId,
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0,
      };

      homeTargetsInputs.engMonths.map((month, monthIndex) => {
        const formValue = e.target[`${item.buildingTypeId}${month}`].value;
        switch (month) {
          case "january":
            payloadSingle.january = formValue;
            break;
          case "february":
            payloadSingle.february = formValue;
            break;
          case "march":
            payloadSingle.march = formValue;
            break;
          case "april":
            payloadSingle.april = formValue;
            break;
          case "may":
            payloadSingle.may = formValue;
            break;
          case "june":
            payloadSingle.june = formValue;
            break;
          case "july":
            payloadSingle.july = formValue;
            break;
          case "august":
            payloadSingle.august = formValue;
            break;
          case "september":
            payloadSingle.september = formValue;
            break;
          case "october":
            payloadSingle.october = formValue;
            break;
          case "november":
            payloadSingle.november = formValue;
            break;
          case "december":
            payloadSingle.december = formValue;
            break;
          default:
            break;
        }
      });

      payload.push(payloadSingle);
    });

    try {
      const response = await POST(`Home/secTarget/${selectedYear}`, payload);
      const { data, status } = response;
      if (status == OK) {
        const { message, success } = data;
        if (success) {
          setMessage(message);
          setSeverity("success");
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
            navigate('/')
          }, 1000);
        } else {
          setMessage(message || NOTIFICATION_MESSAGE.ERROR);
          setSeverity("error");
          setAlert(true);
        }
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setSeverity("error");
        setAlert(true);
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      console.log(error);
      setMessage(error || NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }
  return (
    <div>
      <Layout>
        <AlertNotification
          alert={alert}
          severity={severity}
          message={message}
        />
        <Container maxWidth={`xl`}>
          <Box
            display={`flex`}
            flexDirection={`column`}
            className="box-wrapper"
          >
            {/* START FORM  */}
            <form onSubmit={onSubmitHandler}>
              <Box display={`flex`} flexDirection={`row`} sx={{ p: 1 }}>
                <Box display={`flex`} sx={{ mr: 1 }}>
                  <div className="subtitle-header">
                    ตั้งค่าเป้าหมาย SEC ของทั้งปี
                  </div>
                </Box>
                <Box display={`flex`}>
                  <DropdownYearPill onSelectHandler={onSelectYearHandler} />
                </Box>
              </Box>
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`flex-start`}
              >
                <table className="table-transparent">
                  <thead>
                    <tr className="row">
                      <th className="col-building-type-placeholder"></th>
                      {homeTargetsInputs.months.map((item, index) => (
                        <td key={index} className="col">
                          {item}
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {secTarget &&
                      secTarget.map((item, index) => (
                        <tr className="row" key={index}>
                          <td className="col-building-type">
                            {item.buildingTypeName} {item.buildingTypeUnit}
                          </td>
                          {homeTargetsInputs.engMonths.map(
                            (month, monthIndex) => (
                              <td key={monthIndex} className="col">
                                <TextField
                                  aria-describedby={`outlined-${monthIndex}-helper-text`}
                                  variant="outlined"
                                  type="number"
                                  name={`${item.buildingTypeId}${month}`}
                                  defaultValue={dataOfInputs(
                                    item,
                                    index,
                                    monthIndex
                                  )}
                                  // name={`input-${item.buildingTypeId}-${monthIndex}`}
                                  id={`input-${item.buildingTypeId}-${monthIndex}`}
                                  placeholder=""
                                  InputProps={{
                                    inputProps: { min: 0, max: 100 },
                                  }}
                                  sx={{
                                    background: "#FFFFFF",
                                    borderRadius: 2,
                                  }}
                                />
                              </td>
                            )
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Box>

              <Box display={`flex`} sx={{ mt: 4 }} justifyContent={`flex-end`}>
                <button type="submit" className="input-active">
                  <Typography sx={{ pb: 2, pr: 2, pl: 2 }}>
                    บันทึกและออก
                  </Typography>
                </button>
              </Box>
            </form>
            {/* END FORM */}
            {/* END BOX WRAPPER */}
          </Box>
        </Container>
      </Layout>
    </div>
  );
}
