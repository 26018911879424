import { Typography } from '@mui/material';
import React from 'react';

export default function NoDataToShow() {
  return <>
    <tr style={{ textAlign: 'center', justifyContent: 'center'}}>
      <td>No data to show</td>
    </tr>
  </>;
}
