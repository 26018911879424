import React, { useState, useEffect } from 'react';
import { Button , Dialog, DialogActions, DialogContent , DialogContentText, DialogTitle} from '@mui/material';


export default function AlertDialog({ isOpenDialog  , dialogType, priority , onCloseDialogHandler }) {
  const [open, setOpen] = useState(isOpenDialog);
  const [title, setTitle] = useState("")
const [content, setContent] = useState("")

  useEffect(() => {
    setAlertContent();
    return () => {
      setOpen(false);
    }
  }, [])

  function setAlertContent() {
    if(dialogType == 0) {
      setTitle("ยืนยัน?") 
      setContent('ท่านต้องการลบข้อมูลใช่หรือไม่',)
    } else if (dialogType == 1) {
      setTitle("ยืนยัน?") 
      setContent('ท่านต้องการบันทึกข้อมูลใช่หรือไม่')
    }
  }
  
  const handleClose = () => {
    setOpen(false);
    onCloseDialogHandler(false ,false)
  };

  const handleCancel = () => {
    setOpen(false);
    onCloseDialogHandler(false ,false)
  };

  function handleConfirm() {
    setOpen(false);
    onCloseDialogHandler(false ,true)
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content} 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>ยกเลิก</Button>
          <Button color='primary' onClick={handleConfirm}>
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}