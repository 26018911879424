import millify from "millify";
import numeral from "numeral";

export const AxisLeftReport = ({ yScale, innerWidth, tickOffset = 3 }) =>
  yScale.ticks().map(tickValue => (
    <g key={tickValue} className="tick" transform={`translate(0,${yScale(tickValue)})`}>
      <line x2={innerWidth} />
      <text
        key={tickValue}
        style={{ textAnchor: 'end'}}
        x={-tickOffset}
        fontSize={`7px`}
        dy=".2em"
      >
        {millify(tickValue)}
      </text>
    </g>
  ));
