import { InputAdornment  } from '@mui/material'
import PeopleIconWhite from './../assets/images/icons/PeopleIconWhite.svg'
import BuildingWhiteIcon from './../assets/images/icons/BuildingWhiteIcon.svg'
import PeopleBuildingWhiteIcon from './../assets/images/icons/PeopleBuildingWhiteIcon.svg'
import PinDropIcon from '@mui/icons-material/PinDrop';
import { v4 as uuidv4 } from 'uuid';
import HospitalIconWhite from './../assets/images/icons/HospitalIconWhite.svg'
import HospitalIcon from './../assets/images/icons/HospitalIcon.svg'
import SchoolIconWhite from './../assets/images/icons/SchoolIconWhite.svg'
import SchoolIcon from './../assets/images/icons/SchoolIcon.svg'
import OfficeIconWhite from './../assets/images/icons/OfficeIconWhite.svg'
import OfficeIcon from './../assets/images/icons/OfficeIcon.svg'
import HotelIcon from './../assets/images/icons/HotelIcon.svg'
import HotelIconWhite from './../assets/images/icons/HotelIconWhite.svg'
import GeneralBuildingIcon from './../assets/images/icons/GeneralBuildingIcon.svg'
import GeneralBuildingIconWhite from './../assets/images/icons/GeneralBuildingIconWhite.svg'
import ShoppingMallIcon from './../assets/images/icons/ShoppingMallIcon.svg'
import ShoppingMallIconWhite from './../assets/images/icons/ShoppingMallIconWhite.svg'
import { BuildingIcon, PeopleBuildingIcon, PeopleIcon } from '../assets';

export const emailInput = {
  id: "5",
  name: "email",
  inputId: "email",
  type: "email",
  placeholder: "E-mail",
  variant: 'outlined',
  validation: {
    required: "กรุณากรอกข้อมูล อีเมลล์ ให้ครบถ้วน",
    maxLength : {
      value: 100,
      message: "ไม่อนุญาตให้กรอกข้อมูลเกิน 100 ตัวอักษร"
    } ,
    pattern: {
      value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i ,
      message: "กรุณากรอกอีเมลล์ในรูปแบบที่ถูกต้อง"
    }       
  }
};
const formInputs = [
  {
    id: uuidv4(),
    group: "",
    inputs: [
      {
        id: "1",
        name: "firstname",
        inputId: "firstname",
        type: "text",
        placeholder: "ชื่อ",
        variant: 'outlined',
        validation: {
          required: "กรุณาเลือก ชื่อ ให้ครบถ้วน",
          maxLength : {
            value: 100,
            message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร"
          }        
        }
      }, 
      {
        id: "2",
        name: "lastname",
        inputId: "lastname",
        type: "text",
        placeholder: "นามสกุล",
        variant: 'outlined',
        validation: {
          required: "กรุณากรอกข้อมูล นามสกุล ให้ครบถ้วน",
          maxLength : {
            value: 100,
            message: "ไม่อนุญาตให้กรอกข้อมูลเกิน 100 ตัวอักษร"
          }        
        }
      },
      {
        id: "3",
        name: "idcard",
        inputId: "idcard",
        type: "text",
        placeholder: "หมายเลขบัตรประชาชน",
        variant: 'outlined',
        validation: {
          required: "กรุณากรอกข้อมูล หมายเลขบัตรประชาชน ให้ครบถ้วน",
          maxLength : {
            value: 13,
            message: "ไม่อนุญาตให้กรอกข้อมูลเกิน 13 ตัวอักษร"
          }        
        }
      },
      {
        id: "4",
        name: "phone",
        inputId: "phone",
        type: "text",
        placeholder: "เบอร์ติดต่อ",
        variant: 'outlined',
        validation: {
          required: "กรุณากรอกข้อมูล เบอร์ติดต่อ ให้ครบถ้วน",
          maxLength : {
            value: 20,
            message: "ไม่อนุญาตให้กรอกข้อมูลเกิน 20 ตัวอักษร"
          }        
        }
      },
      emailInput,
      {
        id: "6",
        name: "office",
        inputId: "office",
        type: "text",
        placeholder: "สถานที่ทำงาน",
        variant: 'outlined',
        validation: {
          required: "กรุณากรอกข้อมูล สถานที่ทำงาน ให้ครบถ้วน",
          maxLength : {
            value: 100,
            message: "ไม่อนุญาตให้กรอกข้อมูลเกิน 100 ตัวอักษร"
          }        
        }
      },
      {
        id: "7",
        name: "position",
        inputId: "position",
        type: "text",
        placeholder: "ตำแหน่งงาน",
        variant: 'outlined',
        validation: {
          required: "กรุณากรอกข้อมูล ตำแหน่งงาน ให้ครบถ้วน",
          maxLength : {
            value: 100,
            message: "ไม่อนุญาตให้กรอกข้อมูลเกิน 100 ตัวอักษร"
          }        
        }
      },
    ]
  }
];

export const passwordInput = {
  id: "1",
  name: "password",
  inputId: "password",
  type: "password",
  placeholder: "Password",
  variant: 'outlined',
  validation: {
    required: "กรุณากรอกข้อมูล Password ให้ครบถ้วน",
    maxLength : {
      value: 100,
      message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร",
    }        
  }
}; 

export const data = {
  buttons: [
    {
      id: 1,
      icon: PeopleIconWhite,
      iconNormal: PeopleIcon,
      wording: 'บุคคลทั่วไป',
      isActive: true
    },
    {
      id: 2,
      icon: BuildingWhiteIcon,
      iconNormal: BuildingIcon,
      wording: 'อาคารควบคุม',
      isActive: false
    },
    {
      id: 3,
      icon: PeopleBuildingWhiteIcon,
      iconNormal: PeopleBuildingIcon,
      wording: 'เจ้าหน้าที่ พพ.',
      isActive: false
    }
  ],
  formInputs: formInputs,
  buildingFormInputs: [...formInputs
  ],
  officerFormInputs: [
    ...formInputs,
    {
      id: uuidv4(),
      group: "ข้อมูลรายชื่อผู้ขอรหัสผู้ใช้และรหัสผ่าน",
      inputs: [
        {
          id: "1",
          name: "accessLevel",
          inputId: "accessLevel",
          type: "text",
          placeholder: "ระดับการเข้าถึงข้อมูล",
          variant: 'outlined',
          validation: {
            required: "กรุณากรอกข้อมูล ระดับการเข้าถึงข้อมูล ให้ครบถ้วน",
            maxLength : {
              value: 100,
              message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร"
            }        
          }
        }
      ]
    }
  ],
  radiosGroups: [
    {
      id: 1,
      label: "กลุ่มอาคาร",
      ariaLabel: "buildingGroup",
      default: 0,
      name: "buildingGroup",
      validation: {
        required: "กรุณาเลือก ชื่ออาคารควบคุม"       
      },
      items: [
        {
          id: 1,
          value: 0,
          label: "กลุ่มที่ 1 อาคารใช้พลังงานรวม น้อยกว่า 60 ล้านเมกะจูล"
        }, 
        {
          id: 2,
          value: 1,
          label: "กลุ่มที่ 2 อาคารใช้พลังงานรวม มากกว่ากว่า 60 ล้านเมกะจูล"
        }
      ]
    },
    {
      id: 2,
      label: "เจ้าของอาคาร",
      ariaLabel: "buildingOwner",
      default: 0,
      name: "buildingOwner",
      validation: {
        required: "กรุณาเลือก เจ้าของอาคาร"       
      },
      items: [
        {
          id: 1,
          value: 0,
          label: "ภาครัฐ"
        }, 
        {
          id: 2,
          value: 1,
          label: "เอกชน"
        }
      ]
    }
  ],
  homeTabPills: [
    {
      id: 1,
      pillText: 'ภาพรวมอาคารควบคุม ระดับประเทศ',
      buildingTypeId: 0,
      icon: null
    },
    {
      id: 2,
      pillText: 'โรงพยาบาล',
      buildingTypeId: 3,
      icon: <img src={HospitalIcon} alt='Hospital Icon' />,
      iconWhite: <img src={HospitalIconWhite} height={100} alt='Hospital Icon' />,
      iconWhiteThumbNail: <img src={HospitalIconWhite} height={35} alt='Hospital Icon' />
    },
    {
      id: 3,
      pillText: 'สถานศึกษา',
      buildingTypeId: 5,
      icon: <img src={SchoolIcon} alt='School Icon' />,
      iconWhite: <img src={SchoolIconWhite} height={100} alt='School Icon' />,
      iconWhiteThumbNail: <img src={SchoolIconWhite} height={35} alt='School Icon' />
    },
    {
      id: 4,
      pillText: 'สำนักงาน',
      buildingTypeId: 1,
      icon: <img src={OfficeIcon} alt='Office Icon' />,
      iconWhite: <img src={OfficeIconWhite} height={100} alt='Office Icon' />,
      iconWhiteThumbNail: <img src={OfficeIconWhite} height={35} alt='Office Icon' />
    },
    {
      id: 5,
      pillText: 'โรงแรม',
      buildingTypeId: 2,
      icon: <img src={HotelIcon} alt='Hotel Icon' />,
      iconWhite: <img src={HotelIconWhite} height={100} alt='Office Icon' />,
      iconWhiteThumbNail: <img src={HotelIconWhite} height={35} alt='Office Icon' />
    },
    {
      id: 6,
      pillText: 'ศูนย์การค้า',
      buildingTypeId: 4,
      icon: <img src={ShoppingMallIcon} alt='ShoppingMall Icon' />,
      iconWhite: <img src={ShoppingMallIconWhite} height={100} alt='ShoppingMall Icon' />,
      iconWhiteThumbNail: <img src={ShoppingMallIconWhite} height={35} alt='ShoppingMall Icon' />
    },
    {
      id: 7,
      pillText: 'อื่นๆ',
      buildingTypeId: 6,
      icon: <img src={GeneralBuildingIcon} alt='GeneralBuilding Icon' />,
      iconWhite: <img src={GeneralBuildingIconWhite} height={100} alt='GeneralBuilding Icon' />,
      iconWhiteThumbNail: <img src={GeneralBuildingIconWhite} height={35} alt='GeneralBuilding Icon' />
    },
  ],
  buildingCategories: [
    {
      id: 1,
      pillText: 'สำนักงาน',
      buildingTypeId: 1,
      icon: <img src={OfficeIcon} alt='Office Icon' />,
      rank: 4
    },
    {
      id: 2,
      pillText: 'โรงแรม',
      buildingTypeId: 2,
      icon: <img src={HotelIcon} alt='Hospital Icon' />,
      rank: 2
    },
    {
      id: 3,
      pillText: 'โรงพยาบาล',
      buildingTypeId: 3,
      icon: <img src={HospitalIcon} alt='School Icon' />,
      rank: 1
    },
    {
      id: 4,
      pillText: 'ศูนย์การค้า',
      buildingTypeId: 4,
      icon: <img src={ShoppingMallIcon} alt='ShoppingMall Icon' />,
      rank: 3
    },
    {
      id: 5,
      pillText: 'สถานศึกษา',
      buildingTypeId: 5,
      icon: <img src={SchoolIcon} alt='ShoppingMall Icon' />,
      rank: 5
    },
    {
      id: 6,
      pillText: 'อื่นๆ',
      buildingTypeId: 6,
      icon: <img src={GeneralBuildingIcon} alt='GeneralBuilding Icon' />,
      rank: 6
    },
  ],
  fuelTypes: [
    { id: 1, text: 'น้ำมัน'},
    { id: 2, text: 'ถ่านหิน'},
    { id: 3, text: 'แก๊สธรรมชาติ'},
  ], 
  mockDataPieChartTable: [
    {id: 1, buildingType: 'โรงพยาบาล', energyUse: '409,000', color: 'rgb(167,206,227)' },
    {id: 2, buildingType: 'ศูนย์การค้า', energyUse: '2,209,020',color: 'rgb(238, 96, 85)' },
    {id: 3, buildingType: 'สถานศึกษา', energyUse: '109,000',color: 'rgb(72,165,214)' },
    {id: 4, buildingType: 'โรงแรม', energyUse: '600,000', color: 'rgb(114,189,71)' },
    {id: 5, buildingType: 'สำนักงาน', energyUse: '1,000,000', color: 'rgb(177,223,138)' },
    {id: 6, buildingType: 'อื่นๆ', energyUse: '20,000', color: 'rgb(114,189,71)' },

  ],
  mockEnergyUseInSystem: [
    {id: 1, buildingType: 'ระบบปรับอากาศแบบรวมศูนย์', energyUse: '409,000', color: 'rgb(167,206,227)' },
    {id: 2, buildingType: 'ระบบปรับอากาศแบบแยกส่วน', energyUse: '2,209,020',color: 'rgb(238, 96, 85)' },
    {id: 4, buildingType: 'ระบบไฟฟ้าแสงสว่าง', energyUse: '109,000',color: 'rgb(72,165,214)' },
    {id: 5, buildingType: 'อื่นๆ', energyUse: '20,000', color: 'rgb(114,189,71)' },
  ],
  mockDataDonutChart: {
    labels: ['โรงพยาบาล', 'ศูนย์การค้า', 'สถานศึกษา', 'โรงแรม', 'สำนักงาน', 'อื่นๆ'],
    series: [409000, 2209020, 109000, 600000, 1000000, 20000],
    colors: ['rgb(167,206,227)', 'rgb(238, 96, 85)', 'rgb(72,165,214)', 'rgb(114,189,71)', 'rgb(177,223,138)', 'rgb(114,189,71)'],
    total: 10011001
  },
  mockDataEnergyUseInSystemDonutChart: {
    labels: ['ระบบปรับอากาศแบบรวมศูนย์', 'ระบบปรับอากาศแบบแยกส่วน', 'ระบบไฟฟ้าแสงสว่าง','อื่นๆ'],
    series: [409000, 2209020, 109000, 600000 ],
    colors: ['rgb(167,206,227)', 'rgb(238, 96, 85)', 'rgb(72,165,214)', 'rgb(114,189,71)'],
    total: 10011001
  }, 
  homeUseOfEnergyIndex: [
    { id: 1, title: 'โรงพยาบาล (kWh/เตียง)', target: 100, secAverage: 100, secMax: 100, secMin: 100},
    { id: 2, title: 'โรงแรม (kWh/ห้อง)', target: 100, secAverage: 100, secMax: 100, secMin: 100},
    { id: 3, title: 'ศูนย์การค้า (kWh/ตารางเมตร)', target: 100, secAverage: 100, secMax: 100, secMin: 100},
    { id: 4, title: 'สำนักงาน (kWh/ตารางเมตร)', target: 100, secAverage: 100, secMax: 100, secMin: 100},
    { id: 5, title: 'สถานศึกษา (kWh/ตารางเมตร)', target: 100, secAverage: 100, secMax: 100, secMin: 100},
    { id: 6, title: 'อื่นๆ (kWh/ตารางเมตร)', target: 100, secAverage: 100, secMax: 100, secMin: 100},
  ],
  homeTargetsInputs: {
    months: ["มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน","กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤศจิกายน","ธันวาคม"],
    engMonths: ["january","february","march","april","may","june","july", "august","september","october","november","december"],
    titles: [
      "โรงพยาบาล (kWh/เตียง)","โรงแรม (kWh/ห้อง)","ศูนย์การค้า (kWh/ตารางเมตร)","สำนักงาน (kWh/ตารางเมตร)","สถานศึกษา (kWh/ตารางเมตร)","อื่นๆ (kWh/ตารางเมตร)"
    ]
  }, 
  secTargetForEachGroup: [
    {id: 1, title: "เป้าหมาย SEC ที่ถูกกำหนด", target: 100000},
    {id: 2, title: "SEC เฉลี่ยของเดือน", target: 10000},
    {id: 3, title: "SEC สูงสุดของเดือน", target: 15000},
    {id: 4, title: "SEC ต่ำสุดของเดือน", target: 30000}

  ], 
  lineGraphPills: [
    // { id: 1, title: 'รวมการใช้พลังงาน'},
    { id: 1, title: 'พลังงานไฟฟ้า'},
    { id: 2, title: 'พลังงานความร้อน'}
  ], 
  buildingEachSystems: [
    {id: 0, systemTypeId: 0, systemTypeName: 'ภาพรวมการใช้พลังงานของอาคาร' },
    {id: 1, systemTypeId: 1, systemTypeName: 'ระบบปรับอากาศแบบรวมศูนย์' },
    {id: 2, systemTypeId: 2, systemTypeName: 'ระบบปรับอากาศแบบแยกส่วน' },
    {id: 3, systemTypeId: 3, systemTypeName: 'ระบบไฟฟ้าแสงสว่าง' },
    {id: 4, systemTypeId: 4, systemTypeName: 'ระบบผลิตไฟฟ้า' },
    {id: 5, systemTypeId: 5, systemTypeName: 'อื่นๆ'},
  ],
  mockDataSupplySide: [
    { id: 1, plantNo: 'plant#1' , plantEfficiency: 0.85},
    { id: 2, plantNo: 'plant#2' , plantEfficiency: 0.65},
    { id: 3, plantNo: 'plant#3' , plantEfficiency: 0.80},
    { id: 4, plantNo: 'plant#4' , plantEfficiency: 0.15},
    { id: 5, plantNo: 'plant#5' , plantEfficiency: 0.20},
    { id: 6, plantNo: 'plant#6' , plantEfficiency: 0.95},
    { id: 7, plantNo: 'plant#7' , plantEfficiency: 0.65},
    { id: 8, plantNo: 'plant#8' , plantEfficiency: 0.70},
    { id: 9, plantNo: 'plant#9' , plantEfficiency: 0.10},
    { id: 10, plantNo: 'plant#10' , plantEfficiency: 0.44},
  ],
  mockAHUData: [
    { id: 1, ahuNo: 'ahu#1' , power: 300 , temperature: 60, setPoint: 25, hz: 50, sumEnergyPerday: 4000},
    { id: 2, ahuNo: 'ahu#2' , power: 300 , temperature: 60, setPoint: 25, hz: 50, sumEnergyPerday: 4000},
    { id: 3, ahuNo: 'ahu#3' , power: 300,  temperature: 60, setPoint: 25, hz: 50, sumEnergyPerday: 4000},
    { id: 4, ahuNo: 'ahu#4' , power: 300,  temperature: 60, setPoint: 25, hz: 50, sumEnergyPerday: 4000},
    { id: 5, ahuNo: 'ahu#5' , power: 300,  temperature: 60, setPoint: 25, hz: 50, sumEnergyPerday: 4000},
    { id: 6, ahuNo: 'ahu#6' , power: 300,  temperature: 60, setPoint: 25, hz: 50, sumEnergyPerday: 4000},
    { id: 7, ahuNo: 'ahu#7' , power: 300,  temperature: 60, setPoint: 25, hz: 50, sumEnergyPerday: 4000},
    { id: 8, ahuNo: 'ahu#8' , power: 300,  temperature: 60, setPoint: 25, hz: 50, sumEnergyPerday: 4000},
    { id: 9, ahuNo: 'ahu#9' , power: 300,  temperature: 60, setPoint: 25, hz: 50, sumEnergyPerday: 4000},
    { id: 10, ahuNo: 'ahu#10' , power: 300,  temperature: 60, setPoint: 25, hz: 50, sumEnergyPerday: 4000},
  ],
  supplySideCategories: [
    { id: 1, text: 'Chiller plant'},
    { id: 2, text: 'Chiller'},
    { id: 3, text: 'CHP,CDP,CT'}
  ], 
  chillerData: [
    { 
      chillerId: 1, chillerNumber: 'CH-01', criticalLevel: 0, 
      averageEfficiency: 0.85, averageEfficiencyUnit: 'kW/RT',sumEnergy: '3,000',sumEnergyUnit: 'kWh',
      power: '500', powerUnit: 'kW', currentLimitPercent: 95, chilledSetPoint: '43.0', compRunHour: '22,191', compRunHourUnit: 'Hr',
      chilledEnteringTemp: '57.1', chilledLeavingTemp: '45.6', deltaChilledWaterTemp: '11.5', refrigerantSuctionTemp: '44.0',
      condDischargeTemp: '105.4',  condApproachTemp: '23.0' , condEnteringTemp: '91.0', condLeavingTemp: '103.1', deltaCondWaterTemp: '12.1'
    }
  ],
  chilerDataBlock: [
    { id: 1, text: 'water'},
    { id: 2, text: 'regrigerant'},
    { id: 3, text: 'condenser'}
  ],
  chilerDelta: [
    { id: 1, text: 'Delta Chilled Water Temp', deltaTemp: '11.5'},
    { id: 2, text: 'Evap Aggroach Temp', deltaTemp: '16.0'},
    { id: 3, text: 'Cond Approach Temp', deltaTemp: '23.0'},
    { id: 4, text: 'Delta Cond Water Temp', deltaTemp: '12.1'},    
  ],
  mockDataZoneEnergyUseTable: [
    { id: 1, zone: 'Zone - 01', sumEnergy: 4000, sumEnergyUnit: 'kWh', power: 300, powerUnit: 'kW'},
    { id: 2, zone: 'Zone - 02', sumEnergy: 8000, sumEnergyUnit: 'kWh', power: 1300, powerUnit: 'kW'},
    { id: 3, zone: 'Zone - 03', sumEnergy: 2000, sumEnergyUnit: 'kWh', power: 200, powerUnit: 'kW'}
  ],

  mockDataPumpEnergyUseTable: {
    cleanWaterPump: [
      { id: 1, engineNumber: 'Pump - 01', sumEnergy: 4000, sumEnergyUnit: 'kWh', power: 300, powerUnit: 'kW', frequencey: 50, frequenceyUnit: 'Hz'},
      { id: 2, engineNumber: 'Pump - 02', sumEnergy: 8000, sumEnergyUnit: 'kWh', power: 1300, powerUnit: 'kW', frequencey: 50, frequenceyUnit: 'Hz'},
      { id: 3, engineNumber: 'Pump - 03', sumEnergy: 2000, sumEnergyUnit: 'kWh', power: 200, powerUnit: 'kW', frequencey: 50, frequenceyUnit: 'Hz'}
    ],
    wasteWaterPump: [
      { id: 1, engineNumber: 'Pump - 01', sumEnergy: 4000, sumEnergyUnit: 'kWh', power: 300, powerUnit: 'kW', frequencey: 50, frequenceyUnit: 'Hz'},
      { id: 2, engineNumber: 'Pump - 02', sumEnergy: 8000, sumEnergyUnit: 'kWh', power: 1300, powerUnit: 'kW', frequencey: 50, frequenceyUnit: 'Hz'},
      { id: 3, engineNumber: 'Pump - 03', sumEnergy: 2000, sumEnergyUnit: 'kWh', power: 200, powerUnit: 'kW', frequencey: 50, frequenceyUnit: 'Hz'}
    ]
  },
  mockDataIndoorLighting: [
  ],
  mockDataOutdoorLighting: [],
  mockDataLigtingMultipleBarchart: [{
    name: "USA",
    values: [
      { date: "2000", price: "100" },
      { date: "2001", price: "110" },
      { date: "2002", price: "145" },
      { date: "2003", price: "241" },
      { date: "2004", price: "101" },
      { date: "2005", price: "90" },
      { date: "2006", price: "10" },
      { date: "2007", price: "35" },
      { date: "2008", price: "21" },
      { date: "2009", price: "201" }
    ]
  },
  {
    name: "Canada",
    values: [
      { date: "2000", price: "200" },
      { date: "2001", price: "120" },
      { date: "2002", price: "33" },
      { date: "2003", price: "21" },
      { date: "2004", price: "51" },
      { date: "2005", price: "190" },
      { date: "2006", price: "120" },
      { date: "2007", price: "85" },
      { date: "2008", price: "221" },
      { date: "2009", price: "101" }
    ]
  },
  {
    name: "Maxico",
    values: [
      { date: "2000", price: "50" },
      { date: "2001", price: "10" },
      { date: "2002", price: "5" },
      { date: "2003", price: "71" },
      { date: "2004", price: "20" },
      { date: "2005", price: "9" },
      { date: "2006", price: "220" },
      { date: "2007", price: "235" },
      { date: "2008", price: "61" },
      { date: "2009", price: "10" }
    ]
  }],
  CHPLegend: [
    { id: 1, text: 'CDP', color: 'green'},
    { id: 2, text: 'CHP', color: 'red'},
    { id: 3, text: 'CT', color: 'blue'},
  ],
  lightingGraphLegend: [
    { id: 1, text: 'Indoor', color: 'green'},
    { id: 2, text: 'Outdoor', color: 'red'},
  ],
  otherGraphLegend: [
    { id: 1, text: 'เครื่องสูบน้ำดี', color: 'green'},
    { id: 2, text: 'เครื่องสูบน้ำเสีย', color: 'red'},
  ],
  population: [
    {name: "<5", value: 19912018}
    ,{name: "5-9", value: 20501982}
    ,{name: "10-14", value: 20679786}
    ,{name: "15-19", value: 21354481}
    ,{name: "20-24", value: 22604232}
    ,{name: "25-29", value: 21698010}
    ,{name: "30-34", value: 21183639}
    ,{name: "35-39", value: 19855782}
    ,{name: "40-44", value: 20796128}
    ,{name: "45-49", value: 21370368}
    , {name: "50-54", value: 22525490}
    , {name: "55-59", value: 21001947}
    , {name: "60-64", value: 18415681}
    , {name: "65-69", value: 14547446}
    , {name: "70-74", value: 10587721}
    , {name: "75-79", value: 7730129}
    , {name: "80-84", value: 5811429}
    , {name: "≥85", value: 5938752}
  ]
}

