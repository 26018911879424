import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Paper,
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Alert,
  FormHelperText
} from "@mui/material";
import { EnvUtils, ApiClient } from "../../Utils";
import AuthLayout from "../../layouts/AuthLayout";
import SignInSideImage from "../../assets/images/SigninSideImage.svg";
import SignInHeaderImage from "../../assets/images/SigninHeaderImage.svg";
import LogoWhite from "../../assets/images/LogoWhite.svg";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { UIData } from "../../uiData";
import { v4 as uuidv4 } from "uuid";
import CustomTextInput from "../Common/CustomTextInput";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styles from "./SigninPage.module.css";
import AlertNotification from './../Common/AlertNotification'
import { ALERT_DISMISS_TIME } from "../../Utils/EnvUtils";
import { AuthUtils, Messages } from "../../Utils";
import { ifTokenExistAndValidThenContinueUsingApp } from "../../Utils/AuthUtils";


export default function SigninPage() {
  const { destroyToken, decodeToken, isTokenTimeExpired } = AuthUtils;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { emailInput } = UIData;
  const [checked, setChecked] = useState(false)
  const { POST_NO_AUTH } = ApiClient;
  const { GET_HTTP_ERROR_MESSAGE } = EnvUtils;
  const { NOTIFICATION_MESSAGE } = Messages;

  const navigate = useNavigate();
  // const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
  const [errorMessage, setErrorMessage] = useState("")
  const [alert, setAlert] = useState(false)
  const [severity, setSeverity] = useState("error")
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  useEffect(() => {
    // redirect if token isExist
    ifTokenExistAndValidThenContinueUsingApp()
    
  }, [])

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  async function onFormSubmit(inputdata) {
    try {
      const payload = {
        username: inputdata.email,
        password: inputdata.password,
        isRemember: checked
      }
      const res = await POST_NO_AUTH("User/authenticate", payload);
      const { data } = res;
      const { success, token, message } = data;
      if (token == null || success == false) {
        setAlert(true)
        setErrorMessage(message)
        setSeverity("error")
        setTimeout(() => {
          setAlert(false)
        }, ALERT_DISMISS_TIME);
        return;
      }
      localStorage.removeItem('access_token');
      // set cookie
      if (checked) {
        localStorage.setItem('access_token', token);
        localStorage.setItem('rememberme', true)

      } else {
        localStorage.setItem('access_token', token);
        localStorage.setItem('rememberme', false)
      }

      setTimeout(() => {
        navigate('/');
      }, 0);

    } catch (ex) {
      console.log('error : ', ex);
      setAlert(true)
      setErrorMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }

  return (
    <>
      <AuthLayout>
        {alert && <AlertNotification alert={alert} severity={severity} message={errorMessage} />}
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={0}
            sm={4}
            md={7}
            sx={{
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            justifyContent={`center`}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: '#25A18E', height: '100vh' }}>
              <Box sx={{ display: 'flex' }} flex={1}>
                <img src={LogoWhite} alt="logo white" width={350} />
              </Box>
              <Box sx={{ display: 'flex' }} flex={2} sx={{ mt: -2 }}>
                <img src={SignInSideImage} alt="signin side image" width={`90%`} />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                <Box>
                  <img src={SignInHeaderImage} alt="Signin Header Image" />
                </Box>
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(onFormSubmit)}
                sx={{ mt: 1, width: "80%" }}
              >
                {/* <CustomTextInput
                  key={uuidv4()}
                  register={register(
                    `${emailInput.name}`,
                    emailInput.validation
                  )}
                  errors={errors}
                  item={emailInput}
                /> */}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
                    <OutlinedInput
                      aria-describedby={`outlined-email-helper-text`}
                      {...register("email", {
                        required: "กรุณากรอกข้อมูล email ให้ครบถ้วน",
                        pattern: {
                          value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                          message: "กรุณากรอกอีเมลล์ในรูปแบบที่ถูกต้อง"
                        },
                        maxLength: {
                          value: 100,
                          message: "ไม่อนุญาตให้กรอกข้อมูลเกิน 100 ตัวอักษร",
                        },
                      })}
                      variant="outlined"
                      name="email"
                      id="email"
                      placeholder="E-mail"
                      sx={{ background: "#FFFFFF", borderRadius: 2 }}
                    />
                    {errors[`email`] && (
                      <FormHelperText
                        id={`outlined-email-helper-text`}
                      >
                        {errors[`email`]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
                    <OutlinedInput
                      aria-describedby={`outlined-password-helper-text`}
                      {...register("password", {
                        required: "กรุณากรอกข้อมูล Password ให้ครบถ้วน",
                        maxLength: {
                          value: 100,
                          message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร",
                          // pattern: {
                          //   value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i ,
                          //   message: "กรุณากรอกอีเมลล์ในรูปแบบที่ถูกต้อง"
                          // }
                        },
                      })}
                      variant="outlined"
                      type={values.showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="Password"
                      sx={{ background: "#FFFFFF", borderRadius: 2 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors[`password`] && (
                      <FormHelperText
                        id={`outlined-password-helper-text`}
                      >
                        {errors[`password`]?.message}
                      </FormHelperText>
                    )}
                    {/* <Typography fontSize={12} sx={{ color: "red", pt: 1 }}>
                      {errors["password"]?.message}
                    </Typography> */}
                  </FormControl>
                </Box>

                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={6} md={6} lg={6}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="rememberme"
                            id="rememberme"
                            color="primary"
                            className={styles.checkboxCircle}
                            onChange={() => setChecked(!checked)}
                          />
                        }
                        label={<Typography>จดจำการเข้าสู่ระบบ</Typography>}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6} justifyContent={`center`}>
                    <Box>
                      <Button
                        variant="outlined"
                        href="/password-reset"
                        sx={{
                          borderColor: "#25A18E",
                          width: "100%",
                          ":hover": {
                            borderColor: "#777",
                          },
                        }}
                      >
                        <Typography sx={{ color: "#25A18E" }}>
                          ลืมรหัสผ่าน?
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                {/* Grid buttons */}
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} lg={6}>
                    <Button
                      type="submit"
                      fullWidth={true}
                      variant="contained"
                      disabled={errors['email'] || errors['password'] ? true : false}
                      sx={{
                        mt: 3,
                        mb: 2,
                        bgcolor: "#25A18E",
                        ":hover": {
                          backgroundColor: "#777",
                        },
                      }}
                    >
                      เข้าสู่ระบบ
                    </Button>
                  </Grid>

                  <Grid item xs={6} md={6} lg={6}>
                    <Button
                      varaint="href"
                      href="/register"
                      fullWidth={true}
                      variant="outlined"
                      sx={{
                        mt: 3,
                        mb: 2,
                        borderColor: "#25A18E",
                        ":hover": {
                          borderColor: "#777",
                        },
                      }}
                    >
                      <Typography sx={{ color: "#25A18E" }}>
                        ลงทะเบียน
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </AuthLayout>
    </>
  );
}
