import React from "react";
import { ENERGY_USE_TYPE } from "../../../Utils/EnvUtils";
import { ReportEnergyUsePage } from "./../ReportEnergyUsePage";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import Exporter from "../Exporter";

export default function Report2() {
  const { state } = useLocation();

  console.log(state);
  const {
    energySummary,
    selectedYear,
    selectedMonth,
    selectedDay,
    selectedTimeType,
    reportType,
  } = state;

  return (
    <>
      <Exporter>
    <Box style={{width: '21cm', height: '29.7cm'}} >
      { reportType == ENERGY_USE_TYPE.ALL &&  energySummary && <ReportEnergyUsePage energySummary={energySummary} selectedYear={selectedYear} 
        selectedMonth={selectedMonth} selectedDay={selectedDay} selectedTimeType={selectedTimeType} reportType={ENERGY_USE_TYPE.ALL} />}
    </Box>
    </Exporter>
      {/* <Box style={{ width: "21cm", height: "29.7cm" }}>
        {reportType == ENERGY_USE_TYPE.ALL && energySummary && (
          <ReportEnergyUsePage
            energySummary={energySummary}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            selectedDay={selectedDay}
            selectedTimeType={selectedTimeType}
            reportType={ENERGY_USE_TYPE.ALL}
          />
        )}
      </Box> */}
    </>
  );
}
