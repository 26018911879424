export const BASE = {
  registerBackgroundColor: '#25A18E',
  // color 
  white: '#FFFFFF', 
  // size
  xs: "10px",
  sm: "12px", 
  regular: "14px",
  md: "16px",
  lg: "18px",
  xl: "24px",
  xxl: "48px"
}