import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import AlertNotification from "../Common/AlertNotification";
import { GET } from "../../Utils/ApiClient";
import { data } from "../../uiData/UIData";
import AccumElectricityUse from "../Home/AccumElectricityUse";
import AccumHeatUse from "../Home/AccumHeatUse";
import AlternativeEnergyPercent from "../Home/AlternativeEnergyPercent";
import BottomSummaryBox from "./BottomSummaryBox";
import {
  ALERT_DISMISS_TIME,
  BASE_URL,
  HTTP_STATUS,
  X_AXIS_TIME_TYPE,
} from "../../Utils/EnvUtils";
import { NOTIFICATION_MESSAGE } from "../../Utils/Messages";
import { D3LineChart } from "../Common/d3/D3LineChart";
import { DownloadIcon } from "../../assets";
import { modifiedLineChartDataTimePeriod } from "../../Utils/FuncUtils";

export default function Overview({
  activePill,
  selectedYear,
  selectedMonth,
  selectedDay,
  buildingInfo,
  buildingEnergyRatio,
  selectedTimeType,
  buildingSummaryEnergyUse
}) {
  const { lineGraphPills } = data;
  const [buildingSummaryEnergy, setBuildingSummaryEnergy] = useState();

  const [buildingEnergyUse, setBuildingEnergyUse] = useState();
  const [buildingEnergyUseByTime, setBuildingEnergyUseByTime] = useState();
  const [buildingEnergyUseByTimeUnit, setBuildingEnergyUseByTimeUnit] =
    useState();
  const [energyType, setEnergyType] = useState(lineGraphPills[0]);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("info");

  useEffect(() => {
    return () => {
      setBuildingEnergyUse();
      setBuildingEnergyUseByTime();
      setBuildingEnergyUseByTimeUnit();
      setEnergyType();
    };
  }, []);

  useEffect(() => {
    fetchEnergyUse();
    fetchEnergyUseByTime();
  }, [
    activePill,
    selectedYear,
    selectedMonth,
    selectedDay,
    buildingInfo,
    energyType,
  ]);
  async function fetchEnergyUse() {
    try {
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const response = await GET(
        `dashboard/overview/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = response;
      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        setBuildingEnergyUse(data);
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
        // todo : set alert
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }
  async function fetchEnergyUseByTime() {
    try {
      if(!buildingInfo) return;
      // todo : add ?buildingId={buildingId}
      // if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const buildingTypeId = buildingInfo ? buildingInfo.buildingTypeId : 0;
      const energyTypeUseId = energyType.id;
      const res = await GET(
        `dashboard/energyUseByPeriod/${energyTypeUseId}/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = res;
      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        const modifiedGraphData = modifiedLineChartDataTimePeriod(
          data,
          selectedYear,
          selectedMonth,
          selectedDay,
          selectedTimeType
        );
        setBuildingEnergyUseByTimeUnit(data.unit);
        setBuildingEnergyUseByTime(modifiedGraphData);
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }
  function onSetEnergyType(item) {
    setEnergyType(item);
  }
  return (
    <>
      <AlertNotification alert={alert} severity={severity} message={message} />
      {/* ACCUMULATE ENERGY USE */}
      <Box
        display={`flex`}
        flexDirection={`row`}
        justifyContent={`space-between`}
      >
        {/* BOX: 1 */}
        <Box
          className="box-wrapper"
          sx={{ mt: 1 }}
          display={`flex`}
          flex={1}
          flexDirection={`column`}
        >
          <Box
            display={`flex`}
            flexDirection={`row`}
            justifyContent={`space-between`}
          >
            <Box display={`flex`} justifyContent={`start`} sx={{ ml: 2 }}>
              <Typography className="text-primary">TSIC ID: </Typography>
            </Box>
            <Box display={`flex`} justifyContent={`start`} sx={{ mr: 2 }}>
              <Typography className="text-black" sx={{ ml: 1 }}>
                {buildingInfo ? buildingInfo.tsicId : "-"}
              </Typography>
            </Box>
          </Box>
          <Box
            display={`flex`}
            flexDirection={`row`}
            justifyContent={`space-between`}
          >
            <Box display={`flex`} justifyContent={`start`} sx={{ ml: 2 }}>
              <Typography className="text-primary">ชือนิติบุคคล: </Typography>
            </Box>
            <Box display={`flex`} justifyContent={`start`} sx={{ mr: 2 }}>
              <Typography className="text-black" sx={{ ml: 1 }}>
                {buildingInfo ? buildingInfo.legalEntityName : "-"}
              </Typography>
            </Box>
          </Box>
          <Box
            display={`flex`}
            flexDirection={`row`}
            justifyContent={`space-between`}
          >
            <Box display={`flex`} justifyContent={`start`} sx={{ ml: 2 }}>
              <Typography className="text-primary">
                ชื่ออาคารควบคุม:{" "}
              </Typography>
            </Box>
            <Box display={`flex`} justifyContent={`start`} sx={{ mr: 2 }}>
              <Typography className="text-black" sx={{ ml: 1 }}>
                {buildingInfo ? buildingInfo.buildingControlName : "-"}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* BOX: 2 */}
        <AccumElectricityUse data={buildingEnergyUse} />
        <AccumHeatUse data={buildingEnergyUse} />
      </Box>
      {/* INDEX */}
      <Box
        sx={{ mt: 1 }}
        display={`flex`}
        flexDirection={`row`}
        justifyContent={`space-between`}
      >
        <AlternativeEnergyPercent
          buildingEnergyUse={buildingEnergyUse}
          activePill={activePill}
        />
        <Box
          display={`flex`}
          flexDirection={`column`}
          className="box-wrapper"
          flex={1}
        >
          {/* INDEX AVERAGE */}
          <Box>
            <Typography className={`subtitle-header`}>
              ค่าดัชนีการใช้พลังงานเฉลี่ยของอาคารควบคุม (kWh/ตารางเมตร)
            </Typography>
          </Box>

          <Box display={`flex`} flexDirection={`row`} justifyContent={`space-around`}>
            <Box display={`flex`}>
              <Box
                className="box-wrapper"
                display={`flex`}
                justifyContent={`center`}
                flexDirection={`column`}
                alignItems={`center`}
                flex={1}
                // sx={{ minWidth: "200px" }}
              >
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "small" }}>
                    ดัชนีการใช้พลังงานเฉลี่ยของอาคาร
                  </Typography>
                </Box>
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "40px" }}>{ buildingEnergyUse && (buildingEnergyUse.sec || '-')}</Typography>
                </Box>
              </Box>
              <Box
                className="box-wrapper"
                display={`flex`}
                flex={1}
                justifyContent={`center`}
                flexDirection={`column`}
                alignItems={`center`}
                // sx={{ minWidth: "200px" }}
              >
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "small" }}>
                    ลำดับที่/อาคารในกลุ่ม
                  </Typography>
                </Box>
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "40px" }}>{ buildingEnergyUse && (buildingEnergyUse.secRating || '-')}</Typography>
                </Box>
              </Box>
              <Box
                className="box-wrapper-active"
                display={`flex`}
                justifyContent={`center`}
                flexDirection={`column`}
                alignItems={`center`}
                flex={1}
                // sx={{ minWidth: "200px" }}
              >
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "small" }}>
                    เป้าหมาย พพ. กำหนด
                  </Typography>
                </Box>
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "40px" }}>{ buildingEnergyUse && (buildingEnergyUse.secTarget || '-')}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* USE ELECTRICITY AND SALE */}
          <Box display={`flex`}>
            <Typography className={`subtitle-header`}>
              ระบบผลิตไฟฟ้าและขายไฟฟ้า
            </Typography>
          </Box>

          <Box display={`flex`} flexDirection={`row`} justifyContent={`space-around`}>
            <Box display={`flex`}>
              <Box
                className="box-wrapper"
                display={`flex`}
                justifyContent={`center`}
                flexDirection={`column`}
                alignItems={`center`}
                flex={1}
                // sx={{ minWidth: "200px" }}
              >
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "small" }}>
                    พลังงานทดแทนที่ผลิตได้
                  </Typography>
                </Box>
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "40px" }}>{ buildingEnergyUse && (buildingEnergyUse.generateElectricity || '-')}</Typography>
                </Box>
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "small" }}>{ buildingEnergyUse && (buildingEnergyUse.generateElectricityUnit || '-')}</Typography>
                </Box>
              </Box>
              <Box
                className="box-wrapper"
                display={`flex`}
                justifyContent={`center`}
                flexDirection={`column`}
                alignItems={`center`}
                flex={1}
                // sx={{ minWidth: "200px" }}
              >
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "small" }}>
                    พลังไฟฟ้าที่ใช้ในระบบ
                  </Typography>
                </Box>
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "40px" }}>{ buildingEnergyUse && (buildingEnergyUse.generateElectricityUse || '-')}</Typography>
                </Box>
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "small" }}>{ buildingEnergyUse && (buildingEnergyUse.generateElectricityUseUnit || '-')}</Typography>
                </Box>
              </Box>
              <Box
                className="box-wrapper"
                display={`flex`}
                justifyContent={`center`}
                flexDirection={`column`}
                alignItems={`center`}
                flex={1}
                // sx={{ minWidth: "200px" }}
              >
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "small" }}>
                    พลังงานไฟฟ้าที่จำหน่ายออก
                  </Typography>
                </Box>
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "40px" }}>{buildingEnergyUse && ( buildingEnergyUse.saleElectricity || '-') }</Typography>
                </Box>
                <Box display={`flex`}>
                  <Typography sx={{ fontSize: "small" }}>{ buildingEnergyUse && (buildingEnergyUse.saleElectricityUnit || '-')}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display={`flex`} flexDirection={`column`} className="box-wrapper">
        <Box
          display={`flex`}
          flexDirection={`row`}
          justifyContent={`space-between`}
        >
          <Box display={`flex`} alignItems={`start`}>
            <div className="subtitle-header">การใช้พลังงานตามช่วงเวลา</div>
          </Box>
          <Box display={`flex`} alignItems={`end`}>
            {lineGraphPills &&
              lineGraphPills.map((item, index) => (
                <Box sx={{ mr: 1 }} key={index}>
                  {/* TODO : FIX ACTIVE BUTTON  */}
                  <button
                    className={`button-graph-select${
                      energyType.id === item.id ? "-active" : ""
                    }`}
                    onClick={() => onSetEnergyType(item)}
                  >
                    <Typography sx={{ pr: 2, pl: 2 }}>{item.title}</Typography>
                  </button>
                </Box>
              ))}
          </Box>
        </Box>
        {/* LINE CHART */}
        <Box display={`flex`}>
          {buildingEnergyUseByTime ? (
            <D3LineChart
              unit={buildingEnergyUseByTimeUnit}
              graphData={buildingEnergyUseByTime}
              timeType={selectedTimeType}
            />
          ) : (<Box display={`flex`} justifyContent={`center`} alignItems={`center`}><Typography>No data to show</Typography></Box>)}
        </Box>
        <Box display={`flex`} justifyContent={`flex-end`} sx={{ mr: 1, mb: 1 }}>
          {(
            <a
              href={`${BASE_URL}/api/dashboard/downloadEnergyUseByPeriod/${buildingInfo ? buildingInfo.buildingTypeId : 0}/${energyType.id}/${selectedYear}/${selectedMonth}/${selectedDay}`}
              download
            >
              <img
                src={DownloadIcon}
                alt="download icon"
                sx={{ cursor: "pointer" }}
              />
            </a>
          )}
        </Box>
      </Box>

      {/* BOTTOM SUMMARY*/}
      <Box display={`flex`} flexDirection={`row`}>
        {
          <BottomSummaryBox
            data={null}
            buildingEnergyRatio={buildingEnergyRatio}
            buildingInfo={buildingInfo}
            energyType={energyType}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            selectedDay={selectedDay}
            buildingSummaryEnergyUse={buildingSummaryEnergyUse}
          />
        }
      </Box>
    </>
  );
}
