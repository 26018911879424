import React from 'react'
import GeneralLayout from './../../layouts/GeneralLayout'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import Logo2White from "../../assets/images/Logo2White.svg";
import { Link } from 'react-router-dom';


export default function EmailSent() {
  return (
    <>
      <GeneralLayout>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItem: 'center', flexDirection: 'column'}}>
          <Box sx={{pt: 20}}>
            <Typography sx={{ fontSize: '32px', color: '#FFFFFF'}}>
              ดำเนินการส่ง link สำหรับเปลี่ยนรหัสผ่านเรียบร้อย กรุณาตรวจสอบในกล่องจดหมาย
            </Typography>            
          </Box>
          <Box sx={{pt: 5}}>
            <Link to={`/signin`}>
              <Typography sx={{textAlign: 'center', fontSize: '20px'}}>
                ลงชื่อเข้าสู่ระบบ
              </Typography>
            </Link>
          </Box>
          <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
              <img src={Logo2White} alt="Signin Header Image" />
            </Box>
        </Box>
      </GeneralLayout>
    </>
  )
}
