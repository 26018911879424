import { Box, Typography } from "@mui/material";
import React from "react";
import { AlarmRedIcon, AlarmGreenIcon, CompressorIcon } from "../../assets";
import { data } from "../../uiData/UIData";
import { D3LineChart } from "../Common/d3/D3LineChart";
import DownloadBox from "../Common/DownloadBox";
import { v4 as uuid } from "uuid";

export default function Chiller({
  buildingInfo,
  chillerList,
  selectedPlantNo,
  chillerPlantGraph,
  chillerGraphUnit,
  selectedYear,
  selectedMonth,
  selectedDay,
  selectedTimeType,
}) {
  const { chillerData, chilerDataBlock, chilerDelta } = data;
  return (
    <div>
      <Box className="box-wrapper" display={`flex`} flexDirection={`column`}>
        <Typography className="subtitle-header">
          การใช้พลังงานไฟฟ้าของ Chiller (Chiller Plant#{selectedPlantNo})
        </Typography>
        <D3LineChart
          graphData={chillerPlantGraph}
          unit={chillerGraphUnit}
          timeType={selectedTimeType}
        />
        <DownloadBox
          endPoint={`api/dashboard/central/downloadchillerPlant/chillerPlantElectricity/${
            buildingInfo ? buildingInfo.buildingId : 0
          }/${selectedPlantNo}/${selectedYear}/${selectedMonth}/${selectedDay}`}
        />
      </Box>
      <Box className="box-wrapper" display={`flex`} flexDirection={`column`}>
        {/* BOX */}
        <Typography className="subtitle-header">
          Chiller (Chiller Plant # {selectedPlantNo}) (Y/M/D){" "}
          {/* graphData, unit, timeType */}
        </Typography>

        <Box display={`flex`} className="box-wrapper" flexDirection={`column`}>
          {chillerList &&
            chillerList.map((item, index) => (
              <Box key={index}>
                <Box
                  key={uuid()}
                  display={`flex`}
                  flexDirection={`row`}
                  justifyContent={`space-between`}
                >
                  <Box display={`flex`} flexGrow={0.6}>
                    <img
                      src={item.isAlert ? AlarmRedIcon : AlarmGreenIcon}
                      alt={`alert icon`}
                    />
                  </Box>
                  <Box display={`flex`} flex={1}>
                    <Typography className="text-primary">
                      ข้อมูล ณ ช่วงเวลาที่เลือก
                    </Typography>
                  </Box>
                  <Box display={`flex`} flex={4} justifyContent={`end`}>
                    <Typography className="text-primary">
                      ข้อมูล ณ ปัจจุบัน
                    </Typography>
                  </Box>
                </Box>
                <Box key={uuid()} display={`flex`} flexDirection={`row`}>
                  {/* 1st COLUMN */}
                  <Box
                    display={`flex`}
                    flexDirection={`column`}
                    alignItems={`center`}
                    justifyContent={`center`}
                    flexGrow={0.1}
                  >
                    <Box display={`flex`} justifyContent={`end`}>
                      <img src={CompressorIcon} alt="machine icon" />
                    </Box>
                    <Box display={`flex`} justifyContent={`start`}>
                      <Typography className="large-title-header">{`CH-0${item.watherChillerNo}`}</Typography>
                    </Box>
                  </Box>

                  {/* 2nd COLUMN */}
                  <Box
                    display={`flex`}
                    flexDirection={`column`}
                    className="box-wrapper-light"
                    flexGrow={0.01}
                  >
                    <Box display={`flex`}>
                      <Typography className="subtitle-header-black">
                        Average Eff.
                      </Typography>
                    </Box>
                    <Box display={`flex`}>
                      <Typography className="large-title-header">
                        {item.averageEfficiency || "-"}
                      </Typography>
                    </Box>
                    <Box display={`flex`}>
                      <Typography className="text-sm-black">
                        {item.averageEfficiencyUnit || "-"}
                      </Typography>
                    </Box>
                    <Box display={`flex`} sx={{ mt: 1 }}>
                      <Typography className="subtitle-header-black">
                        Sum Energy
                      </Typography>
                    </Box>
                    <Box display={`flex`}>
                      <Typography className="large-title-header">
                        {item.sumEnergy || "-"}
                      </Typography>
                    </Box>
                    <Box display={`flex`}>
                      <Typography className="text-sm-black">
                        {item.sumEnergyUnit || "-"}
                      </Typography>
                    </Box>
                  </Box>

                  {/* 3rd COLUMN */}
                  <Box
                    display={`flex`}
                    flexDirection={`row`}
                    className="box-wrapper"
                    flex={1}
                  >
                    {/* 3.1 */}
                    <Box
                      display={`flex`}
                      flexDirection={`column`}
                      sx={{ mr: 2 }}
                    >
                      <Box display={`flex`}>
                        <Typography className="subtitle-header-black">
                          Power
                        </Typography>
                      </Box>
                      <Box display={`flex`} flexDirection={`column`}>
                        <Typography className="text-sm-black">
                          <br />
                        </Typography>
                        <Typography className="large-title-header">
                          {item.power || "-"}
                        </Typography>
                      </Box>
                      <Box display={`flex`}>
                        <Typography className="text-sm-black">
                          {item.powerUnit || "-"}
                        </Typography>
                      </Box>
                      <Box display={`flex`}>
                        <Typography className="subtitle-header-black">
                          Current Limit
                        </Typography>
                      </Box>
                      <Box display={`flex`} flexDirection={`column`}>
                        <Typography className="text-sm-black">
                          <br />
                        </Typography>
                        <Typography className="large-title-header">
                          {item.limit}
                        </Typography>
                      </Box>
                      <Box display={`flex`}>
                        <Typography className="text-sm-black">
                          {item.limitUnit}
                        </Typography>
                      </Box>
                    </Box>
                    {/* 3.2 */}
                    <Box display={`flex`} flexDirection={`column`}>
                      <Box display={`flex`}>
                        <Typography
                          className="subtitle-header-black"
                          sx={{ flexWrap: "nowrap" }}
                        >
                          Chilled Setpoint
                        </Typography>
                      </Box>
                      <Box display={`flex`} flexDirection={`column`}>
                        <Typography className="text-sm-black">
                          <br />
                        </Typography>
                        <Typography className="large-title-header">
                          {item.chilledSetPoint || "-"}
                        </Typography>
                      </Box>
                      <Box display={`flex`}>
                        <Typography className="text-sm-black">
                          <span>{item.chilledSetPointUnit}</span>
                        </Typography>
                      </Box>
                      <Box display={`flex`}>
                        <Typography className="subtitle-header-black">
                          Comp. Run Hour
                        </Typography>
                      </Box>
                      <Box display={`flex`} flexDirection={`column`}>
                        <Typography className="text-sm-black">
                          <br />
                        </Typography>
                        <Typography className="large-title-header">
                          {item.compRun}
                        </Typography>
                      </Box>
                      <Box display={`flex`}>
                        <Typography className="text-sm-black">
                          {item.compRunUnit}
                        </Typography>
                      </Box>
                    </Box>

                    {/* DIVIDER */}
                    <Box display={`flex`} sx={{ ml: 2, mr: 2 }}>
                      <div className="custom-divider-vertical-primary"></div>
                    </Box>

                    {/* 3.3 */}
                    <Box
                      display={`flex`}
                      flexDirection={`column`}
                      flex={3}
                      sx={{ minWidth: "800px" }}
                    >
                      <Box
                        display={`flex`}
                        flexDirection={`row`}
                        // justifyContent={`space-around`}
                      >
                        <Box display={`flex`}>
                          <Typography className="subtitle-header-black" style={{ marginRight: '150px'}}>
                            Chilled Water
                          </Typography>
                        </Box>
                        <Box display={`flex`}>
                          <Typography className="subtitle-header-black" style={{ marginRight: '150px'}}>
                            Refrigerant
                          </Typography>
                        </Box>
                        <Box display={`flex`}>
                          <Typography className="subtitle-header-black">
                            Condenser
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display={`flex`}
                        flexDirection={`row`}
                        // justifyContent={`space-around`}
                      >
                        {chilerDataBlock &&
                          chilerDataBlock.map((c, index) => (
                            <Box key={index} display="flex">
                              {/* ENTERING */}
                              <Box
                                display={`flex`}
                                flexDirection={`column`}
                                flex={1}
                              >
                                <Box display={`flex`} flexDirection={`column`}>
                                  <Typography className="text-sm-black" style={{minHeight: '50px', minWidth: '90px', marginRight: '40px'}}>
                                    {c.id == 1 && (`Entering-Temp` || "-")}
                                    {c.id == 2 && (`Suction-Temp` || "-")}
                                    {c.id == 3 && (`Leaving-Temp` || "-")}
                                  </Typography>
                                  <Typography className="large-title-header">
                                    {c.id == 1 && (item.waterEntering || "-")}
                                    {c.id == 2 &&
                                      (item.refrigerantSuction || "-")}
                                    {c.id == 3 &&
                                      (item.condenserLeaving || "-")}
                                  </Typography>
                                </Box>
                                <Box display={`flex`}>
                                  <Typography className="text-sm-black">
                                    <span>{item.condenserLeavingUnit}</span>
                                  </Typography>
                                </Box>
                              </Box>
                              {/* LEAVING */}
                              <Box
                                display={`flex`}
                                flexDirection={`column`}
                                flex={1}
                              >
                                <Box display={`flex`} flexDirection={`column`}>
                                  <Typography className="text-sm-black" style={{minHeight: '50px',  marginRight: '40px'}}>
                                    {c.id == 1 && (`Leaving-Temp` || "-")}
                                    {c.id == 2 && (`Discharge-Temp` || "-")}
                                    {c.id == 3 && (`Entering-Temp` || "-")}{" "}
                                  </Typography>
                                  <Typography className="large-title-header">
                                    {c.id == 1 && (item.waterLeavinging || "-")}
                                    {c.id == 2 &&
                                      (item.condenserDisCharge || "-")}
                                    {c.id == 3 &&
                                      (item.condenserEntering || "-")}
                                  </Typography>
                                </Box>
                                <Box display={`flex`}>
                                  <Typography className="text-sm-black">
                                    <span>{item.condenserEnteringUnit}</span>
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          ))}
                      </Box>

                      <Box
                        display={`flex`}
                        flexDirection={`row`}
                        justifyContent={`start`}
                        className={`link-box-wrapper`}
                      >
                        <Box className="link-box"></Box>
                        <Box className="link-box"></Box>
                        <Box className="middle-link-box"></Box>
                        <Box className="link-box"></Box>
                        <Box className="last-link-box"></Box>
                      </Box>

                      {/* DELTA */}
                      <Box
                        display={`flex`}
                        flexDirection={`row`}
                        // justifyContent={`space-around`}
                      >
                        {chilerDelta &&
                          chilerDelta.map((c, index) => (
                            <Box key={index}>
                              {/* ENTERING */}
                              <Box
                                display={`flex`}
                                flexDirection={`column`}
                                flex={1}
                              >
                                <Box display={`flex`} flexDirection={`column`}
                                style={{minHeight: '50px',  marginRight: '40px'}}
                                >
                                  <Typography className={`text-sm-black ${index == 1 ? `mr-30` : ``}`} style={{maxWidth: '100px',  marginRight: '10px'}}>
                                    {c.text}
                                  </Typography>
                                  <Typography className="large-title-header">
                                    {c.id == 1 && (item.waterDelta || "-")}
                                    {c.id == 2 &&
                                      (item.waterEvapAggroach || "-")}
                                    {c.id == 3 &&
                                      (item.condenserCondApproach || "-")}
                                    {c.id == 4 && (item.condenserDelta || "-")}
                                  </Typography>
                                </Box>
                                <Box display={`flex`}>
                                  <Typography className="text-sm-black">
                                    <span>{item.condenserLeavingUnit}</span>
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </div>
  );
}
