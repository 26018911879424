//dev
export const LOCAL_URL_HTTPS = "https://localhost:5001"
export const LOCAL_URL_HTTP = "http://localhost:5000"
export const DEV_URL = "https://ensolapi.hostcyber4u.com"
export const DEV_THAIBEV_URL = "http://ftddev.apps.thaibev.com/bemapi";
export const UAT_URL = "https://otcuat.apps.thaibev.com/horecaapi"

export const BASE_URL = "https://ensolapi.hostcyber4u.com"
export const APP_NAME = "BEM"
export const ENV = "PROD"

// NOUSE
export const GET_HTTP_ERROR_MESSAGE = (statusCode) => {
  let message = '';
  switch (statusCode) {
    case -1: 
      message = "ขออภัยเกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง"
    case 400:
      message = "BAD REQUEST";
      break;
    case 401:
      message = "UNAUTHENTICATED";
      break;
    case 403:
      message = "UNAUTHORIZED";
      break;
    default:
      message = "UNKNOWN ERROR"
      break;
  }
  return message;
}

export const REGISTER_TYPE = {
  General: 1,
  Building: 3,
  Officer: 2
} 

export const HTTP_STATUS = {
  OK: 200,
  INTERNAL_SERVER_ERROR: 500
}

export const ALERT_DISMISS_TIME = 5000;
export const GRID_PAGE_SIZE = 15;

export const ALERT_TYPE = {
  alertError: "error",
  alertSuccess: "success", 
  alertInfo: "info"
}

export const DIALOG_TYPE = {
  DELETE: 0,
  SAVE: 1
}

export const ExceptionInterceptor = [
  `${BASE_URL}/api/building`,
]

export const TIME_ZONE = {
  AsiaBangkok: 'Asia/Bangkok'
}

export const X_AXIS_TIME_TYPE = {
  TIME: 0,
  DAY: 1,
  MONTH: 2
}

export const LAUNCH_STATUS_FEATURE = {
  HOME: true,
  BUILDING_INFO: true,
  USER_MANAGEMENT: true,
  DASHBOARD: true 
}

export const ENERGY_USE_TYPE = {
  ALL: 0,
  BY_BUILDING: 1,
  CONTROL: 2
}


export default {
  BASE_URL,
  //BASE_URL_PROD,
  APP_NAME,
  ENV,
  GET_HTTP_ERROR_MESSAGE,
  REGISTER_TYPE,
  ALERT_DISMISS_TIME,
  GRID_PAGE_SIZE,
  DIALOG_TYPE,
  ENERGY_USE_TYPE
};
