import { Box } from "@mui/material";
import React, {useState } from "react";
import SideMenu from "../components/Common/SideMenu";

export default function Layout({ children }) {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <SideMenu />
        <Box
          component="main"
          sx={{ flex: 1}}
          className={`main-wrapper`}
        >
          {children}
        </Box>
      </Box>
    </>
  );
}
