import React from 'react'
import { Box, Typography, OutlinedInput, FormControl, InputProps, FormHelperText } from '@mui/material'
import { v4 as uuidv4 } from 'uuid';

export default function CustomTextInput({ register, errors, item }) {
  return (
    <>
      <Box key={uuidv4()}>
        <FormControl
          variant='outlined'
          sx={{ mt: 2, width: '100%' }}
        >
          <OutlinedInput
            aria-describedby={`outlined-${item.name}-helper-text`}
            {...register}
            variant={item.variant}
            type={item.type}
            name={item.name}
            id={item.inputId}
            placeholder={item.placeholder}
            sx={{ background: '#FFFFFF', borderRadius: 2 }}
            inputProps={{
              readOnly: item.readOnly,
            }}
          />
        </FormControl>
        {errors[`${item.name}`] && (
          <FormHelperText
            id={`outlined-${item.name}-helper-text`}
          >
            {errors[`${item.name}`]?.message}
          </FormHelperText>
        )}
      </Box>
    </>
  )
}
