import React, { useState  } from 'react'
import { Modal,Box,Button,Typography,Backdrop  } from '@mui/material';
import { data } from './PdPaText';

export default function PdPdModal({ isOpen, handleClose }) {
  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="pdpa-modal-title"
        aria-describedby="pdpa-modal-description"
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <Typography id="pdpa-modal-title" variant="h6" component="h2">
            นโยบายคุ้มครองข้อมูลส่วนบุคคล
          </Typography>
          <Box id="pdpa-modal-description" sx={{ mt: 2 }}>
            { data.pdPaText }
          </Box>
        </Box>
      </Modal>
    </>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
