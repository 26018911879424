const initialState = {
  menus: [],
  currentMenu: null,
  userInfo: null,
  activeMenu: {},
  log: {}, 
  isFetching: false,
  isError: false,
};

const menuReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_CURRENT_ACTIVE_MENU":
      return { ...state, currentMenu: payload };
    case "SET_USER_INFO":
      return { ...state, userInfo: payload };
    case "SAVE_ACTIVITY_SUCCESS": 
    return { ...state, log: payload };
    default:
      return state;
  }
};

export default menuReducer;
