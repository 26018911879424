export const data = {
  pdPaText: <>
  <div className="entry-content">
  <p><span style={{fontWeight: 400}}>บริษัท เอ็นโซ จำกัด (ต่อไปนี้จะเรียกว่า ‘บริษัท/เรา’) เป็นผู้ให้บริการเว็บไซต์ </span><a href="https://ensol.hostcyber4u.com/"><b>https://ensol.hostcyber4u.com/</b></a><span style={{fontWeight: 400}}> รวมไปถึงแพลตฟอร์มออนไลน์ และช่องทางโซเชียลมีเดียอื่นๆ ในเครือ บริษัทขอเรียนว่า บริษัทเคารพความเป็นส่วนตัวของผู้ใช้งาน และตระหนักดีว่าข้อมูลส่วนบุคคลของผู้ใช้งานแต่ละท่านมีความสำคัญอย่างยิ่ง บริษัทจึงต้องการที่จะชี้แจงให้ทราบเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>ดังนั้น บริษัทจึงได้มีนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ เพื่อชี้แจงรายละเอียดและ วิธีการจัดเก็บรวบรวม ใช้ และ/หรือเปิดเผย การคุ้มครองข้อมูล การเข้าถึงข้อมูล การโอนย้าย และการวิเคราะห์ประมวลผลข้อมูลส่วนบุคคลของท่าน ดังต่อไปนี้</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>ข้อมูลส่วนบุคคลในทีนี้หมายถึงข้อมูลต่างๆ ในการทำการลงทะเบียนผ่านเว็บไซต์ BEM, BEM Member, การลงทะเบียนเข้าร่วมกิจกรรมต่างๆ ของบริษัท คุกกี้ ข้อมูลการทำรายการ และประสบการณ์การใช้งาน</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>นโยบายการคุ้มครองข้อมูลส่วนบุคคล (Privacy Policy) นี้อยู่ภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 โดยบริษัทมีอำนาจหน้าที่ตัดสินใจเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล ซึ่งตามกฎหมายเรียกว่า ‘ผู้ควบคุมข้อมูลส่วนบุคคล’ โดยมีพนักงานที่บริษัทมอบหมายโดยเฉพาะให้มีหน้าที่ดำเนินการเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของบริษัท ซึ่งตามกฎหมายเรียกว่า ‘ผู้ประมวลผลข้อมูลส่วนบุคคล’ ส่วนท่านถือเป็น ‘เจ้าของข้อมูลส่วนบุคคล’ ตามกฎหมายนี้</span></p>
  <p>&nbsp;</p>
  <p><strong>ข้อ 1. ข้อมูลส่วนบุคคลอะไรบ้างที่บริษัทเก็บรวบรวม ใช้ และ/หรือเปิดเผย</strong></p>
  <p><span style={{fontWeight: 400}}><br />
      เราจะเก็บรวบรวมข้อมูลส่วนบุคคลซึ่งเป็นข้อมูลที่ทำให้สามารถระบุตัวตนของท่านได้ ไม่ว่าทางตรงหรือทางอ้อม ได้แก่ ข้อมูลที่ท่านให้ไว้โดยตรงจากการลงทะเบียนผ่านระบบ BEM Member การลงทะเบียนเข้าร่วมกิจกรรมต่างๆ ของบริษัท คุกกี้ ข้อมูลการทำรายการ และประสบการณ์การใช้งานผ่านหน้าเว็บไซต์ ผู้ที่ได้รับมอบหมาย หรือช่องทางอื่นใด เช่น</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล อายุ วันเดือนปีเกิด สถานภาพสมรส เลขประจำตัวประชาชน เลขหนังสือเดินทาง</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>ข้อมูลการติดต่อ เช่น ที่อยู่อาศัย สถานที่ทำงาน หมายเลขโทรศัพท์ อีเมล ไอดีไลน์</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>ข้อมูลอุปกรณ์หรือเครื่องมือ เช่น IP Address MAC Address Cookie ID</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>ข้อมูลอื่นๆ เช่น การใช้งานเว็บไซต์ เสียง ภาพนิ่ง ภาพเคลื่อนไหว และข้อมูลอื่นใดที่ถือว่าเป็นข้อมูลส่วนบุคคลภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล</span></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><b>ข้อ 2. วัตถุประสงค์ในการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูล</b></p>
  <p><span style={{fontWeight: 400}}><br />
      เราจะนำข้อมูลของท่านมาใช้เพื่อการพัฒนาและปรับปรุงเว็บไซต์ </span><a href="https://ensol.hostcyber4u.com"><span style={{fontWeight: 400}}>https://ensol.hostcyber4u.com</span></a><span style={{fontWeight: 400}}> แพลตฟอร์มออนไลน์ และช่องทางโซเชียลมีเดียอื่นๆ ในเครือ ตลอดจนการวิเคราะห์และประมวลผลข้อมูลส่วนบุคคล เพื่อให้ตอบโจทย์การใช้งานของผู้ใช้งาน ด้วยวิธีการทางอิเล็กทรอนิกส์แก่ท่านให้มีประสิทธิภาพมากยิ่งขึ้น</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>หากภายหลังมีการเปลี่ยนแปลงวัตถุประสงค์ บริษัทจะแจ้งให้ท่านทราบ เพื่อขอความยินยอม และจัดให้มีบันทึกการแก้ไขเพิ่มเติมไว้เป็นหลักฐาน</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>ทั้งนี้ บริษัทจะไม่กระทำการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน เพื่อประโยชน์อย่างอื่น ที่นอกเหนือจากวัตถุประสงค์ที่ได้แจ้งไว้กับท่านไว้ก่อนหรือขณะเก็บรวบรวม&nbsp;</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><b>ข้อ 3. การคุ้มครองข้อมูลส่วนบุคคล</b></p>
  <p><span style={{fontWeight: 400}}><br />
      บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้เป็นอย่างดีตามมาตรการเชิงเทคนิค (Technical Measure) และมาตรการเชิงบริหารจัดการ (Organizational Measure) เพื่อรักษาความมั่นคงปลอดภัยในการประมวลผลข้อมูลส่วนบุคคลที่เหมาะสม และเพื่อป้องกันการละเมิดข้อมูลส่วนบุคคล การสูญหาย การเข้าถึง ทำลาย ใช้ แปลง แก้ไข การนำข้อมูลไปใช้ หรือเปิดเผยข้อมูลนอกวัตถุประสงค์ หรือโดยไม่มีอำนาจหรือโดยไม่ชอบ</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>ก่อนที่บริษัทจะทำการเก็บรวบรวมข้อมูล ใช้ หรือเปิดเผยข้อมูลของท่าน บริษัทจะทำการขอความยินยอมจากท่านก่อน โดยการขอความยินยอมบริษัทจะทำโดยชัดแจ้ง เป็นหนังสือหรือทำโดยระบบอิเล็กทรอนิกส์&nbsp;</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>ทั้งนี้ ท่านมีความเป็นอิสระในการให้ความยินยอมในการที่บริษัทจะเก็บรวบรวม ใช้ หรือเปิดเผย ข้อมูลส่วนบุคคลของท่าน บริษัทจะไม่กำหนดเงื่อนไขในการให้ความยินยอมเพื่อให้เข้าถึงการรับบริการ หรือเข้าทำสัญญากับบริษัท หากข้อมูลส่วนบุคคลนั้นไม่มีความจำเป็นหรือเกี่ยวข้องสำหรับการเข้าทำสัญญาหรือการรับบริการนั้น</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>นอกจากนี้ ผู้บริหาร พนักงาน ลูกจ้าง ผู้รับจ้าง ตัวแทน ที่ปรึกษา และผู้รับข้อมูลจากบริษัทมีหน้าที่ต้องรักษาข้อมูลส่วนบุคคลตามมาตรการรักษาความลับที่บริษัทกำหนดขึ้น</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>ในกรณีที่ท่านเจ้าของข้อมูลส่วนบุคคลท่านใดเป็นผู้ยังไม่บรรลุนิติภาวะ ถือเป็นผู้เยาว์ตามประมวลกฎหมายแพ่งและพาณิชย์ การให้ความยินยอมของท่านต้องได้รับความยินยอมจากผู้ใช้อำนาจปกครองที่มีอำนาจกระทำแทนท่านด้วย หากผู้จะให้ความยินยอมนั้นเป็นผู้เยาว์ที่อายุไม่เกิน 10 ปี ต้องขอความยินยอมจากผู้ใช้อำนาจปกครองนั้นโดยตรง</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>ในกรณีเจ้าของข้อมูลส่วนบุคคลท่านใดเป็นบุคคลไร้ความสามารถหรือบุคคลเสมือนไร้ความสามารถ ต้องขอความยินยอมจากผู้อนุบาลหรือผู้พิทักษ์ที่มีอำนาจกระทำการแทนบุคคลไร้ความสามารถหรือบุคคลเสมือนไร้ความสามารถนั้นแล้วแต่กรณี</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>บริษัทจะดำเนินการให้ข้อมูลของท่านนั้นถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><b>ข้อ</b> <b>4. การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล</b></p>
  <p><b><br />
      การเก็บรวบรวมส่วนบุคคล </b><span style={{fontWeight: 400}}>บริษัทจะเก็บรวบรวมข้อมูลของท่านโดยขอความยินยอมจากท่านก่อน เว้นแต่กรณีที่กฎหมายให้อำนาจไว้ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 มาตรา 24 หรือ มาตรา 26</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>บริษัทจะเก็บรวบรวมข้อมูลของท่านไว้เป็นระยะเวลา 10 ปี นับแต่วันที่เลิกสัญญา</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>บริษัทจะเก็บรวบรวมข้อมูลของท่านเฉพาะเท่าที่จำเป็น ภายใต้วัตถุประสงค์ที่บริษัทได้แจ้งไว้ใน ข้อ 2.&nbsp;</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>ทั้งนี้ หากมีความจำเป็นที่ท่านจะต้องให้ข้อมูลส่วนบุคคลแก่บริษัทเพื่อปฏิบัติตามกฎหมายหรือสัญญา หรือเพื่อต้องเข้าทำสัญญากับบริษัท บริษัทจะแจ้งให้ท่านทราบก่อน และจะแจ้งผลกระทบหากท่านไม่ยินยอมให้ข้อมูลแก่บริษัท</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>บริษัทจะไม่เก็บรวบรวมข้อมูลของท่านจากแหล่งที่มาอื่นที่ไม่ใช่ของท่านโดยตรง เว้นแต่กรณีที่บริษัทได้แจ้งท่านถึงการเก็บรวบรวมข้อมูลของท่านจากแหล่งที่มาอื่นภายใน 30 วัน และได้รับความยินยอมจากท่านแล้ว หรือเป็นการเก็บรวบรวมที่ได้รับการยกเว้นไม่ต้องขอความยินยอม ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 มาตรา 24 หรือมาตรา 26</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>บริษัทจะไม่เก็บรวบรวมข้อมูลส่วนบุคคลที่เกี่ยวกับเชื้อชาติ เผ่าพันธ์ุ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ หรือข้อมูลอื่นใดซึ่งกระทบต่อท่านในทำนองเดียวกันตามประกาศของคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล โดยไม่ได้รับความยินยอมโดยชัดแจ้งจากท่าน เว้นแต่เป็นกรณีตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 มาตรา 26 บัญญัติให้กระทำได้</span></p>
  <p>&nbsp;</p>
  <p><b>การใช้ หรือเปิดเผยข้อมูลส่วนบุคคล</b><span style={{fontWeight: 400}}> บริษัทจะไม่ ใช้ หรือเปิดเผยข้อมูลของท่านก่อนที่จะได้รับความยินยอมจากท่าน เว้นแต่เป็นข้อมูลของท่านที่เก็บรวบรวมได้โดยไม่ต้องขอความยินยอม ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 มาตรา 24 หรือมาตรา 26</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>บริษัทจะดูแลให้พนักงานที่ได้รับมอบหมายในเรื่องนี้โดยเฉพาะ มิให้ ใช้ หรือเปิดเผย แสดง หรือทำให้ปรากฏในลักษณะอื่นใดซึ่งข้อมูลส่วนบุคคลของท่าน นอกเหนือไปจากวัตถุประสงค์ ภายใต้หลักเกณฑ์ที่กฎหมายอนุญาตให้เปิดเผยได้ตามขอบเขตที่ท่านได้ให้ความยินยอม หรือขอบเขตที่เกี่ยวข้องในนโยบายฉบับนี้</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>ในกรณีที่บริษัทจะเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลหรือนิติบุคคลภายนอก บุคคลหรือนิติบุคคลภายนอกนั้นจะต้องแจ้งวัตถุประสงค์ในการ ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านให้บริษัททราบก่อน และต้องไม่ใช้หรือเปิดเผยข้อมูลส่วนบุคคล นอกเหนือจากวัตถุประสงค์ที่ได้ให้ไว้แก่บริษัท</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของท่านแก่บริษัทผู้ร่วมวิเคราะห์ข้อมูล เพื่อการวิเคราะห์และพัฒนาปรับปรุงผลิตภัณฑ์และ/หรือบริการ การทำวิจัยหรือจัดทำข้อมูลทางสถิติ การบริหารกิจการ และการส่งเสริมการตลาด เช่น การประชาสัมพันธ์กิจกรรม ผลิตภัณฑ์ และ/หรือบริการ ให้แก่ท่าน</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>ในกรณีที่บริษัท ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่ได้รับยกเว้นไม่ต้องขอความยินยอม บริษัทจะบันทึกการใช้หรือเปิดเผยข้อมูลนั้นเป็นหนังสือหรือระบบอิเล็กทรอนิกส์</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>ในกรณีที่บริษัทส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ ประเทศปลายทางหรือองค์กรระหว่างประเทศที่รับข้อมูลส่วนบุคคลต้องมีมาตราฐานคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอ ทั้งนี้ ต้องเป็นไปตามหลักเกณฑ์การคุ้มครองข้อมูลส่วนบุคคลตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลกำหนด เว้นแต่เป็นกรณีตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 มาตรา 28 (1) – (6)</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><b>ข้อ 5. สิทธิของท่านเกี่ยวกับข้อมูลส่วนบุคคล</b></p>
  <p><span style={{fontWeight: 400}}><br />
      สิทธิของท่านในข้อนี้เป็นสิทธิตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 และกฎหมายอื่นที่เกี่ยวข้องที่ท่านควรทราบ โดยท่านสามารถขอใช้สิทธิต่างๆ ได้ภายใต้ข้อกำหนดของกฎหมาย และนโยบายที่บริษัทกำหนดไว้ก่อนหรือในขณะหรือที่จะมีการแก้ไขเพิ่มเติมในอนาคต ตลอดจนหลักเกณฑ์ตามที่บริษัทกำหนดขึ้น</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>5.1 สิทธิในการขอถอนความยินยอม: ท่านมีสิทธิที่จะถอนความยินยอมเมื่อใดก็ได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่กับบริษัท เว้นแต่มีข้อจำกัดสิทธินั้นโดยกฎหมายหรือมีสัญญาที่ให้ประโยชน์แก่ท่านอยู่ (ไม่ว่าจะเป็นความยินยอมที่ท่านให้ไว้ก่อนวันที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลใช้บังคับหรือหลังจากนั้น)</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>5.2 สิทธิในการขอเข้าถึง ขอรับข้อมูลหรือสำเนาข้อมูล: ท่านมีสิทธิขอเข้าถึง หรือรับ ข้อมูลส่วนบุคคลของท่านเองที่อยู่ในความรับผิดชอบของบริษัท และขอให้บริษัททำสำเนาข้อมูลดังกล่าวให้แก่ท่าน รวมถึงขอให้เปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่ท่านไม่ได้ให้ความยินยอมว่าบริษัทได้ข้อมูลส่วนบุคคลของท่านมาได้อย่างไร</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>5.3 สิทธิในการคัดค้าน: ท่านมีสิทธิคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเมื่อใดก็ได้ ในกรณีที่กฎหมายให้อำนาจบริษัทในการเก็บรวบรวมข้อมูลของท่านได้โดยไม่ต้องขอความยินยอมจากท่านก่อน</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>5.4 สิทธิขอให้แก้ไขข้อมูล: ท่านมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลของท่านให้ถูกต้อง เป็นปัจจุบัน และไม่ก่อให้เกิดความเข้าใจผิด</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>5.5 สิทธิขอให้ลบหรือทำลายข้อมูล: ท่านมีสิทธิขอลบหรือทำลายข้อมูลส่วนบุคคลของท่าน หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของได้ ดังต่อไปนี้</span></p>
  <p>&nbsp;</p>
  <ol>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>&nbsp;ข้อมูลของท่านไม่มีความจำเป็นที่จะเก็บรักษาไว้ตามวัตถุประสงค์ในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลของท่าน&nbsp;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>&nbsp;เมื่อท่านได้ใช้สิทธิขอถอนความยินยอมและบริษัทไม่มีอำนาจตามกฎหมายที่จะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านได้&nbsp;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>&nbsp;เมื่อท่านใช้สิทธิคัดค้านตาม ข้อ 5.3 และบริษัทไม่อาจปฏิเสธคำขอได้ตามกฎหมาย</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>&nbsp;เมื่อข้อมูลของท่านได้ถูกเก็บ ใช้ หรือเปิดเผยโดยไม่ชอบด้วยกฎหมาย</span></li>
  </ol>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>5.6 สิทธิขอให้ระงับการใช้ข้อมูล: ท่านมีสิทธิขอให้บริษัทระงับการใช้ข้อมูลของท่าน ในกรณีดังต่อไปนี้</span></p>
  <p>&nbsp;</p>
  <ol>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>&nbsp;เมื่อบริษัทอยู่ในระหว่างการตรวจสอบตามที่ท่านร้องขอ</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>&nbsp;เมื่อเป็นข้อมูลส่วนบุคคลที่ต้องลบหรือทำลาย แต่ท่านขอให้ระงับการใช้แทน</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>&nbsp;เมื่อข้อมูลของท่านหมดความจำเป็นในการเก็บรักษาตามวัตถุประสงค์ในการเก็บรวบรวมข้อมูลของท่าน แต่ท่านมีความจำเป็นต้องขอให้เก็บรักษาไว้เพื่อใช้ในการตั้งสิทธิเรียกร้องตามกฎหมายการปฏิบัติตามหรือการใช้สิทธิเรียกร้องตามกฎหมาย หรือการยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>&nbsp;เมื่อบริษัทอยู่ระหว่างพิสูจน์คำขอคัดค้านของท่านตามข้อ 5.3 เพื่อดูว่าบริษัทมีอำนาจตามกฎหมายให้ปฏิเสธคำคัดค้านของท่านได้หรือไม่</span></li>
  </ol>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>5.7 ท่านมีสิทธิร้องขอให้บริษัทดำเนินการให้ข้อมูลของท่านนั้นถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด หากบริษัทไม่ดำเนินการตามคำร้องขอ บริษัทจะบันทึกคำร้องขอของท่านพร้อมด้วยเหตุผลไว้เป็นหนังสือหรือระบบอิเล็กทรอนิกส์</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>5.8 สิทธิร้องเรียน: ท่านมีสิทธิร้องเรียนต่อผู้มีอำนาจตามกฎหมายที่เกี่ยวข้อง หากท่านเชื่อว่าการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเป็นการกระทำในลักษณะที่ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้อง</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>5.9 การใช้สิทธิของท่านดังกล่าวข้างต้นอาจถูกจำกัดภายใต้กฎหมายที่เกี่ยวข้อง และมีบางกรณีที่มีเหตุจำเป็นที่บริษัทอาจปฏิเสธหรือไม่สามารถดำเนินการตามคำขอใช้สิทธิข้างต้นของท่านได้ เช่น ต้องปฏิบัติตามกฎหมายหรือคำสั่งศาล การใช้สิทธิละเมิดสิทธิหรือเสรีภาพของบุคคลอื่น เป็นต้น</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><b>ข้อ 6. การเชื่อมโยงข้อมูลส่วนบุคคลกับบุคคลอื่นหรือหน่วยงานอื่น</b></p>
  <p><span style={{fontWeight: 400}}><br />
      6.1 บริษัทอาจเชื่อมโยงข้อมูลส่วนบุคคลกับบุคคลหรือหน่วยงานอื่น โดยบริษัทจะแจ้งให้ท่านทราบก่อนที่จะทำการเชื่อมโยงข้อมูลส่วนบุคคล พร้อมทั้งขอความยินยอม โดยมีรายละเอียดต่อไปนี้เป็นอย่างน้อย&nbsp;</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>บุคคลหรือหน่วยงานที่จะทำการเชื่อมโยงข้อมูลส่วนบุคคล&nbsp;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>วัตถุประสงค์ในการเชื่อมโยงข้อมูลส่วนบุคคล&nbsp;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>วิธีการในการเชื่อมโยงข้อมูลส่วนบุคคล&nbsp;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>ข้อมูลส่วนบุคคลที่จะทำการเชื่อมโยง&nbsp;</span></li>
  </ul>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>6.2 หากมีการเปลี่ยนแปลงการเชื่อมโยงข้อมูล บริษัทจะแจ้งให้ท่านทราบถึงการเปลี่ยนแปลงดังกล่าว และขอความยินยอมก่อนการดำเนินการ</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><b>ข้อ 7. การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล</b></p>
  <p><span style={{fontWeight: 400}}><br />
      บริษัทอาจปรับปรุงนโยบายส่วนบุคคลนี้เป็นครั้งคราว เพื่อให้สอดคล้องกับการเปลี่ยนแปลงของการให้บริการและ/หรือผลิตภัณฑ์ของบริษัท การดำเนินงานของบริษัท และข้อเสนอแนะหรือความคิดเห็นจากท่าน โดยบริษัทจะประกาศแจ้งการเปลี่ยนแปลงให้ทราบอย่างชัดเจนก่อนจะเริ่มดำเนินการเปลี่ยนแปลง หรืออาจส่งประกาศให้ท่านทราบโดยตรง</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><b>ข้อ 8. คุกกี้ (Cookies) คืออะไร?</b></p>
  <p><span style={{fontWeight: 400}}><br />
      คุกกี้ (Cookies) คือไฟล์ที่เว็บไซต์ที่คุณเข้าชมสร้างขึ้น ซึ่งช่วยให้คุณทำสิ่งต่างๆ ทางออนไลน์ได้ง่ายขึ้นด้วยการบันทึกข้อมูลการท่องเว็บ โดยเว็บไซต์ใช้คุกกี้เพื่อให้คุณลงชื่อเข้าใช้อยู่เสมอ จดจำค่ากำหนดเว็บไซต์ และมอบเนื้อหาที่เกี่ยวข้องให้แก่คุณ&nbsp;</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><b>ข้อ 9. เราใช้คุกกี้อย่างไร?</b></p>
  <p><span style={{fontWeight: 400}}><br />
      เราจะจัดเก็บข้อมูลการเข้าเยี่ยมชมเว็บไซต์จากผู้เข้าเยี่ยมชมทุกรายผ่านคุกกี้หรือ เทคโนโลยีใกล้เคียง เพื่อพัฒนาประสิทธิภาพในการใช้งานแพลตฟอร์มและการเข้าถึงบริการของเราผ่านระบบอินเทอร์เน็ต โดยจะใช้เพื่อกรณีดังต่อไปนี้</span></p>
  <p>&nbsp;</p>
  <ol>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>เพื่อให้ท่านสามารถเข้าสู่ระบบบัญชีของท่านในแพลตฟอร์มของเราได้อย่างต่อเนื่องและปลอดภัย</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>เพื่อบันทึกข้อมูลการใช้งานบนแพลตฟอร์มของท่าน เนื้อหาข้อมูล รวมถึงรูปแบบแพลตฟอร์มที่ท่านได้ตั้งค่าไว้</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>เพื่อศึกษาพฤติกรรมการใช้งานและเยี่ยมชมแพลตฟอร์มของท่าน เพื่อพัฒนาและปรับปรุงเว็บไซต์ให้ตรงกับความต้องการของท่านมากที่สุด</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>เพื่อศึกษาพฤติกรรมของผู้เยี่ยมชมแพลตฟอร์มโดยรวม และนำไปพัฒนาเว็บไซต์ให้สามารถใช้งานได้ง่าย รวดเร็ว และมีประสิทธิภาพยิ่งขึ้น</span></li>
  </ol>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><b>ข้อ 10. ประเภทของคุกกี้ที่เราใช้</b></p>
  <p><span style={{fontWeight: 400}}><br />
      เว็บไซต์ของเราประกอบไปด้วยคุกกี้ดังต่อไปนี้</span></p>
  <ol>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>คุกกี้ประกอบการทำงานของแพลตฟอร์ม (Functionality Cookies): ใช้เพื่อการจดจำสิ่งที่ท่านเลือกหรือตั้งค่าบนแพลตฟอร์ม รวมไปถึงการนำเสนอข้อมูลที่ตรงความต้องการเฉพาะบุคคล เช่น ชื่อบัญชีผู้ใช้ ภาษา และรูปแบบแพลตฟอร์ม</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>คุกกี้สำหรับการโฆษณา (Advertising Cookies): ใช้ในการจดจำสิ่งที่ท่านเคยเยี่ยมชม รวมไปถึงลักษณะการใช้งานของท่าน เพื่อนำเสนอสินค้า บริการ หรือ สื่อโฆษณาที่เกี่ยวข้องและตรงกับความสนใจของท่าน และประเมินประสิทธิผลของแคมเปญโฆษณาต่างๆ</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>คุกกี้ทางเทคนิค (Strictly Necessary Cookies): เป็นประเภทคุกกี้ที่มีความจำเป็นต่อการใช้งานแพลตฟอร์ม เพื่อให้ท่านเข้าถึงข้อมูลได้อย่างทั่วถึงและปลอดภัย</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>คุกกี้เพื่อวัดผลการทำงานของแพลตฟอร์ม (Performance Cookies): ใช้ในการจัดเก็บข้อมูลของผู้เข้าชมแพลตฟอร์มแบบไม่ระบุตัวตน และนำมาวิเคราะห์จำนวนและพฤติกรรมของผู้เข้าชม เพื่อปรับปรุงแพลตฟอร์มให้มีประสิทธิภาพและตรงกับความต้องการของผู้ใช้มากขึ้น</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>คุกกี้บุคคลที่สาม (Third-party Cookies): คุกกี้ชนิดนี้จะถูกกำหนดใช้โดยผู้บริการซึ่งเป็นบุคคลที่สาม อาทิ Google Analytics</span></li>
  </ol>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><b>ข้อ 11. การตั้งค่าคุกกี้</b></p>
  <p><span style={{fontWeight: 400}}><br />
      ในกรณีที่คุณไม่ประสงค์ให้มีการเก็บรวบรวมข้อมูลของผู้ใช้บริการผ่านทางคุกกี้ ซอฟต์แวร์ และเครื่องมือการตรวจวัด คุณสามารถลบหรือปฏิเสธคุกกี้ หรือซอฟต์แวร์การตรวจวัดบางรายการผ่านทาง</span><span style={{fontWeight: 400}}>เบราว์เซอร์</span><span style={{fontWeight: 400}}>ได้ โดยหากคุณนำคุกกี้ออก คุณจะออกจากระบบเว็บไซต์ และระบบอาจลบค่ากำหนดที่บันทึกไว้ของคุณ ด้วยวิธีการดังนี้&nbsp;</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>การตั้งค่าคุกกี้ใน </span><a href="https://support.google.com/chrome/answer/95647"><span style={{fontWeight: 400}}>Chrome</span></a><span style={{fontWeight: 400}}>&nbsp;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>การตั้งค่าคุกกี้ใน </span><a href="https://support.apple.com/en-us/HT201265"><span style={{fontWeight: 400}}>Safari</span></a><span style={{fontWeight: 400}}> และ </span><a href="https://support.apple.com/en-us/HT201265"><span style={{fontWeight: 400}}>iOS</span></a><span style={{fontWeight: 400}}>&nbsp;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>การตั้งค่าคุกกี้ใน </span><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"><span style={{fontWeight: 400}}>Internet Explorer</span></a></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>การตั้งค่าคุกกี้ใน </span><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"><span style={{fontWeight: 400}}>Firefox</span></a><span style={{fontWeight: 400}}>&nbsp;</span></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><b>ข้อ 12. ท่านจะติดต่อบริษัทได้อย่างไร</b><span style={{fontWeight: 400}}>&nbsp;</span></p>
  <p><span style={{fontWeight: 400}}><br />
      หากท่านมีข้อเสนอแนะ หรือต้องการสอบถามข้อมูลเกี่ยวกับรายละเอียดการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคล รวมถึงการขอใช้สิทธิตามนโยบายฉบับนี้ ท่านสามารถติดต่อบริษัทได้ผ่านช่องทางดังนี้</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>อีเมล: </span><a href="mailto:info@ensol.hostcyber4u.com"><span style={{fontWeight: 400}}>info@ensol.hostcyber4u.com</span></a></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>โทร: 0 2203 1142 (วันเวลาทำการ จันทร์-ศุกร์ 09.30-17.00 น.)</span></li>
    {/* <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>สถานที่ติดต่อ: บริษัท เอ็นโซ จำกัด เลขที่ 23/100-102 ซอยศูนย์วิจัย ถนนพระราม 9 แขวงบางกะปิ เขตห้วยขวาง กรุงเทพฯ 10310</span></li> */}
  </ul>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>เมื่อคุณตกลงยอมรับนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ บริษัทจะถือว่าท่านยอมรับว่าการใช้บริการใดๆ ของบริษัทถือเป็นการยอมรับข้อกำหนดและเงื่อนไขการใช้บริการทั้งหมด และการตกลงยอมรับดังกล่าวมีผลสมบูรณ์</span></p>
</div>
</>
}