import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export default function SelectedTab({ pill }) {
  return (
    <>
      <Box
        display={`flex`}
        flexDirection={`row`}
        className="box-wrapper input-active"
        justifyContent={`space-between`}
        flex={1}
        sx={{ pr: 2 }}
        alignItems={`center`}
      >
        <Box display={`flex`} sx={{ mr: 3 }}>
          {pill.iconWhite}
        </Box>
        <Box display={`flex`}>
          <Typography sx={{ fontSize: "40px" }}>{pill.pillText}</Typography>
        </Box>
      </Box>
    </>
  );
}
