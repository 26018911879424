import jwt_decode from "jwt-decode";

const currentToken = localStorage.getItem("access_token");
const destroyToken = () => localStorage.removeItem("access_token");

const decodeToken = (token) => {
  try {
    const decoded = jwt_decode(token);
    return decoded;
  } catch (error) {
    console.log(error)
  }
};

const decryptedToken = currentToken && jwt_decode(currentToken);

function isTokenTimeExpired(exp) {
  if(!exp) {
    return true;
  }
  const expired = Date.now() >= exp * 1000;
  return expired;
}

function guard() {
  if (!currentToken) {
    window.location.href = "/signin";
  }
}

// this should check with api for more security 
export function ifTokenExistAndValidThenContinueUsingApp() {
  const localToken = localStorage.getItem("access_token");
  if (localToken) {
    try {
      const decoded = decodeToken(localToken);
      const isExpired = decoded && isTokenTimeExpired(decoded.exp);
      if (!isExpired) {
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error)  
    }
  }
}

export default {
  decodeToken,
  isTokenTimeExpired,
  guard,
  currentToken,
  destroyToken,
  decryptedToken,
};
