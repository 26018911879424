import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import {
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import "./Home.css";
import SearchIcon from "@mui/icons-material/Search";
import { data } from "../../uiData/UIData";
import { Messages } from "../../Utils";
import DropdownYearPill from "../Common/DropdownYearPill";
import DropdownMonthPill from "../Common/DropdownMonthPill";
import DropdownDayPill from "../Common/DropdownDayPill";
import { DownloadIcon } from "../../assets";
import AlertNotification from "../Common/AlertNotification";
import { D3LineChart } from "../Common/d3/D3LineChart";
import ApexDoughnutChart from "../Common/Apex/ApexDoughnutChart";
import AccumElectricityUse from "./AccumElectricityUse";
import AccumOilUse from "./AccumOilUse";
import AccumHeatUse from "./AccumHeatUse";
import TargetGrid from "./TargetGrid";
import SelectedTab from "./SelectedTab";
import TargetGroupTable from "./TargetGroupTable";
import { GET } from "../../Utils/ApiClient";
import HospitalIcon from "./../../assets/images/icons/HospitalIcon.svg";
import HospitalIconWhite from "./../../assets/images/icons/HospitalIconWhite.svg";
import SchoolIconWhite from "./../../assets/images/icons/SchoolIconWhite.svg";
import SchoolIcon from "./../../assets/images/icons/SchoolIcon.svg";
import OfficeIconWhite from "./../../assets/images/icons/OfficeIconWhite.svg";
import OfficeIcon from "./../../assets/images/icons/OfficeIcon.svg";
import HotelIcon from "./../../assets/images/icons/HotelIcon.svg";
import HotelIconWhite from "./../../assets/images/icons/HotelIconWhite.svg";
import ShoppingMallIcon from "./../../assets/images/icons/ShoppingMallIcon.svg";
import ShoppingMallIconWhite from "./../../assets/images/icons/ShoppingMallIconWhite.svg";
import {
  ALERT_DISMISS_TIME,
  BASE_URL,
  HTTP_STATUS,
  X_AXIS_TIME_TYPE,
} from "../../Utils/EnvUtils";
import SearchResultList from "../Common/SearchResultList";
import AlternativeEnergyPercent from "./AlternativeEnergyPercent";
import SideDataDonutTable from "./SideDataDonutTable";
import moment from "moment";

const defaultEnergyType = {
  id: 0,
  text: "รวมการใช้พลังงาน",
}
export default function Home() {
  const { homeTabPills, lineGraphPills } = data;
  const { OK } = HTTP_STATUS;
  const { NOTIFICATION_MESSAGE } = Messages;
  const [searchPhrase, setSearchPhrase] = useState("");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [activePill, setActivePill] = useState({
    id: 1,
    pillText: "ภาพรวมอาคารควบคุม ระดับประเทศ",
    buildingTypeId: 0,
    icon: null,
  });
  const [energyType, setEnergyType] = useState(activePill.buildingTypeId === 0 && defaultEnergyType);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedDay, setSelectedDay] = useState(
    moment(new Date()).format("D")
  );
  const [buildingEnergyUse, setBuildingEnergyUse] = useState();
  const [buildingEnergyUseEachType, setBuildingEnergyUseEachType] = useState();
  const [systemEnergyUseEachType, setSystemEnergyUseEachType] = useState();
  const [buildingEnergyUseByTime, setBuildingEnergyUseByTime] = useState();
  const [energyConsumption, setEnergyConsumption] = useState();
  const [energyConsumptionByArea, setEnergyConsumptionByArea] = useState();
  const [energyConsumptionByProvince, setEnergyConsumptionByProvince] =
    useState();
  const [buildingEnergyUseByTimeUnit, setBuildingEnergyUseByTimeUnit] =
    useState();
  const [buildingInfoList, setBuildingInfoList] = useState();
  const [buildingInfo, setBuildingInfo] = useState();
  const [selectedTimeType, setSelectedTimeType] = useState(
    X_AXIS_TIME_TYPE.TIME
  );
  const [selectedDate, setSelectedDate] = useState()

  useEffect(() => {
    return () => {
      setBuildingEnergyUse();
      setBuildingEnergyUseEachType();
      setBuildingEnergyUseByTime();
      setEnergyConsumption();
      setEnergyConsumptionByArea();
      setEnergyConsumptionByProvince();
      setBuildingEnergyUseByTimeUnit();
      setSelectedYear();
      setSelectedMonth();
      setSelectedDay();
    };
  }, []);

  useEffect(() => {
    if(activePill.buildingTypeId > 0) {
      setEnergyType(lineGraphPills[0])
    } else {
      setEnergyType(defaultEnergyType)
    }
  }, [activePill])
  
  useEffect(() => {
    let theSelectedDate = new Date();
    if(selectedDay != -1 && selectedMonth != -1) {
      theSelectedDate = moment(`${selectedYear}-${selectedMonth}-${selectedDay}`).format('YYYY-MM-DD HH:mm:ss');
    } else if(selectedDay == -1 && selectedMonth != -1) {
      theSelectedDate = moment(`${selectedYear}-${selectedMonth}-01`).format('YYYY-MM-DD HH:mm:ss');
    } else if (selectedDay == -1 && selectedMonth == -1) {
      theSelectedDate = moment(`${selectedYear}-${(new Date()).getMonth() + 1}-01`).format('YYYY-MM-DD HH:mm:ss');
    } else {
      theSelectedDate = moment(`${selectedYear}-${selectedMonth}-${selectedDay}`).format('YYYY-MM-DD HH:mm:ss');
    }
    
    setSelectedDate(new Date(theSelectedDate))
    fetchEnergyUse();
    fetchEnergyUseEachType();
    fetchEnergyUseEachSystem();
    fetchEnergyConsumption();
    fetchEnergyUseByTime();

    if (activePill.buildingTypeId !== 0) {
      fetchEnergyConsumptionByArea();
      fetchEnergyConsumptionByProvince();
    }
  }, [activePill, selectedYear, selectedMonth, selectedDay, energyType]);

  useEffect(() => {
    if (
      searchPhrase &&
      searchPhrase != "" &&
      buildingInfoList &&
      searchPhrase != (buildingInfo && buildingInfo.buildingControlName)
    ) {
      fetchBuildingInfo();
    } else {
      if (buildingInfoList && buildingInfoList.length > 0) {
        setBuildingInfoList([]);
      }
    }
  }, [searchPhrase]);

  async function fetchBuildingInfo() {
    try {
      const res = await GET(
        `building${!searchPhrase || searchPhrase == "" ? "" : `?search=`}${
          searchPhrase || ""
        }`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == OK) {
        setBuildingInfoList(data);
      } else {
        console.log(error);
        setAlert(true);
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setSeverity("error");
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchEnergyUse() {
    // Home/{buildingTypeId}/{year}/{month}/{day}
    try {
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const { buildingTypeId } = activePill;
      const response = await GET(
        `Home/${buildingTypeId}/${selectedYear}/${selectedMonth}/${selectedDay}?buildingId=${buildingId}`
      );
      const { data, status } = response;
      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        setBuildingEnergyUse(data);
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
        // todo : set alert
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchEnergyUseEachType() {
    // GET: Home/energyUse/{year}/{month}/{day}
    try {
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      // const response = await getEnergyUseEachBuilding(buildingId, selectedYear, selectedMonth, selectedDay)
      const response = await GET(
        `Home/energyUse/${selectedYear}/${selectedMonth}/${selectedDay}?buildingId=${buildingId}`
      );
      const { data, status } = response;
      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        setBuildingEnergyUseEachType(data);
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchEnergyUseEachSystem() {
    // GET: Home/energyUse/{year}/{month}/{day}
    try {
      // todo : add ?buildingId={buildingId}

      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const { buildingTypeId } = activePill;
      const response = await GET(
        `Home/electricityUse/${buildingTypeId}/${selectedYear}/${selectedMonth}/${selectedDay}?buildingId=${buildingId}`
      );
      // const response = await getEnergyUseEachSystem(buildingId, buildingTypeId, selectedYear, selectedMonth, selectedDay);
      const { data, status } = response;
      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        setSystemEnergyUseEachType(data);
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchEnergyUseByTime() {
    // GET: Home/energyUse/{year}/{month}/{day}
    try {
      // todo : add ?buildingId={buildingId}
      const response = await GET(
        `Home/energyUseByPeriod/${activePill.buildingTypeId}/${
          energyType.id
        }/${selectedYear}/${selectedMonth}/${selectedDay}?buildingId=${
          buildingInfo ? buildingInfo.buildingId : 0
        }`
      );
      const { data, status } = response;
      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        // setBuildingEnergyUseByTime(data);
        const modifiedGraphData =
          data &&
          data.data.map((item) => {
            let modifiedDateFormattedString = "";
            if (selectedMonth < 0) {
              modifiedDateFormattedString = `${selectedYear}-${item.xaxis}-01 00:01`;
            } else if (selectedDay < 0) {
              modifiedDateFormattedString = `${selectedYear}-${selectedMonth}-${item.xaxis} 00:01`;
            } else {
              modifiedDateFormattedString = `${selectedYear}-${selectedMonth}-${selectedDay} ${item.xaxis}`;
            }
            const theDate = new Date(modifiedDateFormattedString);
            return {
              timestamp: theDate,
              electricityUse: item.yaxis,
            };
          });
        setBuildingEnergyUseByTimeUnit(data.unit);
        setBuildingEnergyUseByTime(modifiedGraphData);
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchEnergyConsumption() {
    // GET:Home/energyConsumption/{buildingTypeId}/{year}/{month}
    if (!activePill) return;
    try {
      // todo : add ?buildingId={buildingId}
      const response = await GET(
        `Home/energyConsumption/${
          activePill.buildingTypeId
        }/${selectedYear}/${selectedMonth}?buildingId=${
          buildingInfo ? buildingInfo.buildingId : 0
        }`
      );
      const { data, status } = response;
      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        setEnergyConsumption(data);
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchEnergyConsumptionByArea() {
    // GET:Home/energyConsumption/{buildingTypeId}/{year}/{month}
    if (!activePill) return;
    try {
      // todo : add ?buildingId={buildingId}
      const response = await GET(
        `Home/energyConsumptionByArea/${
          activePill.buildingTypeId
        }/${selectedYear}/${selectedMonth}?buildingId=${
          buildingInfo ? buildingInfo.buildingId : 0
        }`
      );
      const { data, status } = response;
      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        setEnergyConsumptionByArea(data);
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchEnergyConsumptionByProvince() {
    // GET:Home/energyConsumption/{buildingTypeId}/{year}/{month}
    if (!activePill) return;
    try {
      // todo : add ?buildingId={buildingId}
      const response = await GET(
        `Home/energyConsumptionByProvince/${
          activePill.buildingTypeId
        }/${selectedYear}/${selectedMonth}?buildingId=${
          buildingInfo ? buildingInfo.buildingId : 0
        }`
      );
      const { data, status } = response;
      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        setEnergyConsumptionByProvince(data);
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  function handleOnSearchPhraseChanging(e) {
    e.preventDefault();
    const text = e.target.value;
    setSearchPhrase(text);
  }

  function onSelectYearHandler(year) {
    setSelectedYear(year);
    const daysInMonth = moment(
      new Date(`${year}-${selectedMonth}-01`)
    ).daysInMonth();
    if (selectedDay <= daysInMonth) {
      setSelectedDay(selectedDay);
    } else {
      const d = moment(new Date(`${year}-${selectedMonth}-${daysInMonth}`)).format(
        "D"
      );
      setSelectedDay(d);
    }
  }

  function onSelectMonthHandler(e) {
    const theMonth = e < 0 ? parseInt(e) : parseInt(e) + 1;
    if (theMonth < 0) {
      setSelectedDay(-1);
      // show 12 months
      setSelectedTimeType(X_AXIS_TIME_TYPE.MONTH);
    } else if (selectedDay < 0) {
      setSelectedTimeType(X_AXIS_TIME_TYPE.DAY);
    } else {
      setSelectedTimeType(X_AXIS_TIME_TYPE.TIME);
      const daysInMonth = moment(
        new Date(`${selectedYear}-${theMonth}-01`)
      ).daysInMonth();
      // if (theMonth === 2) {        
      //   if (selectedDay <= daysInMonth) {
      //     setSelectedDay(selectedDay);
      //   } else {
      //     const d = moment(new Date(`${selectedYear}-${theMonth}-${daysInMonth}`)).format(
      //       "D"
      //     );
      //     setSelectedDay(d);
      //   }
      // } else {
      //   setSelectedDay(selectedDay);
      // }
      if (selectedDay <= daysInMonth) {
        setSelectedDay(selectedDay);
      } else {
        const d = moment(new Date(`${selectedYear}-${theMonth}-${daysInMonth}`)).format(
          "D"
        );
        setSelectedDay(d);
      }
    }
    setSelectedMonth(theMonth);
  }

  function onSelectDayHandler(e) {
    if (e < 0) {
      setSelectedDay(e);
      // show 31 days
      setSelectedTimeType(X_AXIS_TIME_TYPE.DAY);
    } else {
      setSelectedTimeType(X_AXIS_TIME_TYPE.TIME);
    }
    setSelectedDay(e);
  }

  function hitEnter(e) {
    if (e.key === "Enter") {
      fetchBuildingInfo();
    }
  }

  function onSearchHandle() {
    fetchBuildingInfo();
  }

  function onBuildingSelectedHandler(item) {
    setBuildingInfo(item);
    setSearchPhrase(item.buildingControlName);
    setBuildingInfoList([]);
    const { buildingId, buildingControlName, buildingTypeId } = item;
    const icon = getIconNameByBuildingTypeId(buildingTypeId, "normal");
    const iconWhite = getIconNameByBuildingTypeId(buildingTypeId, "white");
    const thumbnailWhite = getIconNameByBuildingTypeId(
      buildingTypeId,
      "whiteThumbnail"
    );
    setActivePill({
      id: buildingId,
      pillText: buildingControlName,
      buildingTypeId: parseInt(buildingTypeId),
      icon,
      iconWhite,
      iconWhiteThumbNail: thumbnailWhite,
    });
  }

  function getIconNameByBuildingTypeId(buildingTypeId, type) {
    if (type == "normal") {
      switch (parseInt(buildingTypeId)) {
        case 1:
          return <img src={OfficeIcon} height={35} alt={buildingTypeId} />;
          break;
        case 2:
          return <img src={HotelIcon} height={35} alt={buildingTypeId} />;
          break;
        case 3:
          return <img src={HospitalIcon} height={35} alt={buildingTypeId} />;
          break;
        case 4:
          return (
            <img src={ShoppingMallIcon} height={35} alt={buildingTypeId} />
          );
          break;
        case 5:
          return <img src={SchoolIcon} height={35} alt={buildingTypeId} />;
          break;
        default:
          break;
      }
    } else if (type == "white") {
      switch (parseInt(buildingTypeId)) {
        case 1:
          return (
            <img src={OfficeIconWhite} height={100} alt={buildingTypeId} />
          );
          break;
        case 2:
          return <img src={HotelIconWhite} height={100} alt={buildingTypeId} />;
          break;
        case 3:
          return (
            <img src={HospitalIconWhite} height={100} alt={buildingTypeId} />
          );
          break;
        case 4:
          return (
            <img
              src={ShoppingMallIconWhite}
              height={100}
              alt={buildingTypeId}
            />
          );
          break;
        case 5:
          return (
            <img src={SchoolIconWhite} height={100} alt={buildingTypeId} />
          );
          break;
        default:
          break;
      }
    } else if (type == "whiteThumbnail") {
      switch (parseInt(buildingTypeId)) {
        case 1:
          return <img src={OfficeIconWhite} height={35} alt={buildingTypeId} />;
          break;
        case 2:
          return <img src={HotelIconWhite} height={35} alt={buildingTypeId} />;
          break;
        case 3:
          return (
            <img src={HospitalIconWhite} height={35} alt={buildingTypeId} />
          );
          break;
        case 4:
          return (
            <img src={ShoppingMallIconWhite} height={35} alt={buildingTypeId} />
          );
          break;
        case 5:
          return <img src={SchoolIconWhite} height={35} alt={buildingTypeId} />;
          break;
        default:
          break;
      }
    }
  }

  return (
    <>
      <Layout>
        <AlertNotification
          alert={alert}
          severity={severity}
          message={message}
        />

        {/* WRAPPER  */}
        <Box sx={{ bgcolor: "rgb(37, 161, 142, .2)" }}>
          {/* <Container maxWidth={`lg`} sx={{minWidth: 'lg'}}>
            
          </Container> */}
          <Box>
            <Box display={`flex`} flexDirection={`column`}>
              {/* SEARCH FORM */}
              {false && (
                <>
                  <Box display={`flex`}>
                    <FormControl sx={{ m: 1, width: "100%" }}>
                      <OutlinedInput
                        id="outlined-adornment-search"
                        value={searchPhrase}
                        onChange={(e) => handleOnSearchPhraseChanging(e)}
                        onKeyDown={(e) => hitEnter(e)}
                        placeholder="ค้นหา"
                        startAdornment={
                          <InputAdornment
                            position="start"
                            sx={{ cursor: "pointer" }}
                            onClick={onSearchHandle}
                          >
                            <SearchIcon />
                          </InputAdornment>
                        }
                        sx={{ bgcolor: "#FFFFFF", borderRadius: "10px" }}
                      />
                    </FormControl>
                  </Box>

                  <Box display={`flex`}>
                    {buildingInfoList && (
                      <SearchResultList
                        list={buildingInfoList}
                        onSelectHandler={onBuildingSelectedHandler}
                      />
                    )}
                  </Box>
                </>
              )}

              {/* TOP PILLS */}
              <Box
                sx={{
                  p: 1,
                  mt: 1,
                  display: "flex",
                  flexWrap: "nowrap",
                  overflowY: "hidden",
                }}
              >
                {homeTabPills.map((item, index) => (
                  <Button
                    display={`flex`}
                    key={index}
                    variant={
                      activePill &&
                      item.buildingTypeId == activePill.buildingTypeId
                        ? `contained`
                        : `outlined`
                    }
                    className={
                      activePill &&
                      item.buildingTypeId == activePill.buildingTypeId
                        ? `custom-contained`
                        : `custom-outlined`
                    }
                    size="large"
                    sx={{
                      width: "200",
                      ml: 1,
                      mr: 1,
                      minWidth: "200",
                      maxWidth: "250px",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setActivePill(item);
                      setSearchPhrase("");
                      setBuildingInfo();
                    }}
                  >
                    <Box sx={{ mr: 2, maxHeight: "35px" }}>
                      {activePill.buildingTypeId === item.buildingTypeId
                        ? item.iconWhiteThumbNail
                        : item.icon}
                    </Box>
                    <Typography>{item.pillText}</Typography>
                  </Button>
                ))}
              </Box>

              {/* TOP Y M D combobox */}
              <Box
                className="box-wrapper"
                sx={{ mt: 1 }}
                display={`flex`}
                flexDirection={`column`}
                justifyContent={`start`}
              >
                <Box display={`flex`}>
                  <div className="subtitle-header">เลือกช่วงเวลาของข้อมูล</div>
                </Box>
                <Box display={`flex`}>
                  <Box sx={{ mt: 1, mr: 1 }}>
                    <DropdownYearPill onSelectHandler={onSelectYearHandler} />
                    {/* <MuiYearDropdown onSelectHandler={onSelectYearHandler} /> */}
                  </Box>
                  <Box sx={{ mt: 1, mr: 1 }}>
                    <DropdownMonthPill onSelectHandler={onSelectMonthHandler} />
                    {/* <MuiMonthDropdown onSelectHandler={onSelectMonthHandler} /> */}
                  </Box>
                  <Box sx={{ mt: 1, mr: 1 }}>
                    <DropdownDayPill
                      selectedMonth={selectedMonth}
                      selectedYear={selectedYear}
                      initDate={selectedDay}
                      onSelectHandler={onSelectDayHandler}
                    />
                    {/* <MuiDayDropdown selectedMonth={selectedMonth}
                        selectedYear={selectedYear}  onSelectHandler={onSelectDayHandler} /> */}
                  </Box>
                </Box>
              </Box>

              {/* SUMMARY USE OF ENERGY */}
              <Box
                sx={{ mt: 1 }}
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
              >
                {/* ACCUMULATIVE ELECTRICITY */}
                {activePill.buildingTypeId !== 0 && (
                  <SelectedTab pill={activePill} />
                )}
                <AccumElectricityUse data={buildingEnergyUse} />
                {/* ACCUMULATIVE HEAT */}
                <AccumHeatUse data={buildingEnergyUse} />
                {/* ACCUMULATIVE OIL */}
                {activePill.buildingTypeId === 0 && (
                  <AccumOilUse data={buildingEnergyUse} />
                )}
              </Box>

              {/* SUMMARY USE ALTERNATIVE ENERGY */}
              <Box
                sx={{ mt: 1 }}
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
                flexWrap={`wrap`}
              >
                {/* COMPARE NETWORK AND ALTERNATIVE ENERGY */}
                <AlternativeEnergyPercent
                  buildingEnergyUse={buildingEnergyUse}
                  activePill={activePill}
                />

                {/* RATIO USE FOR EACH BUILDING */}
                {activePill.buildingTypeId === 0 && (
                  <>
                    <Box
                      display={`flex`}
                      flex={1}
                      flexDirection={`column`}
                      className="box-wrapper"
                      flexGrow={1}
                    >
                      <Box>
                        <div className="subtitle-header">
                          สัดส่วนการใช้พลังงานของอาคารแต่ละประเภท
                        </div>
                      </Box>
                      <Box
                        sx={{ p: 1, mt: 1 }}
                        display={`flex`}
                        flexDirection={`row`}
                        justifyContent={`center`}
                        flex={1}
                      >
                        <Box display={`flex`} width={`300px`}>
                          {buildingEnergyUseEachType && (
                            <ApexDoughnutChart
                              chartname={`electrical-consuming-each-building-type`}
                              graphData={buildingEnergyUseEachType}
                            />
                          )}
                        </Box>
                        {buildingEnergyUseEachType && (
                          <Box sx={{ mt: 2 }} display={`flex `}>
                            <SideDataDonutTable
                              buildingEnergyUseEachType={
                                buildingEnergyUseEachType
                              }
                              unit={`MWh`}
                            />
                          </Box>
                        )}
                      </Box>

                      <Box
                        display={`flex`}
                        justifyContent={`flex-end`}
                        sx={{ mr: 1, mb: 1 }}
                      >
                        { buildingEnergyUseEachType && buildingEnergyUseEachType.data.length > 0 ? (                        <a
                          href={`${BASE_URL}/api/Home/downloadEnergyUse/${selectedYear}/${selectedMonth}/${selectedDay}`}
                          download
                        >
                          <img
                            src={DownloadIcon}
                            alt="download icon"
                            sx={{ cursor: "pointer" }}
                          />
                        </a>) : (<Box></Box>)}
                      </Box>
                    </Box>
                  </>
                )}

                {/* RATIO USE FOR EACH BUILDING SYSTEM */}
                <Box
                  display={`flex`}
                  flex={activePill.buildingTypeId === 0 ? 1 : 2}
                  flexDirection={`column`}
                  className="box-wrapper"
                  flexGrow={activePill.buildingTypeId === 0 ? 1 : 1.2}
                >
                  <Box>
                    <div className="subtitle-header">
                      {activePill.buildingTypeId === 0
                        ? `สัดส่วนการใช้พลังงานของอาคารแต่ละระบบ`
                        : `สัดส่วนการใช้พลังงานของ${activePill.pillText}`}
                    </div>
                  </Box>
                  <Box
                    sx={{ p: 1, mt: 1 }}
                    display={`flex`}
                    flexDirection={`row`}
                    justifyContent={`space-around`}
                  >
                    {systemEnergyUseEachType ? (
                      <Box width={`300px`}>
                        <ApexDoughnutChart
                          chartname={`electrical-consuming-each-system-type`}
                          graphData={systemEnergyUseEachType}
                        />
                      </Box>
                    ) : (
                      <Box>-</Box>
                    )}
                    {systemEnergyUseEachType &&
                      systemEnergyUseEachType.data.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                          <SideDataDonutTable
                            buildingEnergyUseEachType={systemEnergyUseEachType}
                            unit={systemEnergyUseEachType.unit}
                          />
                        </Box>
                      )}
                  </Box>

                  <Box
                    display={`flex`}
                    justifyContent={`flex-end`}
                    sx={{ mr: 1, mb: 1 }}
                  >
                    {/* ​/api​/Home​/downloadElectricityUse​/{buildingTypeId}​/{year}​/{month}​/{day} */}
                    {activePill &&
                      systemEnergyUseEachType &&
                      systemEnergyUseEachType.data.length > 0 ? (
                        <a
                          href={`${BASE_URL}/api/Home/downloadElectricityUse/${
                            activePill.buildingTypeId
                          }/${selectedYear}/${selectedMonth}/${selectedDay}?buildingId=${
                            buildingInfo ? buildingInfo.buildingId : 0
                          }`}
                          download
                        >
                          <img
                            src={DownloadIcon}
                            alt="download icon"
                            sx={{ cursor: "pointer" }}
                          />
                        </a>
                      ) : <Box></Box>}
                  </Box>
                </Box>
              </Box>

              {/* LINE CHART */}
              <Box
                className="box-wrapper"
                sx={{ mt: 1 }}
                display={`flex`}
                flexDirection={`column`}
                justifyContent={`center`}
              >
                <Box
                  display={`flex`}
                  flexDirection={`row`}
                  justifyContent={`space-between`}
                >
                  <Box display={`flex`} alignItems={`start`}>
                    <div className="subtitle-header">
                      การใช้พลังงานตามช่วงเวลา
                    </div>
                  </Box>
                  <Box display={`flex`} alignItems={`end`}>
                    {activePill.buildingTypeId === 0 && (
                      <>
                        <Box sx={{ mr: 1 }}>
                          <button
                            className={`button-graph-select${
                              energyType.id === 0 ? "-active" : ""
                            }`}
                            onClick={() =>
                              setEnergyType({
                                id: 0,
                                text: "รวมการใช้พลังงาน",
                              })
                            }
                          >
                            <Typography sx={{ pr: 2, pl: 2 }}>
                              รวมการใช้พลังงาน
                            </Typography>
                          </button>
                        </Box>
                      </>
                    )}

                    {lineGraphPills &&
                      lineGraphPills.map((item, index) => (
                        <Box sx={{ mr: 1 }} key={index}>
                          {/* TODO : FIX ACTIVE BUTTON  */}
                          <button
                            className={`button-graph-select${
                              energyType.id === item.id ? "-active" : ""
                            }`}
                            onClick={() => setEnergyType(item)}
                          >
                            <Typography sx={{ pr: 2, pl: 2 }}>
                              {item.title}
                            </Typography>
                          </button>
                        </Box>
                      ))}
                  </Box>
                </Box>
                <Box display={`flex`}>
                  {buildingEnergyUseByTime && (
                    <D3LineChart
                      unit={buildingEnergyUseByTimeUnit}
                      graphData={buildingEnergyUseByTime}
                      timeType={selectedTimeType}
                    />
                  )}
                </Box>
                <Box
                  display={`flex`}
                  justifyContent={`flex-end`}
                  sx={{ mr: 1, mb: 1 }}
                >
                  <a
                    href={`${BASE_URL}/api/Home/downloadEnergyUseByPeriod/${activePill.buildingTypeId}/${energyType.id}/${selectedYear}/${selectedMonth}/${selectedDay}`}
                    download
                  >
                    <img
                      src={DownloadIcon}
                      alt="download icon"
                      sx={{ cursor: "pointer" }}
                    />
                  </a>
                </Box>
              </Box>

              {/* INDEX GRID */}
              {/** TODO: selected date */}
              {activePill.buildingTypeId === 0 ? (
                <TargetGrid data={energyConsumption} selectedDate={selectedDate} />
              ) : (
                energyConsumption &&
                activePill && (
                  <TargetGroupTable
                    pill={activePill}
                    secData={energyConsumption}
                    energyConsumptionByArea={energyConsumptionByArea}
                    energyConsumptionByProvince={energyConsumptionByProvince}
                  />
                )
              )}
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
}
