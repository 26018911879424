import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";

import millify from "millify";
import { Box } from "@mui/system";
export default function ApexDoughnutChartReport({
  chartname,
  graphData,
  chartWidth,
  chartHeight,
}) {
  const [chart, setChart] = useState();

  useEffect(() => {
    const srs = graphData && graphData.data;
    setChart({
      options: {
        chart: {
          type: "donut",
          height: "180px", // chartHeight ? chartHeight : 'auto',
          width: "180px", // chartWidth ? chartWidth : '380px',
          animations: {
            enabled: false,
          },
        },
        legend: {
          show: false,
          fontFamily: "Khanit, Arial",
          showForSingleSeries: true,
          horizontalAlign: "left",
        },
        // series: graphData && graphData.data,
        colors: graphData.backgroundColor,
        labels: graphData.labels,
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return parseInt(val) + " %";
          },
          style: {
            fontFamily: "Khanit, Arial, sans-serif",
            fontSize: "10px",
            colors: ["#000000"],
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  formatter: function (val) {
                    return val;
                  }
                },
                total: {
                  show: false,                
                  label: "อาคาร",
                  formatter: (value) => graphData.total,
                },
              },
            },
          },
        },
      },
      series: graphData && graphData.data,
    });
    return () => {
      setChart();
    };
  }, [graphData]);

  return (
    <>
      {/* <Box id={`doughnut-chart-${chartname}`}></Box> */}
      { chart && <Chart
        className="custom-donut"
        id={`doughnut-chart-report-${chartname}`}
        options={chart.options}
        series={chart.series}
        type="donut"
      /> }
    </>
  );
}
