import { line, curveNatural, curveBasis, curveLinear } from 'd3';
export const MarksReport = ({
  data,
  xScale,
  yScale,
  xValue,
  yValue,
  tooltipFormat,
  circleRadius,
  strokeColor
}) => (
  <g className="marks">
    <path
      fill="none"
      stroke={ strokeColor ? strokeColor : 'black'}
      d={line()
        .x(d => xScale(xValue(d)))
        .y(d => yScale(yValue(d)))
        .curve(curveLinear)(data)
      }
    />
    {
      //data.map(d => (
      //  <circle cx={xScale(xValue(d))} cy={yScale(yValue(d))} r={circleRadius}>
      //    <title>{tooltipFormat(xValue(d))}</title>
      //  </circle>
      //))
    }
  </g>
);
