import { Box, Typography } from "@mui/material";
import { ChilledPumpIcon, CondPumpIcon, ThermometerIcon } from "../../assets";

import React, { useState, useEffect } from "react";
import { GET } from "../../Utils/ApiClient";
import { ALERT_DISMISS_TIME, HTTP_STATUS } from "../../Utils/EnvUtils";
import { NOTIFICATION_MESSAGE } from "../../Utils/Messages";
import { modifiedLineChartDataTimePeriodArray } from "../../Utils/FuncUtils";
import { D3MultipleLineChart } from "../Common/d3/Line/Multiple/D3MultipleLineChart";
import { data } from "../../uiData/UIData";
import DownloadBox from "../Common/DownloadBox";
import { v4 as uuid } from 'uuid'
import { AddBoxOutlined } from "@mui/icons-material";
export default function CHPSystem({
  chpSystemData,
  selectedPlantNo,
  buildingInfo,
  selectedYear,
  selectedMonth,
  selectedDay,
  selectedTimeType,
}) {
  const { CHPLegend } = data;
  const { chilledPump, condPump, collingTower } = chpSystemData;
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("info");

  const [cdp, setCdp] = useState();
  const [cdpUnit, setCdpUnit] = useState();

  const [chp, setChp] = useState();
  const [chpUnit, setChpUnit] = useState();

  const [cte, setCte] = useState();
  const [cteUnit, setCteUnit] = useState();

  useEffect(() => {
    if (
      buildingInfo &&
      selectedYear &&
      selectedMonth &&
      selectedDay &&
      selectedPlantNo
    ) {
      fetchCHPGraphData();
    }
    return () => {};
  }, [
    buildingInfo,
    selectedYear,
    selectedMonth,
    selectedDay,
    selectedTimeType,
    selectedPlantNo,
  ]);

  async function fetchCHPGraphData() {
    try {
      // GET:/dashboard/central/chillerPlant/pumpElectricity/{buildingId}/{plantNo}/{year}/{month}/{day}
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      // todo: make this available to 2 line graph
      const response = await GET(
        `dashboard/central/chillerPlant/pumpElectricity/${buildingId}/${selectedPlantNo}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = response;

      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        const { cdp, chp, cte, unit } = data;
        if (data.cdp) {
          const modifiedData = modifiedLineChartDataTimePeriodArray(
            data.cdp,
            selectedYear,
            selectedMonth,
            selectedDay
          );
          setCdp(modifiedData);
          setCdpUnit(unit);
        }

        if (data.chp) {
          const modifiedData = modifiedLineChartDataTimePeriodArray(
            data.chp,
            selectedYear,
            selectedMonth,
            selectedDay
          );
          setChp(modifiedData);
          setChpUnit(unit);
        }

        if (data.cte) {
          const modifiedData = modifiedLineChartDataTimePeriodArray(
            data.cte,
            selectedYear,
            selectedMonth,
            selectedDay
          );
          setCte(modifiedData);
          setCteUnit(unit);
        }
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
        // todo : set alert
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }
  return (
    <div>
      <Box
        className="box-wrapper"
        sx={{ mt: 1 }}
        display={`flex`}
        flex={1}
        flexDirection={`column`}
      >
        <Box display={`flex`} flexDirection={`row`}>
          <Box display={`flex`} flexDirection={`column`} flex={4} flexGrow={4}>
            <Typography className="subtitle-header">
              การใช้พลังงานไฟฟ้าของ CHP, CDP, CT (Chiller Plant #{" "}
              {selectedPlantNo}{" "})
            </Typography>
            {cdp && chp && cte && (
              <D3MultipleLineChart
                chartData={[cdp, chp, cte]}
                unit={cdpUnit}
                timeType={selectedTimeType}
              />
            )}
          </Box>
          <Box
            display={`flex`}
            flexDirection={`column`}
            flex={1}
            flexGrow={0.3}
            justifyContent={`center`}
            alignContent={`center`}
          >
            <Box display={`flex`} flexDirection={`column`}>
              <table className="table">
                <tbody>
                  {CHPLegend &&
                    CHPLegend.map((item, index) => (
                      <tr className="row" key={index}>
                        <td>
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              background: `${item.color}`,
                            }}
                          ></div>
                        </td>
                        <td>{item.text} </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
        <DownloadBox endPoint={`api/dashboard/central/chillerPlant/downloadPumpElectricity/${buildingInfo ? buildingInfo.buildingId : 0}/${selectedPlantNo}/${selectedYear}/${selectedMonth}/${selectedDay}`} />
      </Box>
      <Box
        className="box-wrapper"
        sx={{ mt: 1 }}
        display={`flex`}
        flex={1}
        flexDirection={`column`}
      >
        <Box display={`flex`}>
          <Typography className="subtitle-header">
            การใช้พลังงานไฟฟ้าของ CHP, CDP, CT(Chiller Plant # {selectedPlantNo}{" "}
            ) แต่ละเครื่อง (Y/M/D)
          </Typography>
        </Box>

        <Box
          display={`flex`}
          flexDirection={`space-around`}
          sx={{ mt: 4, maxHeight: "500", overFlowY: "scroll" }}
        >
          {/* BOX#1 PUMP */}
          <Box display={`flex`} flexDirection={`column`} flex={1}>
            {chilledPump &&
              chilledPump.map((item, index) => (
                <Box key={index} display={`flex`} flexDirection={`row`}>
                    {/* ICON and Its name */}
                    <Box display={`flex`} flexDirection={`column`}>
                      <Box>
                        <img
                          height={80}
                          width={80}
                          src={ChilledPumpIcon}
                          alt="chilled pump icon "
                        />
                      </Box>
                      <Box display={`flex`}>
                        <Typography className="subtitle-header">
                          Chilled Pump
                        </Typography>
                      </Box>
                    </Box>

                    {/* Its data  */}
                    <Box
                      display={`flex`}
                      flexDirection={`row`}
                      justifyContent={`space-between`}
                    >
                      <Box display={`flex`} flexDirection={`column`}>
                        <Box display={`flex`} sx={{ p: 1 }}>
                          <Typography className="title-header">
                            CHP-0{item.chillerNo}
                          </Typography>
                        </Box>
                        <Box
                          display={`flex`}
                          sx={{ p: 1 }}
                          flexDirection={`row`}
                        >
                          <Box display={`flex`} className="text-primary-small">
                            ช่วงเวลาที่เลือก
                          </Box>
                        </Box>
                        <Box
                          display={`flex`}
                          sx={{ p: 1, minWidth: "100px" }}
                          flexDirection={`column`}
                          className={`box-wrapper-active`}
                        >
                          <Box display={`flex`} justifyContent={`center`}>
                            <Typography className={`subtitle-header-black`}>
                              SUM ENERGY
                            </Typography>
                          </Box>
                          <Box display={`flex`} justifyContent={`center`}>
                            <Typography className={`large-title-header`}>
                              {item.sumEnergy || "-"}
                            </Typography>
                          </Box>
                          <Box display={`flex`} justifyContent={`start`}>
                            <Typography className={`subtitle-header-black`}>
                              {item.sumEnergyUnit || "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        display={`flex`}
                        flexDirection={`column`}
                        sx={{ minWidth: "180px" }}
                      >
                        <Box display={`flex`} sx={{ p: 1, mt: 4.5 }}>
                          <Typography className="title-header">{` `}</Typography>
                        </Box>
                        <Box
                          display={`flex`}
                          sx={{ p: 1 }}
                          flexDirection={`row`}
                          justifyContent={`end`}
                        >
                          <Box
                            display={`flex`}
                            className="text-primary-small"
                            alignItems={`end`}
                          >
                            ณ ปัจจุบัน
                          </Box>
                        </Box>
                        <Box
                          display={`flex`}
                          sx={{ p: 1 }}
                          flexDirection={`row`}
                          className={`box-wrapper`}
                          justifyContent={`space-around`}
                        >
                          <Box display={`flex`} flexDirection={`column`}>
                            <Box display={`flex`} justifyContent={`start`}>
                              <Typography className={`subtitle-header-black`}>
                                POWER
                              </Typography>
                            </Box>
                            <Box display={`flex`} justifyContent={`center`}>
                              <Typography className={`large-title-header`}>
                                {item.power || "-"}
                              </Typography>
                            </Box>
                            <Box display={`flex`} justifyContent={`start`}>
                              <Typography className={`subtitle-header-black`}>
                                {item.powerUnit || "-"}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display={`flex`} flexDirection={`column`}>
                            <Box display={`flex`} justifyContent={`start`}>
                              <Typography className={`subtitle-header-black`}>
                                Hz
                              </Typography>
                            </Box>
                            <Box display={`flex`} justifyContent={`center`}>
                              <Typography className={`large-title-header`}>
                                {item.frequency || "-"}
                              </Typography>
                            </Box>
                            <Box display={`flex`} justifyContent={`start`}>
                              <Typography className={`subtitle-header-black`}>
                                {item.frequencyUnit || "-"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
              ))}
          </Box>

          <Box display={`flex`} flexDirection={`column`} flex={1}>
            {condPump &&
              condPump.map((item, index) => (
                <Box key={index}>
                  <Box key={uuid()} display={`flex`} flexDirection={`row`}>
                    {/* ICON and Its name */}
                    <Box display={`flex`} flexDirection={`column`}>
                      <Box>
                        <img
                          height={80}
                          width={80}
                          src={CondPumpIcon}
                          alt="cond pump icon "
                        />
                      </Box>
                      <Box display={`flex`}>
                        <Typography className="subtitle-header">
                          Cond. Pump
                        </Typography>
                      </Box>
                    </Box>

                    {/* Its data  */}
                    <Box
                      display={`flex`}
                      flexDirection={`row`}
                      justifyContent={`space-between`}
                    >
                      <Box display={`flex`} flexDirection={`column`}>
                        <Box display={`flex`} sx={{ p: 1 }}>
                          <Typography className="title-header">
                            CDP-0{item.chillerNo}
                          </Typography>
                        </Box>
                        <Box
                          display={`flex`}
                          sx={{ p: 1 }}
                          flexDirection={`row`}
                        >
                          <Box display={`flex`} className="text-primary-small">
                            ช่วงเวลาที่เลือก
                          </Box>
                        </Box>
                        <Box
                          display={`flex`}
                          sx={{ p: 1, minWidth: "100px" }}
                          flexDirection={`column`}
                          className={`box-wrapper-active`}
                        >
                          <Box display={`flex`} justifyContent={`center`}>
                            <Typography className={`subtitle-header-black`}>
                              SUM ENERGY
                            </Typography>
                          </Box>
                          <Box display={`flex`} justifyContent={`center`}>
                            <Typography className={`large-title-header`}>
                              {item.sumEnergy || "-"}
                            </Typography>
                          </Box>
                          <Box display={`flex`} justifyContent={`start`}>
                            <Typography className={`subtitle-header-black`}>
                              {item.sumEnergyUnit || "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        display={`flex`}
                        flexDirection={`column`}
                        sx={{ minWidth: "180px" }}
                      >
                        <Box display={`flex`} sx={{ p: 1, mt: 4.5 }}>
                          <Typography className="title-header">{` `}</Typography>
                        </Box>
                        
                        <Box
                          display={`flex`}
                          sx={{ p: 1 }}
                          flexDirection={`row`}
                          justifyContent={``}
                        >
                          <Box
                            display={`flex`}
                            className="text-primary-small"
                            alignItems={`end`}
                          >
                            ณ ปัจจุบัน
                          </Box>
                        </Box>
                        <Box
                          display={`flex`}
                          sx={{ p: 1 }}
                          flexDirection={`row`}
                          className={`box-wrapper`}
                          justifyContent={`space-around`}
                        >
                          <Box display={`flex`} flexDirection={`column`}>
                            <Box display={`flex`} justifyContent={`center`}>
                              <Typography className={`subtitle-header-black`}>
                                POWER
                              </Typography>
                            </Box>
                            <Box display={`flex`} justifyContent={`center`}>
                              <Typography className={`large-title-header`}>
                                {item.power || "-"}
                              </Typography>
                            </Box>
                            <Box display={`flex`} justifyContent={`start`}>
                              <Typography className={`subtitle-header-black`}>
                                {item.powerUnit || "-"}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display={`flex`} flexDirection={`column`}>
                            <Box display={`flex`} justifyContent={`center`}>
                              <Typography className={`subtitle-header-black`}>
                                Hz
                              </Typography>
                            </Box>
                            <Box display={`flex`} justifyContent={`center`}>
                              <Typography className={`large-title-header`}>
                                {item.frequency || "-"}
                              </Typography>
                            </Box>
                            <Box display={`flex`} justifyContent={`start`}>
                              <Typography className={`subtitle-header-black`}>
                                {item.frequencyUnit || "-"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
          {/* BOX#3 COOLING TOWER */}
          <Box display={`flex`} flexDirection={`column`} flex={1}>
            {collingTower &&
              collingTower.map((item, index) => (
                <Box key={index}>
                  <Box key={uuid()} display={`flex`} flexDirection={`row`}>
                    {/* ICON and Its name */}
                    <Box display={`flex`} flexDirection={`column`}>
                      <Box>
                        <img
                          height={80}
                          width={80}
                          src={ThermometerIcon}
                          alt="thermometer pump icon "
                        />
                      </Box>
                      <Box display={`flex`}>
                        <Typography className="subtitle-header">
                          Cooling Tower
                        </Typography>
                      </Box>
                    </Box>

                    {/* Its data  */}
                    <Box
                      display={`flex`}
                      flexDirection={`row`}
                      justifyContent={`space-between`}
                    >
                      <Box display={`flex`} flexDirection={`column`}>
                        <Box display={`flex`} sx={{ p: 1 }}>
                          <Typography className="title-header">
                            CT-0{item.chillerNo}
                          </Typography>
                        </Box>
                        <Box
                          display={`flex`}
                          sx={{ p: 1 }}
                          flexDirection={`row`}
                        >
                          <Box display={`flex`} className="text-primary-small">
                            ช่วงเวลาที่เลือก
                          </Box>
                        </Box>
                        <Box
                          display={`flex`}
                          sx={{ p: 1, minWidth: "100px" }}
                          flexDirection={`column`}
                          className={`box-wrapper-active`}
                        >
                          <Box display={`flex`} justifyContent={`center`}>
                            <Typography className={`subtitle-header-black`}>
                              SUM ENERGY
                            </Typography>
                          </Box>
                          <Box display={`flex`} justifyContent={`center`}>
                            <Typography className={`large-title-header`}>
                              {item.sumEnergy || "-"}
                            </Typography>
                          </Box>
                          <Box display={`flex`} justifyContent={`start`}>
                            <Typography className={`subtitle-header-black`}>
                              {item.sumEnergyUnit || "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        display={`flex`}
                        flexDirection={`column`}
                        sx={{ minWidth: "180px" }}
                      >
                        <Box display={`flex`} sx={{ p: 1, mt: 4.5 }}>
                          <Typography className="title-header">{` `}</Typography>
                        </Box>
                        <Box
                          display={`flex`}
                          sx={{ p: 1 }}
                          flexDirection={`row`}
                          justifyContent={`end`}
                        >
                          <Box
                            display={`flex`}
                            className="text-primary-small"
                            alignItems={`end`}
                          >
                            ณ ปัจจุบัน
                          </Box>
                        </Box>
                        <Box
                          display={`flex`}
                          sx={{ p: 1 }}
                          flexDirection={`row`}
                          className={`box-wrapper`}
                          justifyContent={`space-around`}
                        >
                          <Box display={`flex`} flexDirection={`column`}>
                            <Box display={`flex`} justifyContent={`center`}>
                              <Typography className={`subtitle-header-black`}>
                                POWER
                              </Typography>
                            </Box>
                            <Box display={`flex`} justifyContent={`center`}>
                              <Typography className={`large-title-header`}>
                                {item.power || "-"}
                              </Typography>
                            </Box>
                            <Box display={`flex`} justifyContent={`start`}>
                              <Typography className={`subtitle-header-black`}>
                                {item.powerUnit || "-"}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display={`flex`} flexDirection={`column`}>
                            <Box display={`flex`} justifyContent={`center`}>
                              <Typography className={`subtitle-header-black`}>
                                Hz
                              </Typography>
                            </Box>
                            <Box display={`flex`} justifyContent={`center`}>
                              <Typography className={`large-title-header`}>
                                {item.frequency || "-"}
                              </Typography>
                            </Box>
                            <Box display={`flex`} justifyContent={`start`}>
                              <Typography className={`subtitle-header-black`}>
                                {item.frequencyUnit || "-"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
