import React from 'react'
import {
  Box, Typography, Button
} from '@mui/material'
import { useForm } from "react-hook-form";
import { UIData } from './../../uiData';
import { v4 as uuidv4 } from 'uuid';
import ConsentInput from '../Common/ConsentInput';
import CustomTextInput from '../Common/CustomTextInput';
import { ApiClient, Messages } from './../../Utils'
import { useNavigate } from 'react-router-dom';

export default function GeneralForm({ onAlertHandler, registerType }) {
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const { POST_NO_AUTH } = ApiClient;
  const { NOTIFICATION_MESSAGE } = Messages;

  const navigate = useNavigate();
  const { data } = UIData;
  async function onSubmit(e) {
    try {
      const payload = {
        firstName: e.firstname,
        lastName: e.lastname,
        citizen: e.idcard,
        phone: e.phone,
        email: e.email,
        workPlace: e.office,
        position: e.position,
        buildingId: -1,
        document: [
        ]
      }
      const res = await POST_NO_AUTH(`User/register/${registerType}`, payload);
      const { data, status } = res;
      const { success , message } = data;
      if (success == true) {
        onAlertHandler(true, message, "success")
        setTimeout(() => {
          navigate('/signin');
        }, 1000);
      } else {
        onAlertHandler(true, message, "error")
      }
    } catch (error) {
      console.log(error);
      onAlertHandler(true, NOTIFICATION_MESSAGE.ERROR , "error")
    }
  }

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {
          data.formInputs && data.formInputs.map((input, i) => (
            <Box key={uuidv4()}>
              <Typography sx={{ fontSize: 20, color: '#25A18E', mt: 5 }}>
                {input.group}
              </Typography>
              {
                input && input.inputs.map((item, i) => (
                  <CustomTextInput
                    key={uuidv4()}
                    register={register(`${item.name}`, item.validation)}
                    errors={errors}
                    item={item}
                  />
                ))
              }
            </Box>
          ))
        }
        <ConsentInput register={register("consent", {
          required: true
        })
        } errors={errors} />
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" sx={{
            bgcolor: '#25A18E', ":hover": {
              backgroundColor: '#777'
            }
          }} type='submit'>ลงทะเบียน</Button>
        </Box>
      </form>
    </>
  )
}
