import { Box } from "@mui/system";
import millify from "millify";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const time = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24,
];
const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
];
const months = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
];
export default function ApexColumnBarchart({ chartData, selectedTimeType }) {
  // const [options, setOptions] = useState();
  // const [series, setSeries] = useState();
  const cData = chartData.data.map((item) => ({
    xaxis: item.xaxis,
    data: item.yaxis,
  }));

  const [chart, setChart] = useState({
    options: {
      dataLabels: {
        style: {
          fontSize: "5px",
        },
        formatter: function (val, opts) {
          return millify(val);
        },
      },
      markers: {
        offsetX: 10,
      },
      chart: {
        type: "bar",
        // width: 500,
        animations: {
          enabled: false,
        },
        height: 150,
        offsetX: 20,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "80%",
        },
      },
      xaxis: {
        offsetX: 0,
        forceNiceScale: false,
        type: "string",
        // categories: time ,
        labels: {
          rotate: -90,
          rotateAlways: true,
          hideOverlappingLabels: false,
          showDuplicates: true,
          offsetX: 0,
          style: {
            fontFamily: 'Kanit'
          }
        },
      },
      yaxis: {
        labels: {
          show: false,
          offsetX: -15,
          minWidth: 20,
          align: "center",
          // formatter: function (val) {
          //   return val;
          // },
          style: {
            cssClass: `custom-apexcharts-yaxis-label`,
          },
        },
        axisTicks: {
          show: true,
        },
      },
      legend: {
        show: false,
        position: "right",
        // offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
    series: cData,
  });
  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    // const srs = [{ data: chartData.data.map((d) => d.yaxis) }];
    const srs = chartData.data.map((item, index) => ({
      xaxis: item.xaxis,
      data: item.yaxis,
    }));

    let categories = [];
    if (selectedTimeType == 0) {
      categories = time;
    } else if (selectedTimeType == 1) {
      categories = days;
    } else if (selectedTimeType == 2) {
      categories = months;
    }

    setChart({
      series: srs,
      options: {
        xaxis: {
          categories: categories,
        },
      },
    });

    // setChart({ series: srs, categories: categories });

    return () => {};
  }, [chartData]);

  return (
    <>
      <Box justifyContent={`end`}>
        <Box style={{ paddingLeft: "15px" }}>
          {chart && (
            <ReactApexChart
              options={chart.options}
              series={chart.series}
              categories={chart.categories}
              type="bar"
              width={500}
              height={250}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
