import React, {useEffect, useState } from "react";
import numeral from 'numeral'
export default function SideDataDonutTable({
  buildingEnergyUseEachType,
  unit,
}) {
  const [sideTableData, setSideTableData] = useState([]);
  useEffect(() => {
    if(buildingEnergyUseEachType) {
      let modifiedData = [];
      buildingEnergyUseEachType.labels.map((i, idx) => {
        modifiedData.push({ label: i, data: buildingEnergyUseEachType.data[idx], color:  buildingEnergyUseEachType.backgroundColor[idx]})  
      })
      setSideTableData(modifiedData)
    }
    return () => {
    };
  }, [buildingEnergyUseEachType]);
  
  useEffect(() => {
    return () => {
      setSideTableData()  
    };
  }, []);
  
  return (
    <>
      <table className="table-donut-chart">
        <thead>
          <tr className="row-donut-chart text-primary">
            <td></td>
            <td></td>
            <td>{unit}</td>
          </tr>
        </thead>
        <tbody className="">
          {sideTableData && sideTableData.length > 0 &&
            sideTableData.map((item, index) => (
              <tr key={index} className="row-donut-chart mt-4">
                <td>
                  <div style={{ width: '10px',height: '10px', borderRadius: '50%', background: item.color, padding: '3px'}}></div>
                </td>
                <td className="" style={{ width: '150px'}}>{ item.label }</td>
                <td className="">{ numeral(item.data).format('0,0') }</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
