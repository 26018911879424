import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

export default function BasicDatePicker({ date, onSetNewDate, name, id , register }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker        
        value={date}
        onChange={(newDate) => {
          onSetNewDate(newDate);
        }}
        id={id}
        inputFormat={`yyyy-MM-dd`}
        renderInput={(params) => <TextField {...params} {...register} name={name} />}
      />
    </LocalizationProvider>
  );
}