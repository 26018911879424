import React, { useState, useEffect  } from "react";
const PrintPage3 =  React.forwardRef((props, ref) =>  {
  return (
    <React.Fragment>
      <div ref={ref}>
        <p>Print page 3</p>
      </div>
    </React.Fragment>
  );
})

export default PrintPage3;