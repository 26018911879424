import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { data } from "../../uiData/UIData";
import NoDataToShow from "../Common/NoDataToShow";

export default function SupplySideTable({
  supplySideData,
  supplySideSingle,
  onSelectSupplySide,
  onSelectSupplySideCategory,
  buildingInfo,
  selectedYear,
  selectedMonth,
  selectedDay,
}) {
  const { mockDataSupplySide, supplySideCategories } = data;
  const [supplySideCategory, setSupplySideCategory] = useState();

  useEffect(() => {
    return () => {};
  }, []);

  function handleOnChangeSupplySideCategory(
    event,
    supplySide,
    supplySideCategory
  ) {
    setSupplySideCategory(supplySideCategory);
    onSelectSupplySideCategory(supplySideCategory);
    onSelectSupplySide(supplySide);

    const elemClass = document.getElementsByClassName(
      "pill-button-outlined-primary-active"
    );
    for (let i = 0; i < elemClass.length; i++) {
      const element = elemClass[i];
      element.className = "pill-button-outlined-primary";
    }
    const targetClassName = event.target.className;
    if (event.target.className.includes("active")) event.target.className = "";
    event.target.className = "pill-button-outlined-primary-active";
  }

  function onBlurSupplySideCategory(event) {
    //event.target.className = "pill-button-outlined-primary";
  }

  function handleOnFocus(event) {
    const elem = event.target;
    const elemId = elem.id;
    if (elemId == "1-0-0") {
      elem.click();
    }
  }

  return (
    <>
      <Box display={`flex`}>
        <table className="table" style={{ background: "#FFFFFF" }}>
          <thead>
            <tr className="row">
              <th className="col">No</th>
              <th className="col">Plant No</th>
              <th className="col">
                <Typography fontSize={`sm`}>
                  Avg. Plant Efficiency{" "}
                  {supplySideData && supplySideData.length > 0
                    ? supplySideData[0].averageEfficiencyUnit
                    : ""}
                </Typography>
              </th>
              <th className="col"></th>
              <th className="col"></th>
              <th className="col"></th>
            </tr>
          </thead>
          <tbody
            style={{
              maxHeight: "370px",
              overflowY: "scroll",
            }}
          >
            {supplySideData && supplySideData.length > 0 ? (
              supplySideData.map((item, index) => (
                <tr key={index} className="row">
                  <td className="col">{item.rowNo}</td>
                  <td className="col">{item.plantNo}</td>
                  <td className="col">{item.averageEfficiency || "-"}</td>
                  {supplySideCategories.map((sps, k) => (
                    <td className="col" key={k}>
                      <button
                        className="pill-button-outlined-primary"
                        id={k == 0 ? `${item.plantNo}-${index}-${k}` : ``}
                        autoFocus={true}
                        onFocus={(event) => handleOnFocus(event)}
                        onBlur={(event) => onBlurSupplySideCategory(event)}
                        onClick={(e) =>
                          handleOnChangeSupplySideCategory(e, item, sps)
                        }
                      >
                        {sps.text}
                      </button>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <NoDataToShow />
            )}
          </tbody>
        </table>
      </Box>
    </>
  );
}
