import {
  Container,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Button,
  InputAdornment,
  IconButton,
  Alert,
  FormHelperText
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useState, useEffect } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { BASE } from "../../theme/BaseStyle";
import { useForm } from "react-hook-form";
import Logo2White from "../../assets/images/Logo2White.svg";
import { UIData } from "../../uiData";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiClient, AuthUtils, Messages, EnvUtils } from "../../Utils";
import { ALERT_DISMISS_TIME } from "../../Utils/EnvUtils";


export default function PasswordNewPage() {
  const location = useLocation().search;
  const refId = new URLSearchParams(location).get('refId');
  const { NOTIFICATION_MESSAGE } = Messages

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();
  const { passwordInput } = UIData;
  const { POST_NO_AUTH, GET_NO_AUTH } = ApiClient;
  const navigate = useNavigate();
  const [validationRefIdResult, setValidationRefIdResult] = useState(null)
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
    passwordConfirm: "",
    showPasswordConfirm: false,
  });
  const [message, setMessage] = useState("")
  const [alert, setAlert] = useState(false)
  const [severity, setSeverity] = useState("error")


  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = (e) => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowPasswordConfirm = (e) => {
    setValues({
      ...values,
      showPasswordConfirm: !values.showPasswordConfirm,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function onFormSubmit(formData) {
    try {
      if (!refId) {
        setAlert(true);
        setMessage("REFID is required");
        setSeverity("error")
        setTimeout(() => {
          setAlert(false)
        }, 3000);
        return;
      }

      await validateRefId()

      if (validationRefIdResult && !validationRefIdResult.success) {
        setAlert(true);
        setMessage(validationRefIdResult.message);
        setSeverity("error")
        setTimeout(() => {
          setAlert(false)
        }, 3000);
      }
      const payload = {
        refId: refId,
        password: formData.password
      }
      const res = await POST_NO_AUTH(`User/newPassword`, payload)
      const { data, status } = res;
      const { message, success } = data;
      if (success) {
        setAlert(true);
        setMessage(message);
        setSeverity("success")
        setTimeout(() => {
          setAlert(false)
          navigate('/signin');
        }, 3000);

      } else {
        setAlert(true);
        setMessage(message);
        setSeverity("error")
        setTimeout(() => {
          setAlert(false)
        }, ALERT_DISMISS_TIME);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error")
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }

  async function validateRefId() {
    try {
      const validateResult = await GET_NO_AUTH(`user/validateRefId?refId=${refId}`)
      const { data, status } = validateResult;
      setValidationRefIdResult(data)
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error")
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }

  return (
    <>
      <AuthLayout>
        {alert && <Alert sx={{ position: 'fixed', width: '100%' }} severity={severity}>{message} </Alert>}

        <Box
          variant="div"
          sx={{
            backgroundColor: BASE.registerBackgroundColor,
            width: "auto",
            height: "100vh",
          }}
        >
          <Container>
            <Box variant="h2" sx={{ pt: 10 }}>
              <Typography
                sx={{ color: "#FFFFFF", fontSize: 20, textAlign: "center" }}
              >
                กรุณากรอกรหัสผ่านใหม่
              </Typography>
            </Box>

            <Box variant="div">
              <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" sx={{ mt: 2, width: "60%" }}>
                    <OutlinedInput
                      aria-describedby={`outlined-password-helper-text`}
                      {...register("password", {
                        required: "กรุณากรอกข้อมูล Password ให้ครบถ้วน",
                        minLength: {
                          value: 8,
                          message: "ต้องมีความยาวอย่างน้อย 8 ตัวอักษร",
                        },
                        maxLength: {
                          value: 100,
                          message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร",
                          // pattern: {
                          //   value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i ,
                          //   message: "กรุณากรอกอีเมลล์ในรูปแบบที่ถูกต้อง"
                          // }
                        },
                      })}
                      variant="outlined"
                      type={values.showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="New Password"
                      sx={{ background: "#FFFFFF", borderRadius: 2 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors[`password`] && (
                      <FormHelperText
                        id={`outlined-password-helper-text`}
                      >
                        {errors[`password`]?.message}
                      </FormHelperText>
                    )}

                  </FormControl>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" sx={{ mt: 2, width: "60%" }}>
                    <OutlinedInput
                      aria-describedby={`outlined-passwordConfirm-helper-text`}
                      {...register("passwordConfirm", {
                        required: "กรุณากรอกข้อมูล Password Comfirm ให้ครบถ้วน",
                        minLength: {
                          value: 8,
                          message: "ต้องมีความยาวอย่างน้อย 8 ตัวอักษร",
                        },
                        maxLength: {
                          value: 100,
                          message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร",
                        },
                        validate: (value) =>
                          value === watch("password") ||
                          "รหัสผ่านต้องตรงกัน",
                      })}
                      variant="outlined"
                      type={values.showPasswordConfirm ? "text" : "password"}
                      name="passwordConfirm"
                      id="passwordConfirm"
                      placeholder="Confirm Password"
                      sx={{ background: "#FFFFFF", borderRadius: 2 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordConfirm}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPasswordConfirm ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors[`passwordConfirm`] && (
                      <FormHelperText
                        id={`outlined-passwordConfirm-helper-text`}
                      >
                        {errors[`passwordConfirm`]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    color="secondary"
                    sx={{ width: "10%", mt: 3, mb: 2, pl: 4, pr: 4 }}
                  >
                    ส่ง
                  </Button>
                </Box>
              </form>
            </Box>

            <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
              <img src={Logo2White} alt="Signin Header Image" />
            </Box>
          </Container>
        </Box>
      </AuthLayout>
    </>
  );
}
