import { Box, Typography } from "@mui/material";
import React from "react";
import { DropletIcon } from "../../assets";


export default function AccumOilUse({ data }) {
  return (
    <>
      <Box
        display={`flex`}
        flex={1}
        flexDirection={`column`}
        className="box-wrapper"
        flexGrow={1}
      >
        <Box>
          <div className="subtitle-header">
            ปริมาณพลังงานสะสมเทียบเป็นตันน้ำมันดิบ
          </div>
        </Box>
        <Box
          sx={{ p: 1, mt: 1 }}
          display={`flex`}
          flexDirection={`row`}
          justifyContent={`space-between`}
        >
          <Box display={`flex`}>
            <img src={DropletIcon} alt={`droplet icon`} />
          </Box>
          <Box display={`flex`} flexDirection={`column`} sx={{ mr: 2 }}>
            <Box display={`flex`}>
              <Typography sx={{ fontSize: "32px" }}>{data && data.energyVsCrudeOil || '-'}</Typography>
            </Box>
            <Box display={`flex`} justifyContent={`end`}>
              <Typography>{data && data.energyVsCrudeOilUnit || '-'}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
