import {
  Container,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Button,
  Alert
} from "@mui/material";
import React, { useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { useNavigate } from 'react-router-dom';
import { BASE } from "../../theme/BaseStyle";
import { useForm } from "react-hook-form";
import Logo2White from "../../assets/images/Logo2White.svg";
import { UIData } from "../../uiData";
import CustomTextInput from "../Common/CustomTextInput";
import { v4 as uuidv4 } from "uuid";
import { ApiClient, Messages } from "../../Utils";
import { ALERT_DISMISS_TIME } from "../../Utils/EnvUtils";



export default function PasswordResetPage() {
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [severity, setSeverity] = useState("error")
  const { NOTIFICATION_MESSAGE } = Messages;
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const { emailInput } = UIData;
  const { POST_NO_AUTH } = ApiClient;
  const navigate = useNavigate();

  async function onFormSubmit(formData) {
    try {
      const payload = {
        email: formData.email
      }
      const res = await POST_NO_AUTH(`user/ForgotPassword`, payload)
      const { data, status } = res;
      const { success, message } = data;
      if (success == true) {
        setAlert(true);
        setAlertMessage(message);
        setSeverity("success")
        setTimeout(() => {
          setAlert(false)
          navigate('/signin')
        }, 2000);
      } else {
        setAlert(true);
        setAlertMessage(message);
        setSeverity("error")
        setTimeout(() => {
          setAlert(false)
        }, ALERT_DISMISS_TIME);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setAlertMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }

  return (
    <>

      <AuthLayout>
        {alert && <Alert sx={{ position: 'fixed', width: '100%' }} severity={severity}>{alertMessage}</Alert>}

        <Box
          variant="div"
          sx={{
            backgroundColor: BASE.registerBackgroundColor,
            width: "auto",
            height: "100vh",
          }}
        >

          <Container>
            <Box variant="h2" sx={{ pt: 10 }}>
              <Typography
                sx={{ color: "#FFFFFF", fontSize: 20, textAlign: "center" }}
              >
                ระบบจะทำการส่ง Link Reset รหัสผ่านไปทาง E-mail
                ของท่านที่ลงทะเบียนไว้
              </Typography>
            </Box>

            <Box variant="div">
              <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box sx={{ width: "60%" }}>
                    <CustomTextInput
                      key={uuidv4()}
                      register={register(
                        `${emailInput.name}`,
                        emailInput.validation
                      )}
                      errors={errors}
                      item={emailInput}
                    />
                  </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    color="secondary"
                    sx={{
                      width: "20%",
                      mt: 3,
                      mb: 2,
                      pl: 4,
                      pr: 4,
                    }}
                  >
                    ลืมรหัสผ่าน
                  </Button>
                </Box>
              </form>
            </Box>

            <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
              <img src={Logo2White} alt="Signin Header Image" />
            </Box>
          </Container>
        </Box>
      </AuthLayout>
    </>
  );
}
