import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { AirCompressorIcon } from "../../assets";
import { GET, GET_NO_AUTH } from "../../Utils/ApiClient";
import {
  ALERT_DISMISS_TIME,
  ALERT_TYPE,
  HTTP_STATUS,
} from "../../Utils/EnvUtils";
import { NOTIFICATION_MESSAGE } from "../../Utils/Messages";
import AlertNotification from "../Common/AlertNotification";
import { data } from "../../uiData/UIData";
import DownloadBox from "../Common/DownloadBox";
import { modifiedLineChartDataTimePeriod } from "../../Utils/FuncUtils";
import { D3LineChart } from "../Common/d3/D3LineChart";

export default function ZoneEnergyUse({
  buildingInfo,
  selectedYear,
  selectedMonth,
  selectedDay,
  selectedTimeType,
}) {
  const { ERROR } = NOTIFICATION_MESSAGE;
  const { mockDataZoneEnergyUseTable } = data;
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [zoneList, setZoneList] = useState();
  const [zoneLineChartData, setZoneLineChartData] = useState();
  const [zoneLineChartDataUnit, setZoneLineChartDataUnit] = useState();

  useEffect(() => {
    if (buildingInfo) {
      fetchZoneEnergyUse();
      fetchZoneEnergyLineGraph();
    }
    return () => {};
  }, [buildingInfo, selectedYear, selectedMonth, selectedDay]);

  async function fetchZoneEnergyUse() {
    try {
      // GET:/dashboard/splitType/zone/{buildingId}/{year}/{month}/{day}
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const res = await GET(
        `dashboard/splitType/zone/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setZoneList(data);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(error);
      setSeverity(ALERT_TYPE.alertError);
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchZoneEnergyLineGraph() {
    try {
      // GET:dashboard/splitType/airConditioning/{buildingId}/{year}/{month}/{day}
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const res = await GET(
        `dashboard/splitType/airConditioning/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        const modifiedData = modifiedLineChartDataTimePeriod(
          data,
          selectedYear,
          selectedMonth,
          selectedDay
        );
        setZoneLineChartData(modifiedData);
        setZoneLineChartDataUnit(data.unit);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(error);
      setSeverity(ALERT_TYPE.alertError);
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  return (
    <>
      <AlertNotification alert={alert} severity={severity} message={message} />
      <Box display={`flex`} flexDirection={`column`}>
        <Box
          display={`flex`}
          flexDirection={`column`}
          justifyContent={`space-between`}
        >
          {/* BOX#1 */}
          <Box
            className="box-wrapper"
            display={`flex`}
            flexDirection={`column`}
          >
            <Box display={`flex`} flexDirection={`column`}>
              {/* { graphData, unit, timeType } */}
              <Typography className="subtitle-header">
                การใช้ไฟฟ้ารวมของระบบปรับอากาศแบบแยกส่วน
              </Typography>
              {zoneLineChartData && (
                <D3LineChart
                  graphData={zoneLineChartData}
                  unit={zoneLineChartDataUnit}
                  timeType={selectedTimeType}
                />
              )}
            </Box>
            <DownloadBox
              endPoint={`api/dashboard/splitType/downloadAirConditioning/${
                buildingInfo ? buildingInfo.buildingId : 0
              }/${selectedYear}/${selectedMonth}/${selectedDay}`}
            />
          </Box>

          {/* BOX#2 */}
          <Box
            className="box-wrapper"
            sx={{ mt: 1 }}
            display={`flex`}
            flex={1}
            flexDirection={`column`}
          >
            <Typography className="subtitle-header">
              การใช้พลังงานไฟฟ้ารวมของระบบปรับอากาศแบบแยกส่วนตาม Zone
            </Typography>
            <Box display={`flex`} flexDirection={`row`}>
              {/* LEFT BOX */}
              { zoneList && zoneList.length > 0 ? <Box
                display={`flex`}
                flexDirection={`column`}
                justifyContent={`center`}
                alignContent={`start`}
                alignItems={`center`}
                textAlign={`left`}
                flex={1}
                sx={{ maxWidth: "150px", m: 2 }}
              >
                <Box display={`flex`}>
                  {/* ICON  */}
                  <img
                    src={AirCompressorIcon}
                    width={100}
                    alt="air icon"
                    sx={{ cursor: "pointer" }}
                  />
                </Box>
                <Box display={`flex`}>
                  <Typography className="large-title-header">
                    Split Type
                  </Typography>
                </Box>
              </Box> : <Box>No data to show</Box>}
              

              {/* RIGHT BOX */}
              <Box display={`flex`} flexDirection={`column`} flex={2}>
                {zoneList &&
                  zoneList.map((item, index) => (
                    <Box key={index} display={`flex`}>
                      <Box className="text-primary d-flex justify-content-center align-items-center" flex={0.2}>
                        <Box
                          display={`flex`}
                          sx={{ mt: 2 }}
                          className="subtitle-header"
                        >
                          {item.zoon || "-"}
                        </Box>
                      </Box>
                      <Box
                        className="box-wrapper-active d-flex justify-content-center align-items-center"
                        style={{ paddingTop: "15px" }}
                        display={`flex`}
                        flex={1}
                      >
                        <Box
                          display={`flex`}
                          justifyContent={`center`}
                          flex={2}
                          className={`title-header-black`}
                        >
                          SUM ENERGY
                        </Box>
                        <Box
                          display={`flex`}
                          justifyContent={`center`}
                          flex={2}
                        >
                          <Box className="lg-title-header">
                            {item.electricity || "-"}
                          </Box>
                          <Box className="text-black" sx={{ ml: 2, mt: 2 }}>
                            {item.electricityUnit || "-"}
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        className="d-flex justify-content-center align-items-center"
                        style={{ paddingTop: "15px" }}
                        justifyContent={`space-around`}
                        display={`flex`}
                        flex={1}
                      >
                       <Box
                          display={`flex`}
                          justifyContent={`center`}
                          flex={2}
                          className={`title-header`}
                        >
                          Power
                        </Box>
                        <Box
                          display={`flex`}
                          justifyContent={`center`}
                          flex={2}
                        >
                          <Box className="xl-title-header">
                            {item.power || "-"}
                          </Box>
                          <Box className="subtitle-header-black" sx={{ ml: 2, mt: 2 }}>
                            {item.powerUnit || "-"}
                          </Box>
                        </Box>
                      </Box>
                      <Box className="d-flex align-items-center" flex={1}></Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
