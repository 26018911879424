import { Box } from '@mui/system';
import React, {useEffect } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import { GET } from '../../Utils/ApiClient';
import { ALERT_DISMISS_TIME, BASE_URL, GRID_PAGE_SIZE, HTTP_STATUS } from "../../Utils/EnvUtils";


export default function UserRefId() {
  
const { OK } = HTTP_STATUS;

  let { refId } = useParams();
const navigate = useNavigate();
  useEffect(() => {
    fetchUserRefId();
  }, [])

  async function fetchUserRefId() {
    const response = await GET(`User?refId=${refId}`);
    if (response.status == OK) {
      const { data } = response;
      const { userId } = data;
      navigate(`/user-management/${userId}`);
    } else {
      navigate('/');
    }
  }
  return (
    <div>
      <Box sx={{m: 5}}>redirect ...</Box>
    </div>
  )
}
