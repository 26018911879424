import axios from 'axios';
import EnvUtils from './EnvUtils';
import jwt_decode from 'jwt-decode';


export let accessToken;
export const getToken = () => {
    const accessToken = localStorage.getItem('access_token');
    return accessToken;
};

axios.interceptors.request.use(function (config) {
    document.body.classList.add('loading-indicator');
    // const token = window.localStorage.token;
    // if (token) {
    //    config.headers.Authorization = `token ${token}`
    // }
    return config
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    document.body.classList.remove('loading-indicator');
    return response;
}, function (error) {
    document.body.classList.remove('loading-indicator');
    return Promise.reject(error);
});

export const HEADER = {
    "headers": {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    }
}

export const HEADER_NO_AUTH = {
    "headers": {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
}

export const GET = (endpoint) => {
    return axios.get(`${EnvUtils.BASE_URL}/api/${endpoint}`, {
        "headers": {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        }
    });
}
export const POST = (endpoint, apiPayload) => {
    return axios.post(`${EnvUtils.BASE_URL}/api/${endpoint}`, apiPayload, {
        "headers": {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        }
    });
}
export const PUT = (endpoint, apiPayload) => {
    return axios.put(`${EnvUtils.BASE_URL}/api/${endpoint}`, apiPayload, {
        "headers": {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        }
    });
}
export const PATCH = (endpoint, apiPayload) => {
    return axios.patch(`${EnvUtils.BASE_URL}/api/${endpoint}`, apiPayload, {
        "headers": {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        }
    });
}


export const DELETE = (endpoint) => {
    return axios.delete(`${EnvUtils.BASE_URL}/api/${endpoint}`, HEADER);
}
export const GET_NO_AUTH = (endpoint) => {
    return axios.get(`${EnvUtils.BASE_URL}/api/${endpoint}`, HEADER_NO_AUTH);
}
export const POST_NO_AUTH = (endpoint, apiPayload) => {
    return axios.post(`${EnvUtils.BASE_URL}/api/${endpoint}`, apiPayload, HEADER_NO_AUTH);
}

export const isTokenExpired = (token) => {
    var decoded = jwt_decode(token)
    if (decoded.exp < Date.now() / 1000) {
        //is expired.
        return true
    } else {
        //not expired
        return false
    }
}

export default {
    GET,
    POST,
    PUT,
    DELETE,
    PATCH,
    getToken,
    isTokenExpired,
    POST_NO_AUTH,
    GET_NO_AUTH
};
