import {
  Container,
  Typography,
  Box,
  TextField,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Grid,
  Switch,
  Button,
  Menu,
  MenuItem,
  Select,
  FormControlLabel,
} from "@mui/material";
import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import Layout from "../../layouts/Layout";
import AdminIcon from "../../assets/images/icons/AdminIcon.svg";
import { useDropzone } from "react-dropzone";
import ImageIcon from "../../assets/images/icons/ImageIcon.svg";
import { InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import UserTable from "./UserTable";
import styles from "./UserListPage.module.css";
import { POST, GET } from "../../Utils/ApiClient";
import { GRID_PAGE_SIZE, HTTP_STATUS } from "../../Utils/EnvUtils";

import { ArticleIcon, CogSmallIcon } from "../../assets";
import { useNavigate } from "react-router-dom";
import UserTypeCombobox from "../Common/UserTypeCombobox";
import PaginationSquared from "../Common/PaginationSquared";

export default function UserListPage() {
  const [searchText, setSearchText] = useState("");
  const [userList, setUserList] = useState([]);
  const [roleId, setRoleId] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Switch" } };
  const { OK } = HTTP_STATUS;
  const [userTypes, setUserTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCount, setCurrentCount] = useState(1);

  useEffect(() => {
    fetchUserRoleAll();
    return () => {
      setUserTypes([]);
    };
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm();
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchUserListData();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [roleId, isActive, searchText, currentPage]);

  async function fetchUserRoleAll() {
    try {
      const res = await GET(`User/role`);
      const { data, status } = res;
      setUserTypes(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchUserListData() {
    try {
      const pageNumber = currentPage;
      const pageSize = GRID_PAGE_SIZE;
      const payload = {
        search: searchText,
        roleId: roleId,
        active: isActive,
        pageNumber: pageNumber,
        pageSize: pageSize,
      };
      const res = await POST(`user/search`, payload);
      const { data, status } = res;
      setUserList(data);
      const total = data.length > 0 ? data[0].total : 1;
      const currentFaction = total / pageSize;
      setCurrentCount(Math.ceil(currentFaction));
    } catch (error) {
      console.log(error);
    }
  }

  async function onChangeHandle(e) {
    setSearchText(e.target.value);
  }

  function goToLogPage(user) {
    window.location.href = `datalog/${user.userId}`;
  }

  function goToUsermanagement(user) {
    // navigate(`user-management/${user.userId}`)
    window.location.href = `user-management/${user.userId}`;
  }

  function handleSelectChange(e) {
    setRoleId(e.target.value);
  }

  function onSwitchChange(e) {
    const checked = e.target.checked;
    setIsActive(checked);
  }

  function onPageChangeHandle(e, pageNumber) {
    setCurrentPage(pageNumber);
  }
  return (
    <div>
      <Layout>
        <Container maxWidth={`2xl`} minWidth={`2xl`}>
          <Box
            // sx={{
            //   p: 4,
            //   mt: 4,
            //   border: "1px solid #25A18E",
            //   borderRadius: "10px",
            //   bgcolor: "#FFFFFF",
            // }}
            className="box-wrapper"
          >
            {/* WRAPPER */}
            <Box>
              <Box varaint="div" sx={{ p: 1 }}>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <OutlinedInput
                    aria-describedby={`outlined-search-helper-text`}
                    inputProps={{
                      "aria-label": "search",
                    }}
                    name="search"
                    id="search"
                    type="search"
                    placeholder="Search ..."
                    onChange={(e) => onChangeHandle(e)}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton aria-label="Serach icon">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>

              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`start`}
              >
                <Box display={`flex`}>
                  <Typography
                    sx={{ p: 2 }}
                    className={styles.mainGridHeaderText}
                  >
                    การจัดการผู้ใช้งาน
                  </Typography>
                </Box>
                <Box display={`flex`} flexDirection={`row`}>
                  <Box display={`flex`} flex={1}>
                    <Typography
                      display={`flex`}
                      sx={{ p: 2 }}
                      className={styles.mainGridHeaderText}
                    >
                      ประเภทผู้ใช้งาน
                    </Typography>
                    <Box display={`flex`} flex={2} minWidth={200}>
                      <UserTypeCombobox
                        handleSelectChange={handleSelectChange}
                        userTypes={userTypes}
                      />
                    </Box>
                  </Box>
                  <Box display={`flex`}>
                    <FormControlLabel
                      control={
                        <Switch
                          {...label}
                          defaultChecked={true}
                          onChange={onSwitchChange}
                        />
                      }
                      label="สถานะเปิดใช้งาน"
                      labelPlacement="start"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <table className="table-light" style={{ minWidth: "800px" }}>
                <thead>
                  <tr className="row-left">
                    <td style={{ width: "50%", textAlign: "center" }}>
                      ผู้ใช้งาน
                    </td>
                    <td style={{ width: "100%" }}>ชื่อ-นามสกุล</td>
                    <td style={{ width: "100%" }}>สถานที่ทำงาน</td>
                    <td style={{ width: "120%" }}>อีเมลล์</td>
                    <td style={{ width: "100%" }}>ประเภทผู้ใช้</td>
                    <td style={{ width: "100%", textAlign: "center" }}>
                      สถานะเปิดใช้งาน
                    </td>
                    <td style={{ width: "100%", textAlign: "center" }}>
                      Data Log
                    </td>
                    <td style={{ width: "100%", textAlign: "center" }}>
                      แก้ไขข้อมูล
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {userList &&
                    userList.map((user, index) => (
                      <tr className="row-left" key={index}>
                        <td style={{ width: "50%", textAlign: "center" }}>
                          {user.userId}
                        </td>
                        <td style={{ width: "100%" }}>
                          {user.firstName} {user.lastName}
                        </td>
                        <td style={{ width: "100%" }}>
                          {/* <Box className="button-pilled-outlined">{`${user.workPlace.substring(
                            0,
                            10
                          )}...`}</Box> */}
                          <Button variant="outlined" sx={{ pl: 1, pr: 1 }}>
                            {`${user.workPlace.substring(0, 10)}...`}
                          </Button>
                        </td>
                        <td style={{ width: "120%" }}>{user.email}</td>
                        <td style={{ width: "100%" }}>
                          {/* <Box className="button-pilled-outlined">
                            {user.roleName}
                          </Box> */}
                          <Button variant="outlined" sx={{ pl: 1, pr: 1 }}>
                            {user.roleName}
                          </Button>
                        </td>
                        <td
                          style={{
                            width: "100%",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Switch checked={user.active} />
                        </td>
                        <td
                          style={{
                            width: "100%",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={ArticleIcon}
                            width={35}
                            alt="article icon"
                            onClick={() => goToLogPage(user)}
                          />
                        </td>
                        <td
                          style={{
                            width: "100%",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={CogSmallIcon}
                            width={35}
                            alt="action icon"
                            onClick={() => goToUsermanagement(user)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Box
                display={`flex`}
                justifyContent={`flex-end`}
                sx={{ mt: 5, mr: 10 }}
              >
                {userList && userList.length > 0 && currentCount > 0 && (
                  <Box display={`flex`}>
                    <PaginationSquared
                      page={currentPage}
                      count={currentCount}
                      onPageChange={onPageChangeHandle}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Container>
      </Layout>
    </div>
  );
}
