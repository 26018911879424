import React, { useState, useEffect } from "react";
import { Link, Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AuthUtils } from './../../Utils'

const authContext = React.createContext();

function useAuth() {
  const token = localStorage.getItem('access_token')

  const [authed, setAuthed] = React.useState(token && token != null && validateToken(token) ? true : false);
  return {
    authed,
    login() {
      return new Promise((res) => {
        setAuthed(true);
        res();
      });
    },
    logout() {
      return new Promise((res) => {
        setAuthed(false);
        res();
      });
    }
  };
}


function validateToken(currentToken) {
  const { decodeToken, isTokenTimeExpired } = AuthUtils;
  if (currentToken) {
    const decodedToken = decodeToken(currentToken)
    const isExpire = isTokenTimeExpired(decodedToken.exp)
    if (isExpire) {
      // window.location.href = '/'
      return false;
    } else {
      return true;
    }
  } else { return false; }
}

function AuthProvider({ children }) {
  const auth = useAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

function AuthConsumer() {
  return React.useContext(authContext);
}


function RequireAuth({ children }) {
  const { authed } = useAuth();
  const location = useLocation();
  return authed === true
    ? children
    : <Navigate to="/signin" replace />;
}


export default { useAuth, AuthProvider, AuthConsumer, RequireAuth }