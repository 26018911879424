import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function PaginationSquared({  page, count, onPageChange }) {
  return (
    <Stack spacing={2}>
      <Pagination count={count} shape="rounded" page={page} onChange={onPageChange} />
    </Stack>
  );
}