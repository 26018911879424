import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import D3PieChart from "../Common/d3/D3PieChart";
import * as d3 from "d3";
import { BASE_URL } from "../../Utils/EnvUtils";
import { DownloadIcon } from "../../assets";

export default function BottomSummaryBox({
  summaryData,
  buildingEnergyRatio,
  buildingInfo,
  energyType,
  selectedYear,
  selectedMonth,
  selectedDay,
  buildingSummaryEnergyUse,
}) {
  const [data, setData] = useState([]);
  const [dataCentralAirCondition, setDataCentralAirCondition] = useState([]);
  const [dataSplitAirCondition, setDataSplitAirCondition] = useState([]);
  const [dataLighting, setDataLighting] = useState([]);
  const [dataOther, setDataOther] = useState([]);

  useEffect(() => {
    if (buildingEnergyRatio) {
      const {
        percenCenterAirConditionSystem,
        percenSplitAirConditionSystem,
        percenLightingSystem,
        percenOtherSystem,
      } = buildingEnergyRatio;
      const rawDataCentralAirCondition = [
        { date: 0, value: percenCenterAirConditionSystem },
        { date: 1, value: 100 - percenCenterAirConditionSystem },
      ];
      const rawDataSplitAirCondition = [
        { date: 0, value: percenSplitAirConditionSystem },
        { date: 1, value: 100 - percenSplitAirConditionSystem },
      ];
      const rawDataLighting = [
        { date: 0, value: percenLightingSystem },
        { date: 1, value: 100 - percenLightingSystem },
      ];
      const rawDatatOther = [
        { date: 0, value: percenOtherSystem },
        { date: 1, value: 100 - percenOtherSystem },
      ];

      setDataCentralAirCondition(rawDataCentralAirCondition);
      setDataSplitAirCondition(rawDataSplitAirCondition);
      setDataLighting(rawDataLighting);
      setDataOther(rawDatatOther);
    }
    return () => {};
  }, [buildingEnergyRatio]);

  return (
    <>
      {/* 1 st BOX */}
      <Box
        display={`flex`}
        flex={1}
        flexDirection={`column`}
        className="box-wrapper"
      >
        {/* HEADER */}
        <Box display={`flex`}>
          <Typography className="subtitle-header">
            ระบบปรับอากาศแบบรวมศูนย์
          </Typography>
        </Box>

        <Box display={`flex`} flexDirection={`row`}>
          {/* LEFT 1st ROW */}
          <Box
            display={`flex`}
            className="box-wrapper"
            flexDirection={`column`}
            flex={1}
          >
            <Typography display={`flex`} className="text-primary">
              SUM ENERGY
            </Typography>
            <Typography
              display={`flex`}
              className="text-black"
              sx={{ fontSize: "40px" }}
            >
              {(buildingSummaryEnergyUse &&
                buildingSummaryEnergyUse.centerAirConditionSystem) ||
                "-"}
            </Typography>
            <Box display={`flex`} justifyContent={`end`}>
              <Typography display={`flex`} className="text-black">
                {(buildingSummaryEnergyUse &&
                  buildingSummaryEnergyUse.centerAirConditionSystemUnit) ||
                  "-"}
              </Typography>
            </Box>
          </Box>
          {/* LEFT 2nd ROW */}
          <Box
            display={`flex`}
            className="box-wrapper"
            flexDirection={`column`}
            flex={1}
          >
            <Typography display={`flex`} className="text-primary">
              AVERAGE CHILLER PLANT EFFICIENCY
            </Typography>
            <Typography
              display={`flex`}
              className="text-black"
              sx={{ fontSize: "40px" }}
            >
              {(buildingSummaryEnergyUse &&
                buildingSummaryEnergyUse.averageCpe) ||
                "-"}
            </Typography>
            <Box display={`flex`} justifyContent={`end`}>
              <Typography display={`flex`} className="text-black">
                {(buildingSummaryEnergyUse &&
                  buildingSummaryEnergyUse.averageCpeUnit) ||
                  "-"}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* 2nd row summary */}

        <Box display={`flex`} flexDirection={`row`}>
          {/* LEFT 1st ROW */}
          <Box display={`flex`} flexDirection={`column`} flex={1}>
            <Box display={`flex`}>
              <Typography className="subtitle-header">
                ระบบปรับอากาศแบบแยกส่วน
              </Typography>
            </Box>
            <Box
              display={`flex`}
              className="box-wrapper"
              flexDirection={`column`}
              flex={1}
            >
              <Typography display={`flex`} className="text-primary">
                SUM ENERGY
              </Typography>
              <Typography
                display={`flex`}
                className="text-black"
                sx={{ fontSize: "40px" }}
              >
                {(buildingSummaryEnergyUse &&
                  buildingSummaryEnergyUse.splitAirConditionSystem) ||
                  "-"}
              </Typography>
              <Box display={`flex`} justifyContent={`end`}>
                <Typography display={`flex`} className="text-black">
                  {(buildingSummaryEnergyUse &&
                    buildingSummaryEnergyUse.splitAirConditionSystemUnit) ||
                    "-"}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* LEFT 2nd ROW */}
          <Box display={`flex`} flexDirection={`column`} flex={1}>
            <Box display={`flex`}>
              <Typography className="subtitle-header">
                ระบบไฟฟ้าแสงสว่าง
              </Typography>
            </Box>
            <Box
              display={`flex`}
              className="box-wrapper"
              flexDirection={`column`}
              flex={1}
            >
              <Typography display={`flex`} className="text-primary">
                SUM ENERGY
              </Typography>
              <Typography
                display={`flex`}
                className="text-black"
                sx={{ fontSize: "40px" }}
              >
                {(buildingSummaryEnergyUse &&
                  buildingSummaryEnergyUse.lightingSystem) ||
                  "-"}
              </Typography>
              <Box display={`flex`} justifyContent={`end`}>
                <Typography display={`flex`} className="text-black">
                  {(buildingSummaryEnergyUse &&
                    buildingSummaryEnergyUse.lightingSystemUnit) ||
                    "-"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* 3rd row summary */}

        <Box display={`flex`} flexDirection={`row`}>
          {/* LEFT 1st ROW */}
          <Box display={`flex`} flexDirection={`column`} flex={1}>
            <Box display={`flex`}>
              <Typography className="subtitle-header">อื่นๆ</Typography>
            </Box>
            <Box
              display={`flex`}
              className="box-wrapper"
              flexDirection={`column`}
              flex={1}
            >
              <Typography display={`flex`} className="text-primary">
                SUM ENERGY
              </Typography>
              <Typography
                display={`flex`}
                className="text-black"
                sx={{ fontSize: "40px" }}
              >
                {(buildingSummaryEnergyUse &&
                  buildingSummaryEnergyUse.otherSystem) ||
                  "-"}
              </Typography>
              <Box display={`flex`} justifyContent={`end`}>
                <Typography display={`flex`} className="text-black">
                  {(buildingSummaryEnergyUse &&
                    buildingSummaryEnergyUse.otherSystemUnit) ||
                    "-"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display={`flex`} flexDirection={`column`} flex={1}></Box>
        </Box>
        <Box display={`flex`} justifyContent={`flex-end`} sx={{ mr: 1, mb: 1 }}>
          {
            <a
              href={`${BASE_URL}/api/dashboard/downloadelectricitySystem/${
                buildingInfo ? buildingInfo.buildingId : 0
              }/${selectedYear}/${selectedMonth}/${selectedDay}`}
              download
            >
              <img
                src={DownloadIcon}
                alt="download icon"
                sx={{ cursor: "pointer" }}
              />
            </a>
          }
        </Box>
      </Box>
      {/* 2nd Box */}
      <Box
        display={`flex`}
        flex={1}
        flexDirection={`column`}
        className="box-wrapper"
      >
        {/* HEADER */}
        <Box display={`flex`}>
          <Typography className="subtitle-header">
            สัดส่วนการใช้พลังงานไฟฟ้า
          </Typography>
        </Box>

        <Box display={`flex`} flexDirection={`row`}>
          {/* LEFT 1st ROW */}
          <Box
            display={`flex`}
            flexDirection={`column`}
            flex={1}
            flexWrap={`wrap`}
            justifyContent="center"
          >
            <Typography
              display={`flex`}
              className="text-black"
              justifyContent="center"
            >
              ระบบปรับอากาศแบบรวมศูนย์
            </Typography>
            <Box display={`flex`} justifyContent="center" sx={{ mt: 2 }}>
              <D3PieChart
                data={dataCentralAirCondition}
                width={200}
                height={200}
                innerRadius={0}
                outerRadius={100}
              />
            </Box>
          </Box>
          {/* LEFT 2nd ROW */}
          <Box
            display={`flex`}
            flexDirection={`column`}
            flex={1}
            justifyContent="center"
          >
            <Typography
              display={`flex`}
              className="text-black"
              justifyContent="center"
            >
              ระบบปรับอากาศแบบแยกส่วน
            </Typography>
            <Box display={`flex`} justifyContent="center" sx={{ mt: 2 }}>
              <D3PieChart
                data={dataSplitAirCondition}
                width={200}
                height={200}
                innerRadius={0}
                outerRadius={100}
              />
            </Box>
          </Box>
        </Box>

        {/* 2nd row summary */}
        <Box display={`flex`} flexDirection={`row`} sx={{ mt: 4}}>
          {/* LEFT 1st ROW */}
          <Box
            display={`flex`}
            flexDirection={`column`}
            flex={1}
            justifyContent="center"
          >
            <Typography
              display={`flex`}
              className="text-black"
              justifyContent="center"
            >
              ระบบไฟฟ้าแสงสว่าง
            </Typography>
            <Box display={`flex`} justifyContent="center" sx={{ mt: 2 }}>
              <D3PieChart
                data={dataLighting}
                width={200}
                height={200}
                innerRadius={0}
                outerRadius={100}
              />
            </Box>
          </Box>
          {/* LEFT 2nd ROW */}
          <Box
            display={`flex`}
            flexDirection={`column`}
            flex={1}
            justifyContent="center"
          >
            <Typography
              display={`flex`}
              className="text-black"
              justifyContent="center"
            >
              อื่นๆ
            </Typography>
            <Box display={`flex`} justifyContent="center" sx={{ mt: 2 }}>
              <D3PieChart
                data={dataOther}
                width={200}
                height={200}
                innerRadius={0}
                outerRadius={100}
              />
            </Box>
          </Box>
        </Box>

        <Box display={`flex`} justifyContent={`flex-end`} sx={{ mr: 1, mb: 1 }}>
          {/* /api/Dashboard/electricitySystemRatio/{buildingId}/{year}/{month}/{day} */}
          {
            <a
              href={`${BASE_URL}/api/dashboard/downloadElectricitySystemRatio/${
                buildingInfo ? buildingInfo.buildingId : 0
              }/${selectedYear}/${selectedMonth}/${selectedDay}`}
              download
            >
              <img
                src={DownloadIcon}
                alt="download icon"
                sx={{ cursor: "pointer" }}
              />
            </a>
          }
        </Box>
      </Box>
    </>
  );
}
