import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { HumidityIcon, SolarPanelIcon, ThermometerIcon2 } from "../../assets";
import AlertNotification from "../Common/AlertNotification";
import DataBox from "../Common/DataBox";
import AlternativeEnergyPercent from "../Home/AlternativeEnergyPercent";
import { data } from "../../uiData/UIData";
import { NOTIFICATION_MESSAGE } from "../../Utils/Messages";
import { ALERT_DISMISS_TIME, HTTP_STATUS } from "../../Utils/EnvUtils";
import { GET } from "../../Utils/ApiClient";
import { D3LineChart } from "../Common/d3/D3LineChart";
import DownloadBox from "../Common/DownloadBox";
import { modifiedLineChartDataTimePeriod } from "../../Utils/FuncUtils";

export default function EnergyGenerateSystem({
  buildingInfo,
  selectedYear,
  selectedMonth,
  selectedDay,
  selectedTimeType,
}) {
  const { homeTabPills } = data;
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [activePill, seTactivePill] = useState(homeTabPills[0]);
  const [buildingEnergyUse, setBuildingEnergyUse] = useState();
  const [electricityGeneration, setElectricityGeneration] = useState();
  const [generationGraphData, setGenerationGraphData] = useState();
  const [generationGraphDataUnit, setGenerationGraphDataUnit] = useState();

  useEffect(() => {
    if (buildingInfo) {
      fetchElectricityGeneration();
      fetchGenerationGraphData();
    }
    return () => {};
  }, [buildingInfo, selectedYear, selectedMonth, selectedDay]);

  async function fetchElectricityGeneration() {
    try {
      // GET:/dashboard/generatio/Electricity/{buildingId}/{pumpTypeId}/{year}/{month}/{day}
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const response = await GET(
        `dashboard/generatio/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = response;
      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        setElectricityGeneration(data);
        const {
          electricity,
          electricityUnit,
          reNewableElectricity,
          reNewableElectricityUnit,
          percentElectricity,
          percentReNewableElectricity,
        } = data;
        const energyUsed = {
          electricity,
          electricityUnit,
          reNewableElectricity,
          reNewableElectricityUnit,
          percentElectricity,
          percentReNewableElectricity,
        };
        setBuildingEnergyUse(energyUsed);
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
        // todo : set alert
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchGenerationGraphData() {
    try {
      // GET: dashboard/generation/energy/{buildingId}/{year}/{month}/{day}
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const response = await GET(
        `dashboard/generation/energy/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = response;
      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        if (data) {
          const modifiedData = modifiedLineChartDataTimePeriod(
            data,
            selectedYear,
            selectedMonth,
            selectedDay
          );
          setGenerationGraphData(modifiedData);
          setGenerationGraphDataUnit(data.unit);
        }
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
        // todo : set alert
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  return (
    <>
      {electricityGeneration && (
        <>
          <Box
            className="box-wrapper"
            sx={{ mt: 1 }}
            display={`flex`}
            flexDirection={`column`}
          >
            <Box display={`flex`}>
              <Typography className={`subtitle-header`}>
                การผลิตพลังงานไฟฟ้าจากพลังงานทดแทน
              </Typography>
            </Box>
            <Box display={`flex`} flexDirection={`row`}>
              {/* BIG LOGO */}
              <Box
                className="big-logo"
                display={`flex`}
                justifyContent={`center`}
                flex={1}
                sx={{ width: "100%", p: 2, m: 2 }}
              >
                <img src={SolarPanelIcon} alt="solar panel" />
              </Box>
              <Box display={`flex`} flex={2}>
                <Box display={`flex`} flexDirection={`column`}>
                  <DataBox
                    title={`กำลังการผลิตติดตั้ง`}
                    dataValue={electricityGeneration.installedPowerCapacity}
                    dataUnit={electricityGeneration.installedPowerCapacityUnit}
                  />
                  <DataBox
                    title={`กำลังการผลิต ณ ปัจจุบัน`}
                    dataValue={electricityGeneration.powerGeneration}
                    dataUnit={electricityGeneration.powerGenerationUnit}
                  />
                </Box>
                <Box display={`flex`} flexDirection={`column`}>
                  <DataBox
                    title={`พลังงานไฟฟ้าที่ผลิตสะสม`}
                    dataValue={electricityGeneration.totalEnergyGeneration}
                    dataUnit={electricityGeneration.totalEnergyGenerationUnit}
                  />
                  <DataBox
                    title={`ลดการปล่อย CO2 สะสม`}
                    dataValue={electricityGeneration.cO2Reduction}
                    dataUnit={electricityGeneration.cO2ReductionUnit}
                  />
                </Box>
                <Box display={`flex`} flexDirection={`column`}>
                  <DataBox
                    title={`พลังงานไฟฟ้าที่ผลิตในวันนี้`}
                    dataValue={electricityGeneration.energyGeneration}
                    dataUnit={electricityGeneration.energyGenerationUnit}
                  />
                  <DataBox
                    title={`พลังงานไฟฟ้าที่กักเก็บในวันนี้`}
                    dataValue={electricityGeneration.energyStorage}
                    dataUnit={electricityGeneration.energyStorageUnit}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display={`flex`} flexDirection={`row`}>
            <Box
              display={`flex`}
              flexDirection={`row`}
              justifyContent={`space-around`}
              className="box-wrapper"
              flex={1}
              alignItems={`center`}
            >
              <Box display={`flex`} flexDirection={`column`}>
                <Box display={`flex`}>
                  <img src={ThermometerIcon2} alt={`thermometer icon`} />
                </Box>
                <Box display={`flex`} className="xxxl-title-header-black">
                  {electricityGeneration.outsideTemperature}
                </Box>
                <Box display={`flex`} className="xxxl-title-header-black">
                  {electricityGeneration.outsideTemperatureUnit}
                </Box>
              </Box>
              <Box display={`flex`} flexDirection={`column`}>
                <Box display={`flex`}>
                  <img src={HumidityIcon} alt={`humidity icon`} />
                </Box>
                <Box display={`flex`} className="xxxl-title-header-black">
                  {electricityGeneration.outsideHumidity}
                </Box>
                <Box display={`flex`} className="xxxl-title-header-black">
                  {electricityGeneration.outsideHumidityUnit}
                </Box>
              </Box>
            </Box>

            <Box
              display={`flex`}
              flexDirection={`column`}
              justifyContent={`center`}
              className="box-wrapper"
              flex={1}
            >
              <AlternativeEnergyPercent
                activePill={activePill}
                buildingEnergyUse={buildingEnergyUse}
              />
            </Box>
          </Box>

          {/* BOX#1 */}
          <Box
            className="box-wrapper"
            display={`flex`}
            flexDirection={`column`}
            sx={{ pb: 2 }}
          >
            <Box display={`flex`} flexDirection={`column`}>
              {/* { graphData, unit, timeType } */}
              <Typography className="subtitle-header">
                พลังงานไฟฟ้าที่ผลิตได้
              </Typography>
              
              {generationGraphData && (
                <D3LineChart
                  graphData={generationGraphData}
                  unit={generationGraphDataUnit}
                  timeType={selectedTimeType}
                />
              )}
            </Box>
            <DownloadBox
              endPoint={`api/dashboard/generation/downloadEnergy/${buildingInfo ? buildingInfo.buildingId : 0}/${selectedYear}/${selectedMonth}/${selectedDay}`}
            />
          </Box>
        </>
      )}
    </>
  );
}
