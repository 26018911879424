import React, { useEffect, useState } from "react";
import "./PrintPage1.css";
import { Box } from "@mui/system";
import ApexDoughnutChartReport from "../Common/Apex/ApexDoughnutChartReport";
import GeneralLayoutLight from "../../layouts/GeneralLayoutLight";
import { Divider, Typography } from "@mui/material";
import {
  DropletIcon,
  ElectricalWireIcon,
  FrameIcon,
  PlugIcon,
  SolarPanelIcon,
} from "../../assets";
import { ENERGY_USE_TYPE, X_AXIS_TIME_TYPE } from "../../Utils/EnvUtils";
import { D3LineChartReport } from "../Common/d3/D3LineChartReport";
import { FuncUtils, Messages } from "../../Utils";
import { v4 as uuidv4 } from "uuid";
import numeral from "numeral";

export const ReportEnergyUsePage = ({
  energySummary,
  selectedYear,
  selectedMonth,
  selectedDay,
  selectedTimeType,
  reportType,
  loopingIndex,
  countArray
}) => {
  const {
    reportDate,
    thermalEnergy,
    thermalEnergyUnit,
    electricitySummary,
    electricitySummaryUnit,
    energyVsCrudeOil,
    energyVsCrudeOilUnit,
    electricity,
    electricityUnit,
    reNewableElectricity,
    reNewableElectricityUnit,
    percentElectricity,
    percentReNewableElectricity,
    energyUseByBuildingType,
    energyUseBySystem,
    electricityUse,
    thermalUse,
    energyConsumption,
  } = energySummary;

  const [buildingEnergyUseByTimeUnit, setBuildingEnergyUseByTimeUnit] =
    useState();
  const [buildingEnergyUseByTime, setBuildingEnergyUseByTime] = useState();
  const [buildingThermalUseByTimeUnit, setBuildingThermalUseByTimeUnit] =
    useState();
  const [buildingThermalUseByTime, setBuildingThermalUseByTime] = useState();
  const { numberWithCommas } = FuncUtils;


  useEffect(() => {
    // const { selectedYear, selectedMonth, selectedDay } = mockSelectedDate;
    const modifiedGraphData =
      electricityUse &&
      electricityUse.data.map((item) => {
        let modifiedDateFormattedString = "";
        if (selectedMonth < 0) {
          modifiedDateFormattedString = `${selectedYear}-${item.xaxis}-01 00:01`;
        } else if (selectedDay < 0) {
          modifiedDateFormattedString = `${selectedYear}-${selectedMonth}-${item.xaxis} 00:01`;
        } else {
          modifiedDateFormattedString = `${selectedYear}-${selectedMonth}-${selectedDay} ${item.xaxis}`;
        }
        const theDate = new Date(modifiedDateFormattedString);
        return {
          timestamp: theDate,
          electricityUse: item.yaxis,
        };
      });
    setBuildingEnergyUseByTimeUnit(electricityUse.unit);
    setBuildingEnergyUseByTime(modifiedGraphData);

    // thermal
    const thermalGraphData =
      thermalUse &&
      thermalUse.data.map((item) => {
        let thermalDateStr = "";
        if (selectedMonth < 0) {
          thermalDateStr = `${selectedYear}-${item.xaxis}-01 00:01`;
        } else if (selectedDay < 0) {
          thermalDateStr = `${selectedYear}-${selectedMonth}-${item.xaxis} 00:01`;
        } else {
          thermalDateStr = `${selectedYear}-${selectedMonth}-${selectedDay} ${item.xaxis}`;
        }
        const theThermalDate = new Date(thermalDateStr);
        return {
          timestamp: theThermalDate,
          electricityUse: item.yaxis,
        };
      });
    setBuildingThermalUseByTimeUnit(thermalUse.unit);
    setBuildingThermalUseByTime(thermalGraphData);
    return () => {};
  }, [selectedYear, selectedMonth, selectedDay, energySummary]);

  return (
    <React.Fragment>
      <div>
        <GeneralLayoutLight>
          <Box
            // display={`flex`}
            // flexDirection={`column`}
            sx={{ p: 2, marginBottom: reportType == ENERGY_USE_TYPE.ALL ? 0 : (loopingIndex == (countArray -1)) ? '0px' : '150px' }}
            style={{height: `29.7cm !important`, width: `21cm !important`}}
            id="report1"
          >
            <Box 
              // display={`flex`}
            >
              <Typography className="subtitle-header">
                Report | รายงานภาพรวมการใช้พลังงานอาคารควบคุมทั้งประเทศ
              </Typography>
            </Box>

            <Box className="custom-divider"></Box>
            <Box 
              // display={`flex`}
            >
              <Typography className="subtitle-header-black">
                {reportType == ENERGY_USE_TYPE.ALL
                  ? `ข้อมูลการใช้พลังงานสะสม`
                  : `ข้อมูลการใช้พลังงานสะสม (${energySummary.reportType})`}
              </Typography>
            </Box>

            <Box display={`flex`}>
              <Typography className="text-primary">
                วันที่ {reportDate}
              </Typography>
            </Box>

            {/* START : 1st SECTION REPORT 1 */}
            {reportType == ENERGY_USE_TYPE.ALL ? (
              <>
                <Box
                  display={`flex`}
                  flexDirection={`row`}
                  justifyContent={`start`}
                >
                  {/* LEFT GROUP */}
                  <Box display={`flex`} flexDirection={`column`} sx={{ mr: 2 }}>
                    {/* INDIVIDUAL BOX */}
                    <Box
                      display={`flex`}
                      sx={{ pt: 1, pb: 1 }}
                      flexDirection={`column`}
                    >
                      <Typography display={`flex`} className="text-black">
                        ความร้อน
                      </Typography>
                      <Box
                        display={`flex`}
                        flexDirection={`row`}
                        sx={{ pl: 1, pr: 1 }}
                        justifyContent={`start`}
                      >
                        <Box display={`flex`} sx={{ mt: 1, mr: 10 }}>
                          <img src={FrameIcon} alt="" />
                        </Box>
                        <Box
                          display={`flex`}
                          flexDirection={`column`}
                          justifyContent={`end`}
                          alignItems={`end`}
                        >
                          <Box display={`flex`}>
                            <Typography className="large-title-header-black">
                              {thermalEnergy}
                            </Typography>
                          </Box>
                          <Box display={`flex`}>
                            <Typography
                              display={`flex`}
                              className="title-header-black"
                            >
                              {thermalEnergyUnit}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    {/* INDIVIDUAL BOX */}
                    <Box
                      display={`flex`}
                      sx={{ pt: 1, pb: 1 }}
                      flexDirection={`column`}
                    >
                      <Typography display={`flex`} className="text-black">
                        ไฟฟ้า
                      </Typography>
                      <Box
                        display={`flex`}
                        flexDirection={`row`}
                        sx={{ pl: 1, pr: 1 }}
                        justifyContent={`start`}
                      >
                        <Box display={`flex`} sx={{ mt: 1, mr: 4, ml: 2 }}>
                          <img src={PlugIcon} alt="" />
                        </Box>
                        <Box
                          display={`flex`}
                          flexDirection={`column`}
                          justifyContent={`end`}
                          alignItems={`end`}
                          sx={{ ml: 4 }}
                        >
                          <Box display={`flex`}>
                            <Typography className="large-title-header-black">
                              {electricitySummary}
                            </Typography>
                          </Box>
                          <Box display={`flex`}>
                            <Typography
                              display={`flex`}
                              className="title-header-black"
                            >
                              {electricitySummaryUnit}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  {/* RIGHT GROUP */}
                  <Box display={`flex`} flexDirection={`column`} sx={{ mr: 2 }}>
                    {/* INDIVIDUAL BOX */}
                    <Box
                      display={`flex`}
                      sx={{ pt: 1, pb: 1 }}
                      flexDirection={`column`}
                    >
                      <Typography display={`flex`} className="text-black">
                        ปริมาณพลังงานรวมสะสมเทียบเป็นตันน้ำมันดิบ
                      </Typography>
                      <Box
                        display={`flex`}
                        flexDirection={`row`}
                        sx={{ pl: 1, pr: 1 }}
                        justifyContent={`start`}
                      >
                        <Box display={`flex`} sx={{ mt: 1, mr: 10 }}>
                          <img src={DropletIcon} alt="" />
                        </Box>
                        <Box
                          display={`flex`}
                          flexDirection={`column`}
                          justifyContent={`end`}
                          alignItems={`end`}
                        >
                          <Box display={`flex`}>
                            <Typography className="large-title-header-black">
                              {energyVsCrudeOil}
                            </Typography>
                          </Box>
                          <Box display={`flex`}>
                            <Typography
                              display={`flex`}
                              className="title-header-black"
                            >
                              {energyVsCrudeOilUnit}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* INDIVIDUAL BOX */}
                    <Box
                      display={`flex`}
                      sx={{ pt: 1, pb: 1, mt: 2 }}
                      flexDirection={`row`}
                      justifyContent={`space-between`}
                    >
                      {/* ROW # 1 */}
                      <Box
                        display={`flex`}
                        flexDirection={`column`}
                        sx={{ mr: 2}}
                      >
                        <Box display={`flex`}>
                          <Typography className="text-sm-primary">
                            การใช้พลังงานจากโครงข่ายไฟฟ้า
                          </Typography>
                        </Box>
                        <Box
                          display={`flex`}
                          flexDirection={`row`}
                          sx={{ pl: 1, pr: 1 }}
                          justifyContent={`space-between`}
                        >
                          <Box display={`flex`}>
                            <img src={ElectricalWireIcon} alt="" />
                          </Box>
                          <Box
                            display={`flex`}
                            flexDirection={`column`}
                            justifyContent={`end`}
                            alignItems={`end`}
                          >
                            <Box display={`flex`} sx={{ mt: 1 }}>
                              <Typography className="subtitle-header-black">
                                {electricity}
                              </Typography>
                              <Typography className="subtitle-header">
                                {electricityUnit}
                              </Typography>
                            </Box>
                            <Box display={`flex`}>
                              <Typography
                                display={`flex`}
                                className="large-title-header-black"
                              >
                                {percentElectricity} %
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {/* ROW #2 */}
                      <Box display={`flex`} flexDirection={`column`}>
                        <Box display={`flex`}>
                          <Typography className="text-sm-primary">
                            การใช้พลังงานจากพลังงานทดแทน
                          </Typography>
                        </Box>
                        <Box
                          display={`flex`}
                          flexDirection={`row`}
                          sx={{ pl: 1, pr: 1 }}
                          justifyContent={`space-between`}
                        >
                          <Box display={`flex`}>
                            <img src={SolarPanelIcon} alt="" />
                          </Box>
                          <Box
                            display={`flex`}
                            flexDirection={`column`}
                            justifyContent={`end`}
                            alignItems={`end`}
                          >
                            <Box display={`flex`} sx={{ mt: 1 }}>
                              <Typography className="subtitle-header-black">
                                {reNewableElectricity}
                              </Typography>
                              <Typography className="subtitle-header">
                                {reNewableElectricityUnit}
                              </Typography>
                            </Box>
                            <Box display={`flex`}>
                              <Typography
                                display={`flex`}
                                className="large-title-header-black"
                              >
                                {percentReNewableElectricity}%
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <></>
            )}
            {/* END : 1st SECTION REPORT 1 */}

            {/* START : 1st SECTION REPORT 2 */}
            {reportType == ENERGY_USE_TYPE.BY_BUILDING ? (
              <>
                <Box
                  display={`flex`}
                  flexDirection={`row`}
                  justifyContent={`space-around`}
                >
                  {/* LEFT GROUP */}
                  <Box display={`flex`} flexDirection={`column`} sx={{ mr: 2 }}>
                    {/* INDIVIDUAL BOX */}
                    <Box
                      display={`flex`}
                      sx={{ pt: 1, pb: 1 }}
                      flexDirection={`column`}
                    >
                      <Typography display={`flex`} className="text-black">
                        ความร้อน
                      </Typography>
                      <Box
                        display={`flex`}
                        flexDirection={`row`}
                        sx={{ pl: 1, pr: 1 }}
                        justifyContent={`start`}
                      >
                        <Box display={`flex`} sx={{ mt: 1, mr: 10 }}>
                          <img src={FrameIcon} alt="" />
                        </Box>
                        <Box
                          display={`flex`}
                          flexDirection={`column`}
                          justifyContent={`end`}
                          alignItems={`end`}
                        >
                          <Box display={`flex`}>
                            <Typography className="large-title-header-black">
                              {thermalEnergy}
                            </Typography>
                          </Box>
                          <Box display={`flex`}>
                            <Typography
                              display={`flex`}
                              className="large-title-header-black"
                            >
                              {thermalEnergyUnit}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    {/* INDIVIDUAL BOX */}
                    <Box
                      display={`flex`}
                      sx={{ pt: 1, pb: 1 }}
                      flexDirection={`column`}
                    >
                      <Typography display={`flex`} className="text-black">
                        การใช้พลังงานจากโครงข่ายไฟฟ้า
                      </Typography>
                      <Box
                        display={`flex`}
                        flexDirection={`row`}
                        sx={{ pl: 1, pr: 1 }}
                        justifyContent={`start`}
                      >
                        <Box display={`flex`} sx={{ mt: 1, mr: 4, ml: 2 }}>
                          <img src={ElectricalWireIcon} alt="" />
                        </Box>
                        <Box
                          display={`flex`}
                          flexDirection={`column`}
                          justifyContent={`end`}
                          alignItems={`end`}
                          sx={{ ml: 4 }}
                        >
                          <Box
                            display={`flex`}
                            flexDirection={`column`}
                            justifyContent={`end`}
                            alignItems={`end`}
                          >
                            <Box display={`flex`} sx={{ mt: 1 }}>
                              <Typography className="subtitle-header-black">
                                {electricity}
                              </Typography>
                              <Typography className="subtitle-header">
                                {" "}
                                {electricityUnit}
                              </Typography>
                            </Box>
                            <Box display={`flex`}>
                              <Typography
                                display={`flex`}
                                className="large-title-header-black"
                              >
                                {percentElectricity} %
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  {/* RIGHT GROUP */}
                  <Box display={`flex`} flexDirection={`column`} sx={{ mr: 2 }}>
                    {/* INDIVIDUAL BOX */}
                    <Box
                      display={`flex`}
                      sx={{ pt: 1, pb: 1 }}
                      flexDirection={`column`}
                    >
                      <Typography display={`flex`} className="text-black">
                        ไฟฟ้า
                      </Typography>
                      <Box
                        display={`flex`}
                        flexDirection={`row`}
                        sx={{ pl: 1, pr: 1 }}
                        justifyContent={`start`}
                      >
                        <Box display={`flex`} sx={{ mt: 1, mr: 10 }}>
                          <img src={PlugIcon} alt="" />
                        </Box>
                        <Box
                          display={`flex`}
                          flexDirection={`column`}
                          justifyContent={`end`}
                          alignItems={`end`}
                        >
                          <Box display={`flex`}>
                            <Typography className="large-title-header-black">
                              {electricitySummary}
                            </Typography>
                          </Box>
                          <Box display={`flex`}>
                            <Typography
                              display={`flex`}
                              className="large-title-header-black"
                            >
                              {electricitySummaryUnit}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    {/* INDIVIDUAL BOX */}
                    <Box
                      display={`flex`}
                      sx={{ pt: 1, pb: 1 }}
                      flexDirection={`column`}
                    >
                      <Typography display={`flex`} className="text-black">
                        การใช้พลังงานจากพลังงานทดแทน
                      </Typography>
                      <Box
                        display={`flex`}
                        flexDirection={`row`}
                        sx={{ pl: 1, pr: 1 }}
                        justifyContent={`start`}
                      >
                        <Box display={`flex`} sx={{ mt: 1, mr: 4, ml: 2 }}>
                          <img src={SolarPanelIcon} alt="" />
                        </Box>
                        <Box
                          display={`flex`}
                          flexDirection={`column`}
                          justifyContent={`end`}
                          alignItems={`end`}
                          sx={{ ml: 4 }}
                        >
                          <Box
                            display={`flex`}
                            flexDirection={`column`}
                            justifyContent={`end`}
                            alignItems={`end`}
                          >
                            <Box display={`flex`} sx={{ mt: 1 }}>
                              <Typography className="subtitle-header-black">
                                {reNewableElectricity}
                              </Typography>
                              <Typography className="subtitle-header">
                                {" "}
                                {reNewableElectricityUnit}
                              </Typography>
                            </Box>
                            <Box display={`flex`}>
                              <Typography
                                display={`flex`}
                                className="large-title-header-black"
                              >
                                {percentReNewableElectricity} %
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <></>
            )}
            {/* END : 1st SECTION REPORT 2 */}

            <Box className="custom-divider"></Box>
            

            <Box display={`flex`}>
              <Typography className="text-black">
                สัดส่วนการใช้พลังงาน
              </Typography>
            </Box>

            <Box
              display={`flex`}
              flexDirection={`row`}
              justifyContent={`start`}
            >
              <Box display={`flex`} flexDirection={`row`} sx={{ mr: 4 }}>
                <Box sx={{ height: "100%" }}>
                  
                  {energyUseByBuildingType && (
                    <ApexDoughnutChartReport
                      chartname={`electrical-consuming-each-building-type-${uuidv4()}`}
                      graphData={energyUseByBuildingType}
                      chartWidth={`400px`}
                      chartHeight={`500px`}
                    />
                  )}
                </Box>
                <Box alignItems={`start`}>
                  <table style={{ fontSize: "10px",marginLeft: '-50px' }}>
                    <tbody>
                      <tr className="">
                        <td className=""></td>
                        <td className=""></td>
                        <td className="">kWh</td>
                      </tr>
                      <tr>
                        <td
                          
                        >
                          {energyUseByBuildingType.backgroundColor.map(
                            (item, index) => (
                              <Box
                                key={index}
                                display={`flex`}
                                alignItems={`center`}
                                style={{
                                  backgroundColor: item,
                                  height: "10px",
                                  width: "10px",
                                  borderRadius: "50%",
                                  margin: "2.5px",
                                }}
                              ></Box>
                            )
                          )}
                        </td>
                        <td
                        >
                          {energyUseByBuildingType.labels.map((item, index) => (
                            <Box key={index} display={`flex`} style={{width: '100px'}}>{item}</Box>
                          ))}
                        </td>
                        <td
                          // style={{
                          //   display: "flex",
                          //   flexDirection: "column",
                          //   marginLeft: "5px",
                          // }}
                        >
                          {energyUseByBuildingType.data.map((item, index) => (
                            <Box key={index} display={`flex`}>{numeral(item).format('0,0')}</Box>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
              </Box>

              <Box display={`flex`} flexDirection={`row`} justifyContent={`space-around`} sx={{mr: 4}}>
                <Box sx={{ height: "100%" }}>
                  {energyUseBySystem && (
                    <ApexDoughnutChartReport
                      chartname={`electrical-consuming-each-system-type-${uuidv4()}`}
                      graphData={energyUseBySystem}
                      chartWidth={`400px`}
                      chartHeight={`500px`}
                    />
                  )}
                </Box>
                <Box alignItems={`start`}>
                  <table style={{ fontSize: "10px", marginLeft: '-50px' }}>
                    <tbody>
                      <tr className="">
                        <td className=""></td>
                        <td className=""></td>
                        <td className="">kWh</td>
                      </tr>
                      <tr style={{ flexDirection: "" }}>
                        <td
                          
                        >
                          {energyUseBySystem.backgroundColor.map(
                            (item, index) => (
                              <Box
                                key={index}
                                display={`flex`}
                                style={{
                                  backgroundColor: item,
                                  height: "10px",
                                  width: "10px",
                                  borderRadius: "50%",
                                  margin: "2.5px",
                                }}
                              ></Box>
                            )
                          )}
                        </td>
                        <td
                          
                        >
                          {energyUseBySystem.labels.map((item, index) => (
                            <Box key={index} display={`flex`} style={{width: '120px'}}>{item}</Box>
                          ))}
                        </td>
                        <td
                          
                        >
                          {energyUseBySystem.data.map((item, index) => (
                            <Box key={index} display={`flex`}>{numeral(item).format('0,0')}</Box>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
              </Box>
            </Box>

            <div className="custom-divider"></div>

            <Box display={`flex`} flexDirection={`row`}>
              <Box
                display={`flex`}
                flexDirection={`column`}
                flex={1}
                flexGrow={1.5}
                justifyContent={`start`}
              >
                <Box display={`flex`} flexDirection={`column`}>
                  <Box display={`flex`} justifyContent={`start`}>
                    <Typography className="text-black">
                      การใช้พลังงานไฟฟ้า
                    </Typography>
                  </Box>
                  <Box display={`flex`} justifyContent={`start`}>
                    <Typography className="text-xs-black">
                      พลังงานไฟฟ้า ({buildingEnergyUseByTimeUnit})
                    </Typography>
                  </Box>
                  {electricityUse && (
                    <D3LineChartReport
                      unit={buildingEnergyUseByTimeUnit}
                      graphData={buildingEnergyUseByTime}
                      timeType={selectedTimeType}
                      strokeColor={`orange`}
                      chartType={1}
                    />
                  )}
                </Box>
                <Box className="custom-divider"></Box>

                <Box display={`flex`} flexDirection={`column`}>
                  <Box display={`flex`} justifyContent={`start`}>
                    <Typography className="subtitle-header-black">
                      การใช้พลังงานความร้อน
                    </Typography>
                  </Box>
                  <Box display={`flex`} justifyContent={`start`}>
                    <Typography className="text-xs-black">
                      พลังงานความร้อน ({buildingThermalUseByTimeUnit})
                    </Typography>
                  </Box>
                  {buildingThermalUseByTime && (
                    <D3LineChartReport
                      unit={buildingThermalUseByTimeUnit}
                      graphData={buildingThermalUseByTime}
                      timeType={selectedTimeType}
                      strokeColor={`red`}
                      chartType={1}
                    />
                  )}
                </Box>
              </Box>
              <Box display={`flex`}>
                <div
                  style={{ height: "95%" }}
                  className="custom-divider-vertical"
                ></div>
              </Box>
              <Box
                display={`flex`}
                flexDirection={`column`}
                flex={1}
                sx={{ ml: 1 }}
              >
                <Box display={`flex`} justifyContent={`start`}>
                  <Typography className="text-black">
                    ค่าดัชนีการใช้พลังงานของอาคารแต่ละประเทศ(SEC)
                  </Typography>
                </Box>

                <Box display={`flex`} flexDirection={`column`}>
                  {reportType == ENERGY_USE_TYPE.ALL &&
                    energyConsumption &&
                    energyConsumption.map((item, index) => (
                      <div key={index}>
                        <Box display={`flex`} className="">
                          {item.buildingTypeName} {item.buildingTypeUnit}
                        </Box>
                        <Box display={`flex`} className="box-wrapper-table">
                          <table
                            className="table-report-light"
                            style={{ fontSize: "0.9px !important" }}
                          >
                            <thead>
                              <tr
                                className="row"
                                style={{ fontSize: "0.9px !important" }}
                              >
                                <td className="col-report">
                                  เป้าหมาย SEC ที่ถูกกำหนดปีนี้
                                </td>
                                <td className="col-report">
                                  SEC เฉลี่ยใน 3 เดือน
                                </td>
                                <td className="col-report">
                                  SEC สูงสุดใน 3 เดือน
                                </td>
                                <td className="col-report">
                                  SEC ต่ำสุดใน 3 เดือน
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                className="row"
                                style={{ fontSize: "10px !important" }}
                              >
                                <td className="col-report">
                                  {numberWithCommas(item.secTarget)}
                                </td>
                                <td className="col-report">
                                  {numberWithCommas(item.secAverage)}
                                </td>
                                <td className="col-report">
                                  {numberWithCommas(item.secMax)}
                                </td>
                                <td className="col-report">
                                  {numberWithCommas(item.secMin)}{" "}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Box>
                      </div>
                    ))}

                  {reportType == ENERGY_USE_TYPE.BY_BUILDING &&
                    energyConsumption && (
                      <div>
                        <Box display={`flex`} className="text-3xs-black">
                          {energyConsumption.buildingTypeName}{" "}
                          {energyConsumption.buildingTypeUnit}
                        </Box>
                        <Box display={`flex`} className="box-wrapper-table">
                          <table
                            className="table-report-light"
                            style={{  }}
                          >
                            <thead>
                              <tr
                                className="row"
                                style={{  }}
                              >
                                <td className="col-report">
                                  เป้าหมาย SEC ที่ถูกกำหนดปีนี้
                                </td>
                                <td className="col-report">
                                  SEC เฉลี่ยใน 3 เดือน
                                </td>
                                <td className="col-report">
                                  SEC สูงสุดใน 3 เดือน
                                </td>
                                <td className="col-report">
                                  SEC ต่ำสุดใน 3 เดือน
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                className="row"
                                style={{ fontSize: "0.2px !important" }}
                              >
                                <td className="col-report">
                                  {numberWithCommas(
                                    energyConsumption.secTarget
                                  )}
                                </td>
                                <td className="col-report">
                                  {numberWithCommas(
                                    energyConsumption.secAverage
                                  )}
                                </td>
                                <td className="col-report">
                                  {numberWithCommas(energyConsumption.secMax)}
                                </td>
                                <td className="col-report">
                                  {numberWithCommas(energyConsumption.secMin)}{" "}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Box>
                      </div>
                    )}
                </Box>
              </Box>
            </Box>
          </Box>
        </GeneralLayoutLight>
      </div>
    </React.Fragment>
  );
};
