import React from 'react'
import { Box } from '@mui/system'

export default function AuthLayout({ children}) {
  return (
    <>
      <Box>
        {children} 
      </Box>
    </>
  )
}
