import { Box, Typography } from "@mui/material";
import React from "react";

export default function DataBox({ title, dataValue, dataUnit }) {
  return (
    <>
      <Box
        className="box-wrapper"
        display={`flex`}
        justifyContent={`center`}
        flexDirection={`column`}
        sx={{ minWidth: "250px" }}
      >
        <Box display={`flex`} alignSelf={`start`}>
          <Typography className="text-primary">{title}</Typography>
        </Box>
        <Box display={`flex`} alignSelf={`center`}>
          <Typography sx={{ fontSize: "40px" }}>
            {dataValue || "-"}
          </Typography>
        </Box>
        <Box display={`flex`} alignSelf={`end`}>
          <Typography>{dataUnit || "-"}</Typography>
        </Box>
      </Box>
    </>
  );
}
