import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import millify from "millify";
import { numberWithCommas } from "../../../Utils/FuncUtils";
import { v4 as uuid } from 'uuid'
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import numeral from "numeral";
export default function ApexDoughnutChart({ chartname, graphData }) {
  if(!graphData) return 'loading ...';
  const [chart, setChart] = useState();
  useEffect(() => {
    return () => {
      setChart(undefined)
    };
  }, [graphData]);

  useEffect(() => {
    if(graphData && graphData.data.length > 0) {
      setChart({
        options: {
          chart: {
            type: "donut",
            height: "250px",
            animations: {
              enabled: false,
            },
          },
          legend: {
            show: false,
            fontFamily: "Khanit, Arial",
            showForSingleSeries: true,
            horizontalAlign: "left",
          },
          tooltip: {
            enabled: false,
            enabledOnSeries: false,
          },
          series: graphData && graphData.data,
          colors: graphData.backgroundColor,
          labels: graphData && graphData.labels,
          dataLabels: {
            enabled: true,
            enabledOnSeries: false,
            formatter: function (val) {
              return `${parseInt(val)} %`;
            },
            style: {
              fontFamily: "Khanit, Arial, sans-serif",
              colors: ["#000000"],
              fontSize: "9px",
              fontWeight: 200,
            },
          },
          plotOptions: {
            pie: {
              expandOnClick: false,
              customScale: 1,

              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                  },
                  total: {
                    show: true,                
                    label: "อาคาร",
                    formatter: (value) => numeral(graphData.total).format('0,0'),
                  },
                  value: {
                    formatter: (value) => numeral(value).format('0,0'),
                  }
                },
              },
            },
          },
        },
        series: graphData && graphData.data,
      })
      return () => {};
    }

  }, [graphData]);

  return (
    <>
      { chart ? (<Chart
        className="custom-donut"
        id={`doughnut-chart-${chartname}-${uuid()}`}
        options={chart.options}
        series={chart.series}
        type="donut"
      />) : (<Box
        display={`flex`}
        justifyContent={`center`}
        alignItems={`center`}
        sx={{ ml: 1 }}
      >
        <Typography display={`flex`} sx={{ fontSize: "16px" }}>No data to show</Typography>
      </Box>) }
    </>
  );
}
