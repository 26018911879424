import React from 'react'
import { Alert, Typography } from '@mui/material'

export default function AlertNotification({ alert, severity, message}) {
  return (
    <>
      { alert && <Alert sx={{position: 'fixed', width: '100%', zIndex: 999}} severity={severity}><Typography>{ message }</Typography></Alert>}
    </>
  )
}
