import React, { useState, useEffect } from "react";
import { scaleLinear, scaleTime, extent } from "d3";
import moment from "moment";
import { X_AXIS_TIME_TYPE } from "../../../Utils/EnvUtils";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { AxisLeftReport } from "./AxisLeftReport";
import { AxisBottomReport } from "./AxisBottomReport";
import { MarksReport } from "./MarksReport";
import { roundNearest } from "../../../Utils/FuncUtils";

const height = 150;
const margin = { top: 10, right: 0, bottom: 30, left: 20 };
const xAxisLabelOffset = 50;
const yAxisLabelOffset = 60;

export const D3LineChartReport = ({
  graphData,
  unit,
  timeType,
  strokeColor,
  chartType,
}) => {
  // const data = useData();

  if (!graphData) {
    return <pre>Loading...</pre>;
  }

  const [width, setWidth] = useState(chartType == 1 ? 430 : 480);
  const [maxNumber, setMaxNumber] = useState();
  const [isAllZero, setIsAllZero] = useState(false);

  useEffect(() => {
    if (graphData) {
      const localMaxArray = [];
      graphData.map((data, item) => {
        const yData = data.electricityUse;
        localMaxArray.push(yData);
      });

      const isAllZeroInThere = localMaxArray.every((item) => item === 0);
      setIsAllZero(isAllZeroInThere);
      const maxNum = Math.max(...localMaxArray);
      const roundedMaxNumber = roundNearest(maxNum);
      setMaxNumber(roundedMaxNumber);
      return () => {
        setMaxNumber();
      };
    }
  }, [graphData]);

  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  const xValue = (d) => d.timestamp;
  const xAxisLabel = "";
  const yValue = (d) => d.electricityUse;
  const yAxisLabel = unit;
  const xAxisTickFormat = (e) => {
    if (timeType == X_AXIS_TIME_TYPE.TIME) {
      return moment(e).format("HH");
    } else if (timeType == X_AXIS_TIME_TYPE.DAY) {
      return moment(e).format("DD");
    } else if (timeType == X_AXIS_TIME_TYPE.MONTH) {
      const formattedMonth = format(e, "MMM", { locale: th });
      return formattedMonth;
    }
  };

  const xScale = scaleTime()
    .domain(extent(graphData, xValue))
    .range([0, innerWidth])
    .nice();

  // const yScale = scaleLinear()
  //   .domain(extent(graphData, yValue))
  //   .range([innerHeight, 0])
  //   .nice();
  const yScale = scaleLinear()
    .domain([0, maxNumber])
    .range([innerHeight, isAllZero ? innerHeight : 0])
    .nice();

  
  return (
    <>
      {graphData && (
        <svg width={`97%`} height={height}>
          <g transform={`translate(${margin.left},${margin.top})`}>
            <AxisBottomReport
              xScale={xScale}
              innerHeight={innerHeight}
              tickFormat={xAxisTickFormat}
              tickOffset={7}
              timeType={timeType}
            />

            <AxisLeftReport
              yScale={yScale}
              innerWidth={innerWidth}
              tickOffset={7}
            />
            <text
              className="axis-label"
              x={innerWidth / 2}
              y={innerHeight + xAxisLabelOffset}
              textAnchor="middle"
            >
              {xAxisLabel}
            </text>
            <MarksReport
              data={graphData}
              xScale={xScale}
              yScale={yScale}
              xValue={xValue}
              yValue={yValue}
              tooltipFormat={xAxisTickFormat}
              circleRadius={3}
              strokeColor={strokeColor}
            />
          </g>
        </svg>
      )}
    </>
  );
};
