import React from 'react'
import { Box } from '@mui/system'
import { Container } from '@mui/material';

export default function GeneralLayout({ children }) {
  return (
    <>
      <Box sx={{background: '#25A18E', height: '100vh'}}>
        <Container maxWidth="lg">
          {children}
        </Container>
      </Box>
    </>
  )
}
