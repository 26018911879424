import React, {useState,useEffect} from 'react'
import { InputLabel , FormControl, MenuItem,Select } from '@mui/material';
import { data } from '../../uiData/UIData';
import { GET } from '../../Utils/ApiClient';

export default function DropdownFuelType({ onSelectHandler }) {
  // const { fuelTypes } = data;
  const [fuel, setFuel] = useState('')
  const [fuels, setFuels] = useState([])
  useEffect(() => {
    fetchFuelType();
  }, [])

  async function fetchFuelType() {
    try {
      const res = await GET(`Building/fuelType`);
      const { data , status } = res;
      setFuels(data);
    } catch (error) {
      console.log(error);
    }
    
  }

  function handleChange(e) {
    onSelectHandler(e.target.value)
    setFuel(e.target.value)
  }
  
  return (
    <div>
      <FormControl sx={{ mt: 1, minWidth: 200, width: '100%' }}>
        <Select
          labelId="fuel-dropdown-label"
          id="fuel-dropdown"
          value={fuel}
          onChange={handleChange}
          defaultValue={fuel}
        >
          <MenuItem value={fuel} selected={true}>
            <em>เลือกชนิดเชื้อเพลิง</em>
          </MenuItem>
          { fuels && fuels.map((item, index) => (<MenuItem key={index} value={item.fuelTypeId}>{ item.fuelType }</MenuItem>))}
        </Select>
      </FormControl>
    </div>
  )
}
