import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DownloadIcon } from "../../assets";
import { BASE_URL } from "../../Utils/EnvUtils";
import { modifiedLineChartDataTimePeriod } from "../../Utils/FuncUtils";
import ApexLineChart from "../Common/Apex/ApexLineChart";
import ApexVerticalBarchart from "../Common/Apex/ApexVerticalBarchart";
import Bar from "../Common/Apex/Bar";
// import D3Barchart from "../Common/d3/Bar/Horizontal/D3Barchart";
import D3VerticalBarchart from "../Common/d3/Bar/Vertical/D3VerticalBarchart";
import { D3LineChart } from "../Common/d3/D3LineChart";
import DownloadBox from "../Common/DownloadBox";

export default function ChillerPlant({
  buildingInfo,
  selectedPlant,
  chillerPlantElectricity,
  chillerLineChartData,
  chillerPlantRefrigerant,
  chillerPlantAhu,
  selectedYear,
  selectedMonth,
  selectedDay,
  selectedTimeType,
}) {
  const [refrigerantLineChartData, setRefrigerantLineChartData] = useState();
  const [refrigerantLineChartDataUnit, setRefrigerantLineChartDataUnit] =
    useState();
  const [ahuLineChartData, setAhuLineChartData] = useState();
  const [ahuLineChartDataUnit, setAhuLineChartDataUnit] = useState();

  useEffect(() => {
    if (chillerPlantRefrigerant && chillerPlantRefrigerant.data) {
      const modifiedGraphData = modifiedLineChartDataTimePeriod(
        chillerPlantRefrigerant,
        selectedYear,
        selectedMonth,
        selectedDay,
        selectedTimeType
      );
      setRefrigerantLineChartData(modifiedGraphData);
      setRefrigerantLineChartDataUnit(chillerPlantRefrigerant.unit);
    }

    if (chillerPlantAhu && chillerPlantAhu.data) {
      const modifiedGraphData = modifiedLineChartDataTimePeriod(
        chillerPlantAhu,
        selectedYear,
        selectedMonth,
        selectedDay,
        selectedTimeType
      );
      setAhuLineChartData(modifiedGraphData);
      setAhuLineChartDataUnit(chillerPlantElectricity.unit);
    }

    return () => {};
  }, [
    chillerPlantRefrigerant,
    chillerPlantAhu,
    selectedYear,
    selectedMonth,
    selectedDay,
    selectedTimeType,
  ]);

  return (
    <>
      <Box className="box-wrapper" display={`flex`} flexDirection={`column`}>
        <Box display={`flex`} flexDirection={`column`}>
          <Typography className="subtitle-header">
            ผลรวมการใช้พลังงานไฟฟ้าของ (Plant # {selectedPlant})
          </Typography>
          <Typography className="text-primary">
            พลังงานไฟฟ้า {chillerPlantElectricity.unit}
          </Typography>
          { chillerPlantElectricity ? <Bar chartData={chillerPlantElectricity} selectedTimeType={selectedTimeType} /> :  (<Box>No data to show</Box>)}
        </Box>
        <Box display={`flex`} justifyContent={`flex-end`} sx={{ mr: 1, mb: 1 }}>
          <a
            href={`${BASE_URL}/api/Home/downloadEnergyUse/${selectedYear}/${selectedMonth}/${selectedDay}`}
            download
          >
            <img
              src={DownloadIcon}
              alt="download icon"
              sx={{ cursor: "pointer" }}
            />
          </a>
        </Box>
      </Box>

      <Box className="box-wrapper" display={`flex`} flexDirection={`column`}>
        <Box display={`flex`} flexDirection={`column`}>
          <Typography className="subtitle-header">
            ตันความเย็นของ (Plant # {selectedPlant})
          </Typography>
          <Typography className="text-primary">
            ตันความเย็น {refrigerantLineChartDataUnit}
          </Typography>
          {/* {chillerPlantRefrigerant && (
            <ApexLineChart chartData={chillerPlantRefrigerant} />
          )} */}

          {refrigerantLineChartData && (
            <D3LineChart
              graphData={refrigerantLineChartData}
              unit={refrigerantLineChartDataUnit}
              timeType={selectedTimeType}
            />
          )}

        </Box>
        <DownloadBox
          endPoint={`api/dashboard/central/downloadchillerPlant/refrigeration/${
            buildingInfo ? buildingInfo.buildingId : 0
          }/${selectedPlant}/${selectedYear}/${selectedMonth}/${selectedDay}`}
        />
      </Box>

      <Box className="box-wrapper" display={`flex`} flexDirection={`column`}>
        <Box display={`flex`} flexDirection={`column`}>
          {/* { graphData, unit, timeType } */}
          <Typography className="subtitle-header">
            การใช้พลังงานของ Chiller (Plant # {selectedPlant})
          </Typography>
          {chillerLineChartData && (
            <D3LineChart
              graphData={chillerLineChartData}
              unit={`kWh`}
              timeType={selectedTimeType}
            />
          )}
        </Box>

        <DownloadBox
          endPoint={`api/dashboard/central/downloadchillerPlant/chillerElectricity/${
            buildingInfo ? buildingInfo.buildingId : 0
          }/${selectedPlant}/${selectedYear}/${selectedMonth}/${selectedDay}`}
        />
      </Box>

      <Box className="box-wrapper" display={`flex`} flexDirection={`column`}>
        <Box display={`flex`} flexDirection={`column`}>
          {/* { graphData, unit, timeType } */}
          <Typography className="subtitle-header">
            การใช้พลังงานไฟฟ้าของ AHU (Plant # {selectedPlant})
          </Typography>
          {ahuLineChartData && (
            <D3LineChart
              graphData={ahuLineChartData}
              unit={ahuLineChartDataUnit}
              timeType={selectedTimeType}
            />
          )}
        </Box>
        <DownloadBox
          endPoint={`api/dashboard/central/downloadchillerPlant/AhuElectricity/${
            buildingInfo ? buildingInfo.buildingId : 0
          }/${selectedPlant}/${selectedYear}/${selectedMonth}/${selectedDay}`}
        />
      </Box>
    </>
  );
}
