import moment from "moment";
import { TIME_ZONE, X_AXIS_TIME_TYPE } from "./EnvUtils";

const toBase64 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function daysInMonth(m, y) {
  // m is 0 indexed: 0-11
  switch (m) {
    case 1:
      return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
    case 8:
    case 3:
    case 5:
    case 10:
      return 30;
    default:
      return 31;
  }
}
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
export function findTick(timeType) {
  switch (timeType) {
    case X_AXIS_TIME_TYPE.TIME:
      return 24;
      break;
    case X_AXIS_TIME_TYPE.DAY:
      return 31;
      break;
    case X_AXIS_TIME_TYPE.MONTH:
      return 12;
      break;
    default:
      return 24;
      break;
  }
}

export function modifiedLineChartDataTimePeriod(
  data,
  selectedYear,
  selectedMonth,
  selectedDay
) {
  const modifiedGraphData =
    data &&
    data.data.map((item) => {
      let modifiedDateFormattedString = "";
      if (selectedMonth < 0) {
        modifiedDateFormattedString = `${selectedYear}-${item.xaxis}-01 00:01`;
      } else if (selectedDay < 0) {
        modifiedDateFormattedString = `${selectedYear}-${selectedMonth}-${item.xaxis} 00:01`;
      } else {
        modifiedDateFormattedString = `${selectedYear}-${selectedMonth}-${selectedDay} ${item.xaxis}`;
      }
      const theDate = new Date(modifiedDateFormattedString);
      return {
        timestamp: theDate,
        electricityUse: item.yaxis,
      };
    });
  return modifiedGraphData;
}

export function modifiedLineChartDataTimePeriodArray(
  data,
  selectedYear,
  selectedMonth,
  selectedDay
) {
  const modifiedGraphData =
    data &&
    data.map((item) => {
      let modifiedDateFormattedString = "";
      if (selectedMonth < 0) {
        modifiedDateFormattedString = `${selectedYear}-${item.xaxis}-01 00:01`;
      } else if (selectedDay < 0) {
        modifiedDateFormattedString = `${selectedYear}-${selectedMonth}-${item.xaxis} 00:01`;
      } else {
        modifiedDateFormattedString = `${selectedYear}-${selectedMonth}-${selectedDay} ${item.xaxis}`;
      }
      const theDate = new Date(modifiedDateFormattedString);
      return {
        timestamp: theDate,
        electricityUse: item.yaxis,
      };
    });
  return modifiedGraphData;
}

export function getMonthFromDate(date) {
  return moment(date).format("MMM");
}

export function getDayFromDate(date) {
  return moment(date).format("DD");
}

export function getTimeFromDate(date) {
  const time = moment(date).format("HH:mm");
  return time;
}

export function vh(v) {
  var h = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  return (v * h) / 100;
}

export function vw(v) {
  var w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  return (v * w) / 100;
}

export function vmin(v) {
  return Math.min(vh(v), vw(v));
}

export function vmax(v) {
  return Math.max(vh(v), vw(v));
}

export function ChartWidth() {
  return 0.82 * window.screen.width;
}

export function BarChartWidth() {
  return 0.78 * window.screen.width;
}

export const getDataFomatFullThai = (date) => {
  const event = new Date(moment(date, "YYYY-MM-DD").utc().format());
  const options = { year: "numeric", month: "short", day: "numeric" };
  if (moment(date, "YYYY-MM-DD").isValid()) {
    return event.toLocaleDateString("th", options);
  }
  return "";
};

export function getCurrentTime() {
  return moment.tz(new Date(), TIME_ZONE.AsiaBangkok).format("yyyy-MM-DD");
}

export function randomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export function roundNearest(num) {
  const divider = findDivider(num);
  const ceiling =  Math.ceil(num / divider , 1);
  var nextNumb=  ceiling * divider ;
  return nextNumb;
}

function findDivider(num) {
  const intNumber = Number(num);
  let dividerNumber;
  if (intNumber / 1 >= 1 && intNumber / 1 < 10) {
    dividerNumber = 1;
  } else if (intNumber / 10 >= 1 && intNumber / 10 < 10) {
    dividerNumber = 10;
  } else if (intNumber / 100 >= 1 && intNumber / 100 < 10) {
    dividerNumber = 100;
  } else if (intNumber / 1000 >= 1 && intNumber / 1000 < 10) {
    dividerNumber = 1000;
  } else if (intNumber / 10000 >= 1 && intNumber / 10000 < 10) {
    dividerNumber = 10000;
  } else if (intNumber / 100000 >= 1 && intNumber / 100000 < 10) {
    dividerNumber = 100000;
  } 
  // million
  else if (intNumber / 1000000 >= 1 && intNumber / 1000000 < 10) {
    dividerNumber = 1000000;
  } else if (intNumber / 10000000 >= 1 && intNumber / 10000000 < 10) {
    dividerNumber = 10000000;
  } else if (intNumber / 100000000 >= 1 && intNumber / 100000000 < 10) {
    dividerNumber = 100000000;
  } 
  // billion
  else if (intNumber / 1000000000 >= 1 && intNumber / 1000000000 < 10) {
    dividerNumber = 1000000000;
  } else if (intNumber / 10000000000 >= 1 && intNumber / 10000000000 < 10) {
    dividerNumber = 10000000000;
  } else if (intNumber / 100000000000 >= 1 && intNumber / 100000000000 < 10) {
    dividerNumber = 100000000000;
  } 
  // trillion
  else if (intNumber / 1000000000000 >= 1 && intNumber / 1000000000000 < 1000000000000) {
    dividerNumber = 1000000000000;
  } else if (intNumber / 10000000000000 >= 1 && intNumber / 10000000000000 < 10000000000000) {
    dividerNumber = 10000000000000;
  }

  return dividerNumber;
}
export default {
  numberDaysInMonth,
  toBase64,
  daysInMonth,
  numberWithCommas,
  findTick,
  modifiedLineChartDataTimePeriod,
  modifiedLineChartDataTimePeriodArray,
  getMonthFromDate,
  getDayFromDate,
  getTimeFromDate,
  vh,
  vw,
  vmax,
  vmin,
  randomNumber,
};
