import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { AirCompressorIcon } from "../../assets";
import { GET } from "../../Utils/ApiClient";
import {
  ALERT_DISMISS_TIME,
  ALERT_TYPE,
  HTTP_STATUS,
} from "../../Utils/EnvUtils";
import { NOTIFICATION_MESSAGE } from "../../Utils/Messages";
import AlertNotification from "../Common/AlertNotification";
import { data } from "../../uiData/UIData";
import { D3MultipleLineChart } from "../Common/d3/Line/Multiple/D3MultipleLineChart";
import {
  modifiedLineChartDataTimePeriod,
  modifiedLineChartDataTimePeriodArray,
} from "../../Utils/FuncUtils";
import DownloadBox from "../Common/DownloadBox";

export default function LightingSystem({
  buildingInfo,
  selectedYear,
  selectedMonth,
  selectedDay,
  selectedTimeType,
}) {
  const { lightingGraphLegend } = data;
  const { ERROR } = NOTIFICATION_MESSAGE;
  const { mockDataIndoorLighting, mockDataOutdoorLighting } = data;
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [lightingIndoorList, setLightingIndoorList] = useState();
  const [lightingOutdoorList, setLightOutdoorList] = useState();
  const [
    ligtingSystemLineChartDataIndoor,
    setLigtingSystemLineChartDataIndoor,
  ] = useState();
  const [
    ligtingSystemLineChartDataIndoorUnit,
    setLigtingSystemLineChartDataIndoorUnit,
  ] = useState();
  const [
    ligtingSystemLineChartDataOutDoor,
    setLigtingSystemLineChartDataOutDoor,
  ] = useState();
  const [
    ligtingSystemLineChartDataOutDoorUnit,
    setLigtingSystemLineChartDataOutDoorUnit,
  ] = useState();

  useEffect(() => {
    if (buildingInfo) {
      fetchIndoorLigting();
      fetchLightingSystemData();
    }
    return () => {};
  }, [buildingInfo, selectedYear, selectedMonth, selectedDay]);

  async function fetchIndoorLigting() {
    try {
      //GET:/dashboard/lighting/{buildingId}/{year}/{month}/{day}
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const res = await GET(
        `dashboard/lighting/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = res;
      const { indoor, outdoor, unit } = data;

      if (status == HTTP_STATUS.OK) {
        setLightingIndoorList(indoor);
        setLightOutdoorList(outdoor);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(error);
      setSeverity(ALERT_TYPE.alertError);
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchLightingSystemData() {
    try {
      // GET: dashboard/lighting/electricity/{buildingId}/{year}/{month}/{day}
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      // todo: make this available to 2 line graph
      const response = await GET(
        `dashboard/lighting/electricity/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = response;
      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        const { indoor, outdoor, unit } = data;

        if (indoor) {
          const modifiedData = modifiedLineChartDataTimePeriodArray(
            indoor,
            selectedYear,
            selectedMonth,
            selectedDay
          );
          setLigtingSystemLineChartDataIndoor(modifiedData);
          setLigtingSystemLineChartDataIndoorUnit(unit);
        }

        if (outdoor) {
          const modifiedData = modifiedLineChartDataTimePeriodArray(
            outdoor,
            selectedYear,
            selectedMonth,
            selectedDay
          );
          setLigtingSystemLineChartDataOutDoor(modifiedData);
          setLigtingSystemLineChartDataOutDoorUnit(unit);
        }
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
        // todo : set alert
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  return (
    <>
      {/* <AlertNotification alert={alert} severity={severity} message={message} /> */}
      <Box display={`flex`} flexDirection={`column`}>
        <Box
          display={`flex`}
          flexDirection={`column`}
          justifyContent={`space-between`}
        >
          {/* BOX#1 */}

          <Box
            className="box-wrapper"
            sx={{ mt: 1 }}
            display={`flex`}
            flex={1}
            flexDirection={`column`}
          >
            <Box display={`flex`} flexDirection={`row`}>
              <Box
                display={`flex`}
                flexDirection={`column`}
                flex={4}
                flexGrow={4}
              >
                <Typography className="subtitle-header">
                  การใช้พลังงานไฟฟ้าของระบบไฟฟ้าแสงสว่าง
                </Typography>
                {ligtingSystemLineChartDataIndoor &&
                  ligtingSystemLineChartDataOutDoor &&  buildingInfo && (
                    <Box>
                      <D3MultipleLineChart
                      chartData={[
                        ligtingSystemLineChartDataIndoor,
                        ligtingSystemLineChartDataOutDoor,
                      ]}
                      unit={ligtingSystemLineChartDataIndoorUnit}
                      timeType={selectedTimeType}
                    />
                    <Box display="flex" justifyContent="center">
                      <Typography>เวลา</Typography>
                    </Box>
                    </Box>
                  )}
              </Box>
              <Box
                display={`flex`}
                flexDirection={`column`}
                flex={1}
                flexGrow={0.3}
                justifyContent={`center`}
                alignContent={`center`}
              >
                <Box display={`flex`} flexDirection={`column`}>
                  <table className="table" style={{ paddingRight: '20px'}}>
                    <tbody>
                      {lightingGraphLegend &&
                        lightingGraphLegend.map((item, index) => (
                          <tr className="row" key={index}>
                            <td>
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  background: `${item.color}`,
                                }}
                              ></div>
                            </td>
                            <td>{item.text} </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Box>
              </Box>
            </Box>
            <DownloadBox
              endPoint={`api/dashboard/lighting/downloadElectricity/${
                buildingInfo ? buildingInfo.buildingId : 0
              }/${selectedYear}/${selectedMonth}/${selectedDay}`}
            />
          </Box>

          {/* BOX#2 */}
          <Box
            sx={{ mt: 1 }}
            display={`flex`}
            flexDirection={`row`}
            justifyContent={`space-between`}
          >
            {/* LEFT BOX */}
            <Box
              display={`flex`}
              className="box-wrapper"
              flex={1}
              flexDirection={`column`}
            >
              <Box display={`flex`}>
                <Typography className="subtitle-header">
                  Indoor Lighting
                </Typography>
              </Box>

              <Box
                className="box-wrapper-active-gray-200"
                display={`flex`}
                flexDirection={`column`}
              >
                {lightingIndoorList &&
                  lightingIndoorList.map((item, index) => (
                    <Box
                      key={index}
                      className={`box-indoor-item-wrapper`}
                      justifyContent={``}
                      display={`flex`}
                      flexDirection={`row`}
                    >
                      <Box
                        display={`flex`}
                        justifyContent={`start`}
                        alignItems={`center`}
                        flex={2}
                      >
                        <Typography className="title-header-black">
                          {`InD-0${item.doorNo}`}
                        </Typography>
                      </Box>
                      <Box
                        display={`flex`}
                        className={`box-wrapper-white`}
                        flexDirection={`column`}
                        alignItems={`flex-end`}
                      >
                        <Box
                          display={`flex`}
                          justifyContent={`center`}
                          alignItems={`center`}
                          flex={1}
                        >
                          <Typography className={`subtitle-header`}>
                            กำลังไฟฟ้า
                          </Typography>
                        </Box>
                        <Box
                          display={`flex`}
                          justifyContent={`center`}
                          alignItems={`center`}
                          flex={1}
                        >
                          <Typography className={`title-header`}>
                            {item.power} {item.powerUnit}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display={`flex`}
                        className={`box-wrapper-white`}
                        flexDirection={`column`}
                      >
                        <Box
                          display={`flex`}
                          justifyContent={`center`}
                          alignItems={`center`}
                          flex={1}
                        >
                          <Typography className={`subtitle-header`}>
                            พลังงานไฟฟ้าต่อวัน
                          </Typography>
                        </Box>
                        <Box
                          display={`flex`}
                          justifyContent={`center`}
                          alignItems={`center`}
                          flex={1}
                        >
                          <Typography className={`title-header`}>
                            {item.electricity} {item.electricityUnit}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
            {/* RIGHT BOX */}
            <Box
              display={`flex`}
              className="box-wrapper"
              flex={1}
              flexDirection={`column`}
            >
              <Box display={`flex`}>
                <Typography className="subtitle-header">
                  Outdoor Lighting
                </Typography>
              </Box>

              <Box
                className="box-wrapper-active"
                display={`flex`}
                flexDirection={`column`}
              >
                {lightingOutdoorList &&
                  lightingOutdoorList.map((item, index) => (
                    <Box
                      key={index}
                      className={`box-outdoor-item-wrapper`}
                      justifyContent={``}
                      display={`flex`}
                      flexDirection={`row`}
                    >
                      <Box
                        display={`flex`}
                        justifyContent={`start`}
                        alignItems={`center`}
                        flex={2}
                      >
                        <Typography className="title-header-black">
                          {`OutD-0${item.doorNo}`}
                        </Typography>
                      </Box>
                      <Box
                        display={`flex`}
                        className={`box-wrapper-white`}
                        flexDirection={`column`}
                        alignItems={`flex-end`}
                      >
                        <Box
                          display={`flex`}
                          justifyContent={`center`}
                          alignItems={`center`}
                          flex={1}
                        >
                          <Typography className={`subtitle-header`}>
                            กำลังไฟฟ้า
                          </Typography>
                        </Box>
                        <Box
                          display={`flex`}
                          justifyContent={`center`}
                          alignItems={`center`}
                          flex={1}
                        >
                          <Typography className={`title-header`}>
                            {item.power} {item.powerUnit}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display={`flex`}
                        className={`box-wrapper-white`}
                        flexDirection={`column`}
                      >
                        <Box
                          display={`flex`}
                          justifyContent={`center`}
                          alignItems={`center`}
                          flex={1}
                        >
                          <Typography className={`subtitle-header`}>
                            พลังงานไฟฟ้าต่อวัน
                          </Typography>
                        </Box>
                        <Box
                          display={`flex`}
                          justifyContent={`center`}
                          alignItems={`center`}
                          flex={1}
                        >
                          <Typography className={`title-header`}>
                            {item.electricity} {item.electricityUnit}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
