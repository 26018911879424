import React from 'react'
import { Box } from '@mui/system'
import GeneralLayout from '../../layouts/GeneralLayout'
import { Typography, Link } from '@mui/material'
import globalStyles from './../../global.module.css'
import Logo2White from "../../assets/images/Logo2White.svg";

export default function NotFoundPage() {
  return (
    <>
      <GeneralLayout>
        <Box sx={{display: 'flex', flexDirection: 'column' }}>
          <Box sx={{display: 'flex', justifyContent: 'center', pt: 10}}>
            <Typography sx={{ fontSize: '150px', color: '#FFFFFF'}}>404</Typography>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'center', pt: 5}}>
            <Typography sx={{ fontSize: '40px', color: '#FFFFFF'}}>ขออภัย ไม่พบหน้าที่ท่านกำลังค้นหา</Typography>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'center', pt: 5}}>
            <a href={`/`} sx={{textDecoration: 'none' }}>
               <Typography className={globalStyles.textNoDecoration} sx={{ fontSize: '30px', color: '#FFFFFF'}}>กลับหน้าหลัก</Typography>
            </a>
          </Box>
        </Box>

        {/* LOGO BEM */}
        <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
          <img src={Logo2White} alt="Signin Header Image" />
        </Box>
      </GeneralLayout>
    </>
  )
}
