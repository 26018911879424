import React, {useState } from 'react'
import { Typography, FormControl, FormControlLabel, Checkbox , Link } from '@mui/material'
import PdPdModal from '../Register/PdPdModal';

export default function ConsentInput({register, errors}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <PdPdModal isOpen={open} handleClose={handleClose} />
      <FormControl sx={{ mt: 4 }}>
          <FormControlLabel
            control={<Checkbox name="consent" id="consent" value="consent" color="primary" />}
            { ...register }
            label={
              <Typography>
                รายละเอียดและเงือนไข <Link
                  onClick={() => handleOpen()}
                >(อ่านรายละเอียดและเงือนไข)</Link>
              </Typography>
            }
          />
          {
            <Typography fontSize={12} sx={{ color: 'red' }}>
              {errors.consent?.type === 'required' && "กรุณาอ่านและยอมรับเงื่อนไข"}
            </Typography>
          }
        </FormControl>
    </>
  )
}
