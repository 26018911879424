import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { data } from "../../uiData/UIData";
import NoDataToShow from "../Common/NoDataToShow";

export default function TargetGroupTable({
  pill,
  secData,
  energyConsumptionByArea,
  energyConsumptionByProvince,
}) {
  const { secTargetForEachGroup } = data;
  const [theSecData, setTheSecData] = useState();

  useEffect(() => {
    const dt = secData.find((x) => x.buildingTypeId === pill.buildingTypeId);
    setTheSecData(dt);
  }, []);

  function renderSec(item, mainSec) {
    switch (item.id) {
      case 1:
        return mainSec.secTarget;
        break;
      case 2:
        return mainSec.secAverage;
        break;
      case 3:
        return mainSec.secMax;
        break;
      case 4:
        return mainSec.secMin;
        break;
      default:
        return 0;
        break;
    }
  }
  return (
    <>
      <Box
        className="box-wrapper"
        sx={{ mt: 1 }}
        display={`flex`}
        flexDirection={`column`}
      >
        <Box display={`flex`}>
          <Typography className="subtitle-header">
            ภาพรวมดัชนีการใช้พลังงานในกลุ่มอาคาร{pill.pillText}{" "}
            เดือนที่ผ่านมา { theSecData && theSecData.buildingTypeUnit || '-'}
          </Typography>
        </Box>

        <Box
          display={`flex`}
          sx={{ mt: 2, mb: 5, ml: 10, mr: 10, p: 5 }}
          flexDirection={`column`}
        >
          <Box
            display={`flex`}
            flexDirection={`row`}
            justifyContent={`space-around`}
            alignItems={`center`}
          >
            <Box display={`flex`} flex={2}>
              <Typography sx={{ fontSize: "20px" }}>ระดับประเทศ</Typography>
            </Box>
            {theSecData &&
              secTargetForEachGroup &&
              secTargetForEachGroup.map((item, index) => (
                <Box
                  key={index}
                  display={`flex`}
                  className="box-wrapper"
                  flexDirection={`column`}
                  justifyContent={`center`}
                  alignItems={`center`}
                  sx={{ pl: 3, pr: 3 }}
                >
                  <Box display={`flex`}>
                    <Typography>{item.title}</Typography>
                  </Box>
                  <Box display={`flex`}>
                    <Typography sx={{ fontSize: "20px" }}>
                      {renderSec(item, theSecData)}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>

          {/* แบ่งตามขนาดอาคาร */}
          <Box
            display={`flex`}
            flexDirection={`row`}
            justifyContent={`space-around`}
            alignItems={`center`}
            sx={{ mt: 1 }}
          >
            <Box display={`flex`} flex={2}>
              <Typography sx={{ fontSize: "20px" }}>
                แบ่งตามขนาดอาคาร
              </Typography>
            </Box>
          </Box>

          {/* BUILDING TYPE TABLE */}
          <Box display={`flex`}>
            <table className="table">
              <thead>
                <tr className="row">
                  <th className="col"></th>
                  <th className="col">จำนวนอาคาร</th>
                  <th className="col">SEC เฉลี่ยของเดือน</th>
                  <th className="col">SEC สูงสุดของเดือน</th>
                  <th className="col">SEC ต่ำของเดือน</th>
                </tr>
              </thead>
              <tbody>
                {energyConsumptionByArea ? (
                  energyConsumptionByArea.map((item, index) => (
                    <tr className="row" key={index}>
                      <th className="col">{item.areaName}</th>
                      <td className="col">{item.total}</td>
                      <td className="col">{item.secAverage}</td>
                      <td className="col">{item.secMax}</td>
                      <td className="col">{item.secMin}</td>
                    </tr>
                  ))
                ) : (
                  <NoDataToShow />
                )}
              </tbody>
            </table>
          </Box>

          {/* ระดับจังหวัด */}
          <Box
            display={`flex`}
            flexDirection={`row`}
            justifyContent={`space-around`}
            alignItems={`center`}
            sx={{ mt: 1 }}
          >
            <Box display={`flex`} flex={2}>
              <Typography sx={{ fontSize: "20px" }}>ระดับจังหวัด</Typography>
            </Box>
          </Box>

          {/* PROVINCE TABLE */}
          <Box display={`flex`}>
            <table
              className="table"
              style={{ maxHeight: "500px", overflowY: "scroll" }}
            >
              <thead>
                <tr className="row">
                  <th className="col"></th>
                  <th className="col">จำนวนอาคาร</th>
                  <th className="col">SEC เฉลี่ยของเดือน</th>
                  <th className="col">SEC สูงสุดของเดือน</th>
                  <th className="col">SEC ต่ำของเดือน</th>
                </tr>
              </thead>
              <tbody>
                {energyConsumptionByProvince ? (
                  energyConsumptionByProvince.map((item, index) => (
                    <tr key={index} className="row">
                      <th className="col">{item.provinceName}</th>
                      <td className="col">{item.total || "-"}</td>
                      <td className="col">{item.secAverage || "-"}</td>
                      <td className="col">{item.secMax || "-"}</td>
                      <td className="col">{item.secMin || "-"}</td>
                    </tr>
                  ))
                ) : (
                  <NoDataToShow />
                )}
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
    </>
  );
}
