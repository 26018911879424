import React, { useState, useEffect, useReducer } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { HomeIcon } from "./../../assets/index";
// import HomeIcon from "../../assets/images/icons/HomeIcon.svg";
import DashboardIcon from "../../assets/images/icons/DashboardIcon.svg";
import BuildingIcon from "../../assets/images/icons/BuildingIcon.svg";
import ReportIcon from "../../assets/images/icons/ReportIcon.svg";
import AdminIcon from "../../assets/images/icons/AdminIcon.svg";
import AdminIconWhite from "../../assets/images/icons/AdminIconWhite.svg";
import ProjectLogo from "../../assets/images/icons/ProjectLogo.svg";
import styles from "./SideMenu.module.css";
import { AuthUtils } from "../../Utils";
import jwt_decode from "jwt-decode";
import { connect, useDispatch } from "react-redux";
import {
  saveActivityLog,
  setCurrentMenu,
  setUserInfo,
} from "../../actions/menu";

const drawerWidth = 200;

const localMenus = [
  {
    id: 1,
    title: "Home",
    titleTh: "หน้าหลัก",
    code: "HOME",
    icon: HomeIcon,
    linkUrl: "/",
  },
  {
    id: 2,
    title: "Dashbord",
    titleTh: "หน้าแสดงผล",
    code: "DASHBOARD",
    icon: DashboardIcon,
    linkUrl: "/dashboard",
  },
  {
    id: 3,
    title: "Building Info",
    titleTh: "ข้อมูลอาคาร",
    code: "BUILDING",
    icon: BuildingIcon,
    linkUrl: "/building-info",
  },
  {
    id: 4,
    title: "Report",
    code: "REPORT",
    titleTh: "รายงาน",
    icon: ReportIcon,
    linkUrl: "/report",
  },
  {
    id: 5,
    title: "Admin",
    titleTh: "การจัดการผู้ใช้งาน",
    code: "ADMIN",
    icon: AdminIcon,
    linkUrl: "/user-list",
  },
];

const SideMenu = ({ menu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { destroyToken, decryptedToken } = AuthUtils;
  const [state, setState] = React.useState({
    top: false,
    left: true,
    bottom: false,
    right: false,
  });
  const [currentMenuId, setCurrentMenuId] = useState("");
  const [authorizedMenus, setAuthorizedMenus] = useState([]);
  const [username, setUsername] = useState("");
  useEffect(() => {
    const localToken = localStorage.getItem("access_token");
    const decrypted = jwt_decode(localToken);
    if (decrypted) {
      const { menu, name, role, userId } = decrypted;
      setUsername(name);
      const userInfo = {
        name,
        role,
        userId,
      };
      dispatch(setUserInfo(userInfo));
      let authorizedMenuArray = [];
      menu.map((m, index) => {
        const roleMenu = localMenus.find((x) => x.code == m.menuCode);
        authorizedMenuArray.push(roleMenu);
      });

      // const pathname = location.pathname;
      // location.pathname == item.linkUrl
      if(location.pathname == "/") {
        const filtered = localMenus.filter(
          (item) => (location.pathname == item.linkUrl ? item : null)
        );
        
        const payload = {
          activity: filtered[0].title,
          desc: filtered[0].titleTh,
        };
        dispatch(saveActivityLog(payload));
      }
      setAuthorizedMenus(authorizedMenuArray);
    }
  }, []);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  function onMenuClickHandler(menu) {
    // todo: possible removable localStorage
    dispatch(setCurrentMenu(menu));
    const payload = {
      activity: menu.title,
      desc: menu.titleTh,
    };
    dispatch(saveActivityLog(payload));
    navigate(menu.linkUrl);
  }

  function signOutHandler() {
    localStorage.removeItem("access_token");
    navigate("/signin");
  }

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : drawerWidth,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={`center`}
        flexWrap={`nowrap`}
      >
        {/* USER LOGO */}
        <Box
          sx={{
            p: 2,
            color: "White",
            textAlign: "center",
            cursor: "pointer",
            backgroundColor: "rgb(37, 161, 142, 1)",
          }}
        >
          <img src={AdminIconWhite} alt="User" height="50" />
          <Typography sx={{ mt: 1 }}>{username}</Typography>
          <Box
            display={`flex`}
            flexDirection={`row`}
            sx={{ justifyContent: "center" }}
          >
            <Box display={`flex`} sx={{ mr: 2 }}>
              <Link
                to={`/password-update`}
                className={styles.changePasswordLink}
              >
                <Typography sx={{ mt: 1, fontSize: "14px", color: "#FFFFFF" }}>
                  เปลี่ยนรหัส
                </Typography>
              </Link>
            </Box>
            <Box display={`flex`}>
              <Typography
                sx={{ mt: 1, fontSize: "14px", color: "#FFFFFF" }}
                onClick={signOutHandler}
              >
                ออกจากระบบ
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* MENU LIST */}
        {authorizedMenus &&
          authorizedMenus.map((item, index) => (
            <Box
              display={`flex`}
              justifyContent={`center`}
              key={index}
              className={
                (menu &&
                  menu.currentMenu &&
                  menu.currentMenu.code == item.code) ||
                location.pathname == item.linkUrl ||
                (location.pathname == "/" && item.code == "HOME")
                  ? `is-active`
                  : ``
              }
              sx={{
                color: "#000000",
                textAlign: "center",
                cursor: "pointer",
                ":hover": {
                  backgroundColor: "rgb(37, 161, 142,.2)",
                },
              }}
              onClick={() => onMenuClickHandler(item)}
            >
              <Box sx={{ pt: 1, pb: 1 }}>
                <img src={item.icon} alt={item.title} height="50" />
                <Typography sx={{ mt: 0 }}>{item.title}</Typography>
                <Typography sx={{ mt: 0 }}>{item.titleTh}</Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          width: drawerWidth,
          display: "flex",
          flexDirection: "column",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
          overflow: "hidden",
        }}
        variant="permanent"
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <Box display={`flex`}>{list("left")}</Box>

        <Box
          sx={{
            pr: 2,
            mr: 2,
            mt: 1,
            ml: 1,
            color: "White",
            textAlign: "center",
            cursor: "pointer",
            marginLeft: "auto",
            position: "fixed",
            bottom: 0,
            textAlign: "center",
            // bgcolor: '#FFFFFF',
            width: drawerWidth,
            padding: "10px 0px",
          }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <img
            sx={{ left: "2%", position: "relative" }}
            src={ProjectLogo}
            alt="Project Logo"
            height="50"
          />
        </Box>
      </Drawer>
      {/* BOTTOM LOGO */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: state.menu,
});

const mapDispatchToProps = {
  setCurrentMenu,
  setUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
