import moment from "moment";
import React, { useState, useEffect } from "react";
import { daysInMonth, numberDaysInMonth } from "../../Utils/FuncUtils";

export default function DropdownDayPill({
  selectedMonth,
  selectedYear,
  onSelectHandler,
  initDate
}) {
  const [day, setDay] = useState();
  const [month, setMonth] = useState(selectedMonth);
  const [year, setYear] = useState(selectedYear);
  const [days, setDays] = useState([]);
  useEffect(() => {
    const selectedDate = new Date(`${selectedYear}-${selectedMonth}-01`);
    const dayNums = moment(selectedDate).daysInMonth();
    let daysArr = [];
    for (let i = 1; i <= dayNums; i++) {
      daysArr.push(i);
    }
    setDays(daysArr);
    setDay(initDate)
    return () => {
      setDay(null);
      setMonth(null);
      setYear(null);
      setDays([]);
    };
  }, [selectedYear, selectedMonth]);

  function handleChange(e) {
    onSelectHandler(e.target.value);
    setDay(e.target.value);
  }
  return (
    <div>
      <select
        aria-label="day-dropdown-label"
        id="day-dropdown"
        className="dropdown-pill"
        onChange={handleChange}
        value={selectedMonth && selectedMonth < 0 ? -1 : day}
        defaultValue={initDate}
        selected={day < 0}
      >
        <option className="option-pill" value={-1}>
          ทั้งหมด
        </option>
        {days &&
          days.map((item) => (
            <option className="option-pill" key={item} value={item} disabled={selectedMonth < 0 ? true : false }>
              {item < 10 ? `0${item}` : item}
            </option>
          ))}
      </select>
    </div>
  );
}
