import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, Modal, FormControl, Autocomplete, OutlinedInput, TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { GET } from '../../Utils/ApiClient';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function ModalAddRoleAccess({ isOpen, onModalCloseHandler }) {
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const [open, setOpen] = React.useState(isOpen);
  const [buildings, setBuildings] = useState([])
  const [building, setBuilding] = useState({})
  const [searchPhrase, setSearchPhrase] = useState("")
  const legalEntityRef = useRef("")
  const buildingControlRef = useRef("")
  useEffect(() => {
    fetchBuildings();
    return () => {
      setBuildings([])
      setBuilding({})
      setSearchPhrase("")
      setOpen(false)
    }
  }, [])


  async function fetchBuildings() {
    const res = await GET(`building?search=${searchPhrase}`);
    const { data } = res;
    setBuildings(data);
  }

  function handleClose() {
    setOpen(false)
    onModalCloseHandler(null)
  }

  function onSubmit(formData) {
    onModalCloseHandler(building)
    setOpen(false)
  }

  async function onBuildingSearchHandler(e) {
    setSearchPhrase(e.target.value)
  }

  function onSelectHandle(event, value) {
    if (value) {
      const { buildingId, legalEntityName, buildingControlName } = value;
      setBuilding({
        buildingId: buildingId,
        legalEntity: legalEntityName,
        buildingControlName: buildingControlName
      })
      legalEntityRef.current.value = legalEntityName;
      buildingControlRef.current.value = buildingControlName;
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-role-modal-title"
        aria-describedby="add-role-modal-description"

      >
        <Box sx={style}>
          <Typography id="add-role-modal-title" variant="h6" component="h2">
            เพิ่มสิทธิ์ผู้ใช้งาน
          </Typography>
          <Box sx={{ mt: 3 }}>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              {/* <input type={`text`} name='tsicid' placeholder='TSIC-ID' className={`general-form-control`} /> */}

              <Box display={`flex`} flexDirection={`column`}>
                {/* TSIC-ID */}
                <Box>
                  <FormControl
                    variant='outlined'
                    sx={{ mt: 2, width: '100%' }}
                  >
                    <Autocomplete
                      options={buildings}
                      getOptionLabel={(option) => option.tsicName}
                      // {...register(`tsicid`, {
                      //   required: 'กรุณากรอกข้อมูล TSIC-ID ให้ครบถ้วน'
                      // })}
                      onChange={onSelectHandle}
                      name={`tsicid`}
                      renderInput={(params) => {
                        return (<>
                          <TextField
                            sx={{ background: '#FFFFFF', borderRadius: 2 }}
                            variant={`outlined`}
                            type={`text`}
                            // name={`tsicid`}
                            onChange={(e) => onBuildingSearchHandler(e)}
                            id={`tsicid`}
                            placeholder={`TSIC-ID`}
                            inputProps={{
                              readOnly: false,
                            }}
                            {...params}
                          /></>)
                      }}
                    >
                    </Autocomplete>
                  </FormControl>
                </Box>

                {/* Legal Entity */}
                <Box>

                  <FormControl
                    variant='outlined'
                    sx={{ mt: 2, width: '100%' }}
                  >
                    <OutlinedInput
                      variant={`outlined`}
                      type={`text`}
                      name={`legalentity`}
                      id={`legalentity`}
                      placeholder={`ชื่อนิติบุคคล`}
                      sx={{ background: '#FFFFFF', borderRadius: 2 }}
                      ref={legalEntityRef}
                      value={building && building.legalEntity}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Box>
                {/* BUILDING CONTROL NAME */}
                <Box>
                  <FormControl
                    variant='outlined'
                    sx={{ mt: 2, width: '100%' }}
                  >
                    <OutlinedInput
                      variant={`outlined`}
                      type={`text`}
                      name={`buildingControlName`}
                      id={`buildingControlName`}
                      placeholder={`ชื่ออาคารควบคุม`}
                      sx={{ background: '#FFFFFF', borderRadius: 2 }}
                      ref={buildingControlRef}
                      value={building && building.buildingControlName}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box display={`flex`} flexDirection={`row`} justifyContent={`end`} sx={{ mt: 3 }}>
                <Box display={`flex`} sx={{ mr: 2 }}>
                  <Button type={`submit`} variant={`contained`} sx={{
                    bgcolor: "#25A18E",
                    borderColor: "#25A18E",
                    ":hover": {
                      borderColor: "#777",
                      bgcolor: '#777'
                    },
                  }}>ตกลง</Button>
                </Box>
                <Box display={`flex`}>
                  <Button variant={`outlined`} onClick={handleClose}>ยกลิก</Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}