import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  ArrowUpSmallIcon,
  ArrowDownSmallIcon,
  ArrowUpLargeIcon,
  ArrowDownLargeIcon,
  FrameIcon,
  PlugIcon,
} from "../../../assets";
import GeneralLayoutLight from "../../../layouts/GeneralLayoutLight";
import ApexColumnBarchart from "../../Common/Apex/ApexColumnBarchart";
import D3StackedBarChart from "../../Common/d3/Bar/Stacked/D3StackedBarChart";
import { D3LineChartReport } from "../../Common/d3/D3LineChartReport";
import D3SunburstChart from "../../Common/d3/Sunburst/D3SunburstChart";

export default function ReportControlBuildingEnergyUse({
  sunburstData,
  selectedYear,
  selectedMonth,
  selectedDay,
  selectedTimeType,
  loopingIndex ,
  countArray
}) {
  const [chillerPlantGraphUnit, setChillerPlantGraphUnit] = useState();
  const [chillerPlantGraph, setChillerPlantGraph] = useState();
  const {
    buildingId,
    buildingName,
    chillerPlant,
    electricity,
    electricityUnit,
    electricityAllSystem,
    electricitySummary,
    electricitySummaryUnit,
    isElectricityUp,
    isLpgUp,
    isOilUp,
    isRatingUp,
    isReNewableElectricityUp,
    isSecTargetUp,
    isSecUp,
    legalEntityName,
    lpg,
    lpgUnit,
    persentLpg, 
    oil,
    oilUnit,
    persentOil,
    persentElectricity,
    persentReNewableElectricity,
    rating,
    reNewableElectricity,
    reNewableElectricityUnit,
    reportDate,
    sec,
    secTarget,
    sunburst,
    thermalEnergySummary,
    thermalEnergySummaryUnit,
    tsicId,
  } = sunburstData;

  useEffect(() => {
    if (selectedYear && selectedMonth && selectedDay && chillerPlant) {
      const modifiedGraphData =
        chillerPlant &&
        chillerPlant.data.map((item) => {
          let modifiedDateFormattedString = "";
          if (selectedMonth < 0) {
            modifiedDateFormattedString = `${selectedYear}-${item.xaxis}-01 00:01`;
          } else if (selectedDay < 0) {
            modifiedDateFormattedString = `${selectedYear}-${selectedMonth}-${item.xaxis} 00:01`;
          } else {
            modifiedDateFormattedString = `${selectedYear}-${selectedMonth}-${selectedDay} ${item.xaxis}`;
          }
          const theDate = new Date(modifiedDateFormattedString);
          return {
            timestamp: theDate,
            electricityUse: item.yaxis,
          };
        });
      setChillerPlantGraph(modifiedGraphData);
      setChillerPlantGraphUnit(chillerPlant.unit);
    }

    return () => {};
  }, [
    selectedYear,
    selectedMonth,
    selectedDay,
    selectedTimeType,
    chillerPlant,
  ]);

  return (
    <>
      <GeneralLayoutLight>
        {/* TODO: Decide wheter or not it printable */}
        <Box style={{height: `29.7cm`, width: `21cm`}}>
        <Box display={`flex`} sx={{ p: 1 }} flexDirection={`column`}>
          <Box display={`flex`} justifyContent={`start`}>
            <Typography className="title-header">
              Report | รายงานภาพรวมการใช้พลังงานอาคารควบคุมทั้งประเทศ
            </Typography>
          </Box>
          <Box className="custom-divider"></Box>
          {/* TOP BOX */}
          <Box
            display={`flex`}
            flexDirection={`row`}
            justifyContent={`space-between`}
          >
            {/* Left BOX */}
            <Box
              display={`flex`}
              flexDirection={`column`}
              flex={1}
              sx={{ p: 1 }}
              flexWrap={`wrap`}
            >
              <Typography className="title-header-black">
                ข้อมูลอาคาร
              </Typography>
              <Box sx={{ marginTop: '5px' }}></Box>

              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
              >
                <Typography className="text-primary">TSIC-ID</Typography>
                <Typography className="text-black" sx={{ mr: 4 }}>
                  {tsicId}
                </Typography>
              </Box>

              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
              >
                <Typography className="text-primary">ชื่อนิติบุคคล</Typography>
                <Typography className="text-black" sx={{ mr: 4 }}>
                  {legalEntityName}
                </Typography>
              </Box>

              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
              >
                <Typography className="text-primary">
                  ชื่ออาคารควบคุม
                </Typography>
                <Typography className="text-black" sx={{ mr: 4 }}>
                  {buildingName}
                </Typography>
              </Box>
            </Box>
            {/* Right BOX */}
            <Box display={`flex`} flexDirection={`column`} flex={1}>
              <Typography className="title-header-black">
                SEC ของอาคาร
              </Typography>
              <Typography className="text-primary">
                ค่าดัชนีการใช้พลังงานเฉลี่ยของอาคารควบคุม (kWh/เตียง)
              </Typography>

              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`start`}
              >
                <Typography
                  display={`flex`}
                  justifyContent={`start`}
                  flex={3}
                  className="text-black"
                >
                  ดัชนีการใช้พลังงานของอาคาร
                </Typography>
                <Typography
                  display={`flex`}
                  justifyContent={`end`}
                  flex={1}
                  className="text-black"
                >
                  {secTarget}
                </Typography>
                <Box display={`flex`} flex={0.5} justifyContent={`end`}>
                  {isSecTargetUp ? (
                    <img src={ArrowUpSmallIcon} alt="Arrow up small icon" />
                  ) : (
                    <img src={ArrowDownSmallIcon} alt="Arrow small icon" />
                  )}
                </Box>
              </Box>
              {/* RANK */}
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`start`}
              >
                <Typography
                  display={`flex`}
                  justifyContent={`start`}
                  flex={3}
                  className="text-black"
                >
                  ลำดับที่
                </Typography>
                <Typography
                  display={`flex`}
                  justifyContent={`end`}
                  flex={1}
                  className="text-black"
                >
                  {rating}
                </Typography>
                <Box display={`flex`} flex={0.5} justifyContent={`end`}>
                  {isRatingUp ? (
                    <img src={ArrowUpSmallIcon} alt="Arrow up small icon" />
                  ) : (
                    <img src={ArrowDownSmallIcon} alt="Arrow small icon" />
                  )}
                </Box>
              </Box>
              {/* TARGET BUILDING  */}
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`start`}
              >
                <Typography
                  display={`flex`}
                  justifyContent={`start`}
                  flex={3}
                  className="text-black"
                >
                  เป้าหมายของอาคาร
                </Typography>
                <Typography
                  display={`flex`}
                  justifyContent={`end`}
                  flex={1}
                  className="text-black"
                >
                  {secTarget}
                </Typography>
                <Box display={`flex`} flex={0.5} justifyContent={`end`}>
                  {isSecTargetUp ? (
                    <img src={ArrowUpSmallIcon} alt="Arrow up small icon" />
                  ) : (
                    <img src={ArrowDownSmallIcon} alt="Arrow small icon" />
                  )}
                </Box>
              </Box>
              {/* TARGET OFFICER */}
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`start`}
              >
                <Typography
                  display={`flex`}
                  justifyContent={`start`}
                  flex={3}
                  className="text-black"
                >
                  เป้าหมายของพพ.
                </Typography>
                <Typography
                  display={`flex`}
                  justifyContent={`end`}
                  flex={1}
                  className="text-black"
                >
                  {sec}
                </Typography>
                <Box display={`flex`} flex={0.5} justifyContent={`end`}>
                  {isSecUp ? (
                    <img src={ArrowUpSmallIcon} alt="Arrow up small icon" />
                  ) : (
                    <img src={ArrowDownSmallIcon} alt="Arrow small icon" />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="custom-divider"></Box>

          {/* BOTTOM BOX */}
          <Box display={`flex`} sx={{ mr: 2 }}>
            <Box display={`flex`} flex={1} flexGrow={1.5} flexDirection={`column`}>
              {/* HEAT */}
              <Typography className="subtitle-header-black">
                ข้อมูลการใช้พลังงานสะสม
              </Typography>
              <Typography className="text-primary">
                วันที่ {reportDate}
              </Typography>
              <Typography className="title-header-primary">
                การใช้พลังงานความร้อนสะสม
              </Typography>
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
              >
                <Box display={`flex`}>
                  <img src={FrameIcon} alt={`frame icon`} />
                </Box>
                <Box display={`flex`} flexDirection={`column`}>
                  <Typography className={`large-title-header-black`}>
                    {thermalEnergySummary}
                  </Typography>
                  <Typography textAlign={`right`} className={`text-black`}>
                    {thermalEnergySummaryUnit}
                  </Typography>
                </Box>
              </Box>
              <Box display={`flex`} flexDirection={``} justifyContent={`end`}>
                <Typography className={`text-sm-primary`}>
                  สัดส่วนพลังงานเมื่อเทียบกับปีที่แล้ว
                </Typography>
              </Box>
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`end`}
              >
                <Box
                  display={`flex`}
                  flex={1}
                  justifyContent={`end`}
                  className={`text-primary`}
                >
                  LPG
                </Box>
                <Box display={`flex`} justifyContent={`end`} flex={1}>
                  <div className={`subtitle-header-black`}>{lpg}</div>&nbsp;
                  <div className="subtitle-header">{lpgUnit}</div>
                </Box>
              </Box>
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
              >
                <Box display={`flex`}></Box>
                <Box
                  display={`flex`}
                  flexDirection={`row`}
                  justifyContent={`space-between`}
                >
                  <Box display={`flex`} sx={{ mr: 2 }}>
                    {isLpgUp ? (
                      <img src={ArrowUpLargeIcon} alt={`arrow icon`} />
                    ) : (
                      <img src={ArrowDownLargeIcon} alt={`arrow icon`} />
                    )}
                  </Box>
                  <Typography
                    display={`flex`}
                    className={`large-title-header-black`}
                  >
                    { persentLpg } %
                  </Typography>
                </Box>
              </Box>
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`end`}
              >
                <Box
                  display={`flex`}
                  flex={1}
                  justifyContent={`end`}
                  className={`text-primary`}
                >
                  น้ำมันเบนซิน
                </Box>
                <Box display={`flex`} justifyContent={`end`} flex={1}>
                  <div className={`subtitle-header-black`}>{oil}</div>&nbsp;
                  <div className="subtitle-header">{oilUnit}</div>
                </Box>
              </Box>

              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
              >
                <Box display={`flex`}></Box>
                <Box
                  display={`flex`}
                  flexDirection={`row`}
                  justifyContent={`space-between`}
                >
                  <Box display={`flex`} sx={{ mr: 2 }}>
                    {isOilUp ? (
                      <img src={ArrowUpLargeIcon} alt={`arrow icon`} />
                    ) : (
                      <img src={ArrowDownLargeIcon} alt={`arrow icon`} />
                    )}
                  </Box>
                  <Typography
                    display={`flex`}
                    className={`large-title-header-black`}
                  >
                    { persentOil } %
                  </Typography>
                </Box>
              </Box>
              {/* ELECTRICITY */}

              <Typography className="title-header-primary">
                การใช้พลังงานไฟฟ้าสะสม
              </Typography>
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
              >
                <Box display={`flex`}>
                  <img src={PlugIcon} alt={`plug icon`} />
                </Box>
                <Box display={`flex`} flexDirection={`column`}>
                  <Typography className={`large-title-header-black`}>
                    {electricitySummary}
                  </Typography>
                  <Typography textAlign={`right`} className={`text-black`}>
                    {electricitySummaryUnit}
                  </Typography>
                </Box>
              </Box>
              <Box display={`flex`} flexDirection={``} justifyContent={`end`}>
                <Typography className={`text-sm-primary`}>
                  การใช้พลังงานจากโครงข่ายไฟฟ้า
                </Typography>
              </Box>
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
              >
                <Box display={`flex`} flex={1}></Box>
                <Box
                  display={`flex`}
                  flexDirection={`row`}
                  justifyContent={`end`}
                  flex={2}
                >
                  <Typography display={`flex`}>
                    <div className={`subtitle-header-black`}>{electricity}</div>&nbsp;
                    <div className="subtitle-header">{electricityUnit}</div>
                  </Typography>
                </Box>
              </Box>
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
              >
                <Box display={`flex`}></Box>
                <Box
                  display={`flex`}
                  flexDirection={`row`}
                  justifyContent={`space-between`}
                >
                  <Box display={`flex`} sx={{ mr: 2 }}>
                    {isElectricityUp ? (
                      <img src={ArrowUpLargeIcon} alt={`arrow icon`} />
                    ) : (
                      <img src={ArrowDownLargeIcon} alt={`arrow icon`} />
                    )}
                  </Box>
                  <Typography
                    display={`flex`}
                    className={`large-title-header-black`}
                  >
                    {persentElectricity} %
                  </Typography>
                </Box>
              </Box>

              <Box display={`flex`} flexDirection={``} justifyContent={`end`}>
                <Typography className={`text-sm-primary`}>
                  การใช้พลังงานจากพลังงานทดแทน
                </Typography>
              </Box>
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
              >
                <Box display={`flex`} flex={1}></Box>
                <Box
                  display={`flex`}
                  flexDirection={`row`}
                  justifyContent={`end`}
                  flex={2}
                >
                  <Typography display={`flex`}>
                    <div className={`subtitle-header-black`}>
                      {reNewableElectricity}
                    </div>
                    &nbsp;
                    <div className="subtitle-header">
                      {reNewableElectricityUnit}
                    </div>
                  </Typography>
                </Box>
              </Box>
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
              >
                <Box display={`flex`}></Box>
                <Box
                  display={`flex`}
                  flexDirection={`row`}
                  justifyContent={`space-between`}
                >
                  <Box display={`flex`} sx={{ mr: 2 }}>
                    {isReNewableElectricityUp ? (
                      <img src={ArrowUpLargeIcon} alt={`arrow icon`} />
                    ) : (
                      <img src={ArrowDownLargeIcon} alt={`arrow icon`} />
                    )}
                  </Box>
                  <Typography
                    display={`flex`}
                    className={`large-title-header-black`}
                  >
                    {persentReNewableElectricity} %
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              className="custom-divider-vertical-primary"
              sx={{ ml: 2, mr: 2 }}
            ></Box>
            {/* RIGHT BOX */}
            <Box display={`flex`} flex={2} flexDirection={`column`}>
              <Typography className="subtitle-header-black">
                การใช้พลังงานไฟฟ้า
              </Typography>
              <Box display={`flex`} flexDirection={`column`}>
                {/* COLUMN BARCHART */}
                <Box
                  display={`flex`}
                  justifyContent={`center`}
                  className="column-barchart"
                >
                  {electricityAllSystem && (
                    <ApexColumnBarchart
                      chartData={electricityAllSystem}
                      selectedTimeType={selectedTimeType}
                    />
                  )}
                </Box>
                <Typography className="subtitle-header-black" style={{ marginTop: '-10px'}}>
                  ประสิทธิภาพของ Chiller Plant
                </Typography>
                <Box
                  display={`flex`}
                  justifyContent={`center`}
                  className="line-chart"
                >
                  {chillerPlant && (
                    <D3LineChartReport
                      unit={chillerPlantGraphUnit}
                      graphData={chillerPlantGraph}
                      timeType={selectedTimeType}
                      strokeColor={`black`}
                      chartType={2}
                    />
                  )}
                </Box>
                <Typography className="subtitle-header-black">
                  สัดส่วนการใช้พลังงานของอาคารแต่ละระบบ{" "}
                </Typography>
                <Box
                  display={`flex`}
                  sx={{ maxWidth: "400px" }}
                  justifyContent={`center`}
                  alignItems={`center`}
                >
                  <Box display={`flex`}>
                    <D3SunburstChart chartData={sunburst} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        </Box>
        { (loopingIndex !== (countArray -1)) && <div style={{marginBottom: '20px'}}></div> } 
      </GeneralLayoutLight>
      {/* WRAPPER */}
    </>
  );
}
