import React, { useEffect, useState } from "react";
import { InputLabel, FormControl, MenuItem, Select, Box } from "@mui/material";
export default function DropdownYearPill({ onSelectHandler }) {
  const [year, setYear] = useState();
  const [years, setYears] = useState([]);
  

  useEffect(() => {
    generateArrayOfYears();
    return () => {
      setYears([]);
      // setYear(new Date().getFullYear());
    };
  }, []);

  function generateArrayOfYears() {
    const currentFullYear = new Date().getFullYear();
    setYear(currentFullYear);
    var max = currentFullYear + 5;
    var min = currentFullYear - 5;
    var yearsArr = [];

    for (var i = max; i >= min; i--) {
      const buddishYear = i + 543;
      yearsArr.push({ ad: i, bd: buddishYear });
    }
    setYears(yearsArr);
  }

  function handleChange(e) {
    onSelectHandler(e.target.value);
    setYear(e.target.value);
  }

  return (
    <div>
      <div>
        <select
          aria-label="year-dropdown-label"
          id="year-dropdown"
          className="dropdown-pill"
          defaultValue={new Date().getFullYear()}
          value={year}
          onChange={(e) => handleChange(e)}
        >
          {years && years.map((item) => (
            <option className="option-pill" key={item.ad} value={item.ad}>
              {/* { format(addYears(item, 543), "yyyy", {locale: th,}) }               */}
              { item.bd }
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
