import axios from 'axios';
import { POST } from '../../Utils/ApiClient';
import { BASE_URL } from '../../Utils/EnvUtils';
import { SAVE_ACTIVITY_SUCCESS, SET_CURRENT_ACTIVE_MENU, SET_USER_INFO } from './../../types'

export const setCurrentMenu = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_CURRENT_ACTIVE_MENU,
      payload: payload,
    });

  } catch (error) {
    console.error(error);
  }
}

export const setUserInfo = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER_INFO,
      payload: payload
    })
  } catch (error) {
    console.error(error);
  }
}

export const saveActivityLog = (payload) => async (dispatch) => {
  try {
    const response = await POST(`Logs`,payload);
    dispatch({
      type: SAVE_ACTIVITY_SUCCESS,
      payload: payload,
    });

  } catch (error) {
    console.error(error);
  }
}
