import { Box } from "@mui/system";
import React from "react";
import DropdownDayPill from "./DropdownDayPill";
import DropdownMonthPill from "./DropdownMonthPill";
import DropdownYearPill from "./DropdownYearPill";

export default function DropdownDateGroup({
  title,
  onSelectYearHandler,
  onSelectMonthHandler,
  onSelectDayHandler,
  initDate,
  selectedMonth,
  selectedYear
}) {
  return (
    <div>
      <Box display={`flex`}>
        <div className="text-primary">
          {title ? title : `เลือกช่วงเวลาของข้อมูล`}
        </div>
      </Box>
      <Box display={`flex`}>
        <Box sx={{ mt: 1, mr: 1 }}>
          <DropdownYearPill onSelectHandler={onSelectYearHandler} />
        </Box>
        <Box sx={{ mt: 1, mr: 1 }}>
          <DropdownMonthPill onSelectHandler={onSelectMonthHandler} />
        </Box>
        <Box sx={{ mt: 1, mr: 1 }}>
          <DropdownDayPill initDate={initDate} selectedMonth={selectedMonth} selectedYear={selectedYear} onSelectHandler={onSelectDayHandler} />
        </Box>
      </Box>
    </div>
  );
}
