import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route, 
  Switch,
  useNavigate
} from "react-router-dom";

import SigninPage from './components/Signin/SigninPage';
import RegisterPage from './components/Register/RegisterPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LinearProgress, Container } from '@mui/material';
import { useNProgress } from '@tanem/react-nprogress'
import { makeStyles } from '@mui/styles';
import Home from './components/Home/Home';
import './App.css';
import PasswordResetPage from './components/PasswordReset/PasswordResetPage';
import PasswordUpdatePage from './components/PasswordReset/PasswordUpdatePage';
import PasswordNewPage from './components/PasswordNew/PasswordNewPage';
import UserManagementPage from './components/User/UserManagementPage';
import DashboardPage from './components/Dashboard/DashboardPage';
import UserListPage from './components/User/UserListPage';
import EmailSent from './components/Common/EmailSent';
import { AuthProvider } from './components/Providers'
import NotFoundPage from './components/Errors/NotFoundPage'
import DataLogPage from './components/User/DataLogPage';
import BuildingInfoPage from './components/BuildingInfo/BuildingInfoPage'
import ReportPage from './components/Report/ReportPage'

import UserRefId from './components/User/UserRefId';
import AddEnergyTarget from './components/Home/AddEnergyTarget';
import Report1 from './components/Report/Prints/Report1';
import Report2 from './components/Report/Prints/Report2';
import PrintPage1 from './components/Report/PrintPage1';
import PrintPage3 from './components/Report/Prints/PrintPage3';
import { ReportEnergyUsePage } from './components/Report/ReportEnergyUsePage';
import ReportControlBuildingEnergyUse from './components/Report/Prints/ReportControlBuildingEnergyUse';
import { Provider } from 'react-redux';
import store from './store';
const CustomRoutes = ({ children }) => {
  return (
    <>
      <Routes>
        {children}
      </Routes></>
  )
}

// todo export
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(37, 161, 142, 1)',
    },
    secondary: {
      main: '#edf2ff',
    },
  },
  Media: {
    height: '100%',
    width: '100%'
  },
  typography: {
    fontFamily: [
      'Kanit',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const Progress = ({ isAnimating }) => {
  const useStyles = makeStyles({
    bar: ({ animationDuration }) => ({
      transitionDuration: `${animationDuration}ms`,
    }),
    container: ({ animationDuration, isFinished }) => ({
      opacity: isFinished ? 0 : 1,
      pointerEvents: 'none',
      transition: `opacity ${animationDuration}ms linear`,
    }),
  })
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
  })
  const classes = useStyles({ animationDuration, isFinished })
  return (
    <Container classes={{ root: classes.container }} disableGutters={true}>
      <LinearProgress
        classes={{ bar1Determinate: classes.bar }}
        value={progress * 100}
        variant="determinate"
      />
    </Container>
  )
}

function App() {
  const { RequireAuth } = AuthProvider;
  const useStyles = makeStyles({
    '@global': {
      body: {
        margin: 0,
      },
    },
    container: {
      alignItems: 'center',
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
    },
  })

  const classes = useStyles()
  const [isAuth, setIsAuth] = useState(false)

  const [state, setState] = useState({
    isAnimating: false,
    key: 0,
  })

  useEffect(() => {
    localStorage.removeItem('menu')
  }, [])

  return (
    <>
      {/* <Progress isAnimating={state.isAnimating} key={state.key} /> */}
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
              <CustomRoutes>
                <Route path="/" element={<RequireAuth><Home /></RequireAuth>} />
                <Route path="/signin" element={<SigninPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/changepassword" element={<PasswordNewPage />} />
                <Route path="/password-reset" element={<PasswordResetPage />} />
                <Route path="/password-update" element={<RequireAuth>
                  <PasswordUpdatePage />
                </RequireAuth>} />
                <Route path="/user-management/:userId" element={<RequireAuth><UserManagementPage /></RequireAuth>} />
                <Route path="/user-list" element={<RequireAuth><UserListPage /></RequireAuth>} />
                <Route path="/email-sent" element={<EmailSent />} />
                <Route path="/dashboard" element={<RequireAuth><DashboardPage /></RequireAuth>} />
                <Route path="/datalog/:userId" element={<RequireAuth><DataLogPage /></RequireAuth>} />
                <Route path="/building-info" element={<RequireAuth><BuildingInfoPage /></RequireAuth>} />
                <Route path="/report" element={<RequireAuth><ReportPage /></RequireAuth>} />
                <Route path="/user-refid/:refId" element={<RequireAuth><UserRefId /></RequireAuth>} />
                <Route path="/add-target" element={<RequireAuth><AddEnergyTarget /></RequireAuth>} />
                <Route path="/report1" element={<RequireAuth><Report1 /></RequireAuth>} />
                <Route path="/report2" element={<RequireAuth><Report2 /></RequireAuth>} />
                <Route path="/report/printpage1" element={<RequireAuth><PrintPage1 /></RequireAuth>} />
                <Route path="/report/printpage3" element={<RequireAuth><PrintPage3 /></RequireAuth>} />
                <Route path="/report/printpage2" element={<RequireAuth><ReportControlBuildingEnergyUse /></RequireAuth>} />
                <Route path="/report/energyuse" element={<RequireAuth><ReportEnergyUsePage /></RequireAuth>} />

                <Route path="*" element={<NotFoundPage />} />

              </CustomRoutes>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;
