import React, { useState, useRef, useEffect } from 'react'
import {
  Box, Grid, Typography, FormControl,
  OutlinedInput, FormControlLabel, Button, FormLabel, RadioGroup, Radio, Autocomplete, TextField
} from '@mui/material'
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import FileUploadIcon from '../../assets/images/icons/FileUploadIcon.svg'
import PdfDownloadIcon from '../../assets/images/icons/PdfDownloadIcon.svg'
import TextFileDownloadIcon from '../../assets/images/icons/TextFileDownloadIcon.svg'
import CloseIcon from '@mui/icons-material/Close';
import { UIData } from './../../uiData';
import { v4 as uuidv4 } from 'uuid';
import ConsentInput from '../Common/ConsentInput';
import CustomTextInput from '../Common/CustomTextInput';
import { ApiClient } from './../../Utils'
import { REGISTER_TYPE } from '../../Utils/EnvUtils';
import { FuncUtils, Messages } from '../../Utils'
import styles from './BuildingForm.module.css'

export default function BuildingForm({ onAlertHandler }) {
  const { register, formState: { errors }, handleSubmit } = useForm();
  const { data } = UIData;
  const { NOTIFICATION_MESSAGE } = Messages;
  const fileUploadRef = useRef(null)
  const navigate = useNavigate();
  const { toBase64 } = FuncUtils;
  const { POST_NO_AUTH, GET_NO_AUTH } = ApiClient;
  const [buildingGroups, setBuildingGroups] = useState([])
  const [buildingOwners, setBuildingOwners] = useState([])
  const [searchPhrase, setSearchPhrase] = useState("")
  const [tsicId, setTsicId] = useState({})
  const [legalEntity, setLegalEntity] = useState({})
  const [buildingGroup, setBuildingGroup] = useState({})
  const [buildingOwner, setBuildingOwner] = useState({})
  const [building, setBuilding] = useState({})
  const [buildings, setBuildings] = useState([])
  const [fileUpload, setFileUpload] = useState([])
  const [concatedFileUploadName, setConcatedFileUploadName] = useState("")
  const legalEntityRef = useRef("")
  const buildingControlRef = useRef("")
  const buildingGroupRef = useRef(0);
  const buildingOwnerRef = useRef(0);
  const iotRef = useRef(0);

  useEffect(() => {
    const fetchBuildingGroup = async () => {
      const res = await GET_NO_AUTH(`building/group`);
      const { data } = res;
      setBuildingGroups(data);
    }
    const fetchBuildingOwner = async () => {
      const res = await GET_NO_AUTH(`building/owner`);
      const { data } = res;
      setBuildingOwners(data)
    }
    fetchBuildingGroup();
    fetchBuildingOwner();
    fetchBuildings();

  }, [])

  useEffect(() => {
    fetchBuildings();
  }, [searchPhrase])

  async function fetchBuildings() {
    const res = await GET_NO_AUTH(`building?search=${searchPhrase}`);
    const { data } = res;
    setBuildings(data);
  }

  async function onFormSubmit(formData) {
    try {
      const { firstname, lastname, idcard, phone, email, office, position } = formData;
      const payload = {
        firstName: firstname,
        lastName: lastname,
        citizen: idcard,
        phone: phone,
        email: email,
        workPlace: office,
        position: position,
        buildingId: building.buildingId,
        document: [...fileUpload]
      }
      const res = await POST_NO_AUTH(`User/register/${REGISTER_TYPE.Building}`, payload);
      const { data } = res;
      const { success, message } = data;
      if (success == true) {
        onAlertHandler(true, message, "success")
        setTimeout(() => {
          navigate('/signin');
        }, 1000);
      } else {
        // alert(message)
        onAlertHandler(true, message, "error")
      }
    } catch (error) {
      console.log(error);
      onAlertHandler(true, NOTIFICATION_MESSAGE.ERROR , "error")
    }
  }
  function onUploadButtonClick() {
    fileUploadRef.current.click();
  };


  function onSelectHandle(event, value) {
    if (value) {
      const { buildingGroupId, buildingOwnerId, buildingId, legalEntityName, buildingControlName, syncIoT } = value;
      setBuilding({
        buildingGroupId: buildingGroupId, buildingOwnerId: buildingOwnerId, buildingId: buildingId,
        legalEntity: legalEntityName, buildingControlName: buildingControlName, syncIoT: syncIoT
      })
      legalEntityRef.current.value = legalEntityName;
      buildingControlRef.current.value = buildingControlName;
      buildingGroupRef.current.value = buildingGroupId;
      buildingOwnerRef.current.value = buildingOwnerId;
      iotRef.current.value = syncIoT;
    }
  }

  function clearFileUpload() {
    setFileUpload([])
    setConcatedFileUploadName("")
  }

  async function onBuildingSearchHandler(e) {
    setSearchPhrase(e.target.value)
  }

  async function onFileUploadChangeHandler(e) {
    if(fileUpload.length > 1) {
      onAlertHandler(true, "ไม่สามารถ upload file เกิน 2 ไฟล์ได้", "error")
      return;
    }
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { name } = file
      const base64String = await toBase64(file);
      const fileUploadPayload = { name, file: base64String }
      fileUpload.push(fileUploadPayload)
      setFileUpload(fileUpload)
    }

    let concatedStringArray = [];
    for (let j = 0; j < fileUpload.length; j++) {
      const file = fileUpload[j];
      concatedStringArray.push(file.name)
    }
    const resultJoin = concatedStringArray.join(",");
    setConcatedFileUploadName(resultJoin)
  }

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
        {
          data.buildingFormInputs && data.buildingFormInputs.map((input) => (
            <Box key={uuidv4()}>
              <Typography sx={{ fontSize: 20, color: '#25A18E', mt: 5 }}>
                {input.group}
              </Typography>
              {
                input && input.inputs.map((item) => (
                  <CustomTextInput
                    key={uuidv4()}
                    register={register(`${item.name}`, item.validation)}
                    errors={errors}
                    item={item}
                  />
                ))
              }
            </Box>
          ))
        }
        <Box>
          <Typography sx={{ fontSize: 20, color: '#25A18E', mt: 5 }}>
            ประเภทอาคาร
          </Typography>
          {/* TSIC-ID */}
          <Box>
            <FormControl
              variant='outlined'
              sx={{ mt: 2, width: '100%' }}
            >
              <Autocomplete
                options={buildings}
                getOptionLabel={(option) => option.tsicName}
                onChange={onSelectHandle}
                name={`tsicid`}
                renderInput={(params) => {
                  return (<>
                    <TextField
                      sx={{ background: '#FFFFFF', borderRadius: 2 }}
                      variant={`outlined`}
                      type={`text`}
                      // name={`tsicid`}
                      onChange={(e) => onBuildingSearchHandler(e)}
                      id={`tsicid`}
                      placeholder={`TSIC-ID`}
                      inputProps={{
                        readOnly: false,
                      }}
                      {...params}
                    /></>)
                }}
              >
              </Autocomplete>
            </FormControl>
          </Box>

          {/* Legal Entity */}
          <Box>

            <FormControl
              variant='outlined'
              sx={{ mt: 2, width: '100%' }}
            >
              <OutlinedInput
                variant={`outlined`}
                type={`text`}
                name={`legalentity`}
                id={`legalentity`}
                placeholder={`ชื่อนิติบุคคล`}
                sx={{ background: '#FFFFFF', borderRadius: 2 }}
                ref={legalEntityRef}
                value={legalEntityRef && legalEntityRef.current.value}
                inputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </Box>
          {/* BUILDING CONTROL NAME */}
          <Box>
            <FormControl
              variant='outlined'
              sx={{ mt: 2, width: '100%' }}
            >
              <OutlinedInput
                variant={`outlined`}
                type={`text`}
                name={`buildingControlName`}
                id={`buildingControlName`}
                placeholder={`ชื่ออาคารควบคุม`}
                sx={{ background: '#FFFFFF', borderRadius: 2 }}
                ref={buildingControlRef}
                value={buildingControlRef && buildingControlRef.current.value}
                inputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </Box>
        </Box>
        {/* BUILDING GROUP */}
        <Box>
          <FormControl sx={{ mt: 2, width: '100%' }} component="fieldset">
            <FormLabel>กลุ่มอาคาร</FormLabel>
            <RadioGroup
              aria-label={`กลุ่มอาคาร`}
              ref={buildingGroupRef}
              name={`buildingGroup`}
            >
              {
                buildingGroups.map((dt, i) => (
                  <FormControlLabel
                    key={i}
                    value={dt.buildingGroupId}
                    control={<Radio checked={buildingGroupRef.current.value == dt.buildingGroupId ? true : false} />}
                    label={dt.groupName}
                  />
                ))
              }
            </RadioGroup>
            <Typography fontSize={12} sx={{ color: 'red', pt: 1 }}>
              {errors[`buildingGroup`]?.message}
            </Typography>
          </FormControl>
        </Box>

        <Box>
          <FormControl sx={{ mt: 2, width: '100%' }} component="fieldset">
            <FormLabel>เจ้าของอาคาร</FormLabel>
            <RadioGroup
              aria-label={`เจ้าของอาคาร`}
              name={`buldingOwners`}
              ref={buildingOwnerRef}
            >
              {
                buildingOwners.map((dt, i) => (
                  <FormControlLabel
                    key={i}
                    value={dt.buildingOwnerId}
                    control={<Radio checked={buildingOwnerRef.current.value == dt.buildingOwnerId ? true : false} />}
                    label={dt.ownerName} />
                ))
              }
            </RadioGroup>
            <Typography fontSize={12} sx={{ color: 'red', pt: 1 }}>
              {errors[`buildingGroup`]?.message}
            </Typography>
          </FormControl>
        </Box>

        <FormControl sx={{ mt: 2, width: '100%' }} component="fieldset">
          <FormLabel>การส่งข้อมูล IoT</FormLabel>
          <RadioGroup
            aria-label="IoTData"
            name="IoTData"
            ref={iotRef}
          >
            <Grid container>
              <Grid item sx={{ mr: 2 }}>
                <FormControlLabel
                  value="0"
                  control={<Radio checked={iotRef.current.value === true ? true : false} />}
                  label="มี IoT" />
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <FormControlLabel
                  value="1"
                  control={<Radio checked={iotRef.current.value === false ? true : false} />}
                  label="ไม่มี IoT"
                />
              </Grid>
            </Grid>
          </RadioGroup>
          <Typography fontSize={12} sx={{ color: 'red' }}>
            {errors.IoTData?.type === 'required' && "กรุณาเลือกการส่งข้อมูล IoT"}
          </Typography>
        </FormControl>

        <Typography sx={{ fontSize: 20, color: '#25A18E', mt: 5 }}>
          ดาวน์โหลดแบบฟอร์มเอกสารเพื่อกรอกข้อมูลอาคารและคำร้องขอใช้ระบบ
        </Typography>
        <FormControl variant='outlined' sx={{ mt: 2, width: '100%' }}>
          <Grid container gap={1}>
            <Grid item xs={2}>
              <Box className={styles.fileUploadIcon} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', borderRadius: 2, mt: 2 }}>
                  <a href=".//documents/manual.pdf" download>
                    <img src={TextFileDownloadIcon} sx={{ width: '100%', height: '100%' }} style={{ cursor: 'pointer' }} alt="PDF download"
                    />
                  </a>
                </Box>
                <br />
                <Box sx={{ display: 'flex' }}>
                  <Typography sx={{ color: 'black', textAlign: 'center' }}>
                    กดที่รูปเพื่อดาวน์โหลดฟอร์ม
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box className={styles.fileUploadIcon} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', borderRadius: 2, mt: 2 }}>
                  <a href=".//documents/manual.pdf" download>
                    <img src={TextFileDownloadIcon} sx={{ width: '100%', height: '100%' }} style={{ cursor: 'pointer' }} alt="PDF download"
                    />
                  </a>
                </Box>
                <br />
                <Box sx={{ display: 'flex' }}>
                  <Typography sx={{ color: 'black', textAlign: 'center' }}>
                    กดที่รูปเพื่อดาวน์โหลดคู่มือขั้นตอนการเชื่อมต่อระบบ
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={2}>


              <Box className={styles.fileUploadIcon} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', borderRadius: 2, mt: 2 }}>
                  <img src={FileUploadIcon} sx={{ width: '100%', height: '100%' }} style={{ cursor: 'pointer' }} alt="People icon"
                    onClick={onUploadButtonClick}
                  />
                  <input
                    multiple
                    type="file"
                    id='fileupload'
                    ref={fileUploadRef}
                    style={{ display: 'none' }}
                    onChange={onFileUploadChangeHandler}
                  />
                </Box>
                <br />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ color: 'black', textAlign: 'center' }}>
                    กดที่รูปเพื่อ Upload form
                  </Typography>

                  {
                    <Typography fontSize={12} sx={{ color: 'red' }}>
                      {fileUpload.length == 0 && "กรุณาเพิ่มเอกสารอัพโหลด"}
                    </Typography>
                  }
                </Box>
              </Box>
              {concatedFileUploadName &&

                <Box sx={{ display: 'flex', flexDirection: 'row', p: 1, borderRadius: '5%' }}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ color: 'black', pt: 1 }}>
                      {concatedFileUploadName}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <CloseIcon sx={{ cursor: 'pointer' }} onClick={clearFileUpload} />
                  </Box>
                </Box>
              }
            </Grid>
          </Grid>
        </FormControl>

        <ConsentInput register={register("consent", { required: true })} errors={errors} />
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" sx={{
            bgcolor: '#25A18E', ":hover": {
              backgroundColor: '#777'
            }
          }} type='submit'>ลงทะเบียน</Button>
        </Box>
      </form>
    </>
  )
}
