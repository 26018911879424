import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import * as d3 from "d3";
import { v4 as uuid } from 'uuid'
import millify from 'millify'
import { randomNumber } from "../../../../Utils/FuncUtils";
// import data from "./data.json";
const SIZE = 280;
const RADIUS = SIZE / 2;
export default function D3SunburstChart({ chartData }) {
  const svgRef = React.useRef(null);
  const data = chartData;
  const partition = (data) =>
    d3.partition().size([2 * Math.PI, RADIUS])(
      d3
        .hierarchy(data)
        .sum((d) => d.value)
        .sort((a, b) => b.value - a.value)
    );

  const color = d3.scaleOrdinal(
    d3.quantize(d3.interpolateRainbow, data.children.length + 1)
  );

  const format = d3.format(",d");

  const arc = d3
    .arc()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(RADIUS / 2)
    .innerRadius((d) => d.y0)
    .outerRadius((d) => d.y1 - 1);

  const getAutoBox = () => {
    if (!svgRef.current) {
      return "";
    }

    const { x, y, width, height } = svgRef.current.getBBox();

    return [x, y, width, height].toString();
  };

  React.useEffect(() => {
    const root = partition(data);

    const svg = d3.select(svgRef.current);

    svg
      .append("g")
      .attr("fill-opacity", 0.6)
      .selectAll("path")
      .data(root.descendants().filter((d) => d.depth))
      .join("path")
      .attr("fill", (d) => {
        while (d.depth > 1) d = d.parent;
        return color(d.data.name);
      })
      .attr("d", arc)
      .append("title")
      .text(
        (d) =>
          `${d
            .ancestors()
            .map((d) => d.data.name)
            .reverse()
            .join("/")}\n${format(d.value)}`
      );

    svg
      .append("g")
      
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .attr("font-size", 10)
      .attr("font-family", "sans-serif")
      .selectAll("text")
      .data(
        root
          .descendants()
          .filter((d) => d.depth && ((d.y0 + d.y1) / 2) * (d.x1 - d.x0) > 10)
      )
      .join("text")
      .attr("transform", function (d, k) {
        const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
        const y = (d.y0 + d.y1) / 2;
        return `rotate(${
          x - 90
        }) translate(${y},0) rotate(${180})`;
      })
      .style("font-size", '5px')
      .attr("dy", "0.35em")
      .html(function (d) {
        return "<tspan x='0' dy='1.2em'>" + d.data.name.substring(0, 10) + "</tspan>" 
             + "<tspan x='0' dy='1.2em'>" + d.data.name.substring(10, Number.MAX_SAFE_INTEGER) + "</tspan>";
      })
      // .text((d) => `${d.data.name} \r\n TEST`);

    svg.attr("viewBox", getAutoBox);
    return () => {
    }
  }, [chartData]);

  return <svg id={uuid()} width={SIZE} height={SIZE} ref={svgRef} />;
}
