import {
  Container,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Button,
  InputAdornment,
  IconButton,
  Alert,
  FormHelperText
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { BASE } from "../../theme/BaseStyle";
import { useForm } from "react-hook-form";
import Logo2White from "../../assets/images/Logo2White.svg";
import { UIData } from "../../uiData";
import { ApiClient, Messages } from "../../Utils";
import { useNavigate } from "react-router-dom";


export default function PasswordUpdatePage() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm();
  const { passwordInput } = UIData;
  const navigate = useNavigate();
  const { POST } = ApiClient;
  const [message, setMessage] = useState("")
  const [alert, setAlert] = useState(false)
  const [severity, setSeverity] = useState("error")
  const { NOTIFICATION_MESSAGE } = Messages;
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
    passwordConfirm: "",
    showPasswordConfirm: false,
    passwordOld: "",
    showPasswordOld: false
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = (e) => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowPasswordConfirm = (e) => {
    setValues({
      ...values,
      showPasswordConfirm: !values.showPasswordConfirm,
    });
  };
  const handleClickShowPasswordOld = (e) => {
    setValues({
      ...values,
      showPasswordOld: !values.showPasswordOld,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function onFormSubmit(formData) {
    try {
      const { password, passwordOld } = formData;
      const payload = {
        oldPassword: passwordOld,
        newPassword: password
      }
      const res = await POST(`user/changePassword`, payload);
      const { data, status } = res;
      const { success, message } = data;
      if (success) {
        navigate('/')
      } else {
        setAlert(true);
        setMessage(message);
        setSeverity(setSeverity)
        setTimeout(() => {
          setAlert(false)
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity(setSeverity)
      setTimeout(() => {
        setAlert(false)
      }, 3000);
    }

  }

  return (
    <>
      <AuthLayout>
        {alert && <Alert sx={{ position: 'fixed', width: '100%' }} severity={severity}>{message} </Alert>}
        <Box
          variant="div"
          sx={{
            backgroundColor: BASE.registerBackgroundColor,
            width: "auto",
            height: "100vh",
          }}
        >
          <Container>
            <Box variant="h2" sx={{ pt: 10 }}>
              <Typography
                sx={{ color: "#FFFFFF", fontSize: 20, textAlign: "center" }}
              >
                เปลี่ยนรหัสผ่าน
              </Typography>
            </Box>

            <Box variant="div">
              <form noValidate onSubmit={handleSubmit(onFormSubmit)}>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" sx={{ mt: 2, width: "60%" }}>
                    <OutlinedInput
                      aria-describedby={`outlined-passwordOld-helper-text`}
                      {...register("passwordOld",
                        {
                          required: "กรุณากรอกข้อมูล Password ให้ครบถ้วน",
                          maxLength: {
                            value: 100,
                            message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร",
                          },
                          minLength: {
                            value: 8,
                            message: "ต้องมีความยาวอย่างน้อย 8 ตัวอักษร",
                          },
                        })}
                      variant="outlined"
                      type={values.showPasswordOld ? "text" : "password"}
                      name="passwordOld"
                      id="passwordOld"
                      placeholder="Old Password"
                      sx={{ background: "#FFFFFF", borderRadius: 2 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordOld}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPasswordOld ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors[`passwordOld`] && (
                      <FormHelperText
                        id={`outlined-passwordOld-helper-text`}
                      >
                        {errors[`passwordOld`]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" sx={{ mt: 2, width: "60%" }}>
                    <OutlinedInput
                      aria-describedby={`outlined-password-helper-text`}
                      {...register("password",
                        {
                          required: "กรุณากรอกข้อมูล Password ให้ครบถ้วน",
                          maxLength: {
                            value: 100,
                            message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร",
                            // pattern: {
                            //   value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i ,
                            //   message: "กรุณากรอกอีเมลล์ในรูปแบบที่ถูกต้อง"
                            // }
                          },
                          minLength: {
                            value: 8,
                            message: "ต้องมีความยาวอย่างน้อย 8 ตัวอักษร",
                          },
                        })}
                      variant="outlined"
                      type={values.showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="New Password"
                      sx={{ background: "#FFFFFF", borderRadius: 2 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors[`password`] && (
                      <FormHelperText
                        id={`outlined-password-helper-text`}
                      >
                        {errors[`password`]?.message}
                      </FormHelperText>
                    )}

                  </FormControl>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" sx={{ mt: 2, width: "60%" }}>
                    <OutlinedInput
                      aria-describedby={`outlined-passwordConfirm-helper-text`}
                      {...register("passwordConfirm", {
                        required: "กรุณากรอกข้อมูล Password ให้ครบถ้วน",
                        minLength: {
                          value: 8,
                          message: "ต้องมีความยาวอย่างน้อย 8 ตัวอักษร",
                        },
                        maxLength: {
                          value: 100,
                          message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร"
                        },
                        validate: (value) => value === watch('password') || "รหัสผ่านต้องตรงกัน"

                      })}
                      variant="outlined"
                      type={values.showPasswordConfirm ? "text" : "password"}
                      name="passwordConfirm"
                      id="passwordConfirm"
                      placeholder="Confirm Password"
                      sx={{ background: "#FFFFFF", borderRadius: 2 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordConfirm}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPasswordConfirm ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors[`passwordConfirm`] && (
                      <FormHelperText
                        id={`outlined-passwordConfirm-helper-text`}
                      >
                        {errors[`passwordConfirm`]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                </Box>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    color="secondary"
                    sx={{ width: "10%", mt: 3, mb: 2, pl: 4, pr: 4 }}
                  >
                    ส่ง
                  </Button>
                </Box>
              </form>
            </Box>

            <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
              <img src={Logo2White} alt="Signin Header Image" />
            </Box>
          </Container>
        </Box>
      </AuthLayout>
    </>
  );
}
