import { Box, Typography } from "@mui/material";
import React from "react";
import { ElectricalWireIcon, SolarPanelIcon } from "../../assets";
import { v4 as uuidv4 } from 'uuid'
export default function AlternativeEnergyPercent({
  buildingEnergyUse,
  activePill,
}) {
  return (
    <>
      <Box
        display={`flex`}
        flex={activePill.buildingTypeId === 0 ? 2 : 1}
        flexGrow={1.1}
        flexDirection={`column`}
        className="box-wrapper"
        style={{ minWidth: '500px'}}
        id={`percent-${uuidv4()}`}
      >
        <Box>
          <div className="subtitle-header">
            การใช้พลังงานไฟฟ้าจากโครงข่ายเทียบกับพลังงานทดแทน
          </div>
        </Box>
        {/* ELECTRICAL WIRE */}
        <Box
          sx={{ p: 1, mt: 1 }}
          display={`flex`}
          flexDirection={`row`}
          justifyContent={`space-between`}
        >
          <Box display={`flex`}>
            <img src={ElectricalWireIcon} alt={`electrical wire icon`} />
          </Box>
          <Box display={`flex`} flexDirection={`column`} sx={{ mr: 2 }}>
            <Box display={`flex`}>
              <Typography sx={{ fontSize: "32px" }}>
                {(buildingEnergyUse && buildingEnergyUse.electricity) || "-"}
              </Typography>
            </Box>
            <Box display={`flex`} justifyContent={`end`}>
              <Typography>
                {(buildingEnergyUse && buildingEnergyUse.electricityUnit) ||
                  "-"}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* GUAGE BAR */}
        {buildingEnergyUse && (
          <Box sx={{ p: 1, mt: 1 }} display={`flex`} flexDirection={`row`}>
            <div
              style={{
                backgroundColor: "rgb(37, 161, 142, 1)",
                height: "35px",
                width: `${
                  buildingEnergyUse 
                    && buildingEnergyUse.percentElectricity >= 0 
                    && buildingEnergyUse.percentElectricity <= 100
                    ? parseInt(buildingEnergyUse.percentElectricity)
                    : 0
                }%`,
              }}
            >
              <Typography sx={{ color: "#FFFFFF", textAlign: "center" }}>
                {buildingEnergyUse 
                  && buildingEnergyUse.percentElectricity >= 0 
                  && buildingEnergyUse.percentElectricity <= 100
                  ? parseInt(buildingEnergyUse.percentElectricity)
                  : 0}
                %
              </Typography>
            </div>
            <div
              style={{
                position: "relative",
                left: 0,
                backgroundColor: "rgb(118, 116, 111)",
                height: "35px",
                width: `${
                  buildingEnergyUse 
                    && buildingEnergyUse.percentReNewableElectricity >= 0 
                    && buildingEnergyUse.percentReNewableElectricity <= 100
                    ? parseInt(buildingEnergyUse.percentReNewableElectricity)
                    : 0
                }%`,
              }}
            >
              <Typography sx={{ color: "#FFFFFF", textAlign: "center" }}>
                {buildingEnergyUse 
                  && buildingEnergyUse.percentReNewableElectricity >= 0 
                  && buildingEnergyUse.percentReNewableElectricity <= 100
                  ? parseInt(buildingEnergyUse.percentReNewableElectricity)
                  : 0}
                %
              </Typography>
            </div>
          </Box>
        )}
        {/* ALTERNATIVE ENERGY */}
        <Box
          sx={{ p: 1, mt: 1 }}
          display={`flex`}
          flexDirection={`row`}
          justifyContent={`space-between`}
        >
          <Box display={`flex`}>
            <img src={SolarPanelIcon} alt={`solar icon`} />
          </Box>
          <Box display={`flex`} flexDirection={`column`} sx={{ mr: 2 }}>
            <Box display={`flex`}>
              <Typography sx={{ fontSize: "32px" }}>
                {(buildingEnergyUse &&
                  buildingEnergyUse.reNewableElectricity) ||
                  "-"}
              </Typography>
            </Box>
            <Box display={`flex`} justifyContent={`end`}>
              <Typography>
                {(buildingEnergyUse &&
                  buildingEnergyUse.reNewableElectricityUnit) ||
                  "-"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
