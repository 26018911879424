import React, { useState, useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { csv, scaleLinear, scaleTime, max, timeFormat, extent } from "d3";
import { useData } from "../../CustomHook/useData";
import { AxisBottom } from "./AxisBottom";
import { AxisLeft } from "./AxisLeft";
import { Marks } from "./Marks";
import moment from "moment";
import * as d3 from "d3";
import { X_AXIS_TIME_TYPE } from "../../../Utils/EnvUtils";
import { roundNearest, vw } from "../../../Utils/FuncUtils";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { addYears } from "date-fns/esm";
import { v4 as uuidv4 } from "uuid";
import millify from "millify";

const width = 0.83 * window.screen.width;
const height = 500;
const margin = { top: 40, right: 30, bottom: 65, left: 90 };
const xAxisLabelOffset = 50;
const yAxisLabelOffset = 67;

export const D3LineChart = ({ graphData, unit, timeType }) => {
  // const data = useData();
  const [maxNumber, setMaxNumber] = useState();
  const [isAllZero, setIsAllZero] = useState(false);

  if (!graphData) {
    return <pre>Loading...</pre>;
  }

  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  const xValue = (d) => d.timestamp;
  const xAxisLabel = "เวลา";
  const yValue = (d) => d.electricityUse;
  const yAxisLabel = unit;

  const xAxisTickFormat = (e) => {
    if (timeType == X_AXIS_TIME_TYPE.TIME) {
      return moment(e).format("HH");
    } else if (timeType == X_AXIS_TIME_TYPE.DAY) {
      return moment(e).format("DD");
    } else if (timeType == X_AXIS_TIME_TYPE.MONTH) {
      const formattedMonth = format(e, "MMM", { locale: th });
      return formattedMonth;
    }
  };

  const xScale = scaleTime()
    .domain(extent(graphData, xValue))
    .range([0, innerWidth])
    .nice();

  const yScale = scaleLinear()
    // .domain(extent(graphData, yValue))
    .domain([0, maxNumber])
    .range([innerHeight, isAllZero ? innerHeight : 0])
    .nice();

  useEffect(() => {
    const localMaxArray = [];
    graphData.map((data, item) => {
      const yData = data.electricityUse;
      localMaxArray.push(yData);
    });

    const isAllZeroInThere = localMaxArray.every((item) => item === 0);
    setIsAllZero(isAllZeroInThere);
    const maxNum = Math.max(...localMaxArray);
    const roundedMaxNumber = roundNearest(maxNum);
    setMaxNumber(roundedMaxNumber);
    return () => {
      setMaxNumber();
    };
  }, [graphData]);

  return (
    <>
      {graphData && (
        <svg id={`${uuidv4()}`} width={`100%`} height={height}>
          <g transform={`translate(${margin.left},${margin.top})`}>
            <AxisBottom
              xScale={xScale}
              innerHeight={innerHeight}
              tickFormat={xAxisTickFormat}
              tickOffset={7}
              timeType={timeType}
            />
            <line y1={-30} y2={innerHeight} stroke={`black`} />
            <text
              className="axis-label"
              textAnchor="middle"
              transform={`translate(${-yAxisLabelOffset},${
                innerHeight / 2
              }) rotate(-90)`}
            >
              {yAxisLabel}
            </text>
            <AxisLeft
              yScale={yScale}
              maximumNumber={maxNumber}
              innerWidth={innerWidth}
              tickOffset={7}
              isAllZero={isAllZero}
            />
            <line
              x1={0}
              x2={innerWidth}
              y1={innerHeight}
              y2={innerHeight}
              stroke={`black`}
            />

            <text
              className="axis-label"
              x={innerWidth / 2}
              y={innerHeight + xAxisLabelOffset}
              textAnchor="middle"
            >
              {xAxisLabel}
            </text>
            {!isAllZero && (
              <Marks
                data={graphData}
                xScale={xScale}
                yScale={yScale}
                xValue={xValue}
                yValue={yValue}
                tooltipFormat={xAxisTickFormat}
                circleRadius={3}
              />
            )}
          </g>
        </svg>
      )}
    </>
  );
};
