import { Box, Typography } from "@mui/material";
import React from "react";
import { PlugIcon } from "../../assets";

export default function AccumElectricityUse({ data }) {
  return (
    <>
      <Box
        display={`flex`}
        flex={1}
        flexDirection={`column`}
        className="box-wrapper"
        flexGrow={1.1}
      >
        <Box>
          <div className="subtitle-header">การใช้พลังงานไฟฟ้าสะสม</div>
        </Box>
        <Box
          sx={{ p: 1, mt: 1 }}
          display={`flex`}
          flexDirection={`row`}
          justifyContent={`space-between`}
        >
          <Box display={`flex`}>
            <img src={PlugIcon} alt={`plug icon`} />
          </Box>
          <Box display={`flex`} flexDirection={`column`} sx={{ mr: 2 }}>
            <Box display={`flex`}>
              <Typography sx={{ fontSize: "32px" }}>
                {(data && data.electricitySummary) || "-"}
              </Typography>
            </Box>
            <Box display={`flex`} justifyContent={`end`}>
              <Typography>
                {(data && data.electricitySummaryUnit) || "-"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
