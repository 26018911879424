import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';

export default function SearchResultList({ list, onSelectHandler }) {
  
  return <>
    <Box className={`search-result-list`} display={`flex`} flexDirection={`column`}>
      { list && list.length > 0 && list.map((item,index) => (
        <Box key={index} display={`flex`} className={`line-style-none`} onClick={() => onSelectHandler(item)}>
          { item.buildingControlName }
        </Box>
      ))}
    </Box>
  </>;
}
