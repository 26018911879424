import React, { useEffect } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";

export default function Exporter(props) {
  console.log('exporting ');
  const navigate = useNavigate();
  useEffect(() => {
    if(props.children) {
      setTimeout(() => {
        exportPdf('pdf', 'download')
      }, 1500);
    }  
    return () => {
      
    }
  }, [props.children])
  
  function exportPdf( type, name ) {
    html2canvas(document.querySelector(`#capture`)).then((canvas) => {
      let dataURL = canvas.toDataURL("image/png");
      if (type === "pdf") {
        var pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(dataURL, "PNG", 1, 1);
        pdf.save(`${name}.pdf`);
        navigate('/report')
      } else if (type === "png") {
        var link = document.createElement("a");
        link.download = `${name}.png`;
        link.href = dataURL;
        link.click();
        navigate('/report')
      }
    });
  }
  return (
    <>
      {/* <button onClick={() => exportPdf("pdf", "my-content")}>Export</button> */}
      <div id={`capture`}>
        {props.children}
      </div>
    </>
  );
}
