import React, { useState, useEffect } from "react";
import { InputLabel, FormControl, MenuItem, Select } from "@mui/material";
import { format } from "date-fns";
import { th } from "date-fns/locale";

export default function DropdownMonthPill({ onSelectHandler }) {
  const [month, setMonth] = useState();
  const [months, setMonths] = useState();

  useEffect(() => {
    let monthsArray = [];
    for (let i = 0; i < 12; i++) {
      monthsArray.push(i);
    }
    setMonth(new Date().getMonth())
    setMonths(monthsArray);
  }, []);

  useEffect(() => {}, []);

  function handleChange(e) {
    onSelectHandler(e.target.value);
    setMonth(e.target.value);
  }

  function renderMonth(monthNumber) {
    const date = new Date(new Date().getFullYear(), monthNumber, 1);
    const formattedDate = format(date, "MMMM", { locale: th });
    return formattedDate;
  }

  return (
    <div>
      <div>
        <select
          aria-label="month-dropdown-label"
          id="month-dropdown"
          className="dropdown-pill"
          onChange={handleChange}
          defaultValue={new Date().getMonth()}
          value={month}
        >
          <option className="option-pill" value={-1}>
            ทั้งหมด
          </option>

          {months &&
            months.length > 0 &&
            months.map((item) => (
              <option className="option-pill" key={item} value={item}>
                {renderMonth(item)}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}
