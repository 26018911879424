import { Box, FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import SearchResultList from "./SearchResultList";

export default function SearchBar({
  list,
  searchPhrase,
  onSearchHandle,
  handleOnSearchPhraseChanging,
  hitEnter,
  onSelectedHandler,
  placeholder,
}) {
  return (
    <>
        <Box display={`flex`}>
          <FormControl sx={{ m: 1, width: "100%" }}>
            <OutlinedInput
              id="outlined-adornment-search"
              value={searchPhrase ? searchPhrase : ""}
              onChange={(e) => handleOnSearchPhraseChanging(e)}
              onKeyDown={(e) => hitEnter(e)}
              placeholder={placeholder ? placeholder : `ค้นหา`}
              startAdornment={
                <InputAdornment
                  position="start"
                  sx={{ cursor: "pointer" }}
                  onClick={onSearchHandle}
                >
                  <SearchIcon />
                </InputAdornment>
              }
              sx={{ bgcolor: "#FFFFFF", borderRadius: "10px" }}
            />
          </FormControl>
        </Box>
        {/* END SEARCH FORM */}

        {/* SEARCH RESULT LIST */}
        <Box display={`flex`}>
          {list && (
            <SearchResultList list={list} onSelectHandler={onSelectedHandler} />
          )}
        </Box>
    </>
  );
}
