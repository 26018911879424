import { Box } from '@mui/material'
import React from 'react'
import { DownloadIcon } from '../../assets'
import { BASE_URL } from '../../Utils/EnvUtils'

export default function DownloadBox({ endPoint }) {
  return (
    <>
      <Box display={`flex`} justifyContent={`flex-end`} sx={{ mr: 1, mb: 1 }}>
          <a
            href={`${BASE_URL}/${endPoint}`}
            download
          >
            <img
              src={DownloadIcon}
              alt="download icon"
              sx={{ cursor: "pointer" }}
            />
          </a>
        </Box>
    </>
  )
}
