import { Container, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../layouts/Layout";
import { ApiClient } from "../../Utils";
import { GRID_PAGE_SIZE, TIME_ZONE } from "../../Utils/EnvUtils";
import PaginationSquared from "../Common/PaginationSquared";
import moment from "moment";
import BasicDatePicker from "../Common/DatePicker";

export default function DataLogPage() {
  const { GET } = ApiClient;
  let { userId } = useParams();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState();
  const [dataLog, setDataLog] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCount, setCurrentCount] = useState(0);
  const [selectedDateFrom, setSelectedDateFrom] = useState(
    moment
      .tz(new Date(), TIME_ZONE.AsiaBangkok)
      .subtract(1, "months")
      .format("yyyy-MM-DD")
  );
  const [selectedDateTo, setSelectedDateTo] = useState(
    moment.tz(new Date(), TIME_ZONE.AsiaBangkok).format("yyyy-MM-DD")
  );

  useEffect(() => {
    fetchLog();
    fetchUserDataLog();
  }, [currentPage, selectedDateFrom, selectedDateTo]);

  useEffect(() => {
    fetchUserDataLog();
  }, []);

  async function fetchLog() {
    try {
      const res = await GET(
        `User/${userId}/logs?pageNumber=${currentPage}&pageSize=${GRID_PAGE_SIZE}&fromDate=${selectedDateFrom}&toDate=${selectedDateTo}`
      );
      const { data, status } = res;
      const { message, success } = data;
      setDataLog(data);
      const total = data.length > 0 ? data[0].total : 1;
      const pageSize = GRID_PAGE_SIZE;
      const currentFaction = total / pageSize;
      setCurrentCount(Math.ceil(currentFaction));
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchUserDataLog() {
    try {
      const res = await GET(`User/${userId}`);
      const { data, status } = res;
      const { message, success } = data;
      setUserInfo(data);
    } catch (error) {
      console.log(error);
    }
  }

  function onPageChangeHandle(e, pageNumber) {
    setCurrentPage(pageNumber);
  }

  function convertToHour(minute) {
    let duration = moment.duration(minute, "minutes");

    let hh =
      duration.years() * (365 * 24) +
      duration.months() * (30 * 24) +
      duration.days() * 24 +
      duration.hours();
    let mm = duration.minutes();
    let hhStr = "";
    if (hh >= 0 && hh < 10) {
      hhStr = `0${hh}`;
    } else {
      hhStr = `${hh}`;
    }
    let mmStr = "";
    if (mm >= 0 && mm < 10) {
      mmStr = `0${mm}`;
    } else {
      mmStr = `${mm}`;
    }
    return `${hhStr}:${mmStr}`;
  }

  function navigateToUserList() {
    window.location.href = "/user-list";
  }

  function handleOnDatePickerChanged(type, newDate) {
    const formattedDate = moment
      .tz(newDate, TIME_ZONE.AsiaBangkok)
      .format("yyyy-MM-DD");
    if (type === "FROM") {
      setSelectedDateFrom(formattedDate);
    } else {
      setSelectedDateTo(formattedDate);
    }
  }
  return (
    <div>
      <Layout>
        <Container maxWidth="2xl">
          <Box
            sx={{
              border: "#EAE6E5 1px solid",
              p: 1,
              mt: 2,
              bgcolor: "#FFFFFF",
            }}
          >
            <Box sx={{ p: 5 }} display={`flex`} flexDirection={`column`}>
              <Box display={`flex`}>
                <Typography
                  sx={{
                    pl: 2,
                    pb: 5,
                    pt: 2,
                    fontSize: "24px",
                    color: "#25A18E",
                  }}
                >
                  Data Log
                </Typography>
              </Box>
              <Box display={`flex`}>
                <Box display={`flex`}>
                  {/* <input
                    type="date"
                    id="filter-date-from"
                    name="filterDateFrom"
                    className="filterDate"
                    defaultValue={}
                    onChange={(e) =>
                      setSelectedDateFrom(
                        moment.tz(e.target.value, TIME_ZONE.AsiaBangkok)
                      )
                    }
                  /> */}
                  <BasicDatePicker
                    date={selectedDateFrom}
                    onSetNewDate={(newDate) =>
                      handleOnDatePickerChanged("FROM", newDate)
                    }
                  />
                </Box>
                <Box
                  display={`flex`}
                  justifyContent={`center`}
                  alignItems={`center`}
                  alignSelf={`center`}
                  sx={{ mr: 1, ml: 1 }}
                >
                  -
                </Box>
                <Box display={`flex`}>
                  {/* <input
                    type="date"
                    id="filter-date-to"
                    name="filterDateTo"
                    className="filterDate"
                    onChange={(e) =>
                      setSelectedDateTo(
                        moment.tz(e.target.value, TIME_ZONE.AsiaBangkok)
                      )
                    }
                  /> */}
                  <BasicDatePicker
                    date={selectedDateTo}
                    onSetNewDate={(newDate) =>
                      handleOnDatePickerChanged("TO", newDate)
                    }
                  />
                </Box>
              </Box>
              {/* USER DATA */}
              <Box varaint="div">
                <table className="table-light">
                  <thead>
                    <tr className="row-left">
                      <td style={{ width: "100%" }}>ผู้ใช้งาน</td>
                      <td style={{ width: "100%" }}>ชื่อ - นามสกุล</td>
                      <td style={{ width: "100%" }}>
                        ชื่ออาคารควบคุม / TSIC-ID
                      </td>
                      <td style={{ width: "100%" }}>ประเภทผู้ใช้งาน</td>
                    </tr>
                  </thead>
                  {userInfo && (
                    <tbody>
                      <tr className="row-left">
                        <td style={{ width: "100%" }}>
                          {userInfo.email || "-"}
                        </td>
                        <td
                          style={{ width: "100%" }}
                        >{`${userInfo.firstName} ${userInfo.lastName}`}</td>
                        <td style={{ width: "100%" }}>{`${
                          userInfo.buildingControlName || "-"
                        }`}</td>
                        <td style={{ width: "100%" }}>{`${
                          userInfo.roleName || "-"
                        }`}</td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </Box>

              {/* DATA LOG */}
              <Box display={`flex`} flexDirection={`column`}>
                <table className="table">
                  <thead>
                    <tr className="row-left">
                      <td style={{ width: "20%" }}></td>
                      <td style={{ width: "100%" }}>เวลา</td>
                      <td style={{ width: "100%" }}>เหตุการณ์</td>
                      <td style={{ width: "100%" }}>คำอธิบาย</td>
                      {/* <td style={{ width: "100%" }}>ระยะเวลา</td> */}
                    </tr>
                  </thead>
                  <tbody>
                    {dataLog &&
                      dataLog.length > 0 &&
                      dataLog.map((log, index) => (
                        <tr className="row-left" key={index}>
                          <td style={{ width: "20%" }}>{log.rowNo || "-"}</td>
                          <td
                            style={{ width: "100%" }}
                          >{`${log.logDateTime}`}</td>
                          <td style={{ width: "100%" }}>{`${
                            log.event || "-"
                          }`}</td>
                          <td style={{ width: "100%" }}>{`${
                            log.description || "-"
                          }`}</td>
                          {/* <td style={{ width: "100%" }}>{`${
                            convertToHour(log.duration) || "-"
                          }`}</td> */}
                        </tr>
                      ))}

                    {!dataLog ||
                      (dataLog.length == 0 && (
                        <Box
                          varaint="div"
                          flex="1"
                          sx={{ p: 2, mt: 1 }}
                          display={`flex`}
                          flexDirection={`column`}
                          justifyContent={`center`}
                        >
                          <Typography
                            sx={{
                              mt: 2,
                              mb: 2,
                              color: "#221717",
                              textAlign: "center",
                            }}
                          >
                            No data to show
                          </Typography>
                        </Box>
                      ))}
                  </tbody>
                </table>
                <Box display={`flex`} alignSelf={`end`} sx={{ mt: 5, mr: 5 }}>
                  {dataLog.length > 0 && (
                    <PaginationSquared
                      page={currentPage}
                      count={currentCount}
                      onPageChange={onPageChangeHandle}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Box display={`flex`} sx={{ mt: 2 }} justifyContent={`end`}>
              <Button
                variant={`contained`}
                onClick={() => navigateToUserList()}
              >
                ออก
              </Button>
            </Box>
          </Box>
        </Container>
      </Layout>
    </div>
  );
}
