import {
  Container,
  Typography,
  Box,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Button,
  Switch,
  FormControlLabel
} from "@mui/material";

import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import Layout from "../../layouts/Layout";
import AdminIcon from "../../assets/images/icons/AdminIcon.svg";
import ImageIcon from "../../assets/images/icons/ImageIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { DELETE, GET, POST, PUT } from "../../Utils/ApiClient";
import DeleteIcon from '@mui/icons-material/Delete';
import AlertDialog from "../Common/AlertDialog";
import { ALERT_DISMISS_TIME, BASE_URL, GRID_PAGE_SIZE, HTTP_STATUS, DIALOG_TYPE } from "../../Utils/EnvUtils";
import AlertNotification from "../Common/AlertNotification";
import SimpleDialog from "../Common/SimpleDialog";
import { FuncUtils, Messages } from "../../Utils";
import PaginationSquared from "../Common/PaginationSquared";
import ModalAddRoleAccess from "../Common/ModalAddRoleAccess";
import UserTypeCombobox from "../Common/UserTypeCombobox";
import './../Common/Common.css'
import RoleCombobox from "../Common/RoleCombobox";


export default function UserManagementPage() {
  const { toBase64 } = FuncUtils;
  const { NOTIFICATION_MESSAGE } = Messages;

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  let { userId } = useParams();
  const navigate = useNavigate();
  const firstnameRef = useRef("");
  const lastnameRef = useRef("");
  const emailRef = useRef("")
  const phoneRef = useRef("")
  const positionRef = useRef("")
  const workplaceRef = useRef("")
  const citizenRef = useRef("")
  const activeRef = useRef("")
  const [message, setMessage] = useState("")
  const [alert, setAlert] = useState(false)
  const [severity, setSeverity] = useState("error")
  const [confirmSave, setConfirmSave] = useState(false)
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [position, setPosition] = useState("")
  const [citizen, setCitizen] = useState("")
  const [active, setActive] = useState();
  const [fileUpload, setFileUpload] = useState("")
  const [workplace, setWorkplace] = useState("")
  const [buildingAccess, setBuildingAccess] = useState([])
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [dialogType, setDialogType] = useState(DIALOG_TYPE.DELETE) // 0 confirm
  const [priority, setPriority] = useState("info")
  const [base64Str, setBase64Str] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [currentCount, setCurrentCount] = useState(1)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [isOpenAddRoleModal, setIsOpenAddRoleModal] = useState(false)
  const [documents, setDocuments] = useState([])
  const [userInfo, setUserInfo] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [isOpenSimpleDialog, setIsOpenSimpleDialog] = useState(false)
  const [simpleDialogMessage, setsimpleDialogMessage] = useState("")
  const [simpleDialogTitle, setSimpleDialogTitle] = useState("")
  const [currentDeletingBuildingAccess, setCurrentDeletingBuildingAccess] = useState(null)
  const [userTypes, setUserTypes] = useState([])
  const [roleId, setRoleId] = useState();

  const { OK } = HTTP_STATUS;

  useEffect(() => {
    // GET: user/{userId}
    setTimeout(() => {
      fetchUserDetail();
      fetchUserRoleAll();
      fetchUserBuildingAccess();
    }, 0);
  }, [])

  useEffect(() => {
    if (!selectedFile) {
      return
    }
    const base64Promise = toBase64(selectedFile);
    base64Promise.then((str) => {
      setBase64Str(str)
    })

  }, [selectedFile])


  useEffect(() => {
    fetchUserBuildingAccess()
  }, [currentPage])

  useEffect(() => {
    if (confirmDelete) {
      deleteBuildingAccess(currentDeletingBuildingAccess);
    }
    return () => {
      setConfirmDelete(false);
      setCurrentDeletingBuildingAccess(null)
    }
  }, [confirmDelete])

  useEffect(() => {

    return () => {
      // cleanup every
    }
  }, [])

  async function fetchUserRoleAll() {
    try {
      const res = await GET(`User/role`);
      const { data , status } = res;
      setUserTypes(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchUserDetail() {
    try {
      const res = await GET(`User/${userId}`);
      const { data } = res;
      setUserInfo(data);
      setRoleId(data.roleId);
      const { firstName, lastName, email, phone, citizen, active, position, workPlace, documents } = data;
      setValue("firstname", firstName, {})
      setValue("lastname", lastName, {})
      setValue("email", email, {})
      setValue("phone", phone, {})
      setValue("citizen", citizen, {})
      setActive(active)
      setValue("position", position, {})
      setValue("workplace", workPlace, {})
      setDocuments(documents);
      if (data && data.profileImage) {
        setBase64Str(data.profileImage)
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchUserBuildingAccess() {
    try {
      const pageNumber = currentPage;
      const pageSize = GRID_PAGE_SIZE;
      const res = await GET(`User/${userId}/buildingAccess?pageNumber=${pageNumber}&pageSize=${pageSize}`);
      const { data, status } = res;
      if (status == OK) {
        setBuildingAccess(data);
        const total = data.length > 0 ? data[0].total : 1;
        const currentFaction = (total / pageSize);
        setCurrentCount(Math.ceil(currentFaction))
      } else {
        const { message } = data;
        setMessage(message)
        setSeverity("error")
        setAlert(true)
      }
    } catch (error) {
      setMessage(error)
      setSeverity("error")
      setAlert(true)
    }
  }

  async function onSubmitHandle(formData) {
    try {
      const {
        firstname,
        email,
        position,
        workplace,
        lastname,
        phone,
        citizen,
        // buildingControlName
      } = formData;

      const payload = {
        firstName: firstname,
        lastName: lastname,
        email,
        position,
        workplace,
        phone,
        citizen,
        status: active,
        profile: base64Str,
        roleId: roleId
      }
      const res = await PUT(`user/${userId}`, payload);
      const { data, status } = res;
      if (status == OK) {
        const { message, success } = data;
        if (success) {
          setMessage(message)
          setSeverity("success")
          setAlert(true)
          setIsOpenSimpleDialog(true)
          setsimpleDialogMessage(message)
          setSimpleDialogTitle("success")
        } else {
          setMessage(message)
          setSeverity("error")
          setAlert(true)
        }
        setTimeout(() => {
          setAlert(false)
        }, ALERT_DISMISS_TIME);
      }
    } catch (error) {
      console.log(error);
      setMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setAlert(true)
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }

  function OpenDialogConfirmDelete(buildingAccess) {
    setCurrentDeletingBuildingAccess(buildingAccess)
    setIsOpenDialog(true);
    setDialogType(DIALOG_TYPE.DELETE);
    setPriority('info');
    // <AlertDialog isOpenDialog={isOpenDialog} dialogType={dialogType} priority={priority} onCloseDialogHandler={onCloseDialogHandler} params={params} />
  }

  async function deleteBuildingAccess(building) {
    try {
      const res = await DELETE(`user/${userId}/buildingAccess/${building.buildingId}`);
      const { data, status } = res;
      if (status == OK) {
        const { message, success } = data;
        if (success) {
          setMessage(message)
          setSeverity("success")
          setAlert(true)
          setIsOpenSimpleDialog(true)
          setsimpleDialogMessage(message)
          setSimpleDialogTitle("success")
          fetchUserBuildingAccess();
        } else {
          setMessage(message)
          setSeverity("error")
          setAlert(true)
        }
        setTimeout(() => {
          setAlert(false)
        }, ALERT_DISMISS_TIME);
      }
    } catch (error) {
      console.log(error);
      setMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setAlert(true)
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }

  function onCloseDialogHandler(isOpenDialog, isConfirm, params) {
    setIsOpenDialog(false)
    if (dialogType == DIALOG_TYPE.DELETE) {
      setConfirmDelete(isConfirm)
    }
    if (dialogType == DIALOG_TYPE.SAVE) {
      setConfirmSave(isConfirm)
    }
  }

  function onPageChangeHandle(e, pageNumber) {
    setCurrentPage(pageNumber);
  }

  function navigateBack() {
    navigate(`/user-list`);
  }


  function onAddRoleOpenModalHandler() {
    setIsOpenAddRoleModal(true)
  }

  async function onSelectFile(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }


  async function onModalCloseHandler(payload) {
    try {
      setIsOpenAddRoleModal(close);
      if (!payload) {
        return;
      }
      const { buildingId } = payload;
      const res = await POST(`User/${userId}/buildingAccess/${buildingId}`)

      const { data, status } = res;
      const { success, message } = data;
      if (status == OK) {
        const { message, success } = data;
        if (success) {
          setMessage(message)
          setSeverity("success")
          setAlert(true)
          setIsOpenSimpleDialog(true);
          setsimpleDialogMessage(message);
          setSimpleDialogTitle("success")
          fetchUserBuildingAccess()
        } else {
          setMessage(message)
          setSeverity("error")
          setAlert(true)
        }
        setTimeout(() => {
          setAlert(false)
        }, ALERT_DISMISS_TIME);
      }
    } catch (error) {
      console.log(error);
      setMessage(NOTIFICATION_MESSAGE.ERROR)
      setSeverity("error")
      setAlert(true)
      setTimeout(() => {
        setAlert(false)
      }, ALERT_DISMISS_TIME);
    }
  }

  function onCloseSimpleDialogHandle() {
    setIsOpenSimpleDialog(false)
  }

  function onUserTypeComboboxSelectChange(e) {    
    e.preventDefault();
    setRoleId(e.target.value)
  }

  return (
    <div>
      <Layout>
        <AlertNotification alert={alert} severity={severity} message={message} />
        {isOpenAddRoleModal && <ModalAddRoleAccess isOpen={isOpenAddRoleModal} onModalCloseHandler={onModalCloseHandler} />}
        <Container maxWidth="2xl">
          {isOpenSimpleDialog && <SimpleDialog isOpen={isOpenSimpleDialog} title={simpleDialogTitle} message={simpleDialogMessage} onCloseDialogHandle={onCloseSimpleDialogHandle} />}
          {isOpenDialog && <AlertDialog isOpenDialog={isOpenDialog} dialogType={dialogType} priority={priority} onCloseDialogHandler={onCloseDialogHandler} />}
          <Box
            sx={{
              p: 4,
              mt: 4,
              border: "1px solid #25A18E",
              borderRadius: "10px",
              minWidth: '1080px',
              bgcolor: 'background.paper',
            }}
          >
            <Box>
              <Typography sx={{ p: 2, color: "#25A18E" }}>
                ข้อมูลผู้ใช้
              </Typography>
            </Box>
            <Box display="flex">
              <Box varaint="div" sx={{ p: 2 }}>
                <img src={AdminIcon} alt="User" height="50" />
              </Box>
              <Box varaint="div" sx={{ p: 2 }}>
                <Typography>ประเภทผู้ใช้งาน</Typography>
                <Typography sx={{ color: "#25A18E" }}>{userInfo ? userInfo.roleName : '-'}</Typography>
              </Box>
            </Box>
            {/* Main form group */}
            <form noValidate onSubmit={handleSubmit(onSubmitHandle)}>
              <Box sx={{ display: "flex", flexDirection: "row" }} >
                {/* <Box flex={1} sx={{ pl: 1, pr: 1 }} justifyContent={`center`} alignItems={`center`} flexDirection={`column`}>
                  {base64Str == "" ? 
                  <Box display={`flex`}>
                    <MyDropzone />
                  </Box> : 
                  <Box display={`flex`} 
                    flex={1}
                    justifyContent={`center`} 
                    alignItems={`center`} 
                    alignSelf={`center`}
                    sx={{border: '#EAE6E5 1px dashed', borderRadius: '5px', height: '350px'}}
                  >
                    <img src={`${base64Str}`} alt="profile" />
                  </Box>}
                </Box> */}
                <Box flex={1} sx={{ pl: 1, pr: 1 }} justifyContent={`center`} alignItems={`center`} flexDirection={`column`}>
                  <Box display={`flex`}
                    flex={1}
                    justifyContent={`center`}
                    alignItems={`center`}
                    alignSelf={`center`}
                    sx={{ border: '#EAE6E5 1px dashed', borderRadius: '5px', height: '280px' }}
                  >
                    {base64Str ? (<img src={`${base64Str}`} alt="profile" style={{ maxHeight: '280px' }} />) :
                      (<img src={ImageIcon} alt="placholder" style={{ maxHeight: '280px' }} />)}
                  </Box>
                  <Box display={`flex`} sx={{ mt: 2 }}>
                    <input type="file" className="custom-file-input" onChange={onSelectFile} />
                  </Box>
                </Box>

                <Box flex={1} sx={{ pl: 1, pr: 1 }}>
                  {/* FIRSTNAME */}
                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant={`outlined`}
                  >
                    <OutlinedInput
                      aria-describedby={`outlined-firstname-helper-text`}
                      inputProps={{
                        "aria-label": `firstname`,
                      }}
                      ref={firstnameRef}
                      name={`firstname`}
                      id={`input-id-firstname`}
                      type={`text`}
                      placeholder={`ชื่อ`}
                      {...register(`firstname`, {
                        required: "กรุณากรอกข้อมูล ชื่อ ให้ครบถ้วน",
                        maxLength: {
                          value: 100,
                          message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร",
                        },
                      })
                      }
                    />
                    {errors[`firstname`] && (
                      <FormHelperText
                        id={`outlined-firstname-helper-text`}
                      >
                        {errors[`firstname`]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  {/* EMAIL */}
                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant={`outlined`}
                  >
                    <OutlinedInput
                      aria-describedby={`outlined-email-helper-text`}
                      inputProps={{
                        "aria-label": `email`,
                      }}
                      ref={emailRef}
                      name={`email`}
                      id={`input-id-email`}
                      type={`email`}
                      placeholder={`E-mail`}
                      {...register(`email`, {
                        required: "กรุณากรอกข้อมูล E-mail ให้ครบถ้วน",
                        maxLength: {
                          value: 100,
                          message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร",
                        },
                        pattern: {
                          value:
                            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                          message: "กรุณากรอกอีเมลล์ในรูปแบบที่ถูกต้อง",
                        },
                      },
                      )
                      }
                    />
                    {errors[`email`] && (
                      <FormHelperText
                        id={`outlined-email-helper-text`}
                      >
                        {errors[`email`]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  {/* POSITION */}
                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant={`outlined`}
                  >
                    <OutlinedInput
                      aria-describedby={`outlined-position-helper-text`}
                      inputProps={{
                        "aria-label": `position`,
                      }}
                      ref={positionRef}
                      name={`position`}
                      id={`input-id-position`}
                      type={`text`}
                      placeholder={`ตำแหน่ง`}
                      {...register(`position`, {
                        required: "กรุณากรอกข้อมูล ตำแหน่ง ให้ครบถ้วน",
                        maxLength: {
                          value: 100,
                          message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร",
                        },
                      })
                      }
                    />
                    {errors[`position`] && (
                      <FormHelperText
                        id={`outlined-position-helper-text`}
                      >
                        {errors[`position`]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  {/* WORKPLACE */}
                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant={`outlined`}
                  >
                    <OutlinedInput
                      aria-describedby={`outlined-workplace-helper-text`}
                      inputProps={{
                        "aria-label": `workplace`,
                      }}
                      ref={workplaceRef}
                      name={`workplace`}
                      id={`input-id-workplace`}
                      type={`text`}
                      placeholder={`สถานที่ทำงาน`}
                      {...register(`workplace`, {
                        required: "กรุณากรอกข้อมูล สถานที่ทำงาน ให้ครบถ้วน",
                        maxLength: {
                          value: 100,
                          message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร",
                        },
                      })
                      }
                    />
                    {errors[`workplace`] && (
                      <FormHelperText
                        id={`outlined-workplace-helper-text`}
                      >
                        {errors[`workplace`]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                  {/* STATUS */}
                  <Box display={`flex`} sx={{ width: "100%" }} flexDirection={`row`} justifyContent={`space-between`}>
                    <Box display={`flex`}>
                      {/* <Switch /> */}
                      <FormControlLabel
                        control={
                          <Switch
                            ref={activeRef}
                            name="active" {...register('active')}
                            checked={active}
                            onChange={e => setActive(e.target.checked)}
                            color="primary" />}
                        label="สถานะเปิดใช้งาน"
                        labelPlacement="start"
                      />
                    </Box>
                    <Box display={`flex`}>
                      <Box sx={{ pt: 1 }}>
                        เอกสาร:

                        {documents.length > 0 && <Box>
                          {documents.map((item, i) => (<Typography key={i}>
                            <a href={`${BASE_URL}/api/User/download/document/${userId}/${item.file}`} download>{item.name}</a>
                          </Typography>))
                          }
                        </Box>}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box flex={1} sx={{ pl: 1, pr: 1 }}>
                  {/* LASTNAME */}
                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant={`outlined`}
                  >
                    <OutlinedInput
                      aria-describedby={`outlined-lastname-helper-text`}
                      inputProps={{
                        "aria-label": `lastname`,
                      }}
                      ref={lastnameRef}
                      name={`lastname`}
                      id={`input-id-lastname`}
                      type={`text`}
                      placeholder={`นามสกุล`}
                      {...register(`lastname`, {
                        required: "กรุณากรอกข้อมูล สกุล ให้ครบถ้วน",
                        maxLength: {
                          value: 100,
                          message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร",
                        },
                      })
                      }
                    />
                    {errors[`lastname`] && (
                      <FormHelperText
                        id={`outlined-lastname-helper-text`}
                      >
                        {errors[`lastname`]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  {/* EMAIL */}
                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant={`outlined`}
                  >
                    <OutlinedInput
                      aria-describedby={`outlined-phone-helper-text`}
                      inputProps={{
                        "aria-label": `phone`,
                      }}
                      ref={phoneRef}
                      name={`phone`}
                      id={`input-id-phone`}
                      type={`text`}
                      placeholder={`โทรศัพท์`}
                      {...register(`phone`, {
                        required: "กรุณากรอกข้อมูล โทรศัพท์ ให้ครบถ้วน",
                        maxLength: {
                          value: 100,
                          message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร",
                        },
                      })
                      }
                    />
                    {errors[`phone`] && (
                      <FormHelperText
                        id={`outlined-phone-helper-text`}
                      >
                        {errors[`phone`]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  {/* CITIZEN */}
                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant={`outlined`}
                  >
                    <OutlinedInput
                      aria-describedby={`outlined-citizen-helper-text`}
                      inputProps={{
                        "aria-label": `citizen`,
                      }}
                      ref={citizenRef}
                      name={`citizen`}
                      id={`input-id-citizen`}
                      type={`text`}
                      placeholder={`หมายเลขบัตรประชาชน`}
                      {...register(`citizen`, {
                        required: "กรุณากรอกข้อมูล หมายเลขบัตรประชาชน ให้ครบถ้วน",
                        maxLength: {
                          value: 100,
                          message: "ไม่อนุญาตให้กรอกเกิน 100 ตัวอักษร",
                        },
                      })
                      }
                    />
                    {errors[`citizen`] && (
                      <FormHelperText
                        id={`outlined-citizen-helper-text`}
                      >
                        {errors[`citizen`]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <RoleCombobox handleSelectChange={onUserTypeComboboxSelectChange} userTypes={userTypes} defaultValue={userInfo && userInfo.roleId} selected={roleId} />

                  <Box sx={{ mt: 10 }} display={`flex`} justifyContent={`flex-end`}>
                    <Box display={`flex`} sx={{ mt: 1, mr: 1 }}>
                      <Button variant="contained" type='submit' sx={{
                        bgcolor: "#25A18E",
                        borderColor: "#25A18E",
                        ":hover": {
                          borderColor: "#777",
                          bgcolor: '#777'
                        },
                      }}>
                        บันทึก
                      </Button>
                    </Box>
                    <Box display={`flex`} sx={{ mt: 1 }}>
                      <Button variant="outlined" onClick={navigateBack}>ออก</Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>

            {/* BUILDING ACCESS GRID */}
            <Box varaint="div" flex="1" sx={{ p: 2, mt: 5 }} display={`flex`} flexDirection={`column`}>
              <Box display={`flex`} flexDirection={`row`} sx={{ color: '#25A18E' }}>
                <Box display={`flex`} flex={`1`} sx={{ pl: 1 }}>No</Box>
                <Box display={`flex`} flex={`1`} sx={{ pl: 1 }}>TSIC-ID</Box>
                <Box display={`flex`} flex={`2`}>ชื่ออาคารควบคุม</Box>
                <Box display={`flex`} flex={`2`}>ชื่อนิติบุคคล</Box>
                <Box display={`flex`} flex={`1`}>ประเภทอาคาร</Box>
                <Box display={`flex`} flex={`1`}>แก้ไขข้อมูล</Box>
              </Box>

              {buildingAccess && buildingAccess.length > 0 &&
                buildingAccess.map((building, index) => (
                  <Box key={index} display={`flex`} flexDirection={`row`} sx={{ pt: 2, pl: 2 }}>
                    <Box display={`flex`} flex={`1`}>{building.rowNo}</Box>
                    <Box display={`flex`} flex={`1`}>{`${building.tsicId}`}</Box>
                    <Box display={`flex`} flex={`2`}>{`${building.buildingControlName}`}</Box>
                    <Box display={`flex`} flex={`2`}>{`${building.legalEntityName}`}</Box>

                    <Box display={`flex`} flex={`1`} alignItems={`center`}>
                      <Button variant="outlined">
                        {building.buildingTypeName}
                      </Button>
                    </Box>
                    <Box display={`flex`} flex={`1`} sx={{ cursor: 'pointer' }}>
                      <DeleteIcon onClick={() => OpenDialogConfirmDelete(building)} />
                    </Box>
                  </Box>
                ))
              }
              <Box display={`flex`} justifyContent={`flex-end`} sx={{ mt: 5, mr: 10 }}>
                {buildingAccess.length > 0 && currentCount > 0 && <Box display={`flex`}>
                  <PaginationSquared page={currentPage} count={currentCount} onPageChange={onPageChangeHandle} /></Box>}
              </Box>

              {buildingAccess.length == 0 ? <Box varaint="div" flex="1" sx={{ p: 2, mt: 5 }} display={`flex`} flexDirection={`column`}>
                <Typography varaint="div" sx={{ mt: 2, mb: 2, color: '#221717', textAlign: 'center' }}>No data to show</Typography>
              </Box> : ""}
            </Box>

            {userInfo &&
              <Box sx={{ mt: 1, ml: 1 }}>
                <Button variant={`contained`} onClick={onAddRoleOpenModalHandler} sx={{
                  bgcolor: "#25A18E",
                  borderColor: "#25A18E",
                  ":hover": {
                    borderColor: "#777",
                    bgcolor: '#777'
                  },
                }}>
                  เพิ่มสิทธิ์
                </Button>
              </Box>
            }
          </Box>
        </Container>
      </Layout>
    </div >
  );
}