import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { AirCompressorIcon, PumpBrokeIcon, PumpIcon } from "../../assets";
import { GET } from "../../Utils/ApiClient";
import {
  ALERT_DISMISS_TIME,
  ALERT_TYPE,
  HTTP_STATUS,
} from "../../Utils/EnvUtils";
import { NOTIFICATION_MESSAGE } from "../../Utils/Messages";
import AlertNotification from "../Common/AlertNotification";
import { data } from "../../uiData/UIData";
import { D3LineChart } from "../Common/d3/D3LineChart";
import DownloadBox from "../Common/DownloadBox";
import {
  modifiedLineChartDataTimePeriod,
  modifiedLineChartDataTimePeriodArray,
} from "../../Utils/FuncUtils";
import { D3MultipleLineChart } from "../Common/d3/Line/Multiple/D3MultipleLineChart";

export default function OtherSystem({
  buildingInfo,
  selectedYear,
  selectedMonth,
  selectedDay,
  selectedTimeType,
}) {
  const { ERROR } = NOTIFICATION_MESSAGE;
  const { otherGraphLegend } = data;
  // const { cleanWaterPump,wasteWaterPump } = mockDataPumpEnergyUseTable;

  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [cleanWaterPump, setCleanWaterPump] = useState();
  const [wasteWaterPump, setWasteWaterPump] = useState();
  const [cleanWaterGraphData, setCleanWaterGraphData] = useState();
  const [cleanWaterGraphDataUnit, setCleanWaterGraphDataUnit] = useState();
  const [wasteWaterGraphData, setWasteWaterGraphData] = useState();
  const [wasteWaterGraphDataUnit, setWasteWaterGraphDataUnit] = useState();

  useEffect(() => {
    if (buildingInfo) {
      fetchOtherSystemEnergyUse();
      fetchOtherSystemData();
    }
    return () => {};
  }, [buildingInfo, selectedYear, selectedMonth, selectedDay]);

  async function fetchOtherSystemEnergyUse() {
    try {
      // GET:/Dashboard/other/pumpEnergy/{buildingId}/{year}/{month}/{day}
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      const res = await GET(
        `dashboard/other/pumpEnergy/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setCleanWaterPump(data.waterPump);
        setWasteWaterPump(data.sewageWastewaterPump);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(error);
      setSeverity(ALERT_TYPE.alertError);
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchOtherSystemData() {
    try {
      // GET:/dashboard/other/energy/{buildingId}/{year}/{month}/{day}
      if (!buildingInfo) return;
      const buildingId = buildingInfo ? buildingInfo.buildingId : 0;
      // todo: make this available to 2 line graph
      const response = await GET(
        `dashboard/other/energy/${buildingId}/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = response;
      const { message } = data;
      if (status === HTTP_STATUS.OK) {
        if (data) {
          const { waterPump, sewageWastewaterPump, unit } = data;
          if (waterPump) {
            const modifiedData = modifiedLineChartDataTimePeriodArray(
              waterPump,
              selectedYear,
              selectedMonth,
              selectedDay
            );
            setCleanWaterGraphData(modifiedData);
            setCleanWaterGraphDataUnit(data.unit);
          }

          if (sewageWastewaterPump) {
            const modifiedDataWaste = modifiedLineChartDataTimePeriodArray(
              sewageWastewaterPump,
              selectedYear,
              selectedMonth,
              selectedDay
            );
            setWasteWaterGraphData(modifiedDataWaste);
            setWasteWaterGraphDataUnit(data.unit);
          }
        }
      } else {
        setMessage(message || NOTIFICATION_MESSAGE.ERROR);
        setAlert(true);
        setSeverity("error");
        // todo : set alert
      }
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    } catch (error) {
      // todo : set alert
      console.log(error);
      setMessage(message || NOTIFICATION_MESSAGE.ERROR);
      setAlert(true);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }
  return (
    <>
      <AlertNotification alert={alert} severity={severity} message={message} />
      <Box display={`flex`} flexDirection={`column`}>
        <Box
          display={`flex`}
          flexDirection={`column`}
          justifyContent={`space-between`}
        >
          {/* BOX#1 */}

          <Box
            className="box-wrapper"
            sx={{ mt: 1 }}
            display={`flex`}
            flex={1}
            flexDirection={`column`}
          >
            <Box display={`flex`} flexDirection={`row`}>
              <Box
                display={`flex`}
                flexDirection={`column`}
                flex={4}
                flexGrow={4}
              >
                <Typography className="subtitle-header">
                  การใช้พลังงานไฟฟ้าของเครื่องสูบน้ำดีและเครื่องสูบน้ำเสีย
                </Typography>
                {cleanWaterGraphData && wasteWaterGraphData && (
                  <Box>
                    <D3MultipleLineChart
                      chartData={[cleanWaterGraphData, wasteWaterGraphData]}
                      unit={cleanWaterGraphDataUnit}
                      timeType={selectedTimeType}
                    />
                    <Box display="flex" justifyContent="center">
                      <Typography>เวลา</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box
                display={`flex`}
                flexDirection={`column`}
                flex={1}
                flexGrow={0.5}
                justifyContent={`center`}
                alignContent={`center`}
              >
                <Box display={`flex`} flexDirection={`column`}>
                  <table className="table">
                    <tbody>
                      {otherGraphLegend &&
                        otherGraphLegend.map((item, index) => (
                          <tr className="row" key={index}>
                            <td>
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  background: `${item.color}`,
                                }}
                              ></div>
                            </td>
                            <td>{item.text} </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Box>
              </Box>
            </Box>
            <DownloadBox
              endPoint={`api/dashboard/other/downloadEnergy/${
                buildingInfo ? buildingInfo.buildingId : 0
              }/${selectedYear}/${selectedMonth}/${selectedDay}`}
            />
          </Box>

          {/* BOX#2 */}
          <Box
            className="box-wrapper"
            sx={{ mt: 1 }}
            display={`flex`}
            flex={1}
            flexDirection={`column`}
          >
            <Box display={`flex`} flexDirection={`row`}>
              {/* LEFT BOX */}
              <Box
                display={`flex`}
                flexDirection={`column`}
                justifyContent={`center`}
                alignContent={`start`}
                alignItems={`center`}
                textAlign={`left`}
                flex={1}
                sx={{ maxWidth: "80px", m: 2 }}
              >
                <Box display={`flex`}>
                  {/* ICON  */}
                  <img
                    src={PumpIcon}
                    alt="pump icon"
                    sx={{ cursor: "pointer" }}
                  />
                </Box>
                <Box
                  display={`flex`}
                  justifyContent={`center`}
                  alignItems={`center`}
                >
                  <Typography className="text-primary">
                    เครื่องสูบน้ำดี
                  </Typography>
                </Box>
              </Box>

              {/* RIGHT BOX */}
              <Box display={`flex`} flexDirection={`column`} flex={2}>
                <table
                  className=""
                  style={{
                    maxHeight: "500px",
                    overflowY: "scroll",
                    background: "#FFFFFF",
                    textAlign: "left",
                  }}
                >
                  <tbody>
                    {cleanWaterPump &&
                      cleanWaterPump.map((item, index) => (
                        <tr key={index} className="row-left">
                          <td className="text-primary w-150 d-flex justify-content-center align-items-center">
                            <Box
                              display={`flex`}
                              justifyContent={`center`}
                              alignItems={`center`}
                              sx={{ mt: 2 }}
                            >
                              {item.pumpNo || "-"}
                            </Box>
                          </td>
                          <td className="box-wrapper-active w-250 d-flex justify-content-center align-items-center">
                            <Box
                              display={`flex`}
                              justifyContent={`center`}
                              flex={2}
                            >
                              SUM ENERGY
                            </Box>{" "}
                            <Box
                              display={`flex`}
                              justifyContent={`center`}
                              flex={2}
                            >
                              <Box className="text-primary">
                                {item.sumEnergy || "-"}
                              </Box>
                              <Box className="text-sm-black" sx={{ ml: 1 }}>
                                {item.sumEnergyUnit || "-"}
                              </Box>
                            </Box>
                          </td>
                          <td
                            className="w-150 d-flex justify-content-center align-items-center"
                            style={{ marginTop: "15px" }}
                          >
                            Power
                          </td>
                          <td className="w-150 d-flex justify-content-center align-items-center">
                            <Box className="xl-title-header">
                              {item.power || "-"}
                            </Box>
                            <Box className="text-sm-black" sx={{ ml: 1 }}>
                              {item.powerUnit || "-"}
                            </Box>
                          </td>
                          <td
                            className="w-150 d-flex justify-content-center align-items-center"
                            style={{ marginTop: "15px" }}
                          >
                            Frequency
                          </td>

                          <td className="w-150 d-flex justify-content-center align-items-center">
                            <Box className="xl-title-header">
                              {item.frequency || "-"}
                            </Box>
                            <Box className="text-sm-black" sx={{ ml: 1 }}>
                              {item.frequencyUnit || "-"}
                            </Box>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Box>
            </Box>
          </Box>

          {/* BOX#2 */}
          <Box
            className="box-wrapper"
            sx={{ mt: 1 }}
            display={`flex`}
            flex={1}
            flexDirection={`column`}
          >
            <Box display={`flex`} flexDirection={`row`}>
              {/* LEFT BOX */}
              <Box
                display={`flex`}
                flexDirection={`column`}
                justifyContent={`center`}
                alignContent={`start`}
                alignItems={`center`}
                textAlign={`left`}
                flex={1}
                sx={{ maxWidth: "80px", m: 2 }}
              >
                <Box display={`flex`}>
                  {/* ICON  */}
                  <img
                    src={PumpBrokeIcon}
                    alt="pump waste icon"
                    sx={{ cursor: "pointer" }}
                  />
                </Box>
                <Box
                  display={`flex`}
                  justifyContent={`center`}
                  alignItems={`center`}
                >
                  <Typography className="text-primary">
                    เครื่องสูบน้ำเสีย
                  </Typography>
                </Box>
              </Box>

              {/* RIGHT BOX */}
              <Box display={`flex`} flexDirection={`column`} flex={2}>
                <table
                  className=""
                  style={{
                    maxHeight: "500px",
                    overflowY: "scroll",
                    background: "#FFFFFF",
                    textAlign: "left",
                  }}
                >
                  <tbody>
                    {wasteWaterPump &&
                      wasteWaterPump.map((item, index) => (
                        <tr key={index} className="row-left">
                          <td className="text-primary w-150 d-flex justify-content-center align-items-center">
                            <Box
                              display={`flex`}
                              justifyContent={`center`}
                              alignItems={`center`}
                              sx={{ mt: 2 }}
                            >
                              {item.pumpNo || "-"}
                            </Box>
                          </td>
                          <td className="box-wrapper-active w-250 d-flex justify-content-center align-items-center">
                            <Box
                              display={`flex`}
                              justifyContent={`center`}
                              flex={2}
                            >
                              SUM ENERGY
                            </Box>{" "}
                            <Box
                              display={`flex`}
                              justifyContent={`center`}
                              flex={2}
                            >
                              <Box className="text-primary">
                                {item.sumEnergy || "-"}
                              </Box>
                              <Box className="text-sm-black" sx={{ ml: 1 }}>
                                {item.sumEnergyUnit || "-"}
                              </Box>
                            </Box>
                          </td>
                          <td
                            className="w-150 d-flex justify-content-center align-items-center"
                            style={{ marginTop: "15px" }}
                          >
                            Power
                          </td>
                          <td className="w-150 d-flex justify-content-center align-items-center">
                            <Box className="xl-title-header">
                              {item.power || "-"}
                            </Box>
                            <Box className="text-sm-black" sx={{ ml: 1 }}>
                              {item.powerUnit || "-"}
                            </Box>
                          </td>
                          <td
                            className="w-150 d-flex justify-content-center align-items-center"
                            style={{ marginTop: "15px" }}
                          >
                            Frequency
                          </td>

                          <td className="w-150 d-flex justify-content-center align-items-center">
                            <Box className="xl-title-header">
                              {item.frequency || "-"}
                            </Box>
                            <Box className="text-sm-black" sx={{ ml: 1 }}>
                              {item.frequencyUnit || "-"}
                            </Box>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
