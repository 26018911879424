import React, { useState, useEffect, useRef } from "react";

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Layout from "../../layouts/Layout";
import { GET } from "../../Utils/ApiClient";
import DropdownDateGroup from "../Common/DropdownDateGroup";
import {
  ALERT_DISMISS_TIME,
  ENERGY_USE_TYPE,
  HTTP_STATUS,
  X_AXIS_TIME_TYPE,
} from "../../Utils/EnvUtils";
import { data } from "../../uiData/UIData";
import SearchBar from "../Common/SearchBar";
import { NOTIFICATION_MESSAGE } from "../../Utils/Messages";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import PrintPage1 from "./PrintPage1";
import { CheckBox } from "@mui/icons-material";
import moment from "moment";

export default function ReportPage() {
  const navigate = useNavigate();
  const [energyUseType, setEnergyUseType] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedDay, setSelectedDay] = useState(
    moment(new Date()).format("D")
  );
  const [selectedTimeType, setSelectedTimeType] = useState(
    X_AXIS_TIME_TYPE.TIME
  );
  const [buildingTypes, setBuildingTypes] = useState([]);
  const [buildingType, setBuildingType] = useState();
  const [energyUseBuildingControl, setEnergyUseBuildingControl] = useState();
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [searchList, setSearchList] = useState();
  const [searchPhrase, setSearchPhrase] = useState();
  const [selectedData, setSelectedData] = useState();
  const [energySummary, setEnergySummary] = useState();
  const [energySummaryArray, setEnergySummaryArray] = useState([]);
  const [selectedBuildingTypes, setSelectedBuildingTypes] = useState([]);
  const [selectedBuildingControlList, setSelectedBuildingControlList] =
    useState([]);
  const [checkedBuildingControlList, setCheckedBuildingControlList] = useState(
    []
  );
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [sunburstData, setSunburstData] = useState();
  const componentRef = useRef();
  const { buildingCategories } = data;
  useEffect(() => {
    return () => {
      setEnergyUseType();
      setSelectedYear();
      setSelectedMonth();
      setSelectedDay();
      setBuildingTypes();
      setBuildingType();
      setEnergyUseBuildingControl();
      setMessage();
      setAlert();
      setSeverity();
      setSearchList();
      setSearchPhrase();
      setSelectedData();
      setEnergySummary();
      // setSelectedBuildingTypes([]);
      setCheckedBuildingControlList([]);
      setSelectedBuildingControlList([]);
    };
  }, []);

  useEffect(() => {
    const sortedList = buildingCategories.sort((a, b) =>
      a.rank > b.rank ? 1 : b.rank > a.rank ? -1 : 0
    );
    setBuildingTypes(sortedList);
  }, []);

  // FOR SEARCH BAR
  useEffect(() => {
    if (
      searchPhrase &&
      searchPhrase != "" &&
      searchList &&
      searchPhrase != (selectedData && selectedData.buildingControlName)
    ) {
      fetchBuildingInfo();
    } else {
      if (setSearchList && setSearchList.length > 0) {
        setSearchList([]);
      }
    }
  }, [searchPhrase]);

  useEffect(() => {
    if (
      energyUseType == ENERGY_USE_TYPE.ALL ||
      energyUseType == ENERGY_USE_TYPE.BY_BUILDING
    ) {
      setCheckedBuildingControlList([]);
    }

    if (energyUseType && energyUseType != ENERGY_USE_TYPE.BY_BUILDING) {
      setSelectedBuildingTypes([]);
    }

    return () => {};
  }, [energyUseType]);

  // REPORT#1
  useEffect(() => {
    if (energyUseType == ENERGY_USE_TYPE.ALL) {
      fetchReportInfo();
    }
    return () => {};
  }, [selectedYear, selectedMonth, selectedDay, energyUseType]);

  // REPORT#3
  useEffect(() => {
    if (energyUseType == ENERGY_USE_TYPE.CONTROL) {
      if (checkedBuildingControlList.length > 0) {
        if (
          checkedBuildingControlList &&
          checkedBuildingControlList.length > 0
        ) {
          fetchSunburstChartData(checkedBuildingControlList);
        }
      }
    }
    return () => {};
  }, [
    selectedYear,
    selectedMonth,
    selectedDay,
    energyUseType,
    checkedBuildingControlList,
    selectedBuildingControlList,
  ]);

  useEffect(() => {
    if (energyUseType == ENERGY_USE_TYPE.BY_BUILDING) {
      if (selectedBuildingTypes && selectedBuildingTypes.length > 0) {
        if (energyUseType == ENERGY_USE_TYPE.BY_BUILDING) {
          fetchEnergyUseInfoByBuildingTypes();
        }
      }
    }
    return () => {
      // setSelectedBuildingTypes([])
    };
  }, [
    selectedYear,
    selectedMonth,
    selectedDay,
    energyUseType,
    selectedBuildingTypes,
  ]);

  // useEffect(() => {
  //   if (checkedBuildingControlList.length > 0) {
  //     console.log('fetching data report 3');
  //     if(checkedBuildingControlList && checkedBuildingControlList.length > 0 && energyUseType == ENERGY_USE_TYPE.CONTROL) {
  //       fetchSunburstChartData(checkedBuildingControlList);
  //     }
  //   }
  //   return () => {
  //   }
  // }, [selectedYear, selectedMonth, selectedDay, selectedBuildingControlList])

  // useEffect(() => {
  //   console.log('year : ', selectedYear);
  //   console.log('month : ', selectedMonth );
  //   console.log('day : ', selectedDay);
  //   console.log('selected building type : ',selectedBuildingTypes);
  //   if (
  //     selectedBuildingTypes &&
  //     selectedBuildingTypes.length > 0
  //   ) {
  //     if (energyUseType == ENERGY_USE_TYPE.BY_BUILDING) {
  //       fetchEnergyUseInfoByBuildingTypes();
  //     }
  //   }
  //   return () => {};
  // }, [
  //   selectedYear,
  //   selectedMonth,
  //   selectedDay,
  //   energyUseType,
  //   selectedBuildingTypes,
  // ]);
  useEffect(() => {
    if (
      selectedBuildingControlList.length ===
        checkedBuildingControlList.length &&
      checkedBuildingControlList.length > 0
    ) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
    return () => {};
  }, [selectedBuildingControlList, checkedBuildingControlList]);

  async function fetchBuildingInfo() {
    try {
      const res = await GET(
        `building${!searchPhrase || searchPhrase == "" ? "" : `?search=`}${
          searchPhrase || ""
        }`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setSearchList(data);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchReportInfo() {
    try {
      const res = await GET(
        `report/energySummary/${selectedYear}/${selectedMonth}/${selectedDay}`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setEnergySummary(data);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchEnergyUseInfoByBuildingTypes() {
    try {
      let buildingTypeString = "";
      // buildingTypeId=1&buildingTypeId=2
      selectedBuildingTypes.map((item, index) => {
        let emphasand = "&";
        if (index == 0) {
          emphasand = "";
        }
        buildingTypeString += `${emphasand}buildingTypeId=${item.buildingTypeId}`;
      });
      const res = await GET(
        `report/energyByBuildingType/${selectedYear}/${selectedMonth}/${selectedDay}${
          selectedBuildingTypes && selectedBuildingTypes.length > 0
            ? `?${buildingTypeString}`
            : null
        }`
      );
      const { data, status } = res;
      const { message, success } = data;
      if (status == HTTP_STATUS.OK) {
        setEnergySummaryArray(data);
      }
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  async function fetchSunburstChartData(list) {
    try {
      if (checkedBuildingControlList.length === 0) return;
      let buildingString = "";
      // buildingTypeId=1&buildingTypeId=2
      checkedBuildingControlList.map((item, index) => {
        let emphasand = "&";
        if (index == 0) {
          emphasand = "";
        }
        buildingString += `${emphasand}buildingId=${item.buildingId}`;
      });
      const res = await GET(
        `report/energyByBuilding/${selectedYear}/${selectedMonth}/${selectedDay}${
          checkedBuildingControlList && checkedBuildingControlList.length > 0
            ? `?${buildingString}`
            : null
        }`
      );
      const { data, status } = res;
      setSunburstData(data);
    } catch (error) {
      console.log(error);
      setAlert(true);
      setMessage(NOTIFICATION_MESSAGE.ERROR);
      setSeverity("error");
      setTimeout(() => {
        setAlert(false);
      }, ALERT_DISMISS_TIME);
    }
  }

  function handleChangeEnergyUseType(e) {
    setEnergyUseType(e.target.value);
    if (e.target.value != ENERGY_USE_TYPE.CONTROL) {
      setSelectedBuildingControlList([]);
      setCheckedBuildingControlList([]);
    }
  }

  function onSelectYearHandler(year) {
    setSelectedYear(year);
    const daysInMonth = moment(
      new Date(`${year}-${selectedMonth}-01`)
    ).daysInMonth();
    if (selectedDay <= daysInMonth) {
      setSelectedDay(selectedDay);
    } else {
      const d = moment(new Date(`${year}-${selectedMonth}-${daysInMonth}`)).format(
        "D"
      );
      setSelectedDay(d);
    }
  }

  function onSelectMonthHandler(e) {
    const theMonth = e < 0 ? parseInt(e) : parseInt(e) + 1;
    if (theMonth < 0) {
      setSelectedDay(-1);
      // show 12 months
      setSelectedTimeType(X_AXIS_TIME_TYPE.MONTH);
    } else if (selectedDay < 0) {
      setSelectedTimeType(X_AXIS_TIME_TYPE.DAY);
    } else {
      setSelectedTimeType(X_AXIS_TIME_TYPE.TIME);
      const daysInMonth = moment(new Date(`${selectedYear}-${theMonth}-01`)).daysInMonth();
      // if(theMonth === 2) {
      //   if(selectedDay <= daysInMonth) {
      //     setSelectedDay(selectedDay);
      //   } else {
      //     const d = moment(new Date(`${selectedYear}-${theMonth}-${daysInMonth}`)).format("D");
      //     setSelectedDay(
      //       d
      //     );
      //   }        
      // } else {
      //   setSelectedDay(selectedDay);
      // }
      if (selectedDay <= daysInMonth) {
        setSelectedDay(selectedDay);
      } else {
        const d = moment(new Date(`${selectedYear}-${theMonth}-${daysInMonth}`)).format(
          "D"
        );
        setSelectedDay(d);
      }
    }
    setSelectedMonth(theMonth);
  }

  function onSelectDayHandler(e) {
    if (e < 0) {
      setSelectedDay(e);
      // show 31 days
      setSelectedTimeType(X_AXIS_TIME_TYPE.DAY);
    } else {
      setSelectedTimeType(X_AXIS_TIME_TYPE.TIME);
    }
    setSelectedDay(e);
  }

  function onChangeBuildingTypeHandler(e) {
    setBuildingType(e.target.value);
  }

  function onChangeEnergyUseBuildingControlHandler(e) {
    setEnergyUseBuildingControl(e.target.value);
  }

  // ###### START: REGION: SEARCH
  function onSearchHandle() {
    fetchBuildingInfo();
  }
  function handleOnSearchPhraseChanging(e) {
    e.preventDefault();
    const text = e.target.value;
    setSearchPhrase(text);
  }
  function hitEnter(e) {
    if (e.key === "Enter") {
      fetchBuildingInfo();
    }
  }
  function onSelectedHandler(item) {
    setSelectedData(item);
    setSearchPhrase("");
    setSearchList([]);
    const existingBuilding = selectedBuildingControlList.filter(
      (x) => x.tsicId === item.tsicId
    );
    if (!existingBuilding || existingBuilding.length === 0) {
      selectedBuildingControlList.push(item);
    } else {
      console.log("duplicated building", selectedBuildingControlList);
    }
  }

  function onCheckBoxBuildingTypeSelected(e) {
    const checked = e.target.checked;
    const buildingType = e.target.value;
    let arr = selectedBuildingTypes;
    if (checked) {
      // arr.push({ buildingTypeId: Number(buildingType) });
      setSelectedBuildingTypes((myarr) => [
        ...myarr,
        { buildingTypeId: Number(buildingType) },
      ]);
    } else {
      setSelectedBuildingTypes((myarr) =>
        myarr.filter((x) => x.buildingTypeId != buildingType)
      );
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // POC
  function printHtmlToPdf() {
    navigate("/report2", {
      state: {
        energySummary,
        selectedYear,
        selectedMonth,
        selectedDay,
        selectedTimeType,
        reportType: energyUseType,
        energySummaryArray,
      },
    });
  }

  function onCheckHandler(event, item) {
    const checked = event.target.checked;
    if (checked) {
      setCheckedBuildingControlList((myarr) => [...myarr, item]);
    } else {
      setCheckedBuildingControlList((myarr) =>
        myarr.filter((x) => x.buildingId !== item.buildingId)
      );
    }
  }

  function onCheckAllHandler(event) {
    const checked = event.target.checked;
    setCheckedBuildingControlList([]);
    if (checked) {
      selectedBuildingControlList.map((item, index) => {
        setCheckedBuildingControlList((myarr) => [...myarr, item]);
      });
    } else {
      setCheckedBuildingControlList([]);
    }

    // if(checkedBuildingControlList.length === selectedBuildingControlList.length) { setIsCheckAll(true)} else {setIsCheckAll(false) }
  }

  return (
    <>
      <Layout>
        <Box
          id="report"
          display={`flex`}
          flexDirection={`column`}
          className={`box-wrapper`}
          sx={{ p: 2 }}
        >
          <Box display={`flex`}>
            <Typography className="subtitle-header">การสร้างรายงาน</Typography>
          </Box>

          <Box display={`flex`}>
            <DropdownDateGroup
              title={`เลือกช่วงเวลาของข้อมูล`}
              onSelectYearHandler={onSelectYearHandler}
              onSelectMonthHandler={onSelectMonthHandler}
              onSelectDayHandler={onSelectDayHandler}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              initDate={selectedDay}
            />
          </Box>

          <Box display={`flex`} sx={{ mt: 1 }}>
            <Typography className="text-black">เลือกระดับของรายงาน</Typography>
          </Box>

          <Box display={`flex`} flexDirection={`row`} sx={{ mt: 1 }}>
            <Box display={`flex`} sx={{ ml: 0, mt: 1 }} flex={1}>
              <RadioGroup
                aria-labelledby="energy-use-type"
                name="energyUseType"
                value={energyUseType}
                onChange={handleChangeEnergyUseType}
                className="text-primary"
              >
                <Box display={`flex`} flexDirection={`row`}>
                  <Box display={`flex`} sx={{ mt: 1 }}>
                    <FormControlLabel
                      value={ENERGY_USE_TYPE.ALL}
                      control={<Radio />}
                      label="การใช้พลังงานระดับประเทศ"
                    />
                  </Box>
                </Box>

                <Box display={`flex`} flexDirection={`column`} sx={{ mt: 1 }}>
                  <Box display={`flex`}>
                    <FormControlLabel
                      value={ENERGY_USE_TYPE.BY_BUILDING}
                      control={<Radio />}
                      label="การใช้พลังงานแยกตามประเภทอาคาร"
                    />
                  </Box>
                  {energyUseType == ENERGY_USE_TYPE.BY_BUILDING && (
                    <Box display={`flex`}>
                      <RadioGroup
                        aria-labelledby="building-type"
                        name="buildingType"
                        value={buildingType}
                        onChange={onChangeBuildingTypeHandler}
                        className="text-primary"
                        defaultValue={buildingType}
                      >
                        <Box
                          display={`flex`}
                          flexDirection={`row`}
                          justifyContent={`space-between`}
                        >
                          {buildingTypes &&
                            buildingTypes.map((item, index) => (
                              <FormControlLabel
                                key={index}
                                sx={{ ml: 4 }}
                                value={item.buildingTypeId}
                                control={
                                  <Checkbox
                                    className={`squared-radio`}
                                    onChange={(e) =>
                                      onCheckBoxBuildingTypeSelected(e)
                                    }
                                  />
                                }
                                label={item.pillText}
                              />
                            ))}
                        </Box>
                      </RadioGroup>
                    </Box>
                  )}
                </Box>

                <Box display={`flex`} flexDirection={`column`} sx={{ mt: 2 }}>
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="การใช้พลังงานระดับอาคารควบคุม"
                  />
                </Box>
              </RadioGroup>
            </Box>
          </Box>

          {energyUseType == 2 && (
            <>
              {/* SEARCH FORM HERE */}
              <Box display={`flex`} flexDirection={`column`} sx={{ p: 2 }}>
                <SearchBar
                  list={searchList}
                  searchPhrase={searchPhrase}
                  onSearchHandle={onSearchHandle}
                  handleOnSearchPhraseChanging={handleOnSearchPhraseChanging}
                  hitEnter={hitEnter}
                  onSelectedHandler={onSelectedHandler}
                  placeholder={`ค้นหา`}
                />
              </Box>
              {/* RESULT TABLE */}
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`start`}
              >
                <Box display={`flex`} flex={1} justifyContent={`center`}>
                  <table
                    className="table-light"
                    style={{ overflowY: "scroll" }}
                  >
                    <thead>
                      <tr className="d-flex d-flex justify-content-start">
                        <th className="w-250">
                          <Checkbox
                            className={`squared-checkbox`}
                            id={`selected-all`}
                            color="primary"
                            onChange={(event) => onCheckAllHandler(event)}
                            disabled={selectedBuildingControlList.length === 0}
                            checked={isCheckAll}
                          />
                        </th>
                        <th className="w-250">รายการ</th>
                        <th className="w-250">TSIC-ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedBuildingControlList &&
                        selectedBuildingControlList.length > 0 &&
                        selectedBuildingControlList.map((item, index) => (
                          <tr
                            className="d-flex justify-content-start align-items-center"
                            key={index}
                            style={{ textAlign: "center" }}
                          >
                            <td className="w-250">
                              <Checkbox
                                className={`squared-checkbox`}
                                id={`selected-${index}`}
                                color="primary"
                                onChange={(event) => {
                                  onCheckHandler(event, item);
                                }}
                                checked={
                                  checkedBuildingControlList.filter(
                                    (x) => x.buildingId == item.buildingId
                                  ).length > 0
                                    ? true
                                    : false
                                }
                              />
                            </td>
                            <td className="w-250">
                              {item.buildingControlName}
                            </td>
                            <td className="w-250">{item.tsicId}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Box>
              </Box>
            </>
          )}

          {/* START REPORT 1 */}
          {energyUseType == ENERGY_USE_TYPE.ALL && (
            <Box display={`flex`} flexDirection={`column`} sx={{ p: 2 }}>
              <Box display={`flex`} justifyContent={`flex-end`}>
                <Button variant="contained" onClick={handlePrint}>
                  เรียกรายงาน
                </Button>
              </Box>
              <Box display={`flex`}>
                {energyUseType == ENERGY_USE_TYPE.ALL && (
                  <div style={{ visibility: "hidden", height: "20px" }}>
                    <PrintPage1
                      energySummary={energySummary}
                      selectedYear={selectedYear}
                      selectedMonth={selectedMonth}
                      selectedDay={selectedDay}
                      selectedTimeType={selectedTimeType}
                      reportType={energyUseType}
                      ref={componentRef}
                    />
                  </div>
                )}
              </Box>
            </Box>
          )}
          {/* END REPORT 1 */}

          {/* START REPORT 2 */}
          {energyUseType == ENERGY_USE_TYPE.BY_BUILDING &&
            energySummaryArray &&
            energySummaryArray.length > 0 && (
              <Box display={`flex`} flexDirection={`column`} sx={{ p: 2 }}>
                <Box display={`flex`} justifyContent={`flex-end`}>
                  <Button
                    variant="contained"
                    onClick={handlePrint}
                    disabled={
                      !selectedBuildingTypes &&
                      selectedBuildingTypes.length == 0
                        ? true
                        : false
                    }
                  >
                    เรียกรายงาน
                  </Button>
                </Box>
                <Box display={`flex`}>
                  {energyUseType == ENERGY_USE_TYPE.BY_BUILDING && (
                    <div style={{ visibility: "hidden", height: "20px" }}>
                      <PrintPage1
                        energySummary={energySummary}
                        selectedYear={selectedYear}
                        selectedMonth={selectedMonth}
                        selectedDay={selectedDay}
                        selectedTimeType={selectedTimeType}
                        reportType={energyUseType}
                        energySummaryArray={energySummaryArray}
                        ref={componentRef}
                      />
                    </div>
                  )}
                </Box>
              </Box>
            )}
          {/* END REPORT 2 */}

          {/* START REPORT 3 */}
          {energyUseType == ENERGY_USE_TYPE.CONTROL && (
            <Box display={`flex`} flexDirection={`column`} sx={{ p: 2 }}>
              <Box display={`flex`} justifyContent={`flex-end`}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setTimeout(() => {
                      handlePrint();
                    }, 500);
                  }}
                  disabled={
                    checkedBuildingControlList &&
                    checkedBuildingControlList.length === 0
                  }
                >
                  เรียกรายงาน
                </Button>
              </Box>
              <Box display={`flex`}>
                {energyUseType == ENERGY_USE_TYPE.CONTROL && (
                  <div style={{ visibility: "hidden", height: "20px" }}>
                    <PrintPage1
                      energySummary={energySummary}
                      selectedYear={selectedYear}
                      selectedMonth={selectedMonth}
                      selectedDay={selectedDay}
                      selectedTimeType={selectedTimeType}
                      reportType={energyUseType}
                      energySummaryArray={energySummaryArray}
                      sunburstData={sunburstData}
                      ref={componentRef}
                    />
                  </div>
                )}
              </Box>
            </Box>
          )}
          {/* END REPORT 3 */}
        </Box>
      </Layout>
    </>
  );
}
